import { Formik } from 'formik'
import { DatePicker, Form, Input, SubmitButton } from 'formik-antd'
import React, { useContext } from 'react'
import StoreContext from '../../../storeContext'
import intl from 'react-intl-universal'
import CountriesDropdown from '../../shared/CountriesDropdown'
import * as Yup from 'yup'
import { Modal } from 'antd'

export default function SingleRequest() {
  const { vetting } = useContext(StoreContext)
  const initValues = {
    first_name: '',
    last_name: '',
    nationality: '',
    birth_date: '',
    residence: '',
  }
  const validation = Yup.object().shape({
    first_name: Yup.string().required(intl.get('nameErrorRequired')),
    last_name: Yup.string().required(intl.get('lastNameRequired')),
    nationality: Yup.string().required(intl.get('nationalityRequired')),
    birth_date: Yup.string().required(intl.get('birthDateRequired')),
  })
  const confirmModal = (vals, reset) => {
    Modal.confirm({
      title: intl.get('requestResourceVetting'),
      content: intl.get('requestResourceVettingText'),
      okText: intl.get('request'),
      onOk: async () => {
        await vetting.sendRequest({ people: [vals] })
        reset()
      },
    })
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Formik
        initialValues={initValues}
        validationSchema={validation}
        onSubmit={(vals, props) => {
          props.setSubmitting(false)
          confirmModal(vals, props.resetForm)
        }}
      >
        {({ setFieldValue }) => (
          <Form style={{ flex: '1 1 0' }}>
            <Form.Item name="first_name">
              <Input name="first_name" addonBefore={intl.get('nameLabel')} />
            </Form.Item>
            <Form.Item name="last_name">
              <Input addonBefore={intl.get('lastName')} name="last_name" />
            </Form.Item>

            <Form.Item name="birth_date">
              <DatePicker
                style={{ width: '100%' }}
                name="birth_date"
                placeholder={intl.get('birthDateLabel')}
                onChange={(date) => {
                  setFieldValue('birth_date', date.format('MM/DD/YYYY'))
                }}
              />
            </Form.Item>
            <Form.Item name="nationality">
              <CountriesDropdown
                label="nationality"
                name="nationality"
                placeholder={intl.get('nationalityLabel')}
              />
            </Form.Item>

            <Form.Item name="residence">
              <CountriesDropdown
                label="en_short_name"
                name="residence"
                placeholder={intl.get('residenceLabel')}
              />
            </Form.Item>
            <div className="ant-row">
              <SubmitButton style={{ width: '100%' }} disabled={false}>
                {intl.get('sendRequest')}
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
