import React from 'react'

const AuthorizationContext = React.createContext(undefined)

export const AuthorizationProvider = AuthorizationContext.Provider

export const useAuthorization = () => {
  const authorization = React.useContext(AuthorizationContext)
  if (authorization === undefined) {
    throw new Error('useAuthorization must be used within a AuthorizationProvider')
  }
  return authorization
}