import React, { useContext, useEffect } from "react";
import { Formik } from "formik";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import * as Yup from "yup";
import StoreContext from "../../storeContext";
import { SurveyTypes } from "../../constants/dropdowns";
import AddRisk from "./AddRisk";
import { action } from "mobx";
import { observer } from "mobx-react";

export default observer(function ({ close }) {
  const { survey } = useContext(StoreContext)
  useEffect(() => {
    action(() => {
      survey.editingSurvey = null
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const init = {
    survey_name: '',
    survey_code: '',
    survey_type: '',
  }
  const validation = Yup.object().shape({
    survey_name: Yup.string().required('Name is required'),
    survey_code: Yup.string()
      .required('Survey is required')
      .test('is_valid_code', 'The JSON code is not valid', (value) => {
        try {
          const code = JSON.parse(value)
          if (!code.pages) return false
          return true
        } catch (e) {
          return false
        }
      }),
    survey_type: Yup.string().required('Survey type is required'),
  })
  const onChangeCode = (e) => {
    action(() => {
      try {
        const temp = JSON.parse(e.target.value)
        if (temp.pages) survey.editingSurvey = temp
      } catch (e) {}
    })()
  }

  return (
    <>
      <Formik
        initialValues={init}
        validationSchema={validation}
        onSubmit={async (vals) => {
          await survey.survey(
            {
              add: {
                ...vals,
                survey_code: JSON.stringify(survey.editingSurvey),
              },
            },
            'add',
          )
          close()
        }}
      >
        {({ setFieldValue, setFieldError }) => (
          <Form>
            <Form.Item name="survey_name">
              <Input addonBefore="Survey name" name="survey_name" />
            </Form.Item>
            <Form.Item name="survey_code">
              <Input.TextArea
                placeholder="Survey JSON"
                rows={3}
                name="survey_code"
                onChange={onChangeCode}
              />
            </Form.Item>
            <Form.Item name="survey_type">
              <Select placeholder="Select survey type" name="survey_type">
                {SurveyTypes.map((type) => (
                  <Select.Option value={type.value}>{type.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <SubmitButton>Add</SubmitButton>
          </Form>
        )}
      </Formik>
      <AddRisk />
    </>
  )
})
