import React, { useContext } from 'react'
import { STATIC_SERVER_URL } from '../../constants/LayoutSetting'
import { Avatar } from 'antd'
import StoreContext from '../../storeContext'
import { observer } from 'mobx-react'

export default observer(function ({ size = '40', id = 'notlogged' }) {
  const { profile } = useContext(StoreContext)
  return (
    <Avatar
      src={`${STATIC_SERVER_URL}${id}.jpg?key=${
        profile.imagesRefreshers[id] || new Date().getTime()
      }`}
      className={`gx-size-${size} gx-pointer gx-mr-3`}
      alt=""
    />
  )
})
