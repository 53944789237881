import React, { useState } from 'react'
import { Button, message, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

export default function ({ buttonText, setFile, remove, errorMessage }) {
  const [fileList, setFileList] = useState([])
  function onFileChange(file) {
    if (!file.name.includes('.pdf')) {
      message.error(errorMessage)
      return false
    }
    setFileList([file])
    const reader = new FileReader()
    reader.onload = () => {
      setFile(reader.result.split(',')[1])
    }
    reader.readAsDataURL(file)
    return false
  }
  function onRemoveFile() {
    setFileList([])
    remove()
  }
  return (
    <Upload
      onRemove={() => onRemoveFile()}
      beforeUpload={(file) => onFileChange(file)}
      fileList={fileList}
      className="upload-terms"
    >
      <Button style={{ width: '100%' }}>
        <UploadOutlined />
        {buttonText}
      </Button>
    </Upload>
  )
}
