import React, { useEffect, useRef, useState } from 'react'
import { Form, Input, SubmitButton } from 'formik-antd'
import intl from 'react-intl-universal'
import { Button } from 'antd'
import { Formik } from 'formik'

export default function ({
  name,
  value,
  icon,
  label,
  validator,
  callback,
  locked,
  component,
  type,
  noValueLabel = 'No data',
  valueLabel,
  init,
  withoutEdit = false,
}) {
  const [edit, setEdit] = useState(false)
  const input = useRef()
  const initialValues = init
    ? init
    : {
        [name]: value,
      }
  useEffect(() => {
    if (edit && input.current) input.current.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit])
  return (
    <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
      {type !== 'cell' && (
        <div className="gx-mr-3" style={{ paddingTop: 23 }}>
          <i className={`icon icon-${icon} gx-fs-xxl gx-text-grey`} />
        </div>
      )}
      <div className="gx-media-body">
        {type !== 'cell' && (
          <span className="gx-mb-0 gx-text-grey gx-fs-sm">{label}</span>
        )}
        <div className="edit-profile-field-wrapper">
          {edit ? (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={validator}
                onSubmit={(vals) => {
                  callback(vals, setEdit)
                }}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form>
                    <Form.Item name={name} style={{ flex: '1 1 0' }}>
                      {component ? (
                        component(
                          setFieldValue,
                          isSubmitting,
                          setEdit,
                          values[name],
                        )
                      ) : (
                        <Input
                          disabled={isSubmitting}
                          ref={input}
                          name={name}
                          placeholder={label}
                        />
                      )}
                    </Form.Item>

                    <div
                      style={{
                        display: 'flex',
                        flex: '0.5 1 0',
                        flexWrap: 'wrap',
                        gap: 5,
                        alignContent: 'start',
                        justifyItems: 'start',
                      }}
                    >
                      {!withoutEdit && (
                        <SubmitButton
                          style={{ padding: 0, margin: 0 }}
                          type="link"
                        >
                          {intl.get('save')}
                        </SubmitButton>
                      )}
                      <Button
                        style={{ padding: 0, margin: 0 }}
                        type="link"
                        onClick={() => setEdit(!edit)}
                      >
                        {intl.get('cancel')}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <>
              <div className="gx-mb-0">
                {valueLabel ? valueLabel : value ? value : noValueLabel}
              </div>
              {!locked && (
                <Button type="link" onClick={() => setEdit(!edit)}>
                  {intl.get('edit')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
