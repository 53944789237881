import { Card, Col, Space } from 'antd'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import intl from 'react-intl-universal'
import StoreContext from '../../../../../../storeContext'
import InfoPopUp from '../../../../../InfoPopUp'
import Rating from '../../Rating'
import IndicatorItem from '../IndicatorItem'
import BcmInfo from './BcmInfo'
import CyberItInfo from './CyberItInfo'
import DeliveryInfo from './DeliveryInfo'
import QualityInfo from './QualityInfo'

export default observer(function () {
  const { companyProfile } = useContext(StoreContext)
  if (!companyProfile.company.operational_risks) return null
  return (
    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
      <Card
        type="inner"
        bordered={false}
        title={intl.get('operationalRisks')}
        extra={
          <div style={{ display: 'flex' }}>
            <InfoPopUp
              title={intl.get('operationalRisks')}
              html={intl.get('operationalRisksInfo')}
            />
            <div style={{ marginRight: 5 }} />
            <Rating
              score={companyProfile.company.operational_risks.risk_rating}
              trafficLight={true}
            />
          </div>
        }
      >
        {/* {intl.get('financialRisksEmptyText')}*/}
        <Space direction="vertical" style={{ width: '100%' }}>
          {companyProfile.company.operational_risks.bcm && (
            <IndicatorItem
              rating={companyProfile.company.operational_risks.bcm}
              name="BCM"
              modal={(show, setShow) => (
                <BcmInfo
                  bcm={companyProfile.company.operational_risks.bcm}
                  setShowPreview={setShow}
                  showPreview={show}
                />
              )}
            />
          )}
          {companyProfile.company.operational_risks.delivery && (
            <IndicatorItem
              rating={companyProfile.company.operational_risks.delivery}
              name="Delivery"
              modal={(show, setShow) => (
                <DeliveryInfo
                  delivery={companyProfile.company.operational_risks.delivery}
                  setShowPreview={setShow}
                  showPreview={show}
                />
              )}
            />
          )}
          {companyProfile.company.operational_risks.quality && (
            <IndicatorItem
              rating={companyProfile.company.operational_risks.quality}
              name="Quality"
              modal={(show, setShow) => (
                <QualityInfo
                  quality={companyProfile.company.operational_risks.quality}
                  setShowPreview={setShow}
                  showPreview={show}
                />
              )}
            />
          )}
          {companyProfile.company.operational_risks.cyber_it && (
            <IndicatorItem
              showView={companyProfile.company.bitsight_view}
              rating={companyProfile.company.operational_risks.cyber_it}
              name="Cyber IT"
              modal={(show, setShow) => (
                <CyberItInfo
                  cyber={companyProfile.company.operational_risks.cyber_it}
                  setShowPreview={setShow}
                  showPreview={show}
                />
              )}
            />
          )}
        </Space>
      </Card>
    </Col>
  )
})
