import { Divider, Modal, Space, Statistic } from "antd";
import moment from "moment";
import Rating from "../../Rating";
import SurveyData from "../SurveyData";
import React from "react";

export default function ({ showPreview, setShowPreview, corporate }) {
  return (
    <Modal
      title="Corporate governance"
      visible={showPreview}
      footer={null}
      destroyOnClose={true}
      onCancel={() => setShowPreview(false)}
    >
      <Space style={{ width: '100%' }}  direction={'vertical'}>
        <Statistic
          title="Date"
          value={
            corporate.questionaire_completed_date
              ? moment(corporate.questionaire_completed_date).format(
                  'DD MMMM YYYY',
                )
              : 'N/A'
          }
        />
        <Divider type="horizontal" />
        <Statistic
          title="Titan rating"
          valueRender={() => <Rating score={corporate.rating} />}
        />

        <Divider type="horizontal" />
        <Statistic
          title={`Self declaration response`}
          valueRender={() => <SurveyData questions={corporate.questions} />}
        />
      </Space>
    </Modal>
  )
}
