import { StoreBase } from '../../../store/StoreBase'
import { NetworkCall } from '../../../store/models/NetworkModels'
import { decorate, flow, observable } from 'mobx'
import intl from 'react-intl-universal'

export const ContractsStoreCalls = Object.freeze({
  GET_CONTRACTS: Object.freeze('getContracts'),
})

export class ContractsStore extends StoreBase {
  contracts = []
  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore

    this.getContractsCall = new NetworkCall(rootStore, {
      path: 'listcontracts',
      secured: true,
      id: ContractsStoreCalls.GET_CONTRACTS,
    })
  }
  updContracts = async (data, type) => {
    if (this.rootStore.companyProfile.contractsCall.callInProgress) return
    const res = await this.rootStore.companyProfile.contractsCall.call(
      'post',
      data,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          if (type === 'addFolder') {
            this.contracts = this.contracts.map((company) => {
              if (company.company_id === data.company_id)
                company.contracts = [
                  ...company.contracts,
                  result.data.contract_folder,
                ]
              return company
            })
          }
          if (type === 'editFolder') {
            this.contracts = this.contracts.map((company) => {
              if (company.company_id === data.company_id) {
                company.contracts = company.contracts.map((contract) => {
                  if (
                    contract.contract_folder_id ===
                    data.edit_folder.contract_folder_id
                  )
                    return result.data.contract_folder
                  return contract
                })
              }
              return company
            })
          }
          if (type === 'deleteFolder') {
            this.contracts = this.contracts
              .map((company) => {
                if (company.company_id === data.company_id)
                  company.contracts = company.contracts.filter(
                    (contract) =>
                      contract.contract_folder_id !==
                      data.remove_folder.contract_folder_id,
                  )
                return company
              })
              .filter((company) => company.contracts.length > 0)
          }
          if (type === 'add_document' || type === 'edit_document') {
            this.contracts = this.contracts.map((company) => {
              if (company.company_id === data.company_id) {
                company.contracts = company.contracts.map((contract) => {
                  if (
                    contract.contract_folder_id ===
                    data[type].contract_folder_id
                  ) {
                    contract.documents = result.data.contract_folder.documents
                  }
                  return contract
                })
              }
              return company
            })
          }
          if (type === 'deleteDocument') {
            this.contracts = this.contracts.map((company) => {
              if (company.company_id === data.company_id)
                company.contracts = company.contracts.map((contract) => {
                  if (
                    contract.contract_folder_id ===
                    data.remove_document.contract_folder_id
                  ) {
                    contract.documents = contract.documents.filter(
                      (document) =>
                        document.document_id !==
                        data.remove_document.document_id,
                    )
                  }
                  return contract
                })
              return company
            })
          }
          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('result.data.message'),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
      }
    })
  }
  getContracts = flow(function* () {
    if (this.getContractsCall.callInProgress) return
    const res = yield this.getContractsCall.call('get')
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.contracts = result.data.companies
          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('result.data.message'),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
      }
    })
  })
}

decorate(ContractsStore, {
  contracts: observable,
})
