import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Col, Row } from 'antd'
import './style.css'
import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined'
import RightOutlined from '@ant-design/icons/lib/icons/RightOutlined'
import { observer } from 'mobx-react'
import StoreContext from '../../../storeContext'
import CompanyIndustryCodes from './CompanyIndustryCodes'
import intl from 'react-intl-universal'
import PreferredSupplier from './PreferredSupplier'
import { MailOutlined, PrinterOutlined } from '@ant-design/icons'
import { PrintContext } from '../../../App'
import { useReactToPrint } from 'react-to-print'
import Email from '../../Email'

export default React.memo(
  observer(function ({ tab, setActive }) {
    const { layout, companyProfile } = useContext(StoreContext)
    const { printRef } = useContext(PrintContext)
    const handlePrint = useReactToPrint({
      content: () => printRef.current,
    })
    const TABS_ARRAY = [
      { name: intl.get('basic'), id: 'basic', show: true },
      { name: intl.get('personalArea'), id: 'personal', show: true },
      { name: intl.get('overview'), id: 'overview', show: true },
      { name: intl.get('details'), id: 'details', show: true },
      {
        name: intl.get('peopleAndOrganisation'),
        id: 'people-and-organization',
        show: true,
      },
      {
        name: intl.get('riskAndCompliance'),
        id: 'risk-and-compliance',
        show: true,
      },
      {
        name: intl.get('resources'),
        id: 'resources',
        show: false,
      },
      {
        name: intl.get('products'),
        id: 'products',
        show: companyProfile.company.company_type !== 1,
      },
      {
        name: intl.get('procurement'),
        id: 'procurement',
        show: true,
      },
    ]
    const [stick, setStick] = useState(false)
    const [canScrollRight, setRight] = useState(false)
    const [canScrollLeft, setLeft] = useState(false)
    const element = createRef()
    const scrollElement = createRef()
    const tabRefs = useRef([])

    useEffect(() => {
      if (tabRefs.current[tab] && stick) {
        tabRefs.current[tab].scrollIntoView({ behavior: 'smooth' })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab])

    useEffect(() => {
      if (element.current) {
        const { top } = element.current.getBoundingClientRect()
        if (
          layout.contentScrollPosition >=
          top - (layout.width > 575 ? 60 : 0)
        ) {
          setStick(true)
        } else {
          setStick(false)
        }
      }
      setCanScroll()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layout.contentScrollPosition])

    const setCanScroll = () => {
      if (scrollElement.current) {
        if (scrollElement.current.scrollLeft > 0) {
          setLeft(true)
        } else {
          setLeft(false)
        }
        if (
          scrollElement.current.scrollWidth >
          scrollElement.current.clientWidth + scrollElement.current.scrollLeft
        ) {
          setRight(true)
        } else {
          setRight(false)
        }
      }
    }

    const scrollTab = (type) => {
      if (type) {
        scrollElement.current.scrollLeft -= 200
      } else {
        scrollElement.current.scrollLeft += 200
      }
    }

    const renderTabs = () => {
      return TABS_ARRAY.filter((t) => t.show).map((t) => {
        return (
          <div
            key={t.id}
            onClick={() => setActive(t.id)}
            ref={(ref) => (tabRefs.current[t.id] = ref)}
            className={`scrollable-tab-item ${
              tab === t.id && 'scrollable-tab-item-active'
            }`}
          >
            {t.name}
          </div>
        )
      })
    }

    return (
      <Row className="scrollable">
        <Col span="24">
          <div
            className={`scrollable-wrapper ${stick && 'sticky'}`}
            style={{ width: stick && layout.contentWidth }}
            ref={element}
          >
            {layout.width > 575 && (
              <div className="scrollable-header">
                <span style={{ fontWeight: 500 }}>
                  {companyProfile.company.name}
                  <Email
                    button={(onClick) => (
                      <MailOutlined
                        style={{
                          marginLeft: 5,
                          cursor: 'pointer',
                          color: '#038fde',
                        }}
                        onClick={onClick}
                      />
                    )}
                    ids={[companyProfile.company.company_id]}
                  />
                  <PrinterOutlined
                    onClick={() => printRef.current && handlePrint()}
                    style={{
                      marginLeft: 5,
                      cursor: 'pointer',
                      color: '#038fde',
                    }}
                  />
                </span>
                <CompanyIndustryCodes />
                <PreferredSupplier />
              </div>
            )}
            <div className="scrollable-tabs">
              <LeftOutlined
                className={`scrollable-tab-arrow ${
                  canScrollLeft && 'can-scroll'
                }`}
                onClick={() => scrollTab(true)}
              />
              <div
                className="scrollable-tab-wrapper"
                ref={scrollElement}
                onScroll={() => setCanScroll()}
              >
                {renderTabs()}
              </div>
              <RightOutlined
                className={`scrollable-tab-arrow ${
                  canScrollRight && 'can-scroll'
                }`}
                onClick={() => scrollTab(false)}
              />
            </div>
          </div>
        </Col>
      </Row>
    )
  }),
)
