import React, { useContext, useState } from 'react'
import { Button, message, Modal, Upload } from 'antd'
import { DatePicker, Form, Input, Select, SubmitButton } from 'formik-antd'
import intl from 'react-intl-universal'
import { Formik } from 'formik'
import { UploadOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import { useParams } from 'react-router'
import * as Yup from 'yup'
import StoreContext from '../../../../../storeContext'

export default observer(function ({ handleOnCancel, visible }) {
  const company_id = useParams().id
  const { companyProfile } = useContext(StoreContext)
  const [fileList, setFileList] = useState([])
  function onRemoveFile(set) {
    set('story_file', '')
    setFileList([])
  }
  function onFileChange(file, set) {
    if (!file.name.includes('.pdf')) {
      message.error('File must be pdf')
      return false
    }
    setFileList([file])
    const reader = new FileReader()
    reader.onload = () => {
      set('story_file', reader.result.split(',')[1])
      set('story_file_name', file.name)
    }
    reader.readAsDataURL(file)
    return false
  }

  const validation = Yup.object().shape({
    story_title: Yup.string()
      .required(intl.get('successStoryNameRequired'))
      .max(100, intl.get('successStoryMaxTitle')),
    story_date: Yup.string().required(intl.get('successStoryDateRequired')),
    story_tags: Yup.array()
      .required(intl.get('successStoryTagsRequired'))
      .max(5, intl.get('successStoryMaxTags')),
    story_file: Yup.string().required(intl.get('storyFileRequired')),
  })

  const initialValues = {
    story_file: '',
    story_title: '',
    story_date: '',
    story_tags: [],
    story_file_name: '',
  }
  const renderTags = () =>
    companyProfile.storiesTags.map((tag) => {
      return <Select.Option value={tag}>{tag}</Select.Option>
    })

  return (
    <Modal
      title={intl.get('addSuccessStory')}
      visible={visible}
      destroyOnClose={true}
      footer={null}
      onCancel={() => {
        setFileList([])
        handleOnCancel()
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={async (vals, { setSubmitting, resetForm }) => {
          await companyProfile.updateCompany({
            success_story_add: vals,
            company_id,
          })
          setFileList([])
          resetForm()
          handleOnCancel()
        }}
      >
        {({ setFieldValue }) => {
          return (
            <Form>
              <Form.Item name="story_title">
                <Input
                  addonBefore={intl.get('successStoryTitle')}
                  name="story_title"
                  placeholder={intl.get('successStoryTitle')}
                />
              </Form.Item>
              <Form.Item name="story_tags">
                <Select
                  mode="multiple"
                  name="story_tags"
                  placeholder={intl.get('selectStoryTags')}
                >
                  {renderTags()}
                </Select>
              </Form.Item>
              <Form.Item name="story_date">
                <DatePicker
                  name="story_date"
                  picker="month"
                  onChange={(s, ss) => setFieldValue('story_date', ss)}
                  format="YYYY-MM"
                  placeholder={intl.get('selectStoryDate')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item name="story_file">
                <Upload
                  name="story_file"
                  onRemove={() => onRemoveFile(setFieldValue)}
                  beforeUpload={(file) => onFileChange(file, setFieldValue)}
                  fileList={fileList}
                >
                  <Button style={{ width: '100%' }}>
                    <UploadOutlined />
                    {intl.get('selectSuccessStoryFile')}
                  </Button>
                </Upload>
              </Form.Item>
              <SubmitButton disabled={false}>{intl.get('add')}</SubmitButton>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
})
