import intl from 'react-intl-universal'
import React, { useContext } from 'react'
import StoreContext from '../../storeContext'
import CommentCell from './DataClassification/CommentCell'
import TermsCell from './DataClassification/TermsCell'
import { CompaniesOverviewTabs } from './store/CompaniesOverviewStore'
import CreditLimitRecommendationCell from './Cmptcs/CreditLimitRecommendationCell'
import FailureScore from './Cmptcs/FailureScore'
import StandardRating from './Cmptcs/StandardRating'
import BitsightDetailsCell from './Bitsight/BitsightDetailsCell'
import DetailsCell from './Kyc/DetailsCell'
import { useObserver } from 'mobx-react-lite'
import FetchButton from './ApiImport/FetchButton'
import { Link } from 'react-router-dom'
import DunsCell from './ApiImport/DunsCell'
import ActionButton from '../shared/ActionButton'
import moment from 'moment'
import ShowHideCell from '../GeneralCriteria/ShowHideCell'

import EditProfileField from '../Profile/EditProfileField'

export const sort = (a, b, type) => {
  if (a[type] === null) return -1
  if (b[type] === null) return 1
  if (a[type].toLowerCase() < b[type].toLowerCase()) return -1
  if (a[type].toLowerCase() > b[type].toLowerCase()) return 1
  return 0
}

function TotalColumn({ title, type }) {
  const { companiesOverview } = useContext(StoreContext)
  return useObserver(() => (
    <>{`${title} (${companiesOverview.getTotalCompaniesForApi(type)})`}</>
  ))
}

function TotalDuns() {
  const { companiesOverview } = useContext(StoreContext)
  return useObserver(() => (
    <>{`${intl.get('duns')} (${companiesOverview.totalDuns})`}</>
  ))
}

export const useColumns = () => {
  const { companiesOverview } = useContext(StoreContext)

  return {
    [CompaniesOverviewTabs.DATA_CLASSIFICATION]: [
      {
        title: intl.get('columnCompanyName'),
        dataIndex: 'company_name',
        render: (text, record) => (
          <Link to={`/dashboard/company/${record.company_id}`}>
            {record.company_name}
          </Link>
        ),
        fixed: true,
        selected: true,
        sorter: (a, b) => sort(a, b, 'company_name'),
        sortDirections: ['descend', 'ascend'],
        width: 200,
      },
      {
        title: intl.get('columnCompanyBenchmark'),
        dataIndex: 'benchmark_rate_card_feature',
        render: (text, record) => (
          <ShowHideCell
            checked="remove"
            unchecked="add"
            visible={record.benchmark_rate_card_feature}
            callback={(checked) =>
              record.company_type === 2 &&
              companiesOverview.updateCompany(
                record.company_id,
                { benchmark_rate_card_feature: checked },
                'benchmark_rate_card_feature',
                checked,
              )
            }
          />
        ),
        selected: true,
        sorter: (a, b) => {
          return a === b ? 0 : a ? -1 : 1
        },
        sortDirections: ['descend', 'ascend'],
        width: 150,
      },
      {
        title: intl.get('columnCompanyResourceVetting'),
        dataIndex: 'resource_vetting_feature',
        render: (text, record) => (
          <ShowHideCell
            checked="remove"
            unchecked="add"
            visible={record.resource_vetting_feature}
            callback={(checked) =>
              companiesOverview.updateCompany(
                record.company_id,
                { resource_vetting_feature: checked },
                'resource_vetting_feature',
                checked,
              )
            }
          />
        ),
        selected: true,
        sorter: (a, b) => {
          return a === b ? 0 : a ? -1 : 1
        },
        sortDirections: ['descend', 'ascend'],
        width: 150,
      },
      {
        title: 'Id',
        dataIndex: 'company_id',
        selected: false,
        sorter: (a, b) => sort(a, b, 'company_id'),
        width: 330,
      },
      {
        title: 'Domain',
        width: 330,
        selected: true,
        dataIndex: 'company_domain',
        render: (text, record) => (
          <EditProfileField
            value={text}
            name="company_domain"
            callback={async (value, setEdit) => {
              await companiesOverview.updateCompany(
                record.company_id,
                value,
                'company_domain',
                value.company_domain,
              )
              setEdit(false)
            }}
            type="cell"
          />
        ),
      },
      {
        title: intl.get('columnCompanyType'),
        selected: true,
        dataIndex: 'company_type',
        sorter: (a, b) => sort(a, b, 'company_type_name'),
        render: (text, record) => {
          if (record.company_type === 0) return 'Both'
          return record.company_type === 1
            ? intl.get('buyerLabel')
            : intl.get('sellerLabel')
        },
        width: 100,
      },
      {
        title: intl.get('columnCompanySize'),
        dataIndex: 'company_size',
        sorter: (a, b) => sort(a, b, 'company_size'),
        selected: true,
        width: 100,
        render: (text, record) =>
          record.company_size ? record.company_size : 'No data',
      },
      {
        title: intl.get('columnCompanyCountry'),
        dataIndex: 'country',
        sorter: (a, b) => sort(a, b, 'country'),
        selected: true,
        width: 100,
      },
      {
        title: intl.get('columnEmployeeNumber'),
        dataIndex: 'employee_count',
        sorter: (a, b) => a['employee_count'] - b['employee_count'],
        render: (text, record) =>
          record.employee_count ? record.employee_count : 'No data',
        selected: true,
        width: 100,
      },
      {
        title: intl.get('dateCreatedColumn'),
        dataIndex: 'date_created',
        sorter: (a, b) =>
          new Date(a['date_created']) - new Date(b['date_created']),
        render: (text, record) =>
          new Date(record['date_created']).toLocaleString(),
        selected: true,
        width: 200,
      },
      {
        title: intl.get('columnCompanyIndustry'),
        sorter: (a, b) => a['industry_code'] - b['industry_code'],
        dataIndex: 'industry_code_description',
        selected: true,
        width: 200,
      },
      {
        title: intl.get('termsVersionColumn'),
        selected: true,
        dataIndex: 'terms_version',
        render: (text, record) => <TermsCell record={record} />,
        sorter: (a, b) => sort(a, b, 'terms_version'),
        width: 300,
      },
      {
        title: intl.get('commentColumn'),
        selected: true,
        dataIndex: 'comment',
        render: (text, record) => <CommentCell record={record} />,
        sorter: (a, b) => sort(a, b, 'comment'),
        width: 200,
      },
      {
        title: intl.get('numberOfProducts'),
        selected: true,
        dataIndex: 'product_count',
        sorter: (a, b) => a['product_count'] - b['product_count'],
        width: 100,
      },
      {
        title: intl.get('companyUserCountColumn'),
        selected: true,
        dataIndex: 'user_count',
        sorter: (a, b) => a['user_count'] - b['user_count'],
        width: 100,
      },
      {
        title: intl.get('lastLoginDate'),
        selected: true,
        dataIndex: 'last_login_date',
        render: (text, record) =>
          record['last_login_date']
            ? moment
                .utc(record['last_login_date'])
                .local()
                .format('MM/DD/YYYY, HH:mm:ss')
            : intl.get('noData'),
        sorter: (a, b) => {
          if (a['last_login_date'] && !b['last_login_date']) {
            return 1
          }
          if (!a['last_login_date'] && b['last_login_date']) {
            return -1
          }
          if (a['last_login_date'] && b['last_login_date'])
            return (
              new Date(a['last_login_date']) - new Date(b['last_login_date'])
            )
          return 0
        },
        width: 200,
      },
      {
        title: intl.get('lastLoginUser'),
        selected: true,
        dataIndex: 'last_login_user',
        render: (text, record) =>
          record['last_login_user'] || intl.get('noData'),
        sorter: (a, b) => sort(a, b, 'last_login_user'),
        width: 250,
      },
      {
        title: intl.get('dateTermsAcceptedColumn'),
        dataIndex: 'terms_accepted_date',
        sorter: (a, b) => {
          if (!a['terms_accepted_date']) return -1
          if (!b['terms_accepted_date']) return 1
          return (
            new Date(a['terms_accepted_date']) -
            new Date(b['terms_accepted_date'])
          )
        },
        render: (text, record) =>
          record['terms_accepted_date']
            ? moment
                .utc(record['terms_accepted_date'])
                .local()
                .format('MM/DD/YYYY, HH:mm:ss')
            : 'No info',
        selected: true,
        width: 200,
      },
      {
        title: 'Action',
        sorter: (a, b) => a['enabled'] - b['enabled'],
        render: (text, record) => (
          <>
            <ActionButton
              record={record}
              type={record.enabled ? '' : 'primary'}
              text={record.enabled ? intl.get('disable') : intl.get('enable')}
              onClick={() =>
                companiesOverview.updateCompany(
                  record.company_id,
                  {
                    enabled: record.enabled ? 'disable' : 'enable',
                  },
                  'enabled',
                  !record.enabled,
                )
              }
            />
            <ActionButton
              record={record}
              text={intl.get('delete')}
              showModal={true}
              confirmTitle={intl.get('deleteCompanyConfirmation')}
              onClick={() => companiesOverview.delete(record.company_id)}
            />
          </>
        ),
        selected: true,
        dataIndex: 'action',
        noCSV: true,
        fixed: 'right',
        width: 250,
      },
    ],
    [CompaniesOverviewTabs.API_IMPORT]: [
      {
        title: intl.get('columnCompanyName'),
        dataIndex: 'company_name',
        fixed: true,
        selected: true,
        sorter: (a, b) => sort(a, b, 'company_name'),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: <TotalDuns />,
        dataIndex: 'duns',
        selected: true,
        render: (text, record) => <DunsCell record={record} />,
        sorter: (a, b) => sort(a, b, 'duns'),
      },
      {
        title: intl.get('columnCompanyType'),
        selected: true,
        dataIndex: 'company_type',
        sorter: (a, b) => sort(a, b, 'company_type_name'),
        render: (text, record) => {
          if (record.company_type === 0) return 'Both'
          return record.company_type === 1
            ? intl.get('buyerLabel')
            : intl.get('sellerLabel')
        },
      },
      {
        title: (
          <TotalColumn
            title={intl.get('cmptcs')}
            type="biznode_cmptcs_profile"
          />
        ),
        dataIndex: 'biznode_cmptcs_profile',
        className: 'button-column',
        selected: true,
        sorter: (a, b) =>
          a['biznode_cmptcs_profile'] - b['biznode_cmptcs_profile'],
        render: (text, record) => (
          <FetchButton
            record={record}
            disabled={!record.duns}
            isReload={record.biznode_cmptcs_profile > 0}
            onClick={() =>
              companiesOverview.fetchCMPTCS([
                { company_id: record.company_id, duns: record.duns },
              ])
            }
          />
        ),
      },
      {
        title: intl.get('cmptcsImportDate'),
        selected: true,
        dataIndex: 'cmptcs_import_date',
        render: (text, record) =>
          record['cmptcs_import_date']
            ? moment
                .utc(record['cmptcs_import_date'])
                .local()
                .format('MM/DD/YYYY, HH:mm:ss')
            : intl.get('noData'),
        sorter: (a, b) => {
          if (a['cmptcs_import_date'] && !b['cmptcs_import_date']) {
            return 1
          }
          if (!a['cmptcs_import_date'] && b['cmptcs_import_date']) {
            return -1
          }
          if (a['cmptcs_import_date'] && b['cmptcs_import_date'])
            return (
              new Date(a['cmptcs_import_date']) -
              new Date(b['cmptcs_import_date'])
            )
          return 0
        },
        width: 200,
      },
      {
        title: <TotalColumn title="Bitsight" type="bitsight_profile" />,
        selected: true,
        className: 'button-column',
        dataIndex: 'bitsight_profile',
        sorter: (a, b) => a['bitsight_profile'] - b['bitsight_profile'],
        render: (text, record) => (
          <FetchButton
            record={record}
            isReload={record.bitsight_profile > 0}
            onClick={() =>
              companiesOverview.fetchBitsight([
                { company_id: record.company_id },
              ])
            }
          />
        ),
      },
      {
        title: intl.get('cmpelkImportDate'),
        selected: true,
        dataIndex: 'cmpelk_import_date',
        render: (text, record) =>
          record['cmpelk_import_date']
            ? moment
                .utc(record['cmpelk_import_date'])
                .local()
                .format('MM/DD/YYYY, HH:mm:ss')
            : intl.get('noData'),
        sorter: (a, b) => {
          if (a['cmpelk_import_date'] && !b['cmpelk_import_date']) {
            return 1
          }
          if (!a['cmpelk_import_date'] && b['cmpelk_import_date']) {
            return -1
          }
          if (a['cmpelk_import_date'] && b['cmpelk_import_date'])
            return (
              new Date(a['cmpelk_import_date']) -
              new Date(b['cmpelk_import_date'])
            )
          return 0
        },
        width: 200,
      },
      {
        title: <TotalColumn title="KYC" type="kyc_profile" />,
        selected: true,
        className: 'button-column',
        sorter: (a, b) => a['kyc_profile'] - b['kyc_profile'],
        dataIndex: 'kyc_profile',
        width: 150,
        render: (text, record) => (
          <FetchButton
            record={record}
            isReload={record.kyc_profile > 0}
            onClick={() =>
              companiesOverview.fetchKYC([{ company_id: record.company_id }])
            }
          />
        ),
      },
      {
        title: intl.get('kycImportDate'),
        selected: true,
        dataIndex: 'kyc_import_date',
        render: (text, record) =>
          record['kyc_import_date']
            ? moment
                .utc(record['kyc_import_date'])
                .local()
                .format('MM/DD/YYYY, HH:mm:ss')
            : intl.get('noData'),
        sorter: (a, b) => {
          if (a['kyc_import_date'] && !b['kyc_import_date']) {
            return 1
          }
          if (!a['kyc_import_date'] && b['kyc_import_date']) {
            return -1
          }
          if (a['kyc_import_date'] && b['kyc_import_date'])
            return (
              new Date(a['kyc_import_date']) - new Date(b['kyc_import_date'])
            )
          return 0
        },
        width: 200,
      },
    ],
    [CompaniesOverviewTabs.CMPTCS]: [
      {
        title: intl.get('columnCompanyName'),
        dataIndex: 'company_name',
        fixed: true,
        width: 200,
        selected: true,
        sorter: (a, b) => sort(a, b, 'company_name'),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Credit limit recommendation',
        dataIndex: 'cmptcs',
        width: 400,
        selected: true,
        sorter: (a, b) => {
          if (a.cmptcs) return 1
          else return -1
        },
        render: (text, record) => (
          <CreditLimitRecommendationCell record={record} />
        ),
      },
      {
        title: 'Failure score',
        dataIndex: 'cmptcs',
        width: 400,
        selected: true,
        render: (text, record) => <FailureScore record={record.cmptcs} />,
      },
      {
        title: 'Severe negative events',
        dataIndex: 'cmptcs',
        width: 100,
        selected: true,
        render: (text, record) =>
          record.cmptcs
            ? record.cmptcs.hasSevereNegativeEvents
              ? 'Yes'
              : 'No'
            : 'No data',
      },
      {
        title: 'Overall risk assessment',
        dataIndex: 'cmptcs',
        width: 150,
        selected: true,
        render: (text, record) =>
          record.cmptcs && record.cmptcs.overallRiskAssessment.description
            ? record.cmptcs.overallRiskAssessment.description
            : 'No data',
      },
      {
        title: 'Standard rating',
        dataIndex: 'cmptcs',
        width: 300,
        selected: true,
        render: (text, record) => <StandardRating record={record.cmptcs} />,
      },
    ],
    [CompaniesOverviewTabs.KYC]: [
      {
        title: intl.get('columnCompanyName'),
        dataIndex: 'company_name',
        fixed: true,
        width: 200,
        selected: true,
        sorter: (a, b) => sort(a, b, 'company_name'),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Address',
        dataIndex: 'kyc',
        selected: true,
        width: 350,
        sorter: (a, b) => {
          if (a.kyc) return 1
          else return -1
        },
        render: (text, record) =>
          record.kyc && record.kyc.caseDetail
            ? record.kyc.caseDetail.details.caseAddress.address
            : 'No data',
      },
      {
        title: 'Risks',
        dataIndex: 'kyc',
        selected: true,
        render: (text, record) =>
          record.kyc && record.kyc.caseDetail ? (
            <span
              style={{
                color: record.kyc.caseDetail.details.risks.total.color,
              }}
            >
              {record.kyc.caseDetail.details.risks.total.name} :{' '}
              {record.kyc.caseDetail.details.risks.total.value}
            </span>
          ) : (
            'No data'
          ),
      },
      {
        title: 'Company details',
        dataIndex: 'kyc',
        selected: true,
        render: (text, record) =>
          record.kyc && record.kyc.caseDetail ? (
            <DetailsCell
              details={record.kyc.caseDetail.details.company.properties}
            />
          ) : (
            'No data'
          ),
      },
    ],
    [CompaniesOverviewTabs.BITSIGHT]: [
      {
        title: intl.get('columnCompanyName'),
        dataIndex: 'company_name',
        fixed: true,
        width: 200,
        selected: true,
        sorter: (a, b) => sort(a, b, 'company_name'),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Range',
        selected: true,
        sorter: (a, b) => {
          if (a.bitsight_rating) return 1
          else return -1
        },
        dataIndex: 'bitsight_rating',
        render: (text, record) =>
          record.bitsight_rating ? record.bitsight_rating.range : 'No data',
      },
      {
        title: 'Rating',
        selected: true,
        dataIndex: 'bitsigh_rating',
        render: (text, record) =>
          record.bitsight_rating ? (
            <span
              style={{
                color: record.bitsight_rating.rating_color,
                fontWeight: 700,
              }}
            >
              {record.bitsight_rating.rating}
            </span>
          ) : (
            'No data'
          ),
      },
      {
        title: 'Rating date',
        selected: true,
        dataIndex: 'bitsight_rating',
        render: (text, record) =>
          record.bitsight_rating
            ? record.bitsight_rating.rating_date
            : 'No data',
      },
      {
        title: 'More details',
        selected: true,
        className: 'button-column',
        dataIndex: 'bitsight_rating_details',
        render: (text, record) => (
          <BitsightDetailsCell record={record.bitsight_rating_details} />
        ),
      },
    ],
    [CompaniesOverviewTabs.IMPORT_LOGS]: [
      {
        title: 'API type',
        dataIndex: 'api_type',
        selected: true,
        sorter: (a, b) => sort(a, b, 'api_type'),
      },
      {
        title: 'Created date',
        dataIndex: 'created_date',
        selected: true,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        selected: true,
      },
    ],
    [CompaniesOverviewTabs.COMPANY_VIEW_MATRIX]: [],
    tab2: [
      {
        title: 'Initial DUNS profile',
        dataIndex: 'company_duns',
        selected: true,
      },
      {
        title: 'Invite send',
        dataIndex: 'company_invite',
        selected: true,
      },
      {
        title: 'Sign-up request only',
        dataIndex: 'company_sign_up',
        selected: true,
      },
      {
        title: 'Basic profile',
        dataIndex: 'company_basic',
        selected: true,
      },
      {
        title: 'Inactive profile',
        dataIndex: 'company_inactive',
        selected: true,
      },
      {
        title: 'GDPR cleaned profile',
        dataIndex: 'company_gdpr',
        selected: true,
      },
    ],
    tab3: [
      {
        title: 'Company profile created %',
        dataIndex: 'company_profile',
        selected: true,
      },
      {
        title: 'Questionaire completeness',
        dataIndex: 'company_completeness',
        selected: true,
      },
      {
        title: 'Capability profile completed',
        dataIndex: 'company_completed',
        selected: true,
      },
      {
        title: 'Number of workforce profiles uploaded',
        dataIndex: 'company_workforce',
        selected: true,
      },
      {
        title: 'Number of product profiles uploaded',
        dataIndex: 'company_profiles',
        selected: true,
      },
      {
        title: 'Public rate card information',
        dataIndex: 'company_public',
        selected: true,
      },
      {
        title: 'Private rate card information',
        dataIndex: 'company_private',
        selected: true,
      },
      {
        title: 'Premium API data: Editing intensity',
        dataIndex: 'company_premium_api',
        selected: true,
      },
      {
        title: 'Premium API data management: Active subscriptions',
        dataIndex: 'company_active_sub',
        selected: true,
      },
      {
        title: 'Number of documents',
        dataIndex: 'company_documents',
        selected: true,
      },
    ],
    tab4: [
      {
        title: 'Company name',
        dataIndex: 'company_name',
        selected: true,
      },
      {
        title: 'Number of accounts',
        dataIndex: 'total_accounts',
        selected: true,
      },
      {
        title: 'Last user activity',
        dataIndex: 'user_activity',
        selected: true,
      },
      {
        title: 'Active accounts',
        dataIndex: 'active_accounts',
        selected: true,
      },
    ],
    tab5: [
      {
        title: 'Visible to all others',
        dataIndex: 'company_visible_others',
        selected: true,
      },
      {
        title: 'Visible only to selected others',
        dataIndex: 'company_visible_selected',
        selected: true,
      },
      {
        title: 'Visible only to self and Titan',
        dataIndex: 'company_visible_self',
        selected: true,
      },
    ],
    tab6: [
      {
        title: 'Risk indicator',
        dataIndex: 'company_risk_indicator',
        selected: true,
      },
      {
        title: 'Industry type',
        dataIndex: 'company_industry_type',
        selected: true,
      },
    ],
  }
}
