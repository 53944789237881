import React, { useContext, useEffect, useState } from 'react'
import { Select } from 'antd'
import StoreContext from '../../storeContext'
import { observer } from 'mobx-react'

export default observer(function AddCompanies() {
  const { compare } = useContext(StoreContext)
  const [companies, setCompanies] = useState([])
  useEffect(() => {
    compare.loadCompanies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCompanies(compare.allCompanies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compare.allCompanies])
  return (
    <Select
      disabled={compare.companies.length > 2}
      value={compare.companies.map((c) => c.company_id)}
      filterOption={false}
      style={{ width: '100%', marginRight: 15 }}
      onSearch={(txt) => {
        setCompanies(
          compare.allCompanies.filter((company) =>
            company.name.toLowerCase().includes(txt.toLowerCase()),
          ),
        )
      }}
      onChange={(e) => {
        compare.updateCompanies(e)
      }}
      placeholder="Add companies"
      mode="multiple"
    >
      {companies.map((comp) => (
        <Select.Option key={comp.company_id} value={comp.company_id}>
          {comp.name}
        </Select.Option>
      ))}
    </Select>
  )
})
