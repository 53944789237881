import { StoreBase } from '../../../store/StoreBase'
import { decorate, flow, observable } from 'mobx'
import { SORT_ORDER, SORT_TYPE } from '../../Search/store/SearchStore'
import { NetworkCall } from '../../../store/models/NetworkModels'
import intl from "react-intl-universal";

const EmailStoreCalls = Object.freeze({
  SEND_MAIL: 'sendMail',
})

export class EmailStore extends StoreBase {
  companies = []
  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
    this.sendMailCall = new NetworkCall(rootStore, {
      id: EmailStoreCalls.SEND_MAIL,
      secured: true,
      path: 'companycommunication',
    })
  }

  sendMail = flow(function* (data) {
    if (this.sendMailCall.callInProgress) return
    const res = yield this.sendMailCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('successfullySentMessage'),
              placement: 'topRight',
            },
            'success',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  loadCompanies = flow(function* () {
    const res = yield this.rootStore.search.searchCall.call(
      'get',
      null,
      `?search_keyword=${''}&sort_type=${SORT_TYPE.name}&sort_order=${
        SORT_ORDER.desc
      }&communication=true`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.companies = result.data.companies
          break
        case 400:
          break
        default:
      }
    })
  })
}

decorate(EmailStore, {
  companies: observable,
})
