import React, { useContext } from 'react'
import StoreContext from '../../../../../storeContext'
import { Formik } from 'formik'
import { DatePicker, Form, Input, Select, SubmitButton } from 'formik-antd'
import intl from 'react-intl-universal'
import * as Yup from 'yup'
import { Currencies } from '../../../../../constants/dropdowns'
import moment from 'moment'

export default function ({ close, folder, update, company_id }) {
  const { companyProfile } = useContext(StoreContext)
  const initialValues = {
    company_id,
    edit_folder: {
      contract_folder_id: folder.contract_folder_id,
      folder_name: folder.folder_name,
      start_date: folder.start_date,
      end_date: folder.end_date || '',
      contract_value: folder.contract_value || '',
      currency: folder.currency || '',
      tags: folder.tags || [],
    },
  }

  const validation = Yup.object().shape({
    edit_folder: Yup.object().shape({
      folder_name: Yup.string().required(intl.get('folderNameRequired')),
      start_date: Yup.string().required(intl.get('startDateRequired')),
      contract_value: Yup.number().typeError(intl.get('contractValueError')),
    }),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={async (vals) => {
        await update(vals, 'editFolder')
        close()
      }}
    >
      {({ setFieldValue, values }) => {
        return (
          <Form>
            <Form.Item name="edit_folder.folder_name">
              <Input
                name="edit_folder.folder_name"
                addonBefore={intl.get('contractFolderName')}
              />
            </Form.Item>
            <Form.Item name="edit_folder.start_date">
              <DatePicker
                name="edit_folder.start_date"
                picker="date"
                disabledDate={(date) =>
                  date > moment(values.edit_folder.end_date)
                }
                onChange={(s, ss) => setFieldValue('add_folder.start_date', ss)}
                format="YYYY-MM-DD"
                placeholder={intl.get('selectStartDate')}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item name="edit_folder.end_date">
              <DatePicker
                name="edit_folder.end_date"
                picker="date"
                disabledDate={(date) =>
                  date < moment(values.edit_folder.start_date)
                }
                onChange={(s, ss) => setFieldValue('add_folder.end_date', ss)}
                format="YYYY-MM-DD"
                placeholder={intl.get('selectEndDate')}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item name="edit_folder.contract_value">
              <Input
                name="edit_folder.contract_value"
                addonBefore={intl.get('contractValue')}
              />
            </Form.Item>
            <Form.Item name="edit_folder.currency">
              <Select
                name="edit_folder.currency"
                placeholder={intl.get('selectCurrency')}
              >
                {Currencies.map((curr) => (
                  <Select.Option value={curr}>{curr}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="edit_folder.tags">
              <Select
                mode="multiple"
                name="edit_folder.tags"
                placeholder={intl.get('selectTags')}
              >
                {companyProfile.storiesTags.map((tag) => {
                  return <Select.Option value={tag}>{tag}</Select.Option>
                })}
              </Select>
            </Form.Item>
            <SubmitButton>{intl.get('save')}</SubmitButton>
          </Form>
        )
      }}
    </Formik>
  )
}
