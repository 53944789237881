import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Row, Table } from 'antd'
import EditProfileField from '../../Profile/EditProfileField'
import AddCertificate from './AddCertificate'
import StoreContext from '../../../storeContext'
import { observer } from 'mobx-react'
import * as Yup from 'yup'
import intl from 'react-intl-universal'
import { ValidationFields } from '../../../utils/validationFields'
import ActionButton from '../../shared/ActionButton'

export default observer(function () {
  const [showAdd, setShowAdd] = useState(false)
  const { generalCriteria } = useContext(StoreContext)

  const columns = [
    {
      title: intl.get('certificateNameEnTitle'),
      dataIndex: 'certificate_name_en',
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="certificate_name_en"
          validator={Yup.object({
            certificate_name_en: ValidationFields.certificateNameEn(),
          })}
          callback={async (value, setEdit) => {
            await generalCriteria.addUpdateCertificate({
              ...value,
              certificate_id: record.certificate_id,
            })
            await generalCriteria.getAllCertificates(true)
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: intl.get('certificateNameDeTitle'),
      dataIndex: 'certificate_name_de',
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="certificate_name_de"
          validator={Yup.object({
            certificate_name_de: ValidationFields.certificateNameDe(),
          })}
          callback={async (value, setEdit) => {
            await generalCriteria.addUpdateCertificate({
              ...value,
              certificate_id: record.certificate_id,
            })
            await generalCriteria.getAllCertificates(true)
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: intl.get('description'),
      dataIndex: 'certificate_description',
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="certificate_description"
          callback={async (value, setEdit) => {
            await generalCriteria.addUpdateCertificate({
              ...value,
              certificate_id: record.certificate_id,
            })
            await generalCriteria.getAllCertificates(true)
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: intl.get('authority'),
      dataIndex: 'certification_authority',
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="certification_authority"
          validator={Yup.object({
            certification_authority: ValidationFields.certificateAuthority(),
          })}
          callback={async (value, setEdit) => {
            await generalCriteria.addUpdateCertificate({
              ...value,
              certificate_id: record.certificate_id,
            })
            await generalCriteria.getAllCertificates(true)
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: 'Action',
      render: (text, record) => (
        <>
          <ActionButton
            record={record}
            text={intl.get('delete')}
            showModal={true}
            confirmTitle={intl.get('deleteCertificateConfirmation')}
            onClick={async () => {
              await generalCriteria.addUpdateCertificate({
                delete: true,
                certificate_id: record.certificate_id,
              })
              await generalCriteria.getAllCertificates(true)
            }}
          />
        </>
      ),
      selected: true,
      dataIndex: 'action',
      noCSV: true,
      fixed: 'right',
      width: 150,
    },
  ]
  useEffect(() => {
    generalCriteria.getAllCertificates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Row>
      <Col span="24">
        <Card title={intl.get('certificates')}>
          <Button onClick={() => setShowAdd(true)} type="primary">
            {intl.get('addCertificate')}
          </Button>
          <Table
            bordered={true}
            rowKey="certificate_id"
            dataSource={generalCriteria.certificates}
            columns={columns}
            scroll={{ x: 1500 }}
          />
        </Card>
      </Col>
      <AddCertificate
        visible={showAdd}
        handleOnCancel={() => setShowAdd(false)}
      />
    </Row>
  )
})
