import { action, computed, decorate, flow, observable } from "mobx";
import { http } from "../../utils/request";
import { StoreBase } from "../StoreBase";
import intl from "react-intl-universal";
import moment from "moment";

export const AsyncStates = Object.freeze({
  NONE: Object.freeze('AsyncStates.NONE'),
  DONE: Object.freeze('AsyncStates.DONE'),
  PENDING: Object.freeze('AsyncStates.PENDING'),
  ERROR: Object.freeze('AsyncStates.ERROR'),
  DATA_READY: Object.freeze('AsyncStates.DATA_READY'),
})

export class NetworkCall extends StoreBase {
  // this.path
  // this.secured
  // this.cacheInterval
  call = flow(function* call(method, data, getParams) {
    return yield this.customCall(
      method,
      `${this.path || ''}${getParams ? getParams : ''}`,
      data,
    )
  })
  customCall = flow(function* customCall(method, path, data) {
    this.callStatus = AsyncStates.PENDING
    this.rootStore.network.addCall(this.id)
    let result
    try {
      result = yield http[method](path, data /*{
        data,
        axios: {
          unsecured: !this.secured,
        },
      }*/)
    } catch (e) {
      return {
        mapResult: () => {
          this.rootStore.toast.setNotification({
            message: intl.get('exception'),
            placement: 'topRight',
          }, 'error')
          this.callStatus = AsyncStates.ERROR
          this.rootStore.network.removeCall(this.id)
        }
      }
    }
    // TODO: Add try catch block and remove on catch
    if (result.data.statusCode === 401) {
      this.callStatus = AsyncStates.ERROR
      this.rootStore.user.logout(this.rootStore.router.location.pathname)
      return {
        mapResult: () => {
          this.callStatus = AsyncStates.ERROR
          this.rootStore.network.removeCall(this.id)
        },
      }
    }
    this.callStatus = AsyncStates.DONE
    if (this.cacheInterval) {
      this.lastCall = moment()
    }
    return {
      ...result,
      mapResult: input => {
        if (input(result)) {
          this.callStatus = AsyncStates.DATA_READY
        } else {
          this.callStatus = AsyncStates.ERROR
        }
        this.rootStore.network.removeCall(this.id)
      },
    }
  })
  get callInProgress() {
    return this.status === AsyncStates.PENDING
  }
  get callError() {
    return this.status === AsyncStates.ERROR
  }
  get callDone() {
    return this.status === AsyncStates.DONE
  }
  get dataReady() {
    return this.status === AsyncStates.DATA_READY
  }
  get status() {
    return this.callStatus || AsyncStates.NONE
  }

  get cached() {
    if (this.cacheInterval && this.lastCall instanceof moment) {
      let current = moment()
      let diff = current.diff(this.lastCall, this.cacheInterval.unit)
      if (diff <= this.cacheInterval.value) {
        return true
      }
    }
    return false
  }

  reset() {
    this.lastCall = null
  }
}

decorate(NetworkCall, {
  path: observable,
  status: computed,
  call: action,
  customCall: action,
  callInProgress: computed,
  callDone: computed,
  dataReady: computed,
  callError: computed,
  lastCall: observable,
  cached: computed,
  reset: action,
  callStatus: observable,
  id: observable
})

export class NetworkCallList extends StoreBase {
  list = []
  add = flow(function* add(config, ...data) {
    const call = new NetworkCall(this.rootStore, config)
    this.list.push(call)
    const result = yield call.call(...data)
    const ind = this.list.indexOf(call)
    this.list.splice(ind, 1)
    return result
  })
  reset() {
    this.list.forEach(el => {
      el.reset()
    })
    this.list = []
  }
}
decorate(NetworkCallList, {
  list: observable,
  add: action,
  reset: action,
})
