import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import StoreContext from '../../../../storeContext'
import { COMPANY_PROFILE_TABS } from '../../index'
import { Card, Modal, Row } from 'antd'
import LoadingWrapper from '../LoadingWrapper'
import Surveys from './Surveys'
import intl from 'react-intl-universal'
import Indicators from './Indicators'
import EditButton from '../../EditButton'
import EditModal from '../../../shared/EditModal'
import EditRiskClass from './EditRiskClass'
import FinancialRisks from './Indicators/FinancialRisks'
import ComplianceRisks from './Indicators/ComplianceRisks'
import ReputationalRisks from './Indicators/ReputationalRisks'
import ContractualRisks from './Indicators/ContractualRisks'
import OperationalRisks from './Indicators/OperationalRisks'
import { RoleGroups } from '../../../../utils/roles'
import InfoPopUp from '../../../InfoPopUp'
import EditRiskOverRule from './EditRiskOverRule'
import EditPersonalRiskNote from './EditPersonalRiskNote'

export default React.memo(
  observer(
    React.forwardRef(function (props, ref) {
      const element = useRef()
      const { companyProfile } = useContext(StoreContext)
      const [showEditRiskClass, setShowEditRiskClass] = useState(false)
      const [showEditRiskOverRule, setShowEditRiskOverRule] = useState(false)
      const [showPersonalRiskNote, setShowPersonalRiskNote] = useState(false)
      const confirmDeleteNote = () => {
        Modal.confirm({
          title: intl.get('deletePersonalRiskNote'),
          okText: intl.get('delete'),
          onOk: async () => {
            await companyProfile.personalRiskNote({
              remove: {
                note_id: companyProfile.company.personal_risk_note.note_id,
              },
              company_id: companyProfile.company.company_id,
            })
          },
        })
      }
      const confirmModal = () => {
        Modal.confirm({
          title: intl.get('riskOverRuleDelete'),
          okText: intl.get('delete'),
          onOk: async () => {
            await companyProfile.riskClassification(
              {
                edit: {
                  risk_review: '',
                  risk_review_comment: '',
                  risk_classification_id:
                    companyProfile.company.risk_classification
                      .risk_classification_id,
                },
              },
              'addEditClassification',
            )
          },
        })
      }
      const menuItems = [
        {
          name: companyProfile.company.risk_classification
            ? intl.get('editRiskClass')
            : intl.get('addRiskClass'),
          callback: () => setShowEditRiskClass(true),
        },
        {
          name: intl.get('riskOverRule'),
          callback: () => setShowEditRiskOverRule(true),
        },
        {
          name: intl.get('editPersonalRiskNote'),
          callback: () => setShowPersonalRiskNote(true),
        },

        {
          name: intl.get('deletePersonalRiskNote'),
          callback: () => confirmDeleteNote(),
          show: !!companyProfile.company.personal_risk_note,
        },
        {
          name: intl.get('riskOverRuleDelete'),
          callback: confirmModal,
          show: companyProfile.company.risk_rating_override,
        },
      ]
      const { user } = useContext(StoreContext)
      useEffect(() => {
        ref.current[COMPANY_PROFILE_TABS.risk.id] = element.current
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [element.current])
      return (
        <Card
          title={intl.get('riskAndCompliance')}
          extra={
            <div style={{ display: 'flex' }}>
              <EditButton
                show={RoleGroups.ADMINS.includes(user.user['custom:role_id'])}
                menuItems={menuItems}
              />
              <InfoPopUp
                html={intl.get('riskInfoPopUp')}
                title={intl.get('riskAndCompliance')}
              />
            </div>
          }
        >
          <LoadingWrapper>
            <div ref={element} id={COMPANY_PROFILE_TABS.risk.id}>
              <Row>
                <Indicators />
                <Surveys />
              </Row>
              <Row>
                <FinancialRisks />
                <ComplianceRisks />
                <ReputationalRisks />
              </Row>
              <Row>
                <ContractualRisks />
                <OperationalRisks />
              </Row>
            </div>
          </LoadingWrapper>
          <EditModal
            handleOnClose={() => setShowEditRiskClass(false)}
            visible={showEditRiskClass}
            title={intl.get('editRiskClass')}
          >
            <EditRiskClass />
          </EditModal>
          <EditModal
            handleOnClose={() => setShowPersonalRiskNote(false)}
            visible={showPersonalRiskNote}
            title={intl.get('editPersonalRiskNote')}
          >
            <EditPersonalRiskNote />
          </EditModal>
          <EditModal
            handleOnClose={() => setShowEditRiskOverRule(false)}
            visible={showEditRiskOverRule}
            title={intl.get('riskOverRule')}
          >
            <EditRiskOverRule />
          </EditModal>
        </Card>
      )
    }),
  ),
)
