import { useEffect } from "react";

export function useClickOutside(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      let modal = false
      const path = event.path || (event.composedPath && event.composedPath())
      path.forEach((e) => {
        if (
          e.className === 'ant-modal-root' ||
          e.className === 'ant-select-item-option-content'
        )
          modal = true
      })
      if (ref.current && !ref.current.contains(event.target) && !modal) {
        callback()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])
}
