import React, { useContext } from 'react'
import { Card, Col, Tag } from 'antd'
import { observer } from 'mobx-react'
import StoreContext from '../../../../../storeContext'
import intl from 'react-intl-universal'

export default observer(function () {
  const { companyProfile } = useContext(StoreContext)
  if (
    companyProfile.company.company_type !== 2 ||
    companyProfile.preferredSuppliers.length === 0
  )
    return null
  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
      <Card
        bordered={false}
        title={intl.get('preferredSupplierOf')}
        type="inner"
      >
        {companyProfile.preferredSuppliers.map((sup) => (
          <Tag>{sup.buyer_name} </Tag>
        ))}
      </Card>
    </Col>
  )
})
