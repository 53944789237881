import React, { useContext, useEffect } from "react";
import StoreContext from "../../storeContext";
import { Formik } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import * as Yup from "yup";
import { action } from "mobx";
import AddRisk from "./AddRisk";

export default function ({ editSurvey, close }) {
  const { survey } = useContext(StoreContext)
  useEffect(() => {
    action(() => {
      survey.editingSurvey = JSON.parse(editSurvey.survey_code)
    })()
    return () => {
      action(() => {
        survey.editingSurvey = null
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const init = editSurvey
  const validation = Yup.object().shape({
    survey_name: Yup.string().required('Name is required'),
    survey_code: Yup.string()
      .required('Survey is required')
      .test('is_valid_code', 'The JSON code is not valid', (value) => {
        try {
          const code = JSON.parse(value)
          return !!code.pages
        } catch (e) {
          return false
        }
      }),
  })
  const onChangeCode = (e) => {
    action(() => {
      try {
        const temp = JSON.parse(e.target.value)
        if (temp.pages) survey.editingSurvey = temp
      } catch (e) {}
    })()
  }
  return (
    <>
      <Formik
        initialValues={init}
        validationSchema={validation}
        onSubmit={async (vals) => {
          await survey.survey(
            {
              edit: {
                ...vals,
                survey_code: JSON.stringify(survey.editingSurvey),
              },
            },
            'edit',
          )
          close()
        }}
      >
        {() => (
          <Form>
            <Form.Item name="survey_name">
              <Input addonBefore="Survey name" name="survey_name" />
            </Form.Item>
            <Form.Item name="survey_code">
              <Input.TextArea
                placeholder="Survey JSON"
                rows={3}
                name="survey_code"
                onChange={onChangeCode}
              />
            </Form.Item>
            <SubmitButton>Save</SubmitButton>
          </Form>
        )}
      </Formik>
      <AddRisk />
    </>
  )
}
