import { Button, Modal } from 'antd'
import React, { useContext } from 'react'
import StoreContext from '../../../storeContext'
import moment from 'moment'
import intl from 'react-intl-universal'

export default function Refetch({ record }) {
  const { vetting } = useContext(StoreContext)
  const confirmModal = () => {
    Modal.confirm({
      title: intl.get('refetchReport'),
      content: intl.get('refetchReportText'),
      okText: intl.get('refetch'),
      onOk: async () => {
        await vetting.sendRequest({
          resource_vetting_request_id: record.resource_vetting_request_id,
          refetch: true,
        })
      },
    })
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {moment(record.aml_details[0].report_date).format('DD/MM/YYYY')}{' '}
      <Button onClick={confirmModal} style={{ margin: 0 }} type="link">
        Refetch
      </Button>
    </div>
  )
}
