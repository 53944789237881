import { StoreBase } from '../../../store/StoreBase'
import { action, computed, decorate, flow, observable } from 'mobx'
import { NetworkCall } from '../../../store/models/NetworkModels'
import { ProductGroup } from '../../../constants/dropdowns'

export const SearchStoreCalls = Object.freeze({
  SEARCH: Object.freeze('search'),
  IMPLEMENTATION_PARTNERS: Object.freeze('implementation_partners'),
})
export const SORT_TYPE = {
  name: 'name',
  match: 'match_count',
  distance: 'distance',
  city: 'city',
  preferredSupplier: 'preferred_supplier',
  rateCard: 'rate_card',
  premiumData: 'premium_data',
}
export const SORT_ORDER = {
  asc: 'asc',
  desc: 'desc',
}

export const SortDropdown = [
  {
    name: 'Name ascending',
    sortType: SORT_TYPE.name,
    sortOrder: SORT_ORDER.asc,
  },
  {
    name: 'Name descending',
    sortType: SORT_TYPE.name,
    sortOrder: SORT_ORDER.desc,
  },
  {
    name: 'Match count ascending',
    sortType: SORT_TYPE.match,
    sortOrder: SORT_ORDER.asc,
  },
  {
    name: 'Match count descending',
    sortType: SORT_TYPE.match,
    sortOrder: SORT_ORDER.desc,
  },
  {
    name: 'Distance ascending',
    sortType: SORT_TYPE.distance,
    sortOrder: SORT_ORDER.asc,
  },
  {
    name: 'Distance descending',
    sortType: SORT_TYPE.distance,
    sortOrder: SORT_ORDER.desc,
  },
  {
    name: 'City ascending',
    sortType: SORT_TYPE.city,
    sortOrder: SORT_ORDER.asc,
  },
  {
    name: 'City descending',
    sortType: SORT_TYPE.city,
    sortOrder: SORT_ORDER.desc,
  },

  {
    name: 'Preferred supplier ascending',
    sortType: SORT_TYPE.preferredSupplier,
    sortOrder: SORT_ORDER.asc,
  },
  {
    name: 'Preferred supplier descending',
    sortType: SORT_TYPE.preferredSupplier,
    sortOrder: SORT_ORDER.desc,
  },
  {
    name: 'Rate card ascending',
    sortType: SORT_TYPE.rateCard,
    sortOrder: SORT_ORDER.asc,
  },
  {
    name: 'Rate card descending',
    sortType: SORT_TYPE.rateCard,
    sortOrder: SORT_ORDER.desc,
  },
  {
    name: 'Premium data ascending',
    sortType: SORT_TYPE.premiumData,
    sortOrder: SORT_ORDER.asc,
  },
  {
    name: 'Premium data descending',
    sortType: SORT_TYPE.premiumData,
    sortOrder: SORT_ORDER.desc,
  },
]
export class SearchStore extends StoreBase {
  results = []
  products = []
  advancedResults = []
  perPage = 10
  currentPage = 1
  searchTerm = ''
  searching = false
  selectedResult = null
  timeoutId = 0
  sortType = SORT_TYPE.match
  sortOrder = SORT_ORDER.desc
  sortItem = 3
  selectedCompanies = []
  sortLists = []
  selectedDescription = null
  filters = [
    {
      name: 'Country',
      code: 'country',
      items: [
        {
          label: 'CH',
          value: 'CH',
          selected: false,
        },
        {
          label: 'LI',
          value: 'LI',
          selected: false,
        },
        {
          label: 'DE',
          value: 'DE',
          selected: false,
        },
        {
          label: 'AT',
          value: 'AT',
          selected: false,
        },
      ],
    },
    {
      name: 'Category',
      filters: [
        {
          name: 'IT',
          code: 'subcategory',
          items: [
            {
              label: 'Hosting',
              value: 'Hosting',
              selected: false,
            },
            {
              label: 'IT Development',
              value: 'IT Development',
              selected: false,
            },
            {
              label: 'IT support services',
              value: 'IT support services',
              selected: false,
            },
            {
              label: 'Software',
              value: 'Software',
              selected: false,
            },
          ],
        },
        {
          name: 'Professional services',
          code: 'subcategory',
          items: [
            {
              label: 'Accounting, audit, tax',
              value: 'Accounting, Audit, Tax',
              selected: false,
            },
            {
              label: 'HR services',
              value: 'HR services',
              selected: false,
            },
            {
              label: 'IT consulting',
              value: 'IT consulting',
              selected: false,
            },
            {
              label: 'Legal',
              value: 'Legal',
              selected: false,
            },
            {
              label: 'Management consulting',
              value: 'Management consulting',
              selected: false,
            },
            {
              label: 'PR consulting',
              value: 'PR consulting',
              selected: false,
            },
            {
              label: 'Recruiting',
              value: 'Recruiting',
              selected: false,
            },
            {
              label: 'Social science research',
              value: 'Social science research',
              selected: false,
            },
            {
              label: 'Temp staffing',
              value: 'Temp staffing',
              selected: false,
            },
          ],
        },
        {
          name: 'Telco',
          code: 'subcategory',
          items: [
            {
              label: 'Carrier',
              value: 'Carrier',
              selected: false,
            },
            {
              label: 'Other telecommunication services',
              value: 'Other telecommunication services',
              selected: false,
            },
          ],
        },
      ],
    },
    {
      name: 'Industry expertise',
      code: 'industry_expertise',
      items: [
        {
          label: 'Financial services',
          value: 'Financial services',
          selected: false,
        },
        {
          label: 'Health care',
          value: 'Health care',
          selected: false,
        },
        {
          label: 'IT/Tech sector',
          value: 'IT/Tech sector',
          selected: false,
        },
        {
          label: 'Public sector',
          value: 'Public sector',
          selected: false,
        },
        {
          label: 'Others',
          value: 'Others',
          selected: false,
        },
      ],
    },
    {
      name: 'Employee count',
      code: 'employee_count',
      items: [
        {
          label: '1-2',
          value: '1-2',
          selected: false,
        },
        {
          label: '3-10',
          value: '3-10',
          selected: false,
        },
        {
          label: '11-50',
          value: '11-50',
          selected: false,
        },
        {
          label: '51-100',
          value: '51-100',
          selected: false,
        },
        {
          label: '101-250',
          value: '101-250',
          selected: false,
        },
        {
          label: '251-1000',
          value: '251-1000',
          selected: false,
        },
        {
          label: '1001-5000',
          value: '1001-5000',
          selected: false,
        },
        {
          label: '>5000',
          value: '>5000',
          selected: false,
        },
      ],
    },
    {
      name: 'Certificates',
      code: 'certificate_list',
      items: [],
    },
    {
      name: 'Product group',
      code: 'product_groups',
      items: ProductGroup.map((prod) => ({
        label: prod.name,
        value: prod.value,
        selected: false,
      })),
    },
    /* {
      name: 'single',
      code: 'partners',
      single: true,
      items: [
        {
          label: 'Implementation partners',
          value: true,
          selected: false,
          disabled: () => this.isProductGroupSelected,
        },
      ],
    }, */
  ]

  get isProductGroupSelected() {
    let total = true
    this.filters.forEach((f) => {
      if (f.code === 'product_groups') {
        f.items.forEach((i) => {
          if (i.selected) total = false
        })
      }
    })
    return total
  }

  resetFilters = (filters) => {
    if (filters.filters) {
      filters.filters = filters.filters.map((f) => {
        f = this.resetFilters(f)
        return f
      })
    }
    if (filters.items) {
      filters.items = filters.items.map((i) => {
        i.selected = false
        return i
      })
    }
    return filters
  }
  resetFiltersAction = () => {
    this.filters = this.filters.map((f) => {
      f = this.resetFilters(f)
      return f
    })
    this.advancedSearch()
  }
  setListSort = (e, callType = 'search') => {
    this.sortLists = e
    this[callType](this.searchTerm)
  }
  applyFilters = () => {
    let filters = '&'
    this.filters.forEach((filter) => {
      if (filter.filters) {
        filter.filters.forEach((f) => {
          f.items.forEach((item) => {
            if (item.selected) filters += `${f.code}=${item.value}&`
          })
        })
      } else {
        filter.items.forEach((item) => {
          if (item.selected) filters += `${filter.code}=${item.value}&`
        })
      }
    })
    return filters === '&' ? '' : filters
  }

  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore

    this.searchCall = new NetworkCall(rootStore, {
      path: 'companysearch',
      secured: true,
      id: SearchStoreCalls.SEARCH,
    })

    this.implementationPartnersCall = new NetworkCall(rootStore, {
      path: 'implementationpartnersearch',
      secured: true,
      id: SearchStoreCalls.IMPLEMENTATION_PARTNERS,
    })
  }

  loadPartners = flow(function* (id, callback) {
    const res = yield this.implementationPartnersCall.call(
      'get',
      null,
      `?product_id=${id}`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          callback(result.data.implementators)
          break
        case 400:
          break
        default:
      }
    })
  })

  toggleSort = (sort, callType = 'search') => {
    this.sortItem = sort
    this[callType](this.searchTerm)
  }
  toggleSearchItem = (id) => {
    if (this.selectedCompanies.indexOf(id) > -1) {
      this.selectedCompanies.splice(this.selectedCompanies.indexOf(id), 1)
    } else {
      this.selectedCompanies.push(id)
    }
  }

  search = flow(function* (data) {
    const res = yield this.searchCall.call(
      'get',
      null,
      `?search_keyword=${data}&sort_type=${this.sortType}&sort_order=${this.sortOrder}`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.results = result.data.companies
          break
        case 400:
          break
        default:
      }
    })
  })

  applyLists = () => {
    if (this.sortLists.length === 0) return ''
    let params = '&'
    this.sortLists.forEach((id, i) => {
      params += `company_list=${id}${this.sortLists.length > i + 1 ? '&' : ''}`
    })
    return params
  }
  advancedSearch = flow(function* () {
    const res = yield this.searchCall.call(
      'get',
      null,
      `?search_keyword=${this.searchTerm}&sort_type=${
        SortDropdown[this.sortItem].sortType
      }&sort_order=${
        SortDropdown[this.sortItem].sortOrder
      }${this.applyFilters()}${this.applyLists()}`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.results = result.data.companies
          this.products = result.data.products
          break
        case 400:
          break
        default:
      }
    })
  })

  setSearch = (value) => {
    if (this.results.length > 0) this.results = []
    this.searchTerm = value
    if (value.length > 2) {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        this.search(value)
      }, 300)
    }
  }

  setSearching = (value) => {
    this.searching = value
  }
  setSelectedDescription = (id) => {
    this.selectedDescription = id
  }
}

decorate(SearchStore, {
  results: observable,
  selectedResult: observable,
  searching: observable,
  searchTerm: observable,
  setSearch: action,
  setSearching: action,
  sortOrder: observable,
  sortType: observable,
  toggleSort: action,
  selectedCompanies: observable,
  advancedResults: observable,
  currentPage: observable,
  filters: observable,
  perPage: observable,
  toggleSearchItem: action,
  sortItem: observable,
  sortLists: observable,
  setListSort: action,
  resetFiltersAction: action,
  isProductGroupSelected: computed,
  selectedDescription: observable,
  setSelectedDescription: action,
  products: observable,
})
