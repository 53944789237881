import { Checkbox } from 'antd'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

const FilterItem = observer(function ({ filter, level = 1 }) {
  const [show, setShow] = useState(false)
  const getTotal = (filters) => {
    let total = 0
    if (filters.items) total = filters.items.filter((i) => i.selected).length
    if (filters.filters)
      filters.filters.forEach((f) => {
        total += getTotal(f)
      })
    return total
  }
  const disabled = (item) => {
    const dis = item.disabled()
    if (dis) {
      action(() => {
        item.selected = false
      })()
    }
    return dis
  }
  if (filter.single)
    return (
      <div className={`filter-item item-level-${level}`}>
        <div className="filter-item-boxes">
          {filter.items.map((item) => (
            <Checkbox
              className="single-filter"
              disabled={disabled(item)}
              onChange={(e) => {
                action(() => {
                  item.selected = e.target.checked
                })()
              }}
              checked={item.selected}
            >
              {item.label}
            </Checkbox>
          ))}
        </div>
      </div>
    )
  return (
    <div className={`filter-item item-level-${level}`}>
      <div
        className="filter-item-title"
        onClick={() => setShow((show) => !show)}
      >
        <div className="filter-title">
          <h4>{filter.name}</h4>
          <span>({getTotal(filter)})</span>
        </div>
        {show ? <UpOutlined /> : <DownOutlined />}
      </div>
      {show &&
        (filter.filters ? (
          filter.filters.map((f) => (
            <FilterItemRecursive filter={f} level={level + 1} />
          ))
        ) : (
          <div className="filter-item-boxes">
            {filter.items.map((item) => (
              <Checkbox
                style={{ display: 'flex', alignItems: 'center' }}
                onChange={(e) => {
                  action(() => {
                    item.selected = e.target.checked
                  })()
                }}
                checked={item.selected}
              >
                {item.label}
              </Checkbox>
            ))}
          </div>
        ))}
    </div>
  )
})

const FilterItemRecursive = (props) => <FilterItem {...props} />

export default FilterItem
