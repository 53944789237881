import React, { useContext } from "react";
import { Col, Layout, Row } from "antd";
import Topbar from "../../containers/Topbar";
import { Formik } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import StoreContext from "../../storeContext";
import intl from "react-intl-universal";
import { ValidationFields } from "../../utils/validationFields";

const { Content } = Layout
const CustomInput = (props) => (
  <InputMask {...props}>{(inputProps) => <Input {...inputProps} />}</InputMask>
)
export default function Confirmation() {
  const {router, user} = useContext(StoreContext)
  const email = new URLSearchParams(router.location.search)
  const initialValues = {
    username: email.get('email'),
    code: '',
    password: '',
    confirmPassword: '',
  }
  const validator = Yup.object({
    username: ValidationFields.email(),
    code: ValidationFields.code(),
    password: ValidationFields.password(),
    confirm_password: ValidationFields.confirmPassword(),
  })
  return (
    <Layout style={{ height: '100%' }}>
      <Topbar showCommands={false} />
      <Content>
        <div
          className="gx-login-container"
          style={{ overflow: 'auto', padding: '30px' }}
        >
          <div className="gx-login-content">
            <div className="gx-login-header gx-text-center">
              <h1 className="gx-login-title">
                {intl.get('resetPasswordConfirmTitle')}
              </h1>
              <Row>
                <Col span={24}>
                  <p style={{ width: '100%' }}>
                    {intl.get('resetPasswordConfirmInfoText')}
                  </p>
                </Col>
              </Row>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validator}
              onSubmit={(values, { setSubmitting, setFieldError }) => {
                user.confirmResetPassword({ ...values, code: values.code.replace(/\s/g, '') }, setSubmitting, setFieldError)
              }}
            >
              {({ setFieldValue, errors }) => (
                <Form>
                  <Form.Item name="username">
                    <Input disabled name="username" placeholder={intl.get('emailLabel')} />
                  </Form.Item>
                  <Form.Item name="code">
                    <CustomInput
                      mask="9 9 9 9 9 9 "
                      name="code"
                      placeholder={intl.get('confirmationCodeLabel')}
                      style={{ textAlign: 'center' }}
                      onChange={(e) => {
                        const value = e.target.value || ''
                        setFieldValue('code', value)
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="password">
                    <Input.Password name="password" placeholder={intl.get('passwordLabel')} />
                  </Form.Item>
                  <Form.Item name="confirm_password">
                    <Input.Password name="confirm_password" placeholder={intl.get('passwordConfirmLabel')} />
                  </Form.Item>
                  {errors['asyncError'] && (
                    <div className="ant-row ant-form-item ant-form-item-with-help ant-form-item-has-error">
                      <div className="ant-col ant-form-item-control">
                        <div className="ant-form-item-explain">
                          <div>
                            <li>{intl.get(errors['asyncError'])}</li>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <Row>
                    <SubmitButton disabled={false} style={{ width: '100%' }}>
                      {intl.get('confirmLabel')}
                    </SubmitButton>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Content>
    </Layout>
  )
}
