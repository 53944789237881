import { StoreBase } from "./StoreBase";
import { action, decorate, observable } from "mobx";
import { computedFn } from "mobx-utils";

export default class NetworkStore extends StoreBase {
  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
    this.runningCalls = []
  }
  addCall = (call) => {
    this.runningCalls = [...this.runningCalls, call]
  }

  removeCall = (call) => {
    this.runningCalls = this.runningCalls.filter(el => el !== call)
  }

  checkRunning = computedFn(function(call){
    return this.runningCalls.filter(el=> call.includes(el)).length > 0
  })
}
decorate(NetworkStore, {
  runningCalls: observable,
  addCall: action,
  removeCall: action
})
