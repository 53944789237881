import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import StoreContext from '../../../../../../storeContext'
import { Card, Col, Space } from 'antd'
import intl from 'react-intl-universal'
import InfoPopUp from '../../../../../InfoPopUp'
import IndicatorItem from '../IndicatorItem'
import CorporateInfo from './CorporateInfo'
import SustainabilityInfo from './SustainabilityInfo'
import CountryInfo from './CountryInfo'
import Rating from '../../Rating'

export default observer(function () {
  const { companyProfile } = useContext(StoreContext)
  if (!companyProfile.company.reputational_sustainability_risks) return null
  return (
    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
      <Card
        type="inner"
        bordered={false}
        title={intl.get('reputationalRisks')}
        extra={
          <div style={{ display: 'flex' }}>
            <InfoPopUp
              title={intl.get('reputationalRisks')}
              html={intl.get('reputationalRisksInfo')}
            />
            <div style={{ marginRight: 5 }} />

            <Rating
              score={
                companyProfile.company.reputational_sustainability_risks
                  .risk_rating
              }
              trafficLight={true}
            />
          </div>
        }
      >
        {/* {intl.get('financialRisksEmptyText')}*/}
        <Space direction="vertical" style={{ width: '100%' }}>
          {companyProfile.company.reputational_sustainability_risks
            .corporate && (
            <IndicatorItem
              rating={
                companyProfile.company.reputational_sustainability_risks
                  .corporate
              }
              name="Governance"
              modal={(show, setShow) => (
                <CorporateInfo
                  corporate={
                    companyProfile.company.reputational_sustainability_risks
                      .corporate
                  }
                  setShowPreview={setShow}
                  showPreview={show}
                />
              )}
            />
          )}
          {companyProfile.company.reputational_sustainability_risks
            .sustainability && (
            <IndicatorItem
              rating={
                companyProfile.company.reputational_sustainability_risks
                  .sustainability
              }
              name="Sustainability"
              modal={(show, setShow) => (
                <SustainabilityInfo
                  sustainability={
                    companyProfile.company.reputational_sustainability_risks
                      .sustainability
                  }
                  setShowPreview={setShow}
                  showPreview={show}
                />
              )}
            />
          )}
          {companyProfile.company.reputational_sustainability_risks.country && (
            <IndicatorItem
              showView={companyProfile.company.kyc_view}
              rating={
                companyProfile.company.reputational_sustainability_risks.country
              }
              name="Country"
              modal={(show, setShow) => (
                <CountryInfo
                  country={
                    companyProfile.company.reputational_sustainability_risks
                      .country
                  }
                  setShowPreview={setShow}
                  showPreview={show}
                />
              )}
            />
          )}
        </Space>
      </Card>
    </Col>
  )
})
