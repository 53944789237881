import React, { useContext } from 'react'
import StoreContext from '../../../../storeContext'
import { Modal } from 'antd'
import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import { useParams } from 'react-router'
import intl from "react-intl-universal";

export default function (props) {
  const {
    visible,
    handleOnCancel,
    links = {
      twitter_url: '',
      facebook_url: '',
      linkedin_url: '',
      youtube_url: '',
      xing_url: '',
      instagram_url: '',
    },
  } = props
  const company_id = useParams().id
  const { companyProfile } = useContext(StoreContext)

  const initialValues = {
    company_id,
    social_media: {
      twitter_url: links.twitter_url,
      facebook_url: links.facebook_url,
      linkedin_url: links.linkedin_url,
      youtube_url: links.youtube_url,
      instagram_url: links.instagram_url,
      xing_url: links.xing_url
    },
  }
  return (
    <Modal
      title={intl.get('editMediaLinks')}
      visible={visible}
      footer={null}
      onCancel={handleOnCancel}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (vals, { setSubmitting }) => {
          await companyProfile.updateCompany(vals)
          setSubmitting(false)
          handleOnCancel()
        }}
      >
        {() => {
          return (
            <Form>
              <Form.Item name="social_media.twitter_url">
                <Input
                  addonBefore="Twitter"
                  name="social_media.twitter_url"
                  placeholder="Twitter"
                />
              </Form.Item>
              <Form.Item name="social_media.linkedin_url">
                <Input
                  addonBefore="Linkedin"
                  name="social_media.linkedin_url"
                  placeholder="Linkedin"
                />
              </Form.Item>
              <Form.Item name="social_media.xing_url">
                <Input
                  addonBefore="Xing"
                  name="social_media.xing_url"
                  placeholder="Xing"
                />
              </Form.Item>
              <Form.Item name="social_media.facebook_url">
                <Input
                  addonBefore="Facebook"
                  name="social_media.facebook_url"
                  placeholder="Facebook"
                />
              </Form.Item>
              <Form.Item name="social_media.instagram_url">
                <Input
                  addonBefore="Instagram"
                  name="social_media.instagram_url"
                  placeholder="Instagram"
                />
              </Form.Item>
              <Form.Item name="social_media.youtube_url">
                <Input
                  addonBefore="Youtube"
                  name="social_media.youtube_url"
                  placeholder="Youtube"
                />
              </Form.Item>
              <SubmitButton>{intl.get('save')}</SubmitButton>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
