import React, { useState } from 'react'
import { Button, message, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

export default function ({
  onRemove,
  onChange,
  alowedType,
  maxSize,
  errorMessage,
  uploadTitle,
  error,
  withName,
}) {
  const [fileList, setFileList] = useState([])
  function onRemoveFile(set) {
    onRemove()
    setFileList([])
  }
  function onFileChange(file) {
    if (
      (alowedType && !alowedType.includes(file.type)) ||
      file.size / 1024 / 1024 > maxSize
    ) {
      message.error(errorMessage)
      return false
    }

    setFileList([file])
    const reader = new FileReader()
    reader.onload = () => {
      if (withName) {
        onChange({ file: reader.result.split(',')[1], name: file.name })
      } else {
        onChange(reader.result.split(',')[1])
      }
    }
    reader.readAsDataURL(file)
    return false
  }

  return (
    <Upload
      name="story_file"
      onRemove={onRemoveFile}
      beforeUpload={onFileChange}
      fileList={fileList}
    >
      <Button
        style={{
          width: '100%',
          margin: 0,
          borderColor: error && '#f5222d',
          color: error && '#f5222d',
        }}
      >
        <UploadOutlined />
        {uploadTitle}
      </Button>
      <div style={{ color: '#f5222d' }}>{error}</div>
    </Upload>
  )
}
