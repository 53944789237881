import { StoreBase } from '../../../store/StoreBase'
import { NetworkCall } from '../../../store/models/NetworkModels'
import { action, decorate, flow, observable, toJS } from 'mobx'
import { RoleGroups } from '../../../utils/roles'
import intl from 'react-intl-universal'

export const RateCardCalls = Object.freeze({
  LOAD_RATE_CARDS: Object.freeze('loadRateCards'),
  LOAD_BENCHMARK: Object.freeze('loadBenchmark'),
  PRIVATE_RATE_CARDS: Object.freeze('privateRateCards'),
  LOAD_PRIVATE: Object.freeze('loadPrivate'),
})

export class RateCardsStore extends StoreBase {
  rateCards = []
  companies = []
  benchmarkRateCards = []
  privateRateCards = []
  publicRateCard = null
  targetRateCards = []
  company = null
  copy = null

  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
    this.loadBenchmarkCall = new NetworkCall(rootStore, {
      path: 'listbenchmark',
      secured: true,
      id: RateCardCalls.LOAD_BENCHMARK,
    })
    this.privateRateCardsCall = new NetworkCall(rootStore, {
      path: 'privateratecard',
      secured: true,
      id: RateCardCalls.PRIVATE_RATE_CARDS,
    })
    this.loadPrivateCall = new NetworkCall(rootStore, {
      path: 'listprivateratecards',
      secured: true,
      id: RateCardCalls.LOAD_PRIVATE,
    })
  }

  loadPrivate = flow(function* () {
    const res = yield this.loadPrivateCall.call(
      'get',
      null,
      `?company_id=${this.rootStore.user.user.company_id}`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rateCards = result.data.rate_cards
          this.loadPrivateRateCards(result.data.rate_card_private)
          break
        default:
      }
    })
  })

  updatePrivateRateCards = flow(function* (data) {
    const res = yield this.privateRateCardsCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.loadPrivate()
          this.rootStore.toast.setNotification(
            {
              message: intl.get('addedPrivateRateCard'),
              placement: 'topRight',
            },
            'success',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  loadPrivateRateCardCompanies = flow(function* () {
    const res = yield this.rootStore.search.searchCall.call(
      'get',
      null,
      `?company_type=${this.rootStore.user.user.company_type === 1 ? 2 : 1}`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.companies = result.data.companies
          break
        default:
      }
    })
  })
  loadBenchmark = flow(function* () {
    if (this.loadBenchmarkCall.callInProgress) return
    const res = yield this.loadBenchmarkCall.call('get')
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rateCards = result.data.rate_cards
          this.loadBenchmarkRateCards(result.data.rate_card_benchmark)
          break
        default:
      }
    })
  })

  getSavedData = (data) => {
    data.forEach((d) => {
      delete d.total
      if (d.child_type === 'group') {
        this.getSavedData(d.children)
        d.children = d.children.filter((c) => c.children.length > 0)
      }
      if (d.child_type === 'item') {
        d.children = d.children.filter((c) => {
          return Object.keys(c).length > 3
        })
      }
    })
    return data.filter((d) => d.children.length > 0)
  }

  updateRest = flow(function* (data) {
    const res = yield this.rootStore.companyProfile.updateCompanyProfileCall.call(
      'post',
      data,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.company = { ...this.company, ...result.data.company }
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  update = flow(function* (data) {
    const res = yield this.rootStore.companyProfile.updateCompanyProfileCall.call(
      'post',
      data,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.company = { ...this.company, ...result.data.company }
          this.rootStore.toast.setNotification(
            {
              message: intl.get('rateCardUpdated'),
              placement: 'topRight',
            },
            'success',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  setCopyData = (record) => {
    const temp = toJS(record)
    delete temp.profile_de
    delete temp.profile_en
    delete temp.profile_currency
    this.copy = temp
  }

  pasteData = (record) => {
    if (this.copy) {
      for (const key in this.copy) {
        record[key] = this.copy[key]
      }
    }
  }

  deleteRow = (record) => {
    delete record.profile_l1
    delete record.profile_l2
    delete record.profile_l3
    delete record.profile_l4
    delete record.profile_l5
    delete record.profile_travel_cost
  }

  createRateCards = (data, saved, names) => {
    let test = []
    saved.forEach((d) => {
      if (names) test = names
      else test = []
      test.push(d.item_name)
      d.total = 0
      if (d.child_type === 'group') {
        this.createRateCards(data, d.children, [...test])
        d.children.forEach((c) => {
          d.total += c.total
        })
      } else {
        d.total = d.children.length
        d.children.forEach((child) => {
          this.getRateCardItemsByName(data, child, test)
        })
      }
    })
    return data
  }

  getRateCardItemsByName = (data, savedItem, names) => {
    for (let item of data) {
      if (!item.total) item.total = 0
      if (item.child_type === 'group') {
        if (names.includes(item.item_name)) {
          this.getRateCardItemsByName(item.children, savedItem, names)
          item.total = 0
          item.children.forEach((c) => {
            item.total += c.total
          })
        }
      } else {
        if (names.includes(item.item_name)) {
          item.children = item.children.map((o) => {
            if (o.profile_en === savedItem.profile_en) {
              if (
                savedItem.profile_l1 ||
                savedItem.profile_l2 ||
                savedItem.profile_l3 ||
                savedItem.profile_l4 ||
                savedItem.profile_l5
              )
                item.total++
              return savedItem
            }
            return o
          })
        }
      }
    }
  }

  loadPublicRateCard = () => {
    this.publicRateCard = this.company.rate_card_public || {}
    this.publicRateCard.allCards = this.createRateCards(
      toJS(this.rateCards),
      this.publicRateCard.rates || [],
    )
    if (!this.publicRateCard.seniority_definition)
      this.publicRateCard.seniority_definition = {
        seniority_L1: '',
        seniority_L2: '',
        seniority_L3: '',
        seniority_L4: '',
        seniority_L5: '',
      }
  }

  loadPrivateRateCards = (cards) => {
    this.privateRateCards = cards.map((card) => {
      card.allCards = RoleGroups.ADMINS.includes(
        this.rootStore.user.user['custom:role_id'],
      )
        ? this.createRateCards(toJS(this.rateCards), card.rates || [])
        : this.calculateTotal(toJS(card.rates || []))
      if (!card.seniority_definition) {
        card.seniority_definition = {
          seniority_L1: '',
          seniority_L2: '',
          seniority_L3: '',
          seniority_L4: '',
          seniority_L5: '',
        }
      }
      return card
    })
  }
  calculateTotal = (cards) => {
    cards.forEach((card) => {
      card.total = 0
      if (card.child_type === 'group') {
        this.calculateTotal(card.children)
        card.children.forEach((child) => {
          card.total += child.total
        })
      } else {
        card.total = card.children.length
      }
    })
    return cards
  }

  loadBenchmarkRateCards = (cards) => {
    this.benchmarkRateCards = cards.map((card) => {
      card.allCards = RoleGroups.TITANS.includes(
        this.rootStore.user.user['custom:role_id'],
      )
        ? this.createRateCards(toJS(this.rateCards), card.rates || [])
        : this.calculateTotal(toJS(card.rates || []))
      if (!card.seniority_definition) {
        card.seniority_definition = {
          seniority_L1: '',
          seniority_L2: '',
          seniority_L3: '',
          seniority_L4: '',
          seniority_L5: '',
        }
      }

      return card
    })
  }

  loadTargetRateCards = (cards) => {
    this.targetRateCards = cards.map((card) => {
      card.allCards = this.rootStore.user.user.claims.update_company
        ? this.createRateCards(toJS(this.rateCards), card.rates || [])
        : this.calculateTotal(toJS(card.rates || []))
      if (!card.seniority_definition) {
        card.seniority_definition = {
          seniority_L1: '',
          seniority_L2: '',
          seniority_L3: '',
          seniority_L4: '',
          seniority_L5: '',
        }
      }
      return card
    })
  }

  loadRateCards = flow(function* (company_id) {
    const res = yield this.rootStore.companyProfile.getCompanyDataCall.call(
      'get',
      null,
      `?company_id=${company_id}`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rateCards = result.data.rate_cards
          this.company = result.data.company
          if (result.data.company.rate_card_target)
            this.loadTargetRateCards(result.data.company.rate_card_target)
          break
        default:
      }
    })
  })
}

decorate(RateCardsStore, {
  rateCards: observable,
  copy: observable,
  company: observable,
  setCopyData: action,
  pasteData: action,
  loadRateCards: action,
  publicRateCard: observable,
  targetRateCards: observable,
  benchmarkRateCards: observable,
  loadTargetRateCards: action,
  loadBenchmarkRateCards: action,
  companies: observable,
  privateRateCards: observable,
  loadPublicRateCard: action,
  deleteRow: action,
})
