import React, { useContext, useEffect } from "react"
import CsvUploader from '../CsvUploader'
import { observer } from 'mobx-react'
import { Card, Col, Row } from "antd"
import StoreContext from "../../storeContext"
import CompaniesTable from "./CompaniesTable"
import intl from 'react-intl-universal'
import csvExample from '../../documents/import_companies_example.csv'

export default observer(function () {
  const {companiesImport, generalCriteria} = useContext(StoreContext)
  useEffect(()=>{
    generalCriteria.getCodes(false)
    companiesImport.getTempCompanies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={10}>
        <Card title={intl.get('uploadCardTitle')}>
          <CsvUploader
            buttonText={intl.get('uploadCSVButtonText')}
            csvExampleFile={csvExample}
            csvExplanationText={intl.get('uploadExplanation')}
            callback={(rows) =>  companiesImport.import(rows)}
          />
        </Card>
        </Col>
      </Row>
      <Card title={intl.get('tableCardTitle')}>
      <CompaniesTable />
      </Card>
    </>
  )
})
