import { createBrowserHistory } from 'history'
import { syncHistoryWithStore } from 'mobx-react-router'
import RootStore from './store/RootStore'
import { createContext } from 'react'

const browserHistory = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
})
export const rootStore = new RootStore()
export const history = syncHistoryWithStore(browserHistory, rootStore.router)

const StoreContext = createContext(rootStore)

export default StoreContext
