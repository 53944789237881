import React, { createContext, useContext, useEffect, useState } from "react";
import Public from "./Public";
import Target from "./Target";
import { Card } from "antd";
import MinusOutlined from "@ant-design/icons/lib/icons/MinusOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import RateCardTable from "./Table";
import View from "./View";
import Add from "./Add";
import Benchmark from "./Benchmark";
import Private from "./Private";
import { observer } from "mobx-react";
import StoreContext from "../../storeContext";

const RateCard = React.memo(
  observer(({ rateCard, level }) => {
    const [open, setOpen] = useState(false)
    const { cards } = useContext(StoreContext)
    const toggle = () => setOpen((open) => !open)
    useEffect(() => {
      if (cards.toggleAll !== null) {
        setOpen(cards.toggleAll)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cards.toggleAll])
    const renderCards = () =>
      rateCard.children.map((card) => (
        <RateCard.Recursive level={level + 1} rateCard={card} />
      ))
    return (
      <Card
        extra={
          open ? (
            <MinusOutlined onClick={toggle} />
          ) : (
            <PlusOutlined onClick={toggle} />
          )
        }
        bodyStyle={{
          padding: !open && 0,
          height: !open && 0,
          overflow: !open && 'hidden',
        }}
        type={level > 1 && 'inner'}
        title={
          <div style={{ cursor: 'pointer' }} onClick={toggle}>
            {`${rateCard.item_name} (${rateCard.total || 0})`}
          </div>
        }
      >
        {open &&
          (rateCard.child_type === 'group' ? (
            renderCards()
          ) : (
            <RateCard.Table items={rateCard.children} />
          ))}
      </Card>
    )
  }),
)

RateCard.Recursive = React.memo((props) => <RateCard {...props} />)

RateCard.Public = Public
RateCard.Target = Target
RateCard.Private = Private
RateCard.Table = RateCardTable
RateCard.Global = View
RateCard.Add = Add
RateCard.Benchmark = Benchmark
export const CardContext = createContext({
  updateCell: () => console.log('default'),
  updateRes: () => console.log('default rest'),
})
export default RateCard
