import React from 'react'

export default function ({ record }) {
  return record ? (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <span>Score date: {record.standardRating.scoreDate}</span>
      <span>Rating: {record.standardRating.rating}</span>
      <span>Risk segment: {record.standardRating.riskSegment}</span>
      <span>Financial strength: {record.standardRating.financialStrength}</span>
    </div>
  ) : (
    'No data'
  )
}
