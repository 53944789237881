import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import StoreContext from '../../../../storeContext'
import { Tag } from 'antd'
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined'
import MinusCircleOutlined from '@ant-design/icons/lib/icons/MinusCircleOutlined'

const RecursiveGroup = (props) => <Group {...props} />

export const Group = observer(({ group, level }) => {
  const { companyProfile } = useContext(StoreContext)
  const [show, setShow] = useState(group.children.length < 3)
  const getColor = () => {
    switch (level) {
      case 1:
        return {
          color: '#fafafa',
          background: '#0061bb',
          borderColor: '#0061bb',
        }
      case 2:
        return {
          color: '#fafafa',
          background: '#0061bbba',
          borderColor: '#fafafa',
        }
      case 3:
        return {
          color: '#fafafa',
          background: '#0061bb',
          borderColor: '#0061bb',
        }
      default:
        return {
          color: ' #0061bb',
          background: ' #fafafa00',
          borderColor: '#0061bb52',
        }
    }
  }
  return (
    <>
      <Tag
        style={{ ...getColor(), cursor: 'pointer' }}
        onClick={() => setShow((show) => !show)}
      >
        <span>
          {group.item_name} ({companyProfile.getTotalItemsSelected(group, true)}
          )
        </span>
        {group.children.length > 0 && show ? (
          <MinusCircleOutlined style={{ fontSize: 13 }} />
        ) : (
          <PlusCircleOutlined style={{ fontSize: 13 }} />
        )}
      </Tag>

      {show &&
        group.children.map((g, i) => {
          return group.child_type === 'group' ? (
            <RecursiveGroup key={g.item_name} group={g} level={level + 1} />
          ) : (
            <Tag
              key={g}
              style={{
                color: '#0061bb',
                background: '#fafafa00',
                borderColor: '#0061bb52',
              }}
            >
              {g}
            </Tag>
          )
        })}
    </>
  )
})

export default observer(function () {
  const { companyProfile } = useContext(StoreContext)
  const renderGroups = () =>
    companyProfile.expertiseView.map((e) => {
      return e.children.map((ee) => (
        <div>
          <Group key={ee.item_name} group={ee} level={1} />
        </div>
      ))
    })
  return renderGroups()
})
