import React, { useContext, useState } from 'react'
import intl from 'react-intl-universal'
import { Button, Descriptions, Divider, Modal } from 'antd'
import StoreContext from '../../../../storeContext'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

export default observer(function () {
  const { companyProfile } = useContext(StoreContext)
  const [show, setShow] = useState(false)
  if (!companyProfile.company.corporate_linkage) return null
  const {
    domestic_ultimate,
    global_ultimate,
    parent,
  } = companyProfile.company.corporate_linkage
  return !domestic_ultimate && !parent && !global_ultimate ? null : (
    <>
      <Button
        onClick={() => setShow(true)}
        style={{ padding: 0, margin: 0 }}
        type="link"
      >
        {intl.get('showParentCompanies')}
      </Button>
      <Modal footer={null} visible={show} onCancel={() => setShow(false)}>
        {parent && (
          <>
            <Descriptions bordered={true} column={1} title={intl.get('parent')}>
              <Descriptions.Item label={intl.get('companyNameLabel')}>
                {!parent.company_id ? (
                  parent.name
                ) : (
                  <Link to={`/dashboard/company/${parent.company_id}`}>
                    {parent.name}
                  </Link>
                )}
              </Descriptions.Item>
              <Descriptions.Item label={intl.get('address')}>{`${parent.address}, ${parent.city}, ${parent.country}`}</Descriptions.Item>
              <Descriptions.Item label="DUNS">{`${parent.duns}`}</Descriptions.Item>
            </Descriptions>
            <Divider />
          </>
        )}
        {domestic_ultimate && (
          <>
            <Descriptions bordered={true} column={1} title={intl.get('domesticUltimate')}>
              <Descriptions.Item label={intl.get('companyNameLabel')}>
                {!domestic_ultimate.company_id ? (
                  domestic_ultimate.name
                ) : (
                  <Link
                    to={`/dashboard/company/${domestic_ultimate.company_id}`}
                  >
                    {domestic_ultimate.name}
                  </Link>
                )}
              </Descriptions.Item>
              <Descriptions.Item label={intl.get('address')}>{`${domestic_ultimate.address}, ${domestic_ultimate.city}, ${domestic_ultimate.country}`}</Descriptions.Item>
              <Descriptions.Item label="DUNS">{`${domestic_ultimate.duns}`}</Descriptions.Item>
            </Descriptions>
            <Divider />
          </>
        )}
        {global_ultimate && (
          <>
            <Descriptions bordered={true} column={1} title={intl.get('globalUltimate')}>
              <Descriptions.Item label={intl.get('companyNameLabel')}>
                {!global_ultimate.company_id ? (
                  global_ultimate.name
                ) : (
                  <Link to={`/dashboard/company/${global_ultimate.company_id}`}>
                    {global_ultimate.name}
                  </Link>
                )}
              </Descriptions.Item>
              <Descriptions.Item label={intl.get('address')}>{`${global_ultimate.address}, ${global_ultimate.city}, ${global_ultimate.country}`}</Descriptions.Item>
              <Descriptions.Item label="DUNS">{`${global_ultimate.duns}`}</Descriptions.Item>
            </Descriptions>
          </>
        )}
      </Modal>
    </>
  )
})
