import React, { useState } from 'react'
import { Switch } from 'antd'
import { observer } from 'mobx-react'
import intl from 'react-intl-universal'

export default observer(function ({
  visible,
  callback,
  checked = 'hide',
  unchecked = 'show',
}) {
  const [updating, setUpdating] = useState(false)
  return (
    <Switch
      style={{ margin: '0px 5px' }}
      checkedChildren={intl.get(checked)}
      checked={visible}
      loading={updating}
      onClick={async (checked) => {
        setUpdating(true)
        await callback(checked)
        setUpdating(false)
      }}
      unCheckedChildren={intl.get(unchecked)}
    />
  )
})
