import React, { useState } from 'react'
import { Select } from 'formik-antd'
import Countries from '../../constants/Countries'

export default function CountriesDropdown({ name, label, placeholder }) {
  const [countries, setcountries] = useState(Countries)
  return (
    <Select
      filterOption={false}
      showSearch={true}
      onSearch={(txt) => {
        setcountries(
          Countries.filter((country) =>
            country[label].toLowerCase().includes(txt.toLowerCase()),
          ),
        )
      }}
      name={name}
      placeholder={placeholder}
    >
      {countries.map((c) => (
        <Select.Option key={c.alpha_2_code} value={c.alpha_2_code}>
          {`${c[label]}, ${c.alpha_2_code}`}
        </Select.Option>
      ))}
    </Select>
  )
}
