import React, { useContext } from 'react'
import intl from 'react-intl-universal'
import { Card, Col, Modal, Row, Space,  Table } from 'antd'
import { FieldArray, Formik } from 'formik'
import { Form, SubmitButton, DatePicker, Input, Select } from 'formik-antd'
import * as Yup from 'yup'
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined'
import MinusCircleOutlined from '@ant-design/icons/lib/icons/MinusCircleOutlined'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import StoreContext from '../../../../../storeContext'
import EditProfileField from '../../../../Profile/EditProfileField'
import { Roles } from '../../../../../utils/roles'
import ActionButton from '../../../../shared/ActionButton'
import EditRevenueCell from './EditRevenueCell'

export default observer(function ({ visible, handleOnCancel, revenues }) {
  const { companyProfile, user } = useContext(StoreContext)
  const columns = [
    {
      title: 'Year',
      dataIndex: 'year',
      width: 300,
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="year"
          component={(setFieldValue, isSubmiting) => (
            <DatePicker
              onChange={(s, ss) => setFieldValue('year', ss)}
              format="YYYY"
              disabledDate={(curr) => curr.year() > new Date().getFullYear()}
              style={{ width: '100%' }}
              name="year"
              picker="year"
            />
          )}
          callback={async (value, setEdit) => {
            action(() => {
              record.year = value.year
              record.reliability =
                user.user['custom:role_id'] === Roles.CLIENT_ADMIN
                  ? 'Confirmed'
                  : 'Titan'
            })()
            await companyProfile.updateCompany({
              company_id: companyProfile.company.company_id,
              financials: companyProfile.company.financials,
            })
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: 'Reliability',
      width: 100,
      dataIndex: 'reliability',
    },
    {
      title: 'Revenue',
      width: 500,
      render: (text, record) => (
        <Space direction="vertical">
          <EditRevenueCell
            record={record}
          />
        </Space>
      ),
    },
    {
      title: 'Action',
      width: 150,
      render: (text, record) => {
        return (
          <ActionButton
            onClick={async () => {
              await companyProfile.updateCompany({
                company_id: companyProfile.company.company_id,
                financials: companyProfile.company.financials.filter(
                  (f) => f !== record,
                ),
              })
            }}
            text="Delete"
            record={record}
          />
        )
      },
    },
  ]

  const initialValues = {
    year: '',
    yearlyRevenue: [{ value: '', currency: '' }],
    reliability:
      user.user['custom:role_id'] === Roles.CLIENT_ADMIN
        ? 'Confirmed'
        : 'Titan',
  }
  const validation = Yup.object().shape({
    yearlyRevenue: Yup.array()
      .required()
      .of(
        Yup.object().shape({
          value: Yup.number()
            .required('Revenue is required')
            .typeError('Revenue must be a number'),
          currency: Yup.string().required('Currency is required'),
        }),
      ),
    year: Yup.string().required('Year is required'),
  })
  return (
    <Modal
      width="80%"
      title={intl.get('editRevenue')}
      visible={visible}
      footer={null}
      onCancel={handleOnCancel}
    >
      <Row>
        <Col xs={0} sm={0} md={0} lg={0} xl={5} />
        <Col xs={24} sm={24} md={24} lg={24} xl={14}>
          <Card title="Add revenue" type="inner">
            <Formik
              initialValues={initialValues}
              validationSchema={validation}
              onSubmit={async (vals, { resetForm }) => {
                await companyProfile.updateCompany({
                  company_id: companyProfile.company.company_id,
                  financials: [...companyProfile.company.financials, vals],
                })
                resetForm()
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <Form>
                    <FieldArray
                      name="yearlyRevenue"
                      render={(helper) => (
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              marginBottom: 10,
                            }}
                          >
                            Add revenue for currency
                            <PlusCircleOutlined
                              style={{ marginLeft: 10 }}
                              onClick={() =>
                                helper.push({ value: '', currency: '' })
                              }
                            />
                          </div>
                          {values.yearlyRevenue.map((rev, ind) => {
                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  flexWrap: 'wrap'
                                }}
                              >
                                <Form.Item
                                  name={`yearlyRevenue[${ind}].value`}
                                  hasFeedback={true}
                                >
                                  <Input
                                    name={`yearlyRevenue[${ind}].value`}
                                    addonBefore="Revenue"
                                  />
                                </Form.Item>
                                <Form.Item
                                  name={`yearlyRevenue[${ind}].currency`}
                                  hasFeedback={true}
                                >
                                  <Select
                                    placeholder="Select currency"
                                    name={`yearlyRevenue[${ind}].currency`}
                                    addonBefore="Currency"
                                  >
                                    <Select.Option value={'CHF'}>
                                      CHF
                                    </Select.Option>
                                    <Select.Option value={'USD'}>
                                      USD
                                    </Select.Option>
                                    <Select.Option value={'EUR'}>
                                      EUR
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                                <MinusCircleOutlined
                                  style={{
                                    alignSelf: 'center',
                                    marginBottom: 20,
                                  }}
                                  onClick={() => helper.remove(ind)}
                                />
                              </div>
                            )
                          })}
                        </div>
                      )}
                    />

                    <Form.Item name="year" hasFeedback={true}>
                      <DatePicker
                        onChange={(s, ss) => setFieldValue('year', ss)}
                        format="YYYY"
                        style={{ width: '100%' }}
                        disabledDate={(curr) =>
                          curr.year() > new Date().getFullYear()
                        }
                        name="year"
                        picker="year"
                        addonBefore="year"
                      />
                    </Form.Item>
                    <SubmitButton>Add</SubmitButton>
                  </Form>
                )
              }}
            </Formik>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table columns={columns} scroll={{x: 1000}} dataSource={revenues} bordered={true} />
        </Col>
      </Row>
    </Modal>
  )
})
