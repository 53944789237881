import React, { useContext, useRef, useState } from 'react'
import { Button } from 'antd'
import { useClickOutside } from '../../../hooks/useClickOutside'
import { observer } from 'mobx-react'
import StoreContext from '../../../storeContext'
import { RoleGroups } from '../../../utils/roles'

export default observer(function ({ menuItems, show, style }) {
  const [showMenu, setShowMenu] = useState(false)
  const { user, companyProfile } = useContext(StoreContext)

  const menuRef = useRef(null)
  useClickOutside(menuRef, () => setShowMenu(false))
  const renderMenuItems = () =>
    menuItems
      .filter((item) => {
        return !(item.show !== undefined && !item.show)
      })
      .map((item) => (
        <span key={item.name} onClick={item.callback}>
          {item.name}
        </span>
      ))
  if (!show && !RoleGroups.TITANS.includes(user.user['custom:role_id'])) {
    if (!RoleGroups.ADMINS.includes(user.user['custom:role_id'])) return null
    if (user.user.company_id !== companyProfile.company.company_id) return null
  }
  return (
    <div ref={menuRef}>
      <Button
        onClick={(event) => {
          setShowMenu(!showMenu)
        }}
        type="link"
        style={{ margin: 0 }}
      >
        Edit
      </Button>
      {showMenu && (
        <div style={style && style} className="edit-menu">
          {renderMenuItems()}
        </div>
      )}
    </div>
  )
})
