import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import StoreContext from '../../storeContext'
const SidebarLogo = observer(() => {
  const store = useContext(StoreContext)
  return (
    <div className="gx-layout-sider-header">
      <div
        className="gx-linebar"
        onClick={() =>
          (store.layout.sideBarCollapsed = !store.layout.sideBarCollapsed)
        }
      >
        <i
          className={`gx-icon-btn icon icon-menu-${
            store.layout.sideBarCollapsed ? 'unfold' : 'fold'
          }`}
        />
      </div>

      <Link to="/" className="gx-site-logo">
        <img
          alt=""
          width="140"
          src={require('../../assets/images/LogoBlack.svg')}
        />
        {/* <LogoIconBlack style={{ fontSize: '5em' }} /> */}
      </Link>
    </div>
  )
})

export default SidebarLogo
