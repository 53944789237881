import React, { useContext, useEffect } from "react"
import { Modal } from 'antd'
import { Formik } from 'formik'
import { Select, SubmitButton, Form } from 'formik-antd'
import { observer } from 'mobx-react'
import StoreContext from "../../../../storeContext"
import { useParams } from "react-router"
import intl from "react-intl-universal";
export default observer(function ({ visible, handleOnCancel, tags }) {
  const {generalCriteria, companyProfile} = useContext(StoreContext)
  const company_id = useParams().id
  useEffect(()=>{
    generalCriteria.getTags(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const initialValues = {
    tags,
  }
  const renderTags = ()=> generalCriteria.tags.map(tag=>{
    return <Select.Option value={tag.tag_id}>{tag.tag_name}</Select.Option>
  })
  return (
    <Modal
      title={intl.get('editTags')}
      visible={visible}
      footer={null}
      onCancel={handleOnCancel}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (vals, {setSubmitting}) => {
          await companyProfile.updateCompany({...vals, company_id})
          setSubmitting(false)
          handleOnCancel(false)
        }}
      >
        {() => {
          return (
            <Form>
              <Form.Item name="tags">
                <Select mode="multiple" name="tags" placeholder={intl.get('selectTags')}>
                  {renderTags()}
                </Select>
              </Form.Item>
              <SubmitButton>{intl.get('save')}</SubmitButton>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
})
