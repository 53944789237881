import React from "react";
import { Divider, Modal, Space, Statistic } from "antd";
import moment from "moment";
import Rating from "../../Rating";
import SurveyData from "../SurveyData";
import intl from "react-intl-universal";

export default function ({ showPreview, setShowPreview, control }) {
  return (
    <Modal
      title={intl.get('control')}
      visible={showPreview}
      footer={null}
      destroyOnClose={true}
      onCancel={() => setShowPreview(false)}
    >
      <Space style={{ width: '100%' }} direction={'vertical'}>
        <Statistic
          title={intl.get('date')}
          value={
            control.questionaire_completed_date
              ? moment(control.questionaire_completed_date).format(
                  'DD MMMM YYYY',
                )
              : 'N/A'
          }
        />
        <Divider type="horizontal" />
        <Statistic
          title={intl.get('controlStandardRating')}
          valueRender={() => <Rating score={control.rating} />}
        />

        <Divider type="horizontal" />
        <Statistic
          title={intl.get('selfDeclarationResponse')}
          valueRender={() => <SurveyData questions={control.questions} />}
        />
      </Space>
    </Modal>
  )
}
