import { StoreBase } from '../../../store/StoreBase'
import { NetworkCall } from '../../../store/models/NetworkModels'
import { action, computed, decorate, flow, observable, toJS } from 'mobx'
import { computedFn } from 'mobx-utils'
import intl from 'react-intl-universal'

export const CompanyProfileStoreCalls = Object.freeze({
  UPDATE_COMPANY_PROFILE: Object.freeze('update_company_profile'),
  GET_COMPANY_DATA: Object.freeze('getCompanyData'),
  INVITE_BASIC_COMPANY: Object.freeze('inviteBasicCompany'),
  PREFERRED_SUPPLIER_LIST: Object.freeze('preferredSupplierList'),
  EDIT_PREFERRED_SUPPLIER: Object.freeze('editPreferredSupplier'),
  GET_COMPLETENESS: Object.freeze('getCompleteness'),
  INTERNAL_NOTE: Object.freeze('internalNote'),
  PRODUCT: Object.freeze('product'),
  CONTRACTS: Object.freeze('contracts'),
  PERSONAL_NOTE: Object.freeze('personalNote'),
  RISK_CLASSIFICATION: Object.freeze('riskClassification'),
  REQUEST_SURVEY: Object.freeze('requestSurvey'),
  REQUEST_SUCCESS_STORY: Object.freeze('requestSuccessStory'),
  PERSONAL_RISK_NOTE: Object.freeze('personalRiskNote'),
})

export class CompanyProfileStore extends StoreBase {
  company = { trend_expertise: [], products: [] }
  privateRateCard = null
  expertises = []
  industries = []
  industryExpertises = []
  companyExpertises = []
  expertiseView = []
  trends = []
  industryView = []
  storiesTags = []
  preferredSuppliers = []
  loadStatus = 200
  profileCompleteness = null
  partners = []
  loaded = false
  lists = []
  toggleAll = null

  reset = () => {
    this.company = { trend_expertise: [], products: [] }
    this.loaded = false
  }

  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore

    this.updateCompanyProfileCall = new NetworkCall(rootStore, {
      path: 'companyupdate',
      secured: true,
      id: CompanyProfileStoreCalls.UPDATE_COMPANY_PROFILE,
    })

    this.getCompanyDataCall = new NetworkCall(rootStore, {
      path: 'companydetails',
      secured: true,
      id: CompanyProfileStoreCalls.GET_COMPANY_DATA,
    })
    this.inviteBasicCompanyCall = new NetworkCall(rootStore, {
      path: 'invitecompanypremium',
      secured: true,
      id: CompanyProfileStoreCalls.INVITE_BASIC_COMPANY,
    })
    this.preferredSupplierListCall = new NetworkCall(rootStore, {
      path: 'listpreferredsupplierstatus',
      secured: true,
      id: CompanyProfileStoreCalls.PREFERRED_SUPPLIER_LIST,
    })
    this.editPreferredSupplierCall = new NetworkCall(rootStore, {
      path: 'preferredsupplierstatus',
      secured: true,
      id: CompanyProfileStoreCalls.EDIT_PREFERRED_SUPPLIER,
    })
    this.getCompletenessCall = new NetworkCall(rootStore, {
      path: 'profilecompleteness',
      secured: true,
      id: CompanyProfileStoreCalls.GET_COMPLETENESS,
    })
    this.internalNoteCall = new NetworkCall(rootStore, {
      path: 'internalnote',
      secured: true,
      id: CompanyProfileStoreCalls.INTERNAL_NOTE,
    })
    this.editProductCall = new NetworkCall(rootStore, {
      path: 'product',
      secured: true,
      id: CompanyProfileStoreCalls.PRODUCT,
    })
    this.contractsCall = new NetworkCall(rootStore, {
      path: 'contracts',
      secured: true,
      id: CompanyProfileStoreCalls.CONTRACTS,
    })
    this.personalNoteCall = new NetworkCall(rootStore, {
      path: 'personalnote',
      secured: true,
      id: CompanyProfileStoreCalls.PERSONAL_NOTE,
    })
    this.riskClassificationCall = new NetworkCall(rootStore, {
      path: 'riskclassification',
      secured: true,
      id: CompanyProfileStoreCalls.RISK_CLASSIFICATION,
    })
    this.requestSurveyCall = new NetworkCall(rootStore, {
      path: 'surveyrequest',
      secured: true,
      id: CompanyProfileStoreCalls.REQUEST_SURVEY,
    })
    this.requestSuccessStoryCall = new NetworkCall(rootStore, {
      path: 'successstoryrequest',
      secured: true,
      id: CompanyProfileStoreCalls.REQUEST_SUCCESS_STORY,
    })
    this.personalRiskCall = new NetworkCall(rootStore, {
      path: 'personalrisknote',
      secured: true,
      id: CompanyProfileStoreCalls.PERSONAL_RISK_NOTE,
    })
  }

  requestSuccessStory = flow(function* (data) {
    if (this.requestSuccessStoryCall.callInProgress) return
    const res = yield this.requestSuccessStoryCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message: 'Successfully sent success story request',
              placement: 'topRight',
            },
            'success',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })
  requestSurvey = flow(function* (data) {
    if (this.requestSurveyCall.callInProgress) return
    const res = yield this.requestSurveyCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message: 'Successfully sent survey request',
              placement: 'topRight',
            },
            'success',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })
  riskClassification = flow(function* (data, type) {
    if (this.riskClassificationCall.callInProgress) return
    const res = yield this.riskClassificationCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          if (type === 'addEditClassification') {
            this.company = { ...this.company, ...result.data }
          }
          if (type === 'requestPremiumRisk') {
            this.rootStore.toast.setNotification(
              {
                message: intl.get('successRequestPremiumRiskData'),
                placement: 'topRight',
              },
              'success',
            )
          }
          break
        default:
      }
    })
  })

  contracts = async (data, type) => {
    if (this.contractsCall.callInProgress) return
    const res = await this.contractsCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          if (type === 'addFolder') {
            this.company.contracts = [
              ...this.company.contracts,
              result.data.contract_folder,
            ]
          }
          if (type === 'editFolder') {
            this.company.contracts = this.company.contracts.map((contract) => {
              if (
                contract.contract_folder_id ===
                data.edit_folder.contract_folder_id
              )
                return result.data.contract_folder
              return contract
            })
          }
          if (type === 'deleteFolder') {
            this.company.contracts = this.company.contracts.filter(
              (contract) =>
                contract.contract_folder_id !==
                data.remove_folder.contract_folder_id,
            )
          }
          if (type === 'add_document' || type === 'edit_document') {
            this.company.contracts = this.company.contracts.map((contract) => {
              if (
                contract.contract_folder_id === data[type].contract_folder_id
              ) {
                contract.documents = result.data.contract_folder.documents
              }
              return contract
            })
          }
          if (type === 'deleteDocument') {
            this.company.contracts = this.company.contracts.map((contract) => {
              if (
                contract.contract_folder_id ===
                data.remove_document.contract_folder_id
              ) {
                contract.documents = contract.documents.filter(
                  (document) =>
                    document.document_id !== data.remove_document.document_id,
                )
              }
              return contract
            })
          }
          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('result.data.message'),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
      }
    })
  }

  getImplementationPartners = flow(function* () {
    const res = yield this.rootStore.search.searchCall.call(
      'get',
      null,
      `?implementation_partners=true`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.partners = result.data.companies
          break
        default:
      }
    })
  })

  editProduct = flow(function* (data, type) {
    if (this.editProductCall.callInProgress) return
    const res = yield this.editProductCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          if (type === 'add') {
            this.company.products = [
              ...this.company.products,
              result.data.product,
            ]
          } else if (
            type === 'edit' ||
            type === 'addfile' ||
            type === 'removefile'
          ) {
            this.company.products = this.company.products.map((product) => {
              if (product.product_id === data[type].product_id) {
                return { ...product, ...result.data.product }
              }
              return product
            })
          } else if (type === 'remove') {
            this.company.products = this.company.products.filter(
              (product) => product.product_id !== data.remove.product_id,
            )
          }

          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('result.data.message'),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
      }
    })
  })

  internalNote = flow(function* (data) {
    if (this.internalNoteCall.callInProgress) return
    const res = yield this.internalNoteCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.company.internal_note = result.data.internal_note
          break
        default:
      }
    })
  })

  personalRiskNote = flow(function* (data) {
    if (this.personalRiskCall.callInProgress) return
    const res = yield this.personalRiskCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.company.personal_risk_note = result.data.personal_risk_note
          break
        default:
      }
    })
  })
  personalNote = flow(function* (data) {
    if (this.personalNoteCall.callInProgress) return
    const res = yield this.personalNoteCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.company.personal_note = result.data.personal_note
          break
        default:
      }
    })
  })

  getCompleteness = flow(function* () {
    if (this.getCompletenessCall.callInProgress) return
    const res = yield this.getCompletenessCall.call(
      'get',
      null,
      `?company_id=${this.rootStore.user.user.company_id}`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.profileCompleteness = result.data
          break
        default:
      }
    })
  })

  get isPreferredSupplier() {
    const t =
      this.preferredSuppliers.filter(
        (supplier) => supplier.buyer_id === this.rootStore.user.user.company_id,
      ).length > 0
    return t
  }

  editPreferredSupplier = flow(function* (data, type) {
    if (this.editPreferredSupplierCall.callInProgress) return
    const res = yield this.editPreferredSupplierCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          if (type === 'delete') {
            this.preferredSuppliers = this.preferredSuppliers.filter(
              (sup) =>
                sup.preferred_supplier_status_id !==
                data.remove.preferred_supplier_status_id,
            )
          } else if (type === 'add') {
            this.preferredSuppliers = [
              ...this.preferredSuppliers,
              result.data.preferred_supplier_status,
            ]
          } else {
            this.preferredSuppliers = this.preferredSuppliers.map((sup) => {
              if (
                sup.preferred_supplier_status_id ===
                result.data.preferred_supplier_status
                  .preferred_supplier_status_id
              )
                return result.data.preferred_supplier_status
              return sup
            })
          }
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  preferredSupplierList = flow(function* (id) {
    if (this.preferredSupplierListCall.callInProgress) return
    const res = yield this.preferredSupplierListCall.call(
      'get',
      null,
      `?company_id=${this.rootStore.user.user.company_id}&other_company_id=${id}&req_type=company_all`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.preferredSuppliers = result.data.preferred_supplier_status
          break
        default:
      }
    })
  })

  getPrivateRateCard = flow(function* (forCompany) {
    const res = yield this.rootStore.cards.loadPrivateCall.call(
      'get',
      null,
      `?company_id=${this.rootStore.user.user.company_id}&other_company_id=${forCompany}`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.privateRateCard =
            result.data.rate_card_private.length > 0 &&
            result.data.rate_card_private[0]
          break
        default:
      }
    })
  })

  sendPrivateRateCardRequest = flow(function* (id) {
    const res = yield this.rootStore.cards.privateRateCardsCall.call('post', {
      request_card: true,
      companies: [id, this.rootStore.user.user.company_id],
    })
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('privateRateCardRequestSuccess'),
              placement: 'topRight',
            },
            'success',
          )
          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  inviteBasicCompany = flow(function* (data) {
    if (this.inviteBasicCompanyCall.callInProgress) return
    const res = yield this.inviteBasicCompanyCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              placement: 'topRight',
            },
            'success',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  saveBusinessExpertise = async (company_id) => {
    const business_expertise = this.generateSaveItems(
      toJS(this.companyExpertises),
    )
    const trend_expertise = this.generateSaveTrends(toJS(this.trends))
    await this.updateCompany({
      company_id,
      business_expertise,
      trend_expertise,
      expertise: true,
    })
    this.loadBusinessExpertise()
  }

  saveIndustryExpertise = async (company_id, highest_revenue_industry) => {
    const industry_expertise = this.generateSaveItems(
      toJS(this.industryExpertises),
    )
    await this.updateCompany({
      company_id,
      highest_revenue_industry,
      industry_expertise,
    })
    this.loadBusinessExpertise()
  }

  generateSaveTrends = (trends) => {
    trends.forEach((e) => {
      e.trend_expertise_items = e.trend_expertise_items
        .filter((item) => item.selected)
        .map((item) => item.name)
    })

    return trends.filter((t) => t.trend_expertise_items.length > 0)
  }
  generateSaveItems = (exp) => {
    exp.forEach((e) => {
      if (e.child_type === 'item') {
        e.children = e.children.filter((c) => c.selected).map((c) => c.name)
      } else e.children = this.generateSaveItems(e.children, e)
    })
    return exp.filter((e) => e.children.length > 0)
  }

  getTotalItemsSelected = computedFn(function (group, type) {
    let total = 0
    if (group.child_type === 'item') {
      if (type) {
        total += group.children.length
      } else
        group.children.forEach((c) => {
          if (c.selected) total++
        })
    } else {
      total = this.getTotal(group, 0, type)
    }
    return total
  })

  getTotal = (group, total, type) => {
    group.children.forEach((c) => {
      if (c.child_type === 'item') {
        if (type) {
          total = total + c.children.length
        } else
          c.children.forEach((ch) => {
            if (ch.selected) total++
          })
      } else {
        total = this.getTotal(c, total, type)
      }
    })

    return total
  }
  getSelectedItems = (exp, arr, path = '') => {
    exp.forEach((e) => {
      if (e.child_type === 'item') {
        e.children.length > 0 &&
          arr.push(...e.children.map((c) => `${path}.${e.item_name}.${c}`))
      } else this.getSelectedItems(e.children, arr, `${path}.${e.item_name}`)
    })
    return arr
  }

  getExpertiseView = (exp, parent) => {
    exp.forEach((e, i) => {
      if (e.children.length > 0) {
        if (e.child_type === 'group') {
          this.getExpertiseView(e.children, e)
        }
      } else {
        exp.splice(i, 1)
      }
    })

    if (parent) {
      parent.children = exp.filter((e) => e !== null && e.children.length > 0)
    } else {
      return exp.filter((e) => e.children.length > 0)
    }
  }

  loadBusinessExpertise = () => {
    const items = this.getSelectedItems(
      toJS(this.company.business_expertise),
      [],
    )
    const selectedTrends = this.getSelectedTrends()
    this.trends = this.loadTrends(toJS(this.allTrends), selectedTrends)
    this.expertiseView = this.getExpertiseView(
      toJS(this.company.business_expertise),
    )
    const industryItems = this.getSelectedItems(
      toJS(this.company.industry_expertise),
      [],
    )
    this.storiesTags = [
      ...industryItems.map((item) => {
        const temp = item.split('.')
        return `${temp[temp.length - 2]} > ${temp[temp.length - 1]}`
      }),
      ...items.map((item) => {
        const temp = item.split('.')
        return `${temp[temp.length - 2]} > ${temp[temp.length - 1]}`
      }),
      ...selectedTrends,
    ].filter((item, pos, self) => self.indexOf(item) === pos)
    this.industryExpertises = this.mapExpertiseItems(
      toJS(this.industries),
      industryItems,
    )
    this.industryView = this.getExpertiseView(
      toJS(this.company.industry_expertise),
    )
    this.companyExpertises = this.mapExpertiseItems(
      toJS(this.expertises),
      items,
    )
  }

  getSelectedTrends = () => {
    const trends = toJS(this.company.trend_expertise)
    let items = []
    trends.forEach((trend) => {
      items = [...items, ...trend.trend_expertise_items]
    })
    return items
  }

  mapExpertiseItems = (exp, items, path = '') => {
    exp.forEach((e) => {
      if (e.child_type === 'item') {
        e.children = e.children.map((item) => {
          const p = `${path}.${e.item_name}.${
            typeof item === 'string' ? item : item.name
          }`
          return typeof item === 'string'
            ? {
                name: item,
                selected: items.includes(p),
              }
            : {
                name: item.name,
                selected: items.includes(p),
              }
        })
      } else {
        this.mapExpertiseItems(e.children, items, `${path}.${e.item_name}`)
      }
    })
    return exp
  }

  loadTrends = (trends, items) => {
    trends.forEach((t) => {
      t.trend_expertise_items = t.trend_expertise_items.map((i) =>
        typeof i === 'string'
          ? {
              name: i,
              selected: items.includes(i),
            }
          : {
              name: i.name,
              selected: items.includes(i.name),
            },
      )
    })

    return trends
  }

  getCompanyData = flow(function* (company_id) {
    if (this.getCompanyDataCall.callInProgress) return
    const res = yield this.getCompanyDataCall.call(
      'get',
      null,
      `?company_id=${company_id}`,
    )
    res.mapResult((result) => {
      this.loadStatus = result.data.statusCode
      this.loaded = true
      switch (result.data.statusCode) {
        case 200:
          this.company = { ...this.company, ...result.data.company }
          this.lists = result.data.company_lists
          this.expertises = result.data.business_expertises
          this.industries = result.data.industry_expertises
          this.allTrends = result.data.trend_expertises
          this.loadBusinessExpertise()
          this.getPrivateRateCard(this.company.company_id)
          break
        case 400:
          break
        default:
          this.company = { trend_expertise: [] }
          this.rootStore.toast.setNotification(
            {
              message: result.data.message,
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  updateCompany = flow(function* (data, type) {
    if (this.updateCompanyProfileCall.callInProgress) return
    const res = yield this.updateCompanyProfileCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          if (type === 'editSuccessStory') {
            this.company.success_story = this.company.success_story.map(
              (story) => {
                if (story.story_id === data.success_story_edit.story_id)
                  return result.data.company.success_story[0]
                return story
              },
            )
          } else if (type === 'deleteTerms') {
            delete this.company.standard_terms
          } else if (type === 'deleteContactPerson') {
            delete this.company.contact_person
          } else {
            this.company = { ...this.company, ...result.data.company }
          }
          if (result.data.business_expertises) {
            this.expertises = result.data.business_expertises
            this.loadBusinessExpertise()
          }
          if (data.industry_codes) {
            this.saveBusinessExpertise(data.company_id)
          }
          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  setToggle = (value) => {
    this.toggleAll = value
  }
}

decorate(CompanyProfileStore, {
  company: observable,
  companyExpertises: observable,
  expertiseView: observable,
  loadBusinessExpertise: action,
  trends: observable,
  industryExpertises: observable,
  industryView: observable,
  storiesTags: observable,
  privateRateCard: observable,
  preferredSuppliers: observable,
  isPreferredSupplier: computed,
  loadStatus: observable,
  profileCompleteness: observable,
  partners: observable,
  reset: action,
  loaded: observable,
  lists: observable,
  toggleAll: observable,
  setToggle: action,
  contracts: action,
})
