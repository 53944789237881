import { StoreBase } from '../../../store/StoreBase'
import { NetworkCall } from '../../../store/models/NetworkModels'
import { decorate, flow, observable, set, toJS } from 'mobx'

export const GeneralCriteriaStoreCalls = Object.freeze({
  GET_ALL_TAGS: Object.freeze('getAllTags'),
  GET_ALL_CERTIFICATES: Object.freeze('getAllCertificates'),
  UPDATE_TAG: Object.freeze('updateTag'),
  ADD_UPDATE_CERTIFICATE: Object.freeze('addUpdateCertificate'),
  GET_ALL_INDUSTRY_CODES: Object.freeze('getAllIndustryCodes'),
  ADD_UPDATE_INDUSTRY_CODE: Object.freeze('addUpdateIndustryCode'),
})

export class GeneralCriteriaStore extends StoreBase {
  tags = []
  invisibleTags = []
  certificates = []
  codes = []
  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
    this.addUpdateTagCall = new NetworkCall(rootStore, {
      path: 'tagaddupdate',
      secured: true,
      id: GeneralCriteriaStoreCalls.UPDATE_TAG,
    })
    this.getAllCertificatesCall = new NetworkCall(rootStore, {
      path: 'certificatelist',
      secured: true,
      id: GeneralCriteriaStoreCalls.GET_ALL_CERTIFICATES,
    })
    this.addUpdateCertificateCall = new NetworkCall(rootStore, {
      path: 'certificateaddupdate',
      secured: true,
      id: GeneralCriteriaStoreCalls.ADD_UPDATE_CERTIFICATE,
    })
    this.addUpdateIndustryCodeCall = new NetworkCall(rootStore, {
      path: 'industrycodeaddupdate',
      secured: true,
      id: GeneralCriteriaStoreCalls.ADD_UPDATE_INDUSTRY_CODE,
    })
  }

  addUpdateIndustryCode = flow(function* (data, code) {
    if (this.addUpdateIndustryCodeCall.callInProgress) return
    const res = yield this.addUpdateIndustryCodeCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          set(code, { ...code, ...result.data.industry_code })
          this.codes = [...toJS(this.codes)]
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: 'Unexpected error',
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  addUpdateCertificate = flow(function* (data) {
    if (this.addUpdateCertificateCall.callInProgress) return
    const res = yield this.addUpdateCertificateCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: 'Unexpected error',
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  getAllCertificates = flow(function* () {
    if (this.getAllCertificatesCall.callInProgress) return
    const res = yield this.getAllCertificatesCall.call('get', null)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.certificates = result.data.certificates
          const filters = result.data.certificates.map((c) => ({
            label: c.certificate_name_en,
            value: `${c.certificate_name_en}&certificate_list=${c.certificate_name_de}`,
            selected: false,
          }))
          this.rootStore.search.filters = this.rootStore.search.filters.map(
            (f) => {
              if (f.code === 'certificate_list') {
                f.items = filters
              }
              return f
            },
          )

          break
        default:
      }
    })
  })

  addUpdateTag = flow(function* (data) {
    if (this.addUpdateTagCall.callInProgress) return
    const res = yield this.addUpdateTagCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: 'Unexpected error',
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  getCodes = flow(function* (type) {
    this.codes = yield this.rootStore.shared.getAllIndustryCodes(type)
  })
  getTags = flow(function* (type) {
    this.tags = yield this.rootStore.shared.getAllTags(type)
  })

  getInvisibleTags = flow(function* () {
    this.invisibleTags = yield this.rootStore.shared.getInvisibleTags()
    console.log(this.invisibleTags)
  })
}

decorate(GeneralCriteriaStore, {
  tags: observable,
  certificates: observable,
  codes: observable,
  invisibleTags: observable,
})
