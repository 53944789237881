import React, { useContext, useEffect } from 'react'
import StoreContext from '../../storeContext'
import { observer } from 'mobx-react'
import { Progress } from 'antd'
import ProfileCompletenessExtra from './ProfileCompletenessExtra'
import './style.css'
import { FrownOutlined, CrownOutlined } from '@ant-design/icons'
import intl from 'react-intl-universal'

export default observer(function () {
  const { companyProfile } = useContext(StoreContext)
  useEffect(() => {
    companyProfile.getCompleteness()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const profileStrengthColors = {
    red: '#f5222d',
    orange: '#fa8c16',
    yellow: '#fadb14',
    blue: '#1890ff',
    green: '#52c41a',
    gold: '#faad14',
  }
  const findProgressColor = (percent) => {
    switch (true) {
      case percent < 21:
        return profileStrengthColors.red
      case percent < 41:
        return profileStrengthColors.orange
      case percent < 61:
        return profileStrengthColors.yellow
      case percent < 81:
        return profileStrengthColors.blue
      case percent < 100:
        return profileStrengthColors.green
      default:
        return profileStrengthColors.gold
    }
  }
  const progress = companyProfile?.profileCompleteness?.profile_complete_percent
  return (
    <div>
      {companyProfile.profileCompleteness && (
        <div className="progress-wrapp">
          <div className="icon-wrapper">
            <FrownOutlined
              style={
                progress < 21
                  ? {
                      color: profileStrengthColors.red,
                    }
                  : {}
              }
            />
            <Progress
              percent={progress}
              steps={5}
              strokeColor={findProgressColor(progress)}
              showInfo={false}
            />
            &nbsp;{progress}%
            <CrownOutlined
              style={
                progress > 99
                  ? {
                      color: profileStrengthColors.gold,
                    }
                  : {}
              }
            />
          </div>
          <p style={{ textAlign: 'center' }}>
            {intl.get('profileStrength')}{' '}
            {companyProfile.profileCompleteness.profile_complete_description}
          </p>
          <ProfileCompletenessExtra
            completeness={companyProfile.profileCompleteness}
          />
        </div>
      )}
    </div>
  )
})
