import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import StoreContext from '../../storeContext'
import { Card, Col, Dropdown, Row, Menu, Checkbox, Button } from 'antd'
import intl from 'react-intl-universal'
import ActiveRequests from './ActiveRequests'
import Email from '../Email'
import { DownOutlined, MailOutlined } from '@ant-design/icons'
import { action } from 'mobx'

export default observer(function MyRequests() {
  const { requests } = useContext(StoreContext)
  const [showDropdown, setShowDropdown] = useState(false)
  useEffect(() => {
    requests.loadRequests()
    // eslint-disable-next-line
  }, [])
  const getMenu = () => {
    return (
      <Menu>
        {requests.requestTypes.map((col) => {
          return (
            <Menu.Item key={col.value}>
              <Checkbox
                onClick={() => {
                  action(() => {
                    requests.requestTypes = requests.requestTypes.map((t) => {
                      if (col.value === t.value) t.selected = !t.selected
                      return t
                    })
                  })()
                }}
                checked={col.selected}
              >
                {intl.get(col.value)}
              </Checkbox>
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }
  return (
    <Card
      title={intl.get('myRequests')}
      extra={
        <Email
          button={(onClick) => (
            <MailOutlined
              style={{
                marginLeft: 5,
                cursor: 'pointer',
                color: '#038fde',
              }}
              onClick={onClick}
            />
          )}
          ids={[]}
        />
      }
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Dropdown
            visible={showDropdown}
            trigger={['click']}
            overlay={getMenu()}
            onVisibleChange={(view) => !view && setShowDropdown(view)}
          >
            <Button
              style={{ margin: 16 }}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              {intl.get('selectColumns')} <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <ActiveRequests type="active" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <ActiveRequests type="archived" />
        </Col>
      </Row>
    </Card>
  )
})
