import { useFormikContext } from 'formik'
import React from 'react'

function CustomPhone(props, ref) {
  const { handleBlur, errors } = useFormikContext()
  console.log(errors)
  return (
    <input ref={ref} {...props} onBlur={handleBlur} className="ant-input" />
  )
}
export const Phone = React.forwardRef(CustomPhone)
