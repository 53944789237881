import React, { useState } from 'react'
import intl from 'react-intl-universal'
import { Button, message, Space, Upload } from 'antd'
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'
import { UploadOutlined } from '@ant-design/icons'

export default function ({
  url,
  callback,
  urlTitle,
  removeCallback,
  selectFileTitle,
  extensions = ['application/pdf'],
  error = 'File must be pdf',
  sizeLimit = 2,
}) {
  const [editing, setEditing] = useState(false)
  function onFileChange(file) {
    if (!extensions.includes(file.type)) {
      message.error(error)
      return false
    }
    if (file.size / 1024 / 1024 > 2) {
      message.error(`Max size is ${sizeLimit} MB`)
    }

    const reader = new FileReader()
    reader.onload = async () => {
      setEditing(true)
      await callback(reader.result.split(',')[1], file.name)
      setEditing(false)
    }
    reader.readAsDataURL(file)
    return false
  }
  return (
    <Space align="center">
      {url && (
        <>
          <a target="_blank" rel="noopener noreferrer" href={url}>
            {urlTitle}
          </a>
          {removeCallback && (
            <Button
              disabled={editing}
              onClick={async () => {
                setEditing(true)
                await removeCallback()
                setEditing(false)
              }}
            >
              {intl.get('delete')}
              {editing && <LoadingOutlined />}
            </Button>
          )}
        </>
      )}
      <Upload fileList={false} beforeUpload={onFileChange}>
        <Button disabled={editing} style={{ width: '100%' }}>
          <UploadOutlined />
          {selectFileTitle}
          {editing && <LoadingOutlined />}
        </Button>
      </Upload>
    </Space>
  )
}
