import React from 'react'
import { Divider, Typography } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import { FileTextOutlined } from '@ant-design/icons'
const { Text } = Typography
const StyledStory = styled.div`
  .story-date {
    color: rgba(29, 42, 61, 0.5);
  }
  .story-title {
    margin-left: 24px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }
`

export default function ({ story }) {
  return (
    <StyledStory>
      <div className="story-date">
        <FileTextOutlined style={{ marginRight: 10 }} />
        {moment(story.story_date).format('YYYY, MMMM')}
      </div>

      <div className="story-title">
        <Text type="strong">{story.story_title}</Text>
        {story.story_url && (
          <div>
            <a rel="noopener noreferrer" href={story.story_url} target="_blank">
              View
            </a>
          </div>
        )}
      </div>
      {/*  <div className="story-tags">
        {story.story_tags.map((t) => (
          <Tag>{t}</Tag>
        ))}
      </div> */}
      <Divider />
    </StyledStory>
  )
}
