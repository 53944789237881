import React, { useContext, useEffect, useState } from 'react'
import {
  Table,
  Dropdown,
  Menu,
  Checkbox,
  Button,
  Input,
  Card,
  Row,
  Col,
} from 'antd'
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined'
import { observer } from 'mobx-react'
import StoreContext from '../../storeContext'
import { useColumns } from './TableColumns'
import intl from 'react-intl-universal'
import ReloadOutlined from '@ant-design/icons/lib/icons/ReloadOutlined'
import './style.css'
import { CSVLink } from 'react-csv'
import {
  CompaniesOverviewStoreCalls,
  CompaniesOverviewTabs,
} from './store/CompaniesOverviewStore'
import useRowSelect from '../../hooks/useRowSelect'
import ApiImportButtons from './ApiImport/ApiImportButtons'
import TableLoading from './TableLoading'

const { Search } = Input

const tabList = (disabled) => [
  {
    key: CompaniesOverviewTabs.DATA_CLASSIFICATION,
    tab: intl.get('tabDataClassification'),
    disabled,
  },
  { key: CompaniesOverviewTabs.CMPTCS, tab: intl.get('tabCmptcs'), disabled },
  { key: CompaniesOverviewTabs.KYC, tab: intl.get('tabKyc'), disabled },
  {
    key: CompaniesOverviewTabs.BITSIGHT,
    tab: intl.get('tabBitsight'),
    disabled,
  },
  { key: CompaniesOverviewTabs.IMPORT_LOGS, tab: 'Import logs', disabled },
  {
    key: CompaniesOverviewTabs.API_IMPORT,
    tab: intl.get('tabDataCompletionStatus'),
    disabled,
  },
  { key: 'tab4', tab: intl.get('tabActivityStatus'), disabled: true },
  { key: 'tab5', tab: intl.get('tabVisibilityStatus'), disabled: true },
  { key: 'tab6', tab: intl.get('tabCompanyParameters'), disabled: true },
]
export default observer(function () {
  const [cols, setCols] = useState(useColumns())

  const { companiesOverview, router, network } = useContext(StoreContext)
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(router.location.search).get('page')) || 1,
  )
  const [search] = useState(
    new URLSearchParams(router.location.search).get('search') || '',
  )

  useEffect(() => {
    if (companiesOverview.filteredCompanies.length > 0) {
      companiesOverview.search(search)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])
  const { tabKey, setTabKey, filteredCompanies } = companiesOverview
  const [showDropdown, setShowDropdown] = useState(false)
  const rowSelection = useRowSelect(tabKey)

  const getColumns = () => {
    return cols[tabKey].filter((col) => col.selected)
  }
  const toggleItem = (col) => {
    setCols({
      ...cols,
      [tabKey]: cols[tabKey].map((c) => {
        if (col.dataIndex === c.dataIndex && !c.fixed) c.selected = !c.selected
        return c
      }),
    })
  }

  const handleSearch = (e) => {
    router.history.push(`?page=${1}&search=${e}`)
  }

  const getMenu = () => {
    return (
      <Menu>
        {cols[tabKey].map((col) => {
          return (
            <Menu.Item key={col.title}>
              <Checkbox
                onClick={() => {
                  toggleItem(col)
                }}
                checked={col.selected}
              >
                {col.title}
              </Checkbox>
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  const getCsvHeaders = () => {
    return cols[tabKey]
      .filter((col) => col.selected && !col.noCSV)
      .map((col) => {
        return { label: col.title, key: col.dataIndex }
      })
  }

  useEffect(() => {
    companiesOverview.loadTable().then(() => companiesOverview.search(search))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey])

  return (
    <>
      <Card
        style={{ width: '100%' }}
        className="company-overview-card"
        tabList={tabList(
          network.checkRunning(CompaniesOverviewStoreCalls.LOAD_COMPANIES),
        )}
        activeTabKey={tabKey}
        onTabChange={(key) => {
          key !== tabKey && setTabKey(key)
        }}
      >
        <Row>
          <Col>
            <Dropdown
              visible={showDropdown}
              trigger={['click']}
              overlay={getMenu()}
              onVisibleChange={(view) => !view && setShowDropdown(view)}
            >
              <Button
                style={{ margin: 16 }}
                onClick={() => setShowDropdown(!showDropdown)}
              >
                {intl.get('selectColumns')} <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
          <Col>
            <Search
              placeholder={intl.get('searchLabel')}
              defaultValue={search}
              onSearch={handleSearch}
              style={{ width: 200, margin: 16 }}
            />
          </Col>
          {tabKey === CompaniesOverviewTabs.API_IMPORT && (
            <Col>
              <ApiImportButtons
                reset={rowSelection.reset}
                selectedCompanies={rowSelection.selectedRowKeys}
              />
            </Col>
          )}
          <Col>
            <Button
              disabled={companiesOverview.filteredCompanies.length === 0}
              style={{ margin: 16 }}
            >
              {companiesOverview.filteredCompanies.length > 0 && (
                <CSVLink
                  filename="Company_Data.csv"
                  data={companiesOverview.filteredCompanies}
                  headers={getCsvHeaders()}
                >
                  {intl.get('downloadCSV')}
                </CSVLink>
              )}
            </Button>
          </Col>
        </Row>
        <div className="loaded-info">
          <ReloadOutlined onClick={() => companiesOverview.loadTable(true)} />
          {companiesOverview.allCompaniesData[tabKey] &&
            companiesOverview.allCompaniesData[tabKey].lastLoaded}
        </div>
        {filteredCompanies.length > 0 ? (
          <Table
            rowKey="company_id"
            bordered
            rowSelection={rowSelection}
            columns={getColumns()}
            dataSource={companiesOverview.filteredCompanies}
            scroll={{ x: 1500 }}
            pagination={{
              total: companiesOverview.filteredCompanies.length,
              current: page,
              showSizeChanger: true,
              defaultPageSize: 10,
              onChange: (page, size) => {
                setPage(page)
                router.history.push(`?page=${page}&search=${search}`)
              },
              pageSizeOptions: ['10', '20', '50', '100', '500'],
            }}
          />
        ) : (
          <TableLoading />
        )}
      </Card>
    </>
  )
})
