import { Card, Col, Row, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import StoreContext from '../../../storeContext'
import intl from 'react-intl-universal'
import moment from 'moment'
import { sort } from '../../CompaniesOverview/TableColumns'

export default observer(function InvitedUsersTable() {
  const { users } = useContext(StoreContext)
  const columns = [
    {
      title: intl.get('usersTableColumnName'),
      dataIndex: 'name',
      width: 200,
      sorter: (a, b) => sort(a, b, 'name'),
      render: (text, record) =>
        record['name'] ? record['name'] : intl.get('noData'),
    },
    {
      title: intl.get('lastName'),
      dataIndex: 'surname',
      sorter: (a, b) => sort(a, b, 'surname'),
      width: 200,
    },
    {
      title: intl.get('emailLabel'),
      sorter: (a, b) => sort(a, b, 'email'),
      dataIndex: 'email',
      width: 200,
    },
    {
      title: intl.get('inviteDate'),
      dataIndex: 'date_created',
      width: 200,
      sorter: (a, b) =>
        new Date(a['date_created']) - new Date(b['date_created']),
      render: (text, record) =>
        record['date_created']
          ? moment
              .utc(record['date_created'])
              .local()
              .format('MM/DD/YYYY, HH:mm:ss')
          : intl.get('noData'),
    },
  ]
  return (
    <Card title={intl.get('invitedUsers')}>
      <Row>
        <Col span="24">
          <Table
            rowKey="date_created"
            bordered
            columns={columns}
            dataSource={users.invitedUsers}
            pagination={{
              total: users.invitedUsers.length,
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '20', '50', '100'],
            }}
          />
        </Col>
      </Row>
    </Card>
  )
})
