import { StoreBase } from '../../../store/StoreBase'
import { action, computed, decorate, flow, observable } from 'mobx'
import { NetworkCall } from '../../../store/models/NetworkModels'
import intl from 'react-intl-universal'
import React from 'react'
import { computedFn } from 'mobx-utils'

export const CompaniesOverviewTabs = Object.freeze({
  DATA_CLASSIFICATION: Object.freeze('data_classification'),
  API_IMPORT: Object.freeze('api_import'),
  COMPANY_VIEW_MATRIX: Object.freeze('company_view_matrix'),
  CMPTCS: Object.freeze('cmptcs'),
  KYC: Object.freeze('kyc'),
  BITSIGHT: Object.freeze('bitsight'),
  IMPORT_LOGS: Object.freeze('import_logs'),
})
export const CompaniesOverviewStoreCalls = Object.freeze({
  LOAD_COMPANIES: Object.freeze('loadCompanies'),
  UPDATE_COMPANY_VIEW_MATRIX: Object.freeze('updateCompanyViewMatrix'),
  DELETE_COMPANY: Object.freeze('deleteCompany'),
  UPDATE_COMPANY: Object.freeze('updateCompany'),
  FETCH_CMPTCS: Object.freeze('fetchCmptcs'),
  FETCH_KYC: Object.freeze('fetchKyc'),
  FETCH_BITSIGHT: Object.freeze('fetchBitsight'),
})
export class CompaniesOverviewStore extends StoreBase {
  allCompaniesData = {
    [CompaniesOverviewTabs.DATA_CLASSIFICATION]: {
      data: [],
      lastLoaded: '',
    },
    [CompaniesOverviewTabs.API_IMPORT]: {
      data: [],
      lastLoaded: '',
    },
    [CompaniesOverviewTabs.COMPANY_VIEW_MATRIX]: {
      data: [],
      lastLoaded: '',
    },
    [CompaniesOverviewTabs.CMPTCS]: {
      data: [],
      lastLoaded: '',
    },
    [CompaniesOverviewTabs.KYC]: {
      data: [],
      lastLoaded: '',
    },
    [CompaniesOverviewTabs.BITSIGHT]: {
      data: [],
      lastLoaded: '',
    },
    [CompaniesOverviewTabs.IMPORT_LOGS]: {
      data: [],
      lastLoaded: '',
    },
  }
  filteredCompanies = []
  tabKey = CompaniesOverviewTabs.DATA_CLASSIFICATION

  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
    this.loadCompaniesCall = new NetworkCall(rootStore, {
      path: 'companyoverview',
      secured: true,
      id: CompaniesOverviewStoreCalls.LOAD_COMPANIES,
    })
    this.updateViewMatrixCall = new NetworkCall(rootStore, {
      path: 'updatecompanyviewmatrix',
      secured: true,
      id: CompaniesOverviewStoreCalls.UPDATE_COMPANY_VIEW_MATRIX,
    })
    this.deleteCall = new NetworkCall(rootStore, {
      path: 'deletecompany',
      secured: true,
      id: CompaniesOverviewStoreCalls.DELETE_COMPANY,
    })
    this.updateCompanyCall = new NetworkCall(rootStore, {
      path: 'companyupdate',
      secured: true,
      id: CompaniesOverviewStoreCalls.UPDATE_COMPANY,
    })
    this.fetchCmptcsCall = new NetworkCall(rootStore, {
      path: 'cmptcs',
      secured: true,
      id: CompaniesOverviewStoreCalls.FETCH_CMPTCS,
    })
    this.fetchKycCall = new NetworkCall(rootStore, {
      path: 'kyc',
      secured: true,
      id: CompaniesOverviewStoreCalls.FETCH_KYC,
    })
    this.fetchBitsightCall = new NetworkCall(rootStore, {
      path: 'bitsight',
      secured: true,
      id: CompaniesOverviewStoreCalls.FETCH_BITSIGHT,
    })
  }

  search = (value) => {
    this.filteredCompanies = this.allCompaniesData[this.tabKey].data.filter(
      (comp) => {
        const allValues = Object.values(comp).join(' ').toLowerCase()
        return allValues.includes(value.toLowerCase())
      },
    )
  }

  setTabKey = (key) => {
    this.filteredCompanies = []
    this.tabKey = key
  }

  fetchBitsight = flow(function* (data) {
    if (this.fetchBitsightCall.callInProgress) return
    const res = yield this.fetchBitsightCall.call('post', data)
    res.mapResult(async (result) => {
      switch (result.data.statusCode) {
        case 200:
          await this.loadTable(true)
          this.rootStore.toast.setNotification(
            {
              message: 'You have successfuly requested Bitsight data',
              description: (
                <div>
                  {intl.get('totalCompaniesDataImported', {
                    0: result.data.updated_companies,
                  })}
                  <span>
                    Total cases created: {result.data.case_created.length}
                  </span>
                  <span>
                    Total cases errors: {result.data.case_errors.length}
                  </span>
                </div>
              ),
              placement: 'topRight',
            },
            'success',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  fetchKYC = flow(function* (data) {
    if (this.fetchKycCall.callInProgress) return
    const res = yield this.fetchKycCall.call('post', data)
    res.mapResult(async (result) => {
      switch (result.data.statusCode) {
        case 200:
          await this.loadTable(true)
          this.rootStore.toast.setNotification(
            {
              message: 'You have successfuly requested KYC data',
              description: (
                <div>
                  {intl.get('totalCompaniesDataImported', {
                    0: result.data.updated_companies,
                  })}
                  <span>
                    Total cases created: {result.data.case_created.length}
                  </span>
                  <span>
                    Total cases errors: {result.data.case_errors.length}
                  </span>
                </div>
              ),
              placement: 'topRight',
            },
            'success',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  fetchCMPTCS = flow(function* (data) {
    if (this.fetchCmptcsCall.callInProgress) return
    const res = yield this.fetchCmptcsCall.call('post', data)
    res.mapResult(async (result) => {
      switch (result.data.statusCode) {
        case 200:
          await this.loadTable(true)
          this.rootStore.toast.setNotification(
            {
              message: intl.get('cmptcsSuccess'),
              description: intl.get('totalCompaniesDataImported', {
                0: result.data.updated_companies,
              }),
              placement: 'topRight',
            },
            'success',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  updateCompany = flow(function* (company_id, data, field, value) {
    if (this.updateCompanyCall.callInProgress) return
    const res = yield this.updateCompanyCall.call('post', {
      ...data,
      company_id,
    })
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('successUpdateCompany'),
              placement: 'topRight',
            },
            'success',
          )
          this.filteredCompanies = this.filteredCompanies.map((c) => {
            if (c.company_id === company_id) c[field] = value
            return c
          })
          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  updateViewMatrix = flow(function* (data) {
    if (this.updateViewMatrixCall.callInProgress) return true
    const res = yield this.updateViewMatrixCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.filteredCompanies = this.filteredCompanies.map((comp) => {
            if (data.companies.includes(comp.company_id)) {
              return { ...comp, ...data }
            }
            return comp
          })
          this.rootStore.toast.setNotification(
            {
              message: intl.get('successUpdateCompany'),
              placement: 'topRight',
            },
            'success',
          )
          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  getTotalCompaniesForApi = computedFn(function (type) {
    let total = 0
    if (this.tabKey === CompaniesOverviewTabs.API_IMPORT) {
      this.filteredCompanies.forEach((c) => {
        total += c[type]
      })
    }
    return total
  })

  get totalDuns() {
    let total = 0
    if (this.tabKey === CompaniesOverviewTabs.API_IMPORT) {
      this.filteredCompanies.forEach((c) => {
        c.duns && total++
      })
    }
    return total
  }

  loadTable = flow(function* (reload) {
    if (this.allCompaniesData[this.tabKey].data.length > 0 && !reload) {
      this.filteredCompanies = this.allCompaniesData[this.tabKey].data
      return
    }
    if (this.loadCompaniesCall.callInProgress) return
    const res = yield this.loadCompaniesCall.call('post', { tab: this.tabKey })
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.allCompaniesData[this.tabKey].data =
            this.tabKey === CompaniesOverviewTabs.IMPORT_LOGS
              ? result.data.logs
              : result.data.companies
          this.allCompaniesData[
            this.tabKey
          ].lastLoaded = new Date().toLocaleTimeString()
          this.filteredCompanies =
            this.tabKey === CompaniesOverviewTabs.IMPORT_LOGS
              ? result.data.logs
              : result.data.companies
          break
        default:
      }
    })
    /* this.allCompaniesData[tab].lastLoaded = new Date().toLocaleTimeString()
    this.filteredCompanies = tableData[tab].data*/
  })
  delete = flow(function* (company_id) {
    if (this.deleteCall.callInProgress) return
    const res = yield this.deleteCall.call('post', {
      table: 'company',
      company_id,
    })
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('successDeletingCompany'),
              placement: 'topRight',
            },
            'success',
          )
          this.filteredCompanies = this.filteredCompanies.filter(
            (o) => o.company_id !== company_id,
          )
          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
      }
    })
  })
}

decorate(CompaniesOverviewStore, {
  allCompaniesData: observable,
  filteredCompanies: observable,
  search: action,
  delete: action,
  tabKey: observable,
  totalDuns: computed,
})
