import { Button } from 'antd'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import StoreContext from '../../../storeContext'
import intl from 'react-intl-universal'

const DESCRIPTION_SIZE = 300
export default observer(function ItemDescription({ item }) {
  const { search } = useContext(StoreContext)
  const currentLocale = intl.determineLocale({
    localStorageLocaleKey: 'lang',
  })
  const getDescription = () =>
    currentLocale === 'en-US'
      ? item.company_description_en
        ? item.company_description_en
        : item.company_description_de
      : item.company_description_de
      ? item.company_description_de
      : item.company_description_en

  const descControll = () => {
    if (search.selectedDescription === item.company_id) {
      return (
        <>
          {getDescription()}
          <Button
            style={{
              lineHeight: '10px',
              margin: 0,
              height: 'fit-content',
            }}
            type="link"
            size="small"
            onClick={() => search.setSelectedDescription(null)}
          >
            Show less
          </Button>
        </>
      )
    }
    return (
      <>
        {`${getDescription().substring(0, DESCRIPTION_SIZE)}...`}
        <Button
          type="link"
          size="small"
          style={{
            lineHeight: '10px',
            margin: 0,
            height: 'fit-content',
          }}
          onClick={() => search.setSelectedDescription(item.company_id)}
        >
          Show more
        </Button>
      </>
    )
  }
  return (
    <div>
      {getDescription().length > DESCRIPTION_SIZE
        ? descControll()
        : getDescription()}
    </div>
  )
})
