import React, { useContext, useEffect, useState } from 'react'
import { Card, Checkbox } from 'antd'
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined'
import MinusOutlined from '@ant-design/icons/lib/icons/MinusOutlined'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import StoreContext from '../../../../storeContext'

export default observer(function ({ trend }) {
  const [show, setShow] = useState(false)
  const { companyProfile } = useContext(StoreContext)
  useEffect(() => {
    if (companyProfile.toggleAll !== null) {
      setShow(companyProfile.toggleAll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProfile.toggleAll])
  return (
    <Card
      title={
        <h3
          style={{ cursor: 'pointer' }}
          onClick={() => setShow((show) => !show)}
        >{`${trend.trend_area}`}</h3>
      }
      type="inner"
      bodyStyle={{
        padding: !show && 0,
        height: !show && 0,
        overflow: !show && 'hidden',
      }}
      extra={
        !show ? (
          <PlusOutlined onClick={() => setShow((show) => !show)} />
        ) : (
          <MinusOutlined onClick={() => setShow((show) => !show)} />
        )
      }
    >
      {trend.trend_expertise_items.map((item) => {
        return (
          <Checkbox
            checked={item.selected}
            onChange={(e) => {
              action(() => {
                item.selected = e.target.checked
              })()
            }}
            style={{ margin: 8 }}
          >
            {item.name}
          </Checkbox>
        )
      })}
    </Card>
  )
})
