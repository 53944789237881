import React, { useContext } from "react";
import { Card, Col, Row } from "antd";
import { observer } from "mobx-react";
import StoreContext from "../../../../storeContext";
import intl from "react-intl-universal";

export default observer(function () {
  const { companyProfile } = useContext(StoreContext)
  const currentLocale = intl.determineLocale({
    localStorageLocaleKey: 'lang',
  })
  if (!companyProfile.company.standard_terms) return null
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Card title="Standard Terms & Condition" type="inner" bordered={false}>
          {currentLocale === 'en-US' ? (
            companyProfile.company.standard_terms.terms_en ? (
              <a
                rel="noopener noreferrer"
                href={companyProfile.company.standard_terms.terms_en.file_url}
                target="_blank"
              >
                {companyProfile.company.standard_terms.terms_en.file_name}
              </a>
            ) : (
              <a
                rel="noopener noreferrer"
                href={companyProfile.company.standard_terms.terms_de.file_url}
                target="_blank"
              >
                {companyProfile.company.standard_terms.terms_de.file_name}
              </a>
            )
          ) : companyProfile.company.standard_terms.terms_de ? (
            <a
              rel="noopener noreferrer"
              href={companyProfile.company.standard_terms.terms_de.file_url}
              target="_blank"
            >
              {companyProfile.company.standard_terms.terms_de.file_name}
            </a>
          ) : (
            <a
              rel="noopener noreferrer"
              href={companyProfile.company.standard_terms.terms_en.file_url}
              target="_blank"
            >
              {companyProfile.company.standard_terms.terms_en.file_name}
            </a>
          )}
        </Card>
      </Col>
    </Row>
  )
})
