import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import {
  Button,
  Col,
  List,
  Pagination,
  Popover,
  Row,
  Skeleton,
  Switch,
} from 'antd'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import StoreContext from '../../../storeContext'
import { Loading } from '../../loading'
import { SearchStoreCalls } from '../store/SearchStore'
import FilterItem from './FilterItem'
import Sort from './Sort'
import Loading3QuartersOutlined from '@ant-design/icons/lib/icons/Loading3QuartersOutlined'
import MenuOutlined from '@ant-design/icons/lib/icons/MenuOutlined'
import intl from 'react-intl-universal'
import ItemAvatar from './ItemAvatar'
import AddCompaniesToList from '../AddCompaniesToList'
import {
  BarChartOutlined,
  CloseOutlined,
  StarOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'
import ItemDescription from './ItemDescription'
import Products from './Products'

const StyledAdvancedSearch = styled.div`
  & h3,
  h4 {
    margin: 0px;
  }
  .filters {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(29, 42, 61, 0.05);
    border-radius: 4px;
    height: fit-content;
  }
  .sort-and-items {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .total-items {
    background: #fafafa;
    padding: 9px;
  }
  .sort-action {
    display: flex;
    > :nth-child(2) {
      margin: 0 10px;
    }
  }
  .desc-wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .icon-wrap {
    padding: 8px;
    background: #f0f0f0;
    text-align: center;
    border-radius: 50%;
    display: flex;
    margin-right: 5px;
  }
  .icon-wrap.icon-active {
    background: #1890ff;
    color: white;
  }
  .ant-list-item-meta-content {
    align-self: baseline;
  }
  .product-switch {
    margin-bottom: 14px;
    background: white;
    padding: 19px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(29, 42, 61, 0.05);
    border-radius: 4px;
    display: flex;
    align-items: center;
    > span {
      margin-left: 10px;
      font-size: 14px;
      color: #7e889b;
    }
  }
`

export default observer(function () {
  const { search, generalCriteria } = useContext(StoreContext)
  const [showFilters, setShowFilters] = useState(true)
  const [showAdd, setShowAdd] = useState(false)
  const [showProducts, setShowProducts] = useState(false)
  useEffect(() => {
    search.advancedSearch()
    generalCriteria.getAllCertificates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const keyPress = (e) => {
    if (e.key === 'Enter') {
      search.advancedSearch()
    }
  }
  return (
    <StyledAdvancedSearch className="advanced-search">
      <Row gutter="10">
        <Col xs={24} sm={24} md={9} lg={8} xl={6}>
          <div className="product-switch">
            <Switch
              onChange={(checked) => setShowProducts(checked)}
              checked={showProducts}
            />
            <span>Product View</span>
          </div>
          <div className="filters">
            <div className="filters-title">
              <h3>
                {intl.get('filters')}
                <CloseOutlined
                  style={{ fontSize: 14, marginLeft: 5 }}
                  onClick={search.resetFiltersAction}
                />
              </h3>
              <MenuOutlined
                className="filters-title-icon"
                onClick={() => setShowFilters((show) => !show)}
              />
            </div>
            {showFilters && (
              <>
                <div className="filter-items">
                  {search.filters.map((filter) => (
                    <FilterItem filter={filter} />
                  ))}
                </div>
                <Loading blockers={[SearchStoreCalls.SEARCH]}>
                  <Loading.Done>
                    <div
                      onClick={() => search.advancedSearch()}
                      className="apply-filters"
                    >
                      {intl.get('applyFilters')}
                    </div>
                  </Loading.Done>
                  <Loading.InProgress>
                    <div className="apply-filters">
                      {intl.get('applyFilters')}
                      <Loading3QuartersOutlined spin />
                    </div>
                  </Loading.InProgress>
                </Loading>
              </>
            )}
          </div>
        </Col>
        <Col xs={24} sm={24} md={15} lg={16} xl={18}>
          <div className="search-header">
            <SearchOutlined />
            <input
              type="search"
              value={search.searchTerm}
              onKeyPress={keyPress}
              onChange={(e) =>
                action(() => {
                  search.searchTerm = e.target.value
                })()
              }
              placeholder={intl.get('searchPlaceholder')}
            />
            <Loading blockers={[SearchStoreCalls.SEARCH]}>
              <Loading.Done>
                <Button
                  size="middle"
                  onClick={() => search.advancedSearch()}
                  className="search-icon"
                  type="primary"
                >
                  Search
                </Button>
                {/* <div
                  onClick={() => search.advancedSearch()}
                  className="search-icon"
                >
                  <SearchOutlined />
                </div> */}
              </Loading.Done>
              <Loading.InProgress>
                <Button
                  size="middle"
                  onClick={() => search.advancedSearch()}
                  className="search-icon"
                  type="primary"
                >
                  <Loading3QuartersOutlined spin /> Searching
                </Button>
              </Loading.InProgress>
            </Loading>
          </div>

          <Loading blockers={[SearchStoreCalls.SEARCH]}>
            <Loading.Done>
              <div className="sort-and-items">
                <Sort setShowAdd={setShowAdd} />
                {showProducts ? (
                  <Products />
                ) : (
                  <div className="search-items">
                    <List
                      itemLayout="horizontal"
                      dataSource={search.results.slice(
                        (search.currentPage - 1) * search.perPage,
                        search.currentPage * search.perPage,
                      )}
                      renderItem={(item) => (
                        <List.Item style={{ gap: '1em' }}>
                          <List.Item.Meta
                            avatar={<ItemAvatar item={item} />}
                            description={
                              <div>
                                <div className="desc-wrap">
                                  <Link
                                    to={`/dashboard/company/${item.company_id}`}
                                  >
                                    {item.name}
                                  </Link>
                                  <div style={{ display: 'flex' }}>
                                    <div
                                      className={`icon-wrap ${
                                        item.list_member ? 'icon-active' : ''
                                      }`}
                                    >
                                      <Popover
                                        placement="topLeft"
                                        content={
                                          item.preferred_supplier
                                            ? intl.get('searchIconInList')
                                            : intl.get('searchIconNotInList')
                                        }
                                        trigger="hover"
                                      >
                                        <UnorderedListOutlined />
                                      </Popover>
                                    </div>
                                    <div
                                      className={`icon-wrap ${
                                        item.preferred_supplier
                                          ? 'icon-active'
                                          : ''
                                      }`}
                                    >
                                      <Popover
                                        placement="topLeft"
                                        content={
                                          item.preferred_supplier
                                            ? intl.get(
                                                'searchIconPreferredSupplier',
                                              )
                                            : intl.get(
                                                'searchIconNotPreferredSupplier',
                                              )
                                        }
                                        trigger="hover"
                                      >
                                        <StarOutlined />
                                      </Popover>
                                    </div>
                                    <div
                                      className={`icon-wrap ${
                                        item.has_rate_card ? 'icon-active' : ''
                                      }`}
                                    >
                                      <Popover
                                        placement="topLeft"
                                        content={
                                          item.has_rate_card
                                            ? intl.get('searchIconRateCard')
                                            : intl.get('searchIconNoRateCard')
                                        }
                                        trigger="hover"
                                      >
                                        <BarChartOutlined />
                                      </Popover>
                                    </div>
                                  </div>
                                </div>
                                <ItemDescription item={item} />
                              </div>
                            }
                          />
                          {/* <Details item={item} /> */}
                        </List.Item>
                      )}
                    />
                    <Pagination
                      defaultCurrent={1}
                      pageSize={search.perPage}
                      onShowSizeChange={(current, size) => {
                        action(() => {
                          search.perPage = size
                        })()
                      }}
                      onChange={(page) => {
                        action(() => {
                          search.currentPage = page
                        })()
                      }}
                      current={search.currentPage}
                      total={search.results.length}
                    />
                  </div>
                )}
              </div>
            </Loading.Done>
            <Loading.InProgress>
              <div style={{ padding: 20 }}>
                <Skeleton active />
              </div>
            </Loading.InProgress>
          </Loading>
        </Col>
      </Row>
      <AddCompaniesToList
        visible={showAdd}
        handleOnCancel={() => setShowAdd(false)}
      />
    </StyledAdvancedSearch>
  )
})
