import * as Yup from 'yup'
import intl from 'react-intl-universal'

export const ValidationFields = {
  name: () =>
    Yup.string()
      .required(intl.get('nameErrorRequired'))
      .max(20, intl.get('nameErrorMax', { 0: '20' })),
  surname: () =>
    Yup.string()
      .required(intl.get('surnameErrorRequired'))
      .max(20, intl.get('surnameErrorMax', { 0: '20' })),
  email: () =>
    Yup.string()
      .email(intl.get('emailErrorNotValid'))
      .required(intl.get('emailErrorRequired')),
  password: () =>
    Yup.string()
      .required(intl.get('passwordErrorRequired'))
      .min(8, intl.get('passwordErrorMin', { 0: 8 }))
      .matches(
        /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/,
        intl.get('passwordErrorStrength'),
      ),
  confirmPassword: () =>
    Yup.string()
      .required(intl.get('passwordConfirmErrorRequired'))
      .oneOf([Yup.ref('password')], intl.get('passwordConfirmErrorMatch')),
  phone: () =>
    Yup.string().matches(
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/im,
      intl.get('phoneErrorFormat'),
    ),
  position: () =>
    Yup.string()
      .required(intl.get('positionErrorRequired'))
      .max(20, intl.get('positionErrorMax', { 0: 50 })),
  companyType: () =>
    Yup.number().required().oneOf([1, 2], intl.get('userTypeError')),
  companyName: () =>
    Yup.string()
      .required(intl.get('companyErrorRequired'))
      .max(50, intl.get('companyErrorMax', { 0: 50 })),
  country: () => Yup.string().required(intl.get('selectCountryErrorRequired')),
  vat: () => Yup.string().required(intl.get('vatErrorRequired')),
  terms: () =>
    Yup.boolean().oneOf([true], intl.get('acceptTermsErrorRequired')),
  code: () =>
    Yup.string()
      .required(intl.get('confirmationCodeErrorRequired'))
      .matches(/^([0-9]\s){6}$/, intl.get('confirmationCodeErrorNotValid')),
  certificateNameEn: () =>
    Yup.string().required(intl.get('certificateNameEnRequired')),
  certificateNameDe: () =>
    Yup.string().required(intl.get('certificateNameDeRequired')),
  certificateAuthority: () =>
    Yup.string().required(intl.get('certificateAuthorityRequired')),
}
