import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import { toJS } from 'mobx'

export default function ({ details }) {
  const [show, setShow] = useState(false)
  const renderDetails = () =>
    Object.keys(toJS(details)).map((key) => {
      return <span style={{marginBottom: 10}}>{`${key}: ${details[key]}`}</span>
    })

  return details ? (
    <>
      <Button onClick={() => setShow(true)}>Company details</Button>
      <Modal
        title="Details"
        visible={show}
        onCancel={() => setShow(false)}
        footer={[
          <Button key="back" onClick={() => setShow(false)}>
            Ok
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {renderDetails()}
        </div>
      </Modal>
    </>
  ) : (
    'No data'
  )
}
