import React, { useContext, useEffect } from 'react'
import ProfileHeader from './ProfileHeader'
import { Card, Col, Row, Skeleton } from 'antd'
import ProfileInfo from './ProfileInfo'
import './style.css'
import { observer } from 'mobx-react'
import StoreContext from '../../storeContext'
import { UserProfileStoreCalls } from './store/UserProfileStore'
import { Loading } from '../loading'
import SingleUser from '../Users/AddUser/SingleUser'
import { Roles } from '../../utils/roles'
import styled from 'styled-components'

const StyledProfile = styled.div`
  .edit-profile-field-wrapper {
    font-weight: 500;
  }
  .gx-profile-banner {
    margin: 0px;
    padding-bottom: 0px;
    background: #1d2a3d;
    border-radius: 4px 4px 0px 0px;
    & .gx-profile-banner-avatar {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      padding: 7px;
      position: relative;
      & .upload-button {
        position: absolute;
        padding: 5px;
        border-radius: 50%;
        text-align: center;
        background: #1890ff;
        width: 28px;
        right: 6px;
        bottom: 6px;
        cursor: pointer;
      }
      & .avatar-wrap {
        border-radius: 50%;
        padding: 4px;
        border: 3px solid rgba(255, 255, 255, 0.2);
      }
      & .gx-mr-3 {
        margin: 0 !important;
      }
    }
  }
  .gx-profile-banner::before {
    content: none;
  }
`
export default observer(function () {
  const { profile, user, router } = useContext(StoreContext)
  const userProfile = new URLSearchParams(router.location.search).get('user')
  useEffect(() => {
    profile.loadProfile(
      userProfile ? userProfile : user.user['cognito:username'],
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile])
  return (
    <Loading blockers={[UserProfileStoreCalls.LOAD_PROFILE]}>
      <Loading.InProgress>
        <Skeleton avatar paragraph={{ rows: 4 }} />
      </Loading.InProgress>
      <Loading.Done>
        <StyledProfile>
          <ProfileHeader
            id={userProfile ? userProfile : user.user['cognito:username']}
          />
          <div className="gx-profile-content">
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <ProfileInfo
                  id={userProfile ? userProfile : user.user['cognito:username']}
                />
              </Col>
              {user.user['custom:role_id'] === Roles.CLIENT_USER && (
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Card title="Invite user" className="gx-card-widget">
                    <SingleUser />
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        </StyledProfile>
      </Loading.Done>
    </Loading>
  )
})
