import { Avatar, Popover } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Pin from '../../../../assets/svgs/map-pin.svg'
import Navigation from '../../../../assets/svgs/navigation.svg'
import {
  BarChartOutlined,
  StarOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import intl from 'react-intl-universal'

const StyledCompanyItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-bottom: 12px;
  flex-direction: row;
  align-items: center;
  .icon-wrap {
    margin-bottom: 5px;
  }
  > div:first-of-type {
    font-size: 12px;
    color: #1d2a3d;
  }
  & .avatar-icons {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 5px;
    color: rgba(29, 42, 61, 0.5);
    > img {
      margin-right: 4px;
    }
  }
`

export default function ProductCompany({ company }) {
  if (!company) return null
  return (
    <StyledCompanyItem>
      <div>
        <div>Provider:</div>
        <div style={{ marginBottom: 5 }}>
          <Avatar
            className="item-avatar"
            shape="square"
            size={40}
            src={`http://${company.logo_url}`}
          />
        </div>
        <div className="avatar-icons">
          <img alt="city icon" src={Pin} />
          <span>{company.city}</span>
        </div>
        <div className="avatar-icons">
          <img alt="city icon" src={Navigation} />
          <span>{`${Math.round(company.distance)} km`}</span>
        </div>
        <div>
          <Link to={`/dashboard/company/${company.company_id}`}>
            {company.name}
          </Link>
        </div>
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto' }}
      >
        <div
          className={`icon-wrap ${company.list_member ? 'icon-active' : ''}`}
        >
          <Popover
            placement="topLeft"
            content={
              company.preferred_supplier
                ? intl.get('searchIconInList')
                : intl.get('searchIconNotInList')
            }
            trigger="hover"
          >
            <UnorderedListOutlined />
          </Popover>
        </div>
        <div
          className={`icon-wrap ${
            company.preferred_supplier ? 'icon-active' : ''
          }`}
        >
          <Popover
            placement="topLeft"
            content={
              company.preferred_supplier
                ? intl.get('searchIconPreferredSupplier')
                : intl.get('searchIconNotPreferredSupplier')
            }
            trigger="hover"
          >
            <StarOutlined />
          </Popover>
        </div>
        <div
          className={`icon-wrap ${company.has_rate_card ? 'icon-active' : ''}`}
        >
          <Popover
            placement="topLeft"
            content={
              company.has_rate_card
                ? intl.get('searchIconRateCard')
                : intl.get('searchIconNoRateCard')
            }
            trigger="hover"
          >
            <BarChartOutlined />
          </Popover>
        </div>
      </div>
    </StyledCompanyItem>
  )
}
