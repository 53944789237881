import React, { useContext } from 'react'
import Search from '../../components/Search'
import ProfileCompleteness from '../../components/ProfileCompleteness'
import { Button, Card } from 'antd'
import {
  IdcardOutlined,
  ProfileOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import intl from 'react-intl-universal'
import './welcome.css'
import StoreContext from '../../storeContext'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const StyledWelcomeCard = styled(Card)`
  border: 0px;
  background: none;
  .welcome-item {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 4px 20px rgba(29, 42, 61, 0.05);
    border-radius: 4px;
    flex: 1 1 350px;
    margin-right: 32px;
    margin-bottom: 32px;
    padding: 30px;
    @media (max-width: 768px) {
      margin-right: 0px;
    }
  }
  .welcome-item-icon {
    width: fit-content;
    padding: 5px;
    margin: 0 auto;
    margin-bottom: 15px;
    border-radius: 50%;
    background: rgba(24, 144, 255, 0.1);

    & .anticon {
      font-size: 30px;
      color: #1890ff;
    }
    > div {
      padding: 15px;
      border-radius: 50%;
      border: 3px solid rgba(24, 144, 255, 0.2);
    }
  }
`
const WelcomePage = observer(() => {
  const { user } = useContext(StoreContext)
  const iconContainer = {
    alignContent: 'center',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }
  return (
    <div className="welcomepage">
      <StyledWelcomeCard>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <h1>{intl.get('welcomeToTitan')}</h1>
          <div
            style={{
              maxWidth: '500px',
            }}
          >
            <ProfileCompleteness />
          </div>
        </div>
        {/* </Card><Card> */}

        <Search />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          <div className="welcome-item" style={iconContainer}>
            <div className="welcome-item-icon">
              <div>
                <ProfileOutlined />
              </div>
            </div>
            <h4>{intl.get('myLists')}</h4>
            <p style={{ flex: '1 1 0' }}>{intl.get('myListsDesc')}</p>
            <Link to="/dashboard/company-lists">
              <Button type="primary">{intl.get('manageLists')}</Button>
            </Link>
          </div>
          <div className="welcome-item" style={iconContainer}>
            <div className="welcome-item-icon">
              <div>
                <UsergroupAddOutlined />
              </div>
            </div>
            <h4>{intl.get('myRequests')}</h4>
            <p style={{ flex: '1 1 0' }}>{intl.get('myRequestsDesc')}</p>
            <Link to="/dashboard/my-requests">
              <Button type="primary">{intl.get('viewRequests')}</Button>
            </Link>
          </div>
          <div className="welcome-item" style={iconContainer}>
            <div className="welcome-item-icon">
              <div>
                <IdcardOutlined />
              </div>
            </div>
            <h4>{intl.get('myCompany')}</h4>
            <p style={{ flex: '1 1 0' }}>{intl.get('companyProfileDesc')}</p>
            <Link to={`/dashboard/company/${user.user.company_id}`}>
              <Button type="primary">{intl.get('viewProfiles')}</Button>
            </Link>
          </div>
        </div>
      </StyledWelcomeCard>
    </div>
  )
})

export default WelcomePage
