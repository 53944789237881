import React, { useState } from 'react'
import { Button, message, Upload } from 'antd'
import intl from 'react-intl-universal'
import { getBase64 } from './ImageUploader'

export default function ({ formats, callback, currentImage, removeCallback }) {
  const [imagePreview, setImagePreview] = useState(currentImage || null)

  const handleChange = (info) => {
    if (!formats.includes(info.file.type) || info.file.size / 1024 / 1024 > 2) {
      message.error(intl.get('uploadImageErrorNotValid'))
      return false
    }
    getBase64(info.file, (image) => {
      setImagePreview(image)
      callback(image)
    })
  }

  const handleBeforeUpload = (file) => {
    return false
  }

  return (
    <div>
      <Upload
        name="avatar"
        listType="picture-card"
        showUploadList={false}
        beforeUpload={handleBeforeUpload}
        onChange={handleChange}
      >
        {imagePreview ? (
          <img src={imagePreview} alt="avatar" style={{ width: '100%' }} />
        ) : (
          <div className="ant-upload-text">{intl.get('selectImageText')}</div>
        )}
      </Upload>
      {currentImage && imagePreview && (
        <Button
          type="primary"
          onClick={() => {
            setImagePreview(null)
            removeCallback()
          }}
        >
          {intl.get('delete')}
        </Button>
      )}
    </div>
  )
}
