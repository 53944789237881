import React, { useContext } from 'react'
import { Modal } from 'antd'
import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import StoreContext from '../../../../storeContext'
import * as Yup from 'yup'
import intl from 'react-intl-universal'

export default function ({ visible, handleOnCancel, note }) {
  const { companyProfile } = useContext(StoreContext)
  const initialValue = {
    note: note ? note.note : '',
  }
  const validation = Yup.object().shape({
    note: Yup.string().required('Note is required'),
  })
  return (
    <Modal
      visible={visible}
      title={intl.get('editInternalNote')}
      footer={null}
      onCancel={handleOnCancel}
      destroyOnClose={true}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={validation}
        onSubmit={async (vals) => {
          if (note) {
            await companyProfile.internalNote({
              edit: { ...vals, note_id: note.note_id },
              company_id: companyProfile.company.company_id,
            })
          } else {
            await companyProfile.internalNote({
              add: vals,
              company_id: companyProfile.company.company_id,
            })
          }
          handleOnCancel()
        }}
      >
        {() => (
          <Form>
            <Form.Item name="note">
              <Input.TextArea
                placeholder={intl.get('addInternalNote')}
                name="note"
              />
            </Form.Item>
            <SubmitButton>{intl.get('save')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
