import React from "react";
import { Space } from "antd";

const styles = {
  root: {
    width: '100%',
    padding: '20px',
    border: '1px solid #e8e8e8',
    justifyContent: 'space-between',
    fontSize: 18,
    marginBottom: '1em',
  },
  wrapp: { display: 'flex', gap: '1em', justifyContent: 'space-between' },
}
export default function ({ item, ind }) {
  return (
    <Space style={styles.root} direction={'vertical'}>
      <div style={styles.wrapp}>
        <span>{ind}</span>
        <span>{item.name}</span>
        <span>{item.share_percentage}</span>
      </div>
    </Space>
  )
}
