import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import StoreContext from '../../storeContext'
import { MinusOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
export default observer(function () {
  const { compare } = useContext(StoreContext)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
      {compare.companies.map((company) => (
        <div style={{ display: 'flex', gap: '1em' }}>
          <MinusOutlined onClick={() => compare.removeCompany(company)} />
          <Link to={`/dashboard/company/${company.company_id}`}>
            {company.name}
          </Link>
        </div>
      ))}

      <Button type="primary">
        <Link to={`/dashboard/compare`}>Compare</Link>
      </Button>
    </div>
  )
})
