import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Form, Input, Radio, SubmitButton } from 'formik-antd'

import intl from 'react-intl-universal'
import StoreContext from '../../../storeContext'

export default function EditRiskReview({ init, close }) {
  const { vetting } = useContext(StoreContext)
  return (
    <Formik
      initialValues={init}
      onSubmit={async (vals) => {
        await vetting.sendRequest(vals)
        close()
      }}
    >
      {() => (
        <Form>
          <Form.Item name="risk_review_rating">
            <Radio.Group
              name="risk_review_rating"
              options={[
                { label: 'Low', value: 'low' },
                { label: 'Moderate', value: 'moderate' },
                { label: 'High', value: 'high' },
                { label: 'Severe', value: 'severe' },
                { label: 'N/A', value: 'N/A' },
              ]}
            />
          </Form.Item>
          <Form.Item name="risk_review_description">
            <Input.TextArea
              name="risk_review_description"
              rows={4}
              placeholder={intl.get('leaveComment')}
            />
          </Form.Item>
          <SubmitButton>{intl.get('save')}</SubmitButton>
        </Form>
      )}
    </Formik>
  )
}
