import { PDFDownloadLink } from '@react-pdf/renderer'
import { Button } from 'antd'
import React, { useState } from 'react'
import PDFGenerator from '../PDFGenerator'
import intl from 'react-intl-universal'

export default function GenerateReportButton({ record, aml }) {
  const [ready, setReady] = useState(false)
  return (
    <div>
      {!ready && (
        <Button
          style={{ margin: 0, padding: 0 }}
          onClick={() => setReady(true)}
          type="link"
        >
          {intl.get('generatePdf')}
        </Button>
      )}
      {ready && (
        <PDFDownloadLink
          document={<PDFGenerator record={record} aml={aml} />}
          fileName={`${record.first_name}_${record.last_name}_report.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? intl.get('generatingReport') : intl.get('downloadReport')
          }
        </PDFDownloadLink>
      )}
    </div>
  )
}
