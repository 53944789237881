import { StoreBase } from "../../../store/StoreBase";
import { NetworkCall } from "../../../store/models/NetworkModels";
import { decorate, flow, observable } from "mobx";
import intl from "react-intl-universal";

export const UserProfileStoreCalls = Object.freeze({
  LOAD_PROFILE: Object.freeze('loadProfile'),
  UPDATE_PROFILE: Object.freeze('updateProfile'),
  CHANGE_PASSWORD: Object.freeze('changePassword'),
  DELETE_USER: Object.freeze('deleteUser'),
})

export class UserProfileStore extends StoreBase {
  profile = null
  imagesRefreshers = {}
  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
    this.loadProfileCall = new NetworkCall(rootStore, {
      path: 'userprofile',
      secured: true,
      id: UserProfileStoreCalls.LOAD_PROFILE,
    })
    this.updateProfileCall = new NetworkCall(rootStore, {
      path: 'updateuser',
      secured: true,
      id: UserProfileStoreCalls.UPDATE_PROFILE,
    })
    this.changePasswordCall = new NetworkCall(rootStore, {
      path: 'resetpassword',
      secured: true,
      id: UserProfileStoreCalls.CHANGE_PASSWORD,
    })
    this.deleteUserCall = new NetworkCall(rootStore, {
      path: 'gdprrequest',
      secured: true,
      id: UserProfileStoreCalls.DELETE_USER,
    })
  }

  deleteUser = flow(function* (user_id) {
    if (this.deleteUserCall.callInProgress) return
    const res = yield this.deleteUserCall.call(
      'get',
      { user_id },
      `?user_id=${user_id}`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('successDeleteUser'),
              placement: 'topRight',
            },
            'success',
          )
          if (this.rootStore.user.user['cognito:username'] === user_id)
            this.rootStore.user.logout()
          else this.rootStore.router.history.replace('/dashboard/users')
          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              placement: 'topRight',
            },
            'error',
          )
          break
        case 500:
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              description: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  loadProfile = flow(function* (user_id) {
    if (this.loadProfileCall.callInProgress) return
    const res = yield this.loadProfileCall.call(
      'get',
      { user_id },
      `?user_id=${user_id}`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.profile = result.data.user
          break
        default:
      }
    })
  })
  updateProfile = flow(function* (data, cancel) {
    if (this.updateProfileCall.callInProgress) return
    const res = yield this.updateProfileCall.call('post', {
      ...data,
      email: this.profile.email,
      user_id: this.profile.user_id,
    })
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.profile = { ...this.profile, ...data }
          this.rootStore.toast.setNotification(
            {
              message: intl.get('updateUserProfileSuccess'),
              placement: 'topRight',
            },
            'success',
          )
          cancel && cancel()
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('updateUserProfileError'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  changePassword = flow(function* () {
    if (this.changePasswordCall.callInProgress) return
    const res = yield this.changePasswordCall.call('post', {
      username: this.profile.email,
    })
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.router.replace(
            `/confirm-reset-password?email=${this.profile.email}`,
          )
          break
        case 400:
        case 500:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('changingPasswordError'),
              description: intl.get(result.data.body),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('changingPasswordError'),
              description: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })
}

decorate(UserProfileStore, {
  profile: observable,
  imagesRefreshers: observable,
})
