import React, { useState } from 'react'
import { Avatar, Button, List } from 'antd'
import intl from 'react-intl-universal'

export default function ({ questions }) {
  const [show, setShow] = useState(false)
  const currentLocale = intl.determineLocale({
    localStorageLocaleKey: 'lang',
  })
  const locale = currentLocale === 'en-US' ? 'default' : 'de'
  return (
    <div>
      {show && (
        <List
          itemLayout="horizontal"
          dataSource={questions}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{
                      backgroundColor: 'grey',
                      verticalAlign: 'middle',
                    }}
                    size="small"
                  />
                }
                title={<span>{item.question[locale]}</span>}
                description={item.answer[locale]}
              />
            </List.Item>
          )}
        />
      )}
      <Button type="link" onClick={() => setShow((show) => !show)}>
        {intl.get('showMore')}
      </Button>
    </div>
  )
}
