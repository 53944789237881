import React, { useContext } from 'react'
import StoreContext from '../../../../../storeContext'
import intl from 'react-intl-universal'
import { Card, Col, Tag } from 'antd'
import { useHistory } from 'react-router'

export default function ImplementationPartner() {
  const { companyProfile } = useContext(StoreContext)
  const history = useHistory()
  if (
    !companyProfile.company.implementation_partner ||
    companyProfile.company.implementation_partner.length === 0
  )
    return null
  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
      <Card
        bordered={false}
        title={intl.get('implementationPartnerFor')}
        type="inner"
      >
        {companyProfile.company.implementation_partner.map((sup) => (
          <Tag
            onClick={() => history.push(`/dashboard/company/${sup.company_id}`)}
          >
            {sup.product_name}{' '}
          </Tag>
        ))}
      </Card>
    </Col>
  )
}
