import { Modal } from 'antd'
import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import React, { useContext } from 'react'
import StoreContext from '../../../storeContext'
import * as Yup from 'yup'
import { ValidationFields } from "../../../utils/validationFields"
import intl from "react-intl-universal";

export default function ({ visible, handleOnCancel }) {
  const { generalCriteria } = useContext(StoreContext)
  const validation = Yup.object({
    certificate_name_en: ValidationFields.certificateNameEn(),
    certificate_name_de: ValidationFields.certificateNameDe(),
    certification_authority: ValidationFields.certificateAuthority()
  })
  const initialValues = {
    certificate_name_en: '',
    certificate_name_de: '',
    certificate_description: '',
    certification_authority: '',
  }
  return (
    <Modal
      title={intl.get('addCertificate')}
      visible={visible}
      footer={null}
      onCancel={handleOnCancel}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={async (vals, { setSubmitting }) => {
          await generalCriteria.addUpdateCertificate(vals)
          await generalCriteria.getAllCertificates()
          setSubmitting(false)
          handleOnCancel()
        }}
      >
        {() => {
          return (
            <Form>
              <Form.Item name="certificate_name_en">
                <Input
                  addonBefore={intl.get('certificateNameEnTitle')}
                  name="certificate_name_en"
                  placeholder={intl.get('certificateNameEnTitle')}
                />
              </Form.Item>
              <Form.Item name="certificate_name_de">
                <Input
                  addonBefore={intl.get('certificateNameDeTitle')}
                  name="certificate_name_de"
                  placeholder={intl.get('certificateNameDeTitle')}
                />
              </Form.Item>
              <Form.Item name="certificate_description">
                <Input
                  addonBefore={intl.get('description')}
                  name="certificate_description"
                  placeholder={intl.get('description')}
                />
              </Form.Item>
              <Form.Item name="certification_authority">
                <Input
                  addonBefore={intl.get('authority')}
                  name="certification_authority"
                  placeholder={intl.get('authority')}
                />
              </Form.Item>
              <SubmitButton>{intl.get('save')}</SubmitButton>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
