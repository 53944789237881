import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import RateCard from '../../../RateCard'
import { action } from 'mobx'
import { observer } from 'mobx-react'

export default observer(function ({ card, description, title }) {
  const [openDetails, setOpenDetails] = useState(false)
  const calculateTotal = (cards) => {
    cards.forEach((card) => {
      card.total = 0
      if (card.child_type === 'group') {
        calculateTotal(card.children)
        card.children.forEach((child) => {
          card.total += child.total
        })
      } else {
        card.total = card.children.length
      }
    })
  }
  useEffect(() => {
    if (card) action(() => calculateTotal(card.rates))()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card])
  return (
    <>
      <Button
        onClick={() => setOpenDetails(true)}
        style={{ lineHeight: 'inherit', padding: 0 }}
        type="link"
      >
        {card.rates.map((country) => (
          <div
            style={{
              display: 'flex',
              columnGap: '0.5em',
              flexWrap: 'wrap',
              marginBottom: '1em',
            }}
          >
            <span>{`${country.item_name} (${country.total})`},</span>
            {country.children.map((family, i) => (
              <span>
                {`${family.item_name} (${family.total})`}
                {i < country.children.length - 1 ? ',' : ''}
              </span>
            ))}
          </div>
        ))}
      </Button>
      <Modal
        title="Rate card"
        width="80%"
        visible={openDetails}
        onCancel={() => {
          setOpenDetails(false)
        }}
        footer={null}
      >
        <RateCard.Global description={description} title={title} card={card} />
      </Modal>
    </>
  )
})
