import { Button, Card, Col, Popover, Space, Statistic } from 'antd'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import intl from 'react-intl-universal'
import StoreContext from '../../../../../storeContext'
import { RoleGroups } from '../../../../../utils/roles'
import InfoPopUp from '../../../../InfoPopUp'
import EditModal from '../../../../shared/EditModal'
import Rating from '../Rating'
import RiskClassification from '../RiskClassification'
import RequestPremiumData from './RequestPremiumData'

export default function () {
  const { companyProfile, user } = useContext(StoreContext)
  const [showRequest, setShowRequest] = useState(false)

  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
      <Card
        bordered={false}
        type="inner"
        title={intl.get('riskIndicator')}
        extra={
          <div style={{ display: 'flex' }}>
            <Button
              size="small"
              style={{
                width: '100%',
                margin: 0,
                marginRight: 5,
                height: 20,
                display: 'flex',
                alignItems: 'center',
              }}
              disabled={
                !RoleGroups.ADMINS.includes(user.user['custom:role_id'])
              }
              onClick={() => setShowRequest(true)}
            >
              {intl.get('requestPremiumRiskData')}
            </Button>
            <InfoPopUp
              title={intl.get('riskIndicator')}
              html={intl.get('riskIndicatorInfo')}
            />
          </div>
        }
      >
        <Space size="large">
          <Statistic
            title={
              <div>
                <h3 style={{ marginBottom: 22 }}>
                  {intl.get('riskIndicator')}
                </h3>
              </div>
            }
            valueRender={() => (
              <Rating
                score={
                  companyProfile.company.risk_rating_override
                    ? 'N/A'
                    : companyProfile.company.risk_rating
                }
              />
            )}
          />
          <RiskClassification />
          {companyProfile.company.risk_rating_override ? (
            <Popover
              content={
                companyProfile.company.risk_classification.risk_review_comment
              }
              title={`${intl.get('riskReviewComment')} - ${
                companyProfile.company.risk_classification
                  .risk_review_updated_by
              }`}
            >
              <div>
                <Statistic
                  title={
                    <div>
                      <h3 style={{ margin: 0 }}>{intl.get('riskReview')}</h3>
                      <small style={{ color: 'rgba(29, 42, 61, 0.5)' }}>
                        {moment(
                          companyProfile.company.risk_classification
                            .risk_review_last_updated,
                        ).format('MMM YYYY')}
                      </small>
                    </div>
                  }
                  valueRender={() => (
                    <Rating
                      score={
                        companyProfile.company.risk_classification.risk_review
                      }
                    />
                  )}
                />
              </div>
            </Popover>
          ) : (
            <Statistic
              title={
                <div>
                  <h3 style={{ margin: 0 }}>{intl.get('riskReview')}</h3>
                  <small style={{ color: 'rgba(29, 42, 61, 0.5)' }}>
                    default
                  </small>
                </div>
              }
              valueRender={() => <Rating riskClass />}
            />
          )}
        </Space>
      </Card>
      <EditModal
        handleOnClose={() => setShowRequest(false)}
        visible={showRequest}
        title={intl.get('requestPremiumRiskData')}
      >
        <RequestPremiumData />
      </EditModal>
    </Col>
  )
}
