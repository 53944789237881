import { StoreBase } from '../../../store/StoreBase'
import { NetworkCall } from '../../../store/models/NetworkModels'
import { decorate, flow, observable } from 'mobx'
import intl from 'react-intl-universal'
import React from 'react'
import { Link } from 'react-router-dom'

export const CompanyImportStoreCalls = Object.freeze({
  IMPORT_COMPANIES: Object.freeze('importCompanies'),
  UPDATE_IMPORTED_COMPANY: Object.freeze('updateImportedCompany'),
  GET_TEMP_COMPANIES: Object.freeze('getTempCompanies'),
  FINISH_IMPORT: Object.freeze('finishImport'),
  DELETE_IMPORTED_COMPANY: Object.freeze('deleteImportedCompany'),
  IMPORT_EXISTING: Object.freeze('importExisting'),
  COMPANY_INVITE: Object.freeze('companyInvite'),
})

export class CompanyImportStore extends StoreBase {
  companies = []
  importStatus = {
    company_already_exists: [],
    duns_not_found: [],
    exception: [],
    no_industry_code_list: []
  }

  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
    this.importCompaniesCall = new NetworkCall(rootStore, {
      path: 'uploadcompanies',
      secured: true,
      id: CompanyImportStoreCalls.IMPORT_COMPANIES,
    })
    this.updateImportedCompanyCall = new NetworkCall(rootStore, {
      path: 'updateuploadcompanies',
      secured: true,
      id: CompanyImportStoreCalls.UPDATE_IMPORTED_COMPANY,
    })
    this.getTempCompaniesCall = new NetworkCall(rootStore, {
      path: 'showuploadcompanies',
      secured: true,
      id: CompanyImportStoreCalls.GET_TEMP_COMPANIES,
    })
    this.finishImportCall = new NetworkCall(rootStore, {
      path: 'finishuploadcompanies',
      secured: true,
      id: CompanyImportStoreCalls.FINISH_IMPORT,
    })
    this.deleteCall = new NetworkCall(rootStore, {
      path: 'deletecompany',
      secured: true,
      id: CompanyImportStoreCalls.DELETE_IMPORTED_COMPANY,
    })
    this.importExistingCall = new NetworkCall(rootStore, {
      path: 'dunsimportexistingcompany',
      secured: true,
      id: CompanyImportStoreCalls.IMPORT_EXISTING,
    })
    this.companyInviteCall = new NetworkCall(rootStore, {
      path: 'invitecompany',
      secured: true,
      id: CompanyImportStoreCalls.COMPANY_INVITE,
    })
  }

  companyInvite = flow(function* (data) {
    if (this.companyInviteCall.callInProgress) return
    const res = yield this.companyInviteCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              description: result.data.company_id && (
                <Link to={`/dashboard/company/${result.data.company_id}`}>
                  {intl.get('goToCompany')}
                </Link>
              ),
              placement: 'topRight',
            },
            'success',
          )
          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  finish = flow(function* (cancel) {
    if (this.finishImportCall.callInProgress) return
    const res = yield this.finishImportCall.call('post', { cancel })
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.importStatus = {
            company_already_exists: [],
            duns_not_found: [],
            exception: [],
            no_industry_code_list: []
          }
          this.companies = []
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              placement: 'topRight',
            },
            'success',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  getTempCompanies = flow(function* () {
    if (this.getTempCompaniesCall.callInProgress) return
    const res = yield this.getTempCompaniesCall.call('post')
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.importStatus = {
            company_already_exists: result.data.company_already_exists_list,
            duns_not_found: result.data.duns_not_found_list,
            exception: result.data.exception_list,
            no_industry_code_list: result.data.no_industry_code_list
          }
          this.companies = result.data.companies
          break
        default:
      }
    })
  })

  importExisting = flow(function* (data) {
    if (this.importExistingCall.callInProgress) return
    const res = yield this.importExistingCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('successCMPLK'),
              placement: 'topRight',
            },
            'success',
          )
          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('errorImportCompany'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  import = flow(function* (data) {
    if (this.importCompaniesCall.callInProgress) return
    const res = yield this.importCompaniesCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('infoImportingCompanies', {
                0: result.data.estimate_time,
              }),
              placement: 'topRight',
            },
            'info',
          )
          setTimeout(() => {
            this.getTempCompanies()
          }, result.data.estimate_time * 1000)
          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('errorImportCompany'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  update = flow(function* (data) {
    if (this.updateImportedCompanyCall.callInProgress) return
    const res = yield this.updateImportedCompanyCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.companies = this.companies.map((o) => {
            if (o.company_id === data.company_id) return { ...o, ...data }
            return o
          })
          this.rootStore.toast.setNotification(
            {
              message: intl.get('successUpdateCompany'),
              placement: 'topRight',
            },
            'success',
          )
          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: result.data.message,
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
      }
    })
  })
  delete = flow(function* (company_id) {
    if (this.deleteCall.callInProgress) return
    const res = yield this.deleteCall.call('post', {
      table: 'company_import',
      company_id,
    })
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('successDeletingCompany'),
              placement: 'topRight',
            },
            'success',
          )
          this.companies = this.companies.filter(
            (comp) => comp.company_id !== company_id,
          )
          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: intl.get(result.data.message),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })
}

decorate(CompanyImportStore, {
  companies: observable,
})
