import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Form, Input,  SubmitButton } from 'formik-antd'
import { Modal } from 'antd'
import { useParams } from 'react-router'
import StoreContext from '../../../../storeContext'
import * as Yup from 'yup'
import intl from 'react-intl-universal'

export default function ({
  visible,
  handleOnCancel,
  company_description_en,
  company_description_de,
}) {
  const company_id = useParams().id
  const { companyProfile } = useContext(StoreContext)
  const validation = Yup.object({
    company_description_en: Yup.string()
      .max(800, intl.get('nameErrorMax', { 0: 800 }))
      .nullable(),
    company_description_de: Yup.string()
      .max(800, intl.get('nameErrorMax', { 0: 800 }))
      .nullable(),
  })
  const initialValues = {
    company_description: true,
    company_description_de,
    company_description_en,
    company_id,
  }
  return (
    <Modal
      title={intl.get('editDescription')}
      visible={visible}
      footer={null}
      onCancel={handleOnCancel}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={async (vals, { setSubmitting }) => {
          await companyProfile.updateCompany(vals)
          setSubmitting(false)
          handleOnCancel()
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Form.Item name="company_description_en">
                <Input.TextArea
                  disabled={isSubmitting}
                  name="company_description_en"
                  rows={10}
                  placeholder={intl.get('descriptionEnglish')}
                />
              </Form.Item>
              <Form.Item name="company_description_de">
                <Input.TextArea
                  disabled={isSubmitting}
                  name="company_description_de"
                  rows={10}
                  placeholder={intl.get('descriptionGerman')}
                />
              </Form.Item>
              <SubmitButton>{intl.get('save')}</SubmitButton>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
