import { StoreBase } from '../../../store/StoreBase'
import { decorate, flow, observable } from 'mobx'
import { NetworkCall } from '../../../store/models/NetworkModels'

export const SurveyStoreCalls = Object.freeze({
  SURVEYS: Object.freeze('surveys'),
  COMPANY_SURVEYS: Object.freeze('companySurveys'),
  SURVEY_EXPORT: Object.freeze('surveyExport'),
})

export class SurveyStore extends StoreBase {
  surveys = []
  industryCodes = []
  companySurveys = []
  editingSurvey = null
  tags = []
  invisibleTags = []
  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
    this.surveyCall = new NetworkCall(rootStore, {
      path: 'surveys',
      secure: true,
      id: SurveyStoreCalls.SURVEYS,
    })
    this.companySurveysCall = new NetworkCall(rootStore, {
      path: 'companysurvey',
      secure: true,
      id: SurveyStoreCalls.COMPANY_SURVEYS,
    })
    this.surveyExportCall = new NetworkCall(rootStore, {
      path: 'surveyexport',
      secure: true,
      id: SurveyStoreCalls.SURVEY_EXPORT,
    })
  }

  surveyExport = flow(function* (id, setData) {
    if (this.surveyExportCall.callInProgress) return
    const res = yield this.surveyExportCall.call(
      'get',
      null,
      `?survey_id=${id}`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          setData(result.data.survey)
          break
        default:
      }
    })
  })

  getCompanySurveys = flow(function* () {
    if (this.companySurveysCall.callInProgress) return
    const res = yield this.companySurveysCall.call('get', null)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.companySurveys = result.data.surveys
          break
        default:
      }
    })
  })

  updateCompanySurvey = flow(function* (data, type) {
    if (this.companySurveysCall.callInProgress) return
    const res = yield this.companySurveysCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.companySurveys = this.companySurveys.map((survey) => {
            if (survey.survey_id === data.survey_answer.survey_id)
              return { ...survey, ...result.data.survey_answer }

            return survey
          })
          break
        default:
      }
    })
  })

  getIndustryCodes = (arr, data) => {
    data.forEach((exp) => {
      if (exp.child_type === 'group') this.getIndustryCodes(arr, exp.children)
      else {
        exp.children.forEach((item) => {
          arr.push(`${exp.item_name}>${item}`)
        })
      }
    })
    return arr
  }

  getSurveys = flow(function* () {
    if (this.surveyCall.callInProgress) return
    const res = yield this.surveyCall.call('get', null)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.surveys = result.data.surveys
          this.industryCodes = this.getIndustryCodes(
            [],
            result.data.industry_expertises,
          )
          break
        default:
      }
    })
  })
  getTags = flow(function* (type) {
    this.tags = yield this.rootStore.shared.getAllTags(type)
  })
  getInvisibleTags = flow(function* () {
    this.invisibleTags = yield this.rootStore.shared.getInvisibleTags()
  })
  survey = flow(function* (data, type) {
    if (this.surveyCall.callInProgress) return
    const res = yield this.surveyCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          if (type === 'add') {
            this.surveys = [...this.surveys, result.data.survey]
          }
          if (type === 'edit') {
            this.surveys = this.surveys.map((survey) => {
              if (survey.survey_id === data.edit.survey_id)
                return result.data.survey
              return survey
            })
          }
          break
        default:
      }
    })
  })
}

decorate(SurveyStore, {
  surveys: observable,
  editingSurvey: observable,
  tags: observable,
  companySurveys: observable,
  industryCodes: observable,
})
