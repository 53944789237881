import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, Input, Row, Table } from 'antd'
import { observer } from 'mobx-react'
import StoreContext from '../../storeContext'
import { Form, Select } from 'formik-antd'
import EditProfileField from '../Profile/EditProfileField'
import BulkMatrixViewUpdate from './BulkMatrixViewUpdate'
import intl from 'react-intl-universal'
import { sort } from '../CompaniesOverview/TableColumns'
import { CompaniesOverviewTabs } from '../CompaniesOverview/store/CompaniesOverviewStore'
import { Countries } from '../../constants/dropdowns'
import ShowHideCell from '../GeneralCriteria/ShowHideCell'

const { Search } = Input
export default observer(function () {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [companies, setCompanies] = useState([])

  const { companiesOverview } = useContext(StoreContext)
  useEffect(() => {
    setCompanies(
      companiesOverview.allCompaniesData[companiesOverview.tabKey].data,
    )
    // eslint-disable-next-line
  }, [companiesOverview.allCompaniesData[companiesOverview.tabKey].data])
  useEffect(() => {
    companiesOverview.setTabKey(CompaniesOverviewTabs.COMPANY_VIEW_MATRIX)
    companiesOverview.loadTable()
    return () => {
      companiesOverview.setTabKey(CompaniesOverviewTabs.DATA_CLASSIFICATION)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getCompanyTypeLabel = (type) => {
    switch (type) {
      case -1:
        return intl.get('noCompanyType')
      case 0:
        return intl.get('allCompanies')
      case 1:
        return intl.get('allBuyers')
      case 2:
        return intl.get('allSupliers')
      default:
        return intl.get('noCompanyType')
    }
  }
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const columns = [
    {
      title: intl.get('columnCompanyName'),
      dataIndex: 'company_name',
      sorter: (a, b) => sort(a, b, 'company_name'),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.get('columnCompanyType'),
      selected: true,
      dataIndex: 'company_type',
      render: (text, record) => {
        if (record.company_type === 0) return 'Both'
        return record.company_type === 1
          ? intl.get('buyerLabel')
          : intl.get('sellerLabel')
      },
    },
    {
      title: intl.get('columnCompanyTypeView'),
      dataIndex: 'company_type_view',
      width: 300,
      render: (text, record) => (
        <EditProfileField
          value={record.company_type_view}
          valueLabel={getCompanyTypeLabel(record.company_type_view)}
          component={() => {
            return (
              <Form.Item name="company_type_view">
                <Select name="company_type_view" placeholder="Select">
                  <Select.Option value={0}>
                    {intl.get('allCompanies')}
                  </Select.Option>
                  <Select.Option value={1}>
                    {intl.get('allBuyers')}
                  </Select.Option>
                  <Select.Option value={2}>
                    {intl.get('allSupliers')}
                  </Select.Option>
                  <Select.Option value={-1}>
                    {intl.get('noCompanyType')}
                  </Select.Option>
                </Select>
              </Form.Item>
            )
          }}
          name="company_type_view"
          callback={async (value, setEdit) => {
            await companiesOverview.updateViewMatrix({
              companies: [record.company_id],
              ...value,
            })
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: intl.get('companiesView'),
      dataIndex: 'companies_view',
      width: 400,
      render: (text, record) =>
        record.country_view && (
          <EditProfileField
            value={record.companies_view}
            valueLabel={
              record.companies_view.length > 0
                ? companiesOverview.allCompaniesData[
                    companiesOverview.tabKey
                  ].data
                    .filter((c) => record.companies_view.includes(c.company_id))
                    .map((c) => c.company_name)
                    .join(',')
                : 'No data'
            }
            component={() => {
              return (
                <Form.Item name="companies_view">
                  <Select
                    mode="multiple"
                    name="companies_view"
                    filterOption={false}
                    placeholder="Select companies"
                    onSearch={(txt) => {
                      setCompanies(
                        companiesOverview.allCompaniesData[
                          companiesOverview.tabKey
                        ].data.filter((company) =>
                          company.company_name
                            .toLowerCase()
                            .includes(txt.toLowerCase()),
                        ),
                      )
                    }}
                  >
                    {companies.map((c) => (
                      <Select.Option key={c.company_id} value={c.company_id}>
                        {c.company_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )
            }}
            name="companies_view"
            callback={async (value, setEdit) => {
              await companiesOverview.updateViewMatrix({
                companies: [record.company_id],
                ...value,
              })
              setEdit(false)
            }}
            type="cell"
          />
        ),
    },
    {
      title: intl.get('columnCountryView'),
      dataIndex: 'country_view',
      width: 400,
      render: (text, record) =>
        record.country_view && (
          <EditProfileField
            value={record.country_view}
            valueLabel={
              record.country_view.length > 0
                ? record.country_view.join(',')
                : 'No data'
            }
            component={() => {
              return (
                <Form.Item name="country_view">
                  <Select
                    mode="multiple"
                    name="country_view"
                    placeholder="Select country"
                  >
                    {Countries.map((country) => (
                      <Select.Option value={country.value}>
                        {country.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )
            }}
            name="country_view"
            callback={async (value, setEdit) => {
              await companiesOverview.updateViewMatrix({
                companies: [record.company_id],
                ...value,
              })
              setEdit(false)
            }}
            type="cell"
          />
        ),
    },
    {
      title: intl.get('cmptcsView'),
      dataIndex: 'cmptcs_view',
      render: (text, record) => (
        <ShowHideCell
          visible={record.cmptcs_view}
          callback={(checked) =>
            companiesOverview.updateViewMatrix({
              companies: [record.company_id],
              cmptcs_view: checked,
            })
          }
        />
      ),
    },
    {
      title: intl.get('bitsightView'),
      dataIndex: 'bitsight_view',
      render: (text, record) => (
        <ShowHideCell
          visible={record.bitsight_view}
          callback={(checked) =>
            companiesOverview.updateViewMatrix({
              companies: [record.company_id],
              bitsight_view: checked,
            })
          }
        />
      ),
    },
    {
      title: intl.get('kycView'),
      dataIndex: 'kyc_view',
      render: (text, record) => (
        <ShowHideCell
          visible={record.kyc_view}
          callback={(checked) =>
            companiesOverview.updateViewMatrix({
              companies: [record.company_id],
              kyc_view: checked,
            })
          }
        />
      ),
    },
    {
      title: intl.get('riskView'),
      dataIndex: 'standard_risk_indicator',
      render: (text, record) => (
        <ShowHideCell
          visible={record.standard_risk_indicator}
          callback={(checked) =>
            companiesOverview.updateViewMatrix({
              companies: [record.company_id],
              standard_risk_indicator: checked,
            })
          }
        />
      ),
    },
    {
      title: intl.get('advancedRiskView'),
      dataIndex: 'advanced_risk_indicator',
      render: (text, record) => (
        <ShowHideCell
          visible={record.advanced_risk_indicator}
          callback={(checked) =>
            companiesOverview.updateViewMatrix({
              companies: [record.company_id],
              advanced_risk_indicator: checked,
            })
          }
        />
      ),
    },
  ]

  const handleSearch = (e) => {
    companiesOverview.search(
      e.target.value,
      CompaniesOverviewTabs.COMPANY_VIEW_MATRIX,
    )
  }

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card title={intl.get('viewMatrixBulkUpdateCardTitle')}>
            <BulkMatrixViewUpdate
              selected={selectedRowKeys}
              resetKeys={() => setSelectedRowKeys([])}
            />
          </Card>
        </Col>
      </Row>
      <Card title={intl.get('viewMatrixTableCardTitle')}>
        <Row>
          <Col>
            <Search
              placeholder={intl.get('searchLabel')}
              onChange={handleSearch}
              style={{ width: 200, margin: 16 }}
            />
          </Col>
        </Row>

        <Table
          bordered={true}
          rowKey="company_id"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={companiesOverview.filteredCompanies}
          scroll={{ x: 1500 }}
        />
      </Card>
    </>
  )
})
