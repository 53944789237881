import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import StoreContext from '../../../../storeContext'
import { Popover, Statistic } from 'antd'
import moment from 'moment'
import Rating from './Rating'
import intl from 'react-intl-universal'

export default observer(function () {
  const { companyProfile } = useContext(StoreContext)
  if (!companyProfile.company.risk_classification)
    return (
      <Statistic
        title={
          <div>
            <h3 style={{ margin: 0 }}>{intl.get('riskClass')}</h3>
            <small style={{ color: 'rgba(29, 42, 61, 0.5)' }}>default</small>
          </div>
        }
        valueRender={() => <Rating score="low" riskClass />}
      />
    )
  return (
    <Popover
      content={companyProfile.company.risk_classification.risk_comment}
      title={intl.get('riskClassificationDescription')}
    >
      <div>
        <Statistic
          title={
            <div>
              <h3 style={{ margin: 0 }}>{intl.get('riskClass')}</h3>
              <small style={{ color: 'rgba(29, 42, 61, 0.5)' }}>
                {moment(
                  companyProfile.company.risk_classification.last_updated,
                ).format('MMM YYYY')}
              </small>
            </div>
          }
          valueRender={() => (
            <Rating
              riskClass
              score={companyProfile.company.risk_classification.risk_class}
            />
          )}
        />
      </div>
    </Popover>
  )
  /*return (
    <Tooltip
      placement="top"
      title={companyProfile.company.risk_classification.risk_comment}
    >
      <div style={{ display: 'flex', gap: '1em' }}>
        <Avatar
          style={{ textTransform: 'uppercase' }}
          size="large"
          shape="square"
        >
          {companyProfile.company.risk_classification.risk_class}
        </Avatar>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <span>Risk class</span>
          <span>
            {moment(
              companyProfile.company.risk_classification.last_updated,
            ).format('MMM YYYY')}
          </span>
        </div>
      </div>
    </Tooltip>
  )*/
})
