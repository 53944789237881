import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Table } from 'antd'
import EditModal from '../../shared/EditModal'
import AddSurvey from '../AddSurvey'
import PreviewSurvey from '../PreviewSurvey'
import { observer } from 'mobx-react'
import StoreContext from '../../../storeContext'
import EditSurvey from '../EditSurvey'
import { SurveyTypes } from '../../../constants/dropdowns'
import ShowHideCell from '../../GeneralCriteria/ShowHideCell'
import EditTargetAudience from '../EditTargetAudience'
import Export from './Export'

export default observer(function () {
  const { survey } = useContext(StoreContext)
  const [showAdd, setShowAdd] = useState(false)
  const [showTargetAudience, setShowTargetAudience] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [selectedSurvey, setSelected] = useState(null)
  useEffect(() => {
    survey.getSurveys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const columns = [
    {
      title: 'Name',
      dataIndex: 'survey_name',
    },
    {
      title: 'Type',
      dataIndex: 'survey_type',
      render: (text, record) => {
        return SurveyTypes.find((type) => type.value === record.survey_type)
          .name
      },
    },
    {
      title: 'Created at',
      dataIndex: 'created_date',
      render: (text, record) =>
        new Date(record.created_date).toLocaleDateString(),
    },
    {
      title: 'Number of results',
      dataIndex: 'results_count',
    },
    {
      title: 'Publish',
      dataIndex: 'survey_published',
      render: (text, record) => (
        <ShowHideCell
          visible={record.survey_published}
          callback={(checked) =>
            survey.survey(
              {
                edit: {
                  survey_published: checked,
                  survey_id: record.survey_id,
                },
              },
              'edit',
            )
          }
        />
      ),
    },
    {
      title: 'Action',
      render: (text, record) => (
        <>
          <Button
            onClick={() => {
              setSelected(record)
              setShowPreview(true)
            }}
            type="primary"
          >
            Preview
          </Button>
          <Button
            onClick={() => {
              setSelected(record)
              setShowEdit(true)
            }}
            type="primary"
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              setSelected(record)
              setShowTargetAudience(true)
            }}
            type="primary"
          >
            Choose target audience
          </Button>
          <Export record={record} />
        </>
      ),
    },
  ]

  return (
    <Card
      title="Manage surveys"
      extra={
        <Button
          onClick={() => setShowAdd(true)}
          style={{ marginBottom: 0 }}
          type="primary"
        >
          Add survey
        </Button>
      }
    >
      <Table bordered={true} columns={columns} dataSource={survey.surveys} />
      <EditModal
        handleOnClose={() => setShowAdd(false)}
        visible={showAdd}
        title="Add new survey"
      >
        <AddSurvey />
      </EditModal>
      <EditModal
        handleOnClose={() => setShowPreview(false)}
        visible={showPreview}
        width="80vw"
        title="Preview survey"
      >
        <PreviewSurvey mode="display" surveyCode={selectedSurvey} />
      </EditModal>
      <EditModal
        handleOnClose={() => setShowEdit(false)}
        visible={showEdit}
        title="Edit survey"
      >
        <EditSurvey editSurvey={selectedSurvey} />
      </EditModal>
      <EditModal
        handleOnClose={() => setShowTargetAudience(false)}
        visible={showTargetAudience}
        title="Edit survey"
      >
        <EditTargetAudience editSurvey={selectedSurvey} />
      </EditModal>
    </Card>
  )
})
