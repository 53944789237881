export const CompanyTypeViewMap = {
  0: 'All companies',
  1: 'All buyers',
  2: 'All suppliers',
}

export const CompanyTypeDropdown = [{}]

export const Currencies = ['CHF', 'EUR', 'USD', 'GBP']

export const SurveyTypes = [
  {
    name: 'Standard self declaration',
    value: 'standard_self_declaration',
  },
  {
    name: 'Sustainability self declaration',
    value: 'sustainability_self_declaration',
  },
  {
    name: 'Delivery performance self declaration',
    value: 'delivery_performance_self_declaration',
  },
  {
    name: 'Advanced self declaration',
    value: 'advanced_self_declaration',
  },
  {
    name: 'Banking and insurances CH',
    value: 'banking_and_insurances_ch',
  },
  {
    name: 'Health care CH',
    value: 'health_care',
  },
  {
    name: 'Other',
    value: 'other',
  },
]

export const RiskSubcategoryOptions = {
  financial_risks: [
    {
      name: 'Payment',
      value: 'payment',
    },
    {
      name: 'Credit',
      value: 'credit',
    },
    {
      name: 'Control',
      value: 'control',
    },
  ],
  compliance_legal_risks: [
    {
      name: 'Governance rating',
      value: 'governance',
    },
    {
      name: 'AML rating',
      value: 'aml',
    },
    {
      name: 'Shareholders',
      value: 'shareholders',
    },
  ],
  reputational_sustainability_risks: [
    {
      name: 'Corporate governance',
      value: 'corporate',
    },
    {
      name: 'Sustainability',
      value: 'sustainability',
    },
    {
      name: 'Country',
      value: 'country',
    },
  ],
  contractual_risks: [
    {
      name: '3rd party',
      value: 'subcontractor',
    },
    {
      name: 'Data protection risk rating',
      value: 'data_protection',
    },
  ],
  operational_risks: [
    {
      name: 'BCM',
      value: 'bcm',
    },
    {
      name: 'Delivery',
      value: 'delivery',
    },
    {
      name: 'Quality',
      value: 'quality',
    },
    {
      name: 'CyberIT',
      value: 'cyber_id',
    },
  ],
}

export const RiskCategoryOptions = [
  {
    name: 'Financial risks',
    value: 'financial_risks',
  },
  {
    name: 'Compliance & legal risks',
    value: 'compliance_legal_risks',
  },
  {
    name: 'Reputational and sustainability risks',
    value: 'reputational_sustainability_risks',
  },
  {
    name: 'Contractual risks',
    value: 'contractual_risks',
  },
  {
    name: 'Operational risks',
    value: 'operational_risks',
  },
]

export const Countries = [
  {
    name: 'Germany',
    value: 'de',
  },
  {
    name: 'Switzerland',
    value: 'ch',
  },
  {
    name: 'Liechtenstein',
    value: 'li',
  },
  {
    name: 'Austria',
    value: 'at',
  },
  {
    name: 'Russia',
    value: 'ru',
  },
  {
    name: 'Other',
    value: 'other',
  },
]

export const DocumentType = [
  { name: 'Service contract', value: 'service_contract' },
  { name: 'Work and labour contract', value: 'work_and_labour_contract' },
  {
    name: 'Maintenance and support contract',
    value: 'maintenance_and_support_contract',
  },
  {
    name: 'Purchase contract',
    value: 'purchase_contract',
  },
  {
    name: 'Cloud services contract',
    value: 'cloud_services_contract',
  },
  {
    name: 'License agreement',
    value: 'license_agreement',
  },
  {
    name: 'Temporary work contract',
    value: 'temporary_work_contract',
  },
  {
    name: 'Framework agreement',
    value: 'framework_agreement',
  },
  {
    name: 'Amendment',
    value: 'amendment',
  },
  {
    name: 'Appendix to contract',
    value: 'appendix_to_contract',
  },
  {
    name: 'Other document',
    value: 'other_document',
  },
]

export const ProductGroup = [
  { name: 'Core banking', value: 'core_banking' },
  { name: 'CRM', value: 'crm' },
  { name: 'RPA Software', value: 'rpa_software' },
  {
    name: 'Risk & Compliance (Transaction based)',
    value: 'risk_and_compliance',
  },
  { name: 'Instant Payment processing', value: 'instant_payment_processing' },
  { name: 'Other', value: 'other' },
]
