import React, { useContext, useState } from "react"
import { Switch } from "antd"
import StoreContext from "../../../storeContext"
import intl from "react-intl-universal";

export default function({ record }) {
  const {generalCriteria} = useContext(StoreContext)
  const [updating, setUpdating] = useState(false)
  return (
    <Switch
      checkedChildren={intl.get('show')}
      checked={record.tag_visible}
      loading={updating}
      onClick={async (checked) => {
        setUpdating(true)
        await generalCriteria.addUpdateTag({tag_visible: checked, tag_id: record.tag_id})
        await generalCriteria.getTags(true)
        setUpdating(false)
      }}
      unCheckedChildren={intl.get('hide')}
    />
  )
}