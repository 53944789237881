import React, { useContext, useEffect } from 'react'
import { Skeleton} from 'antd'
import './style.css'
import { Loading } from '../loading'
import { CompanyProfileStoreCalls } from './store/CompanyProfileStore'
import Containers from './Containers'
import { observer } from 'mobx-react'
import { useParams } from 'react-router'
import StoreContext from '../../storeContext'

export const COMPANY_PROFILE_TABS = {
  basic: {
    id: 'basic',
  },
  personal: {
    id: 'personal',
  },
  overview: {
    id: 'overview',
  },
  details: {
    id: 'details',
  },
  peopleAndOrganization: {
    id: 'people-and-organization',
  },
  risk: {
    id: 'risk-and-compliance',
  },
  products: {
    id: 'products',
  },
  resources: {
    id: 'resources',
  },
  procurement: {
    id: 'procurement',
  },
}

export default observer(function () {
  const company_id = useParams().id
  const { companyProfile } = useContext(StoreContext)
  useEffect(() => {
    companyProfile.getCompanyData(company_id)
    return () => {
      companyProfile.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id])
  return (
    <Loading blockers={[CompanyProfileStoreCalls.GET_COMPANY_DATA]}>
      <Loading.Done>{companyProfile.loaded && <Containers />}</Loading.Done>
      <Loading.InProgress>
        <Skeleton active />
      </Loading.InProgress>
    </Loading>
  )
})
