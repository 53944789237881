import { Divider, Modal, Space, Statistic } from "antd";
import moment from "moment";
import Rating from "../../Rating";
import SurveyData from "../SurveyData";
import React from "react";

export default function ({ showPreview, setShowPreview, delivery }) {
  return (
    <Modal
      title="Delivery"
      visible={showPreview}
      footer={null}
      destroyOnClose={true}
      onCancel={() => setShowPreview(false)}
    >
      <Space style={{ width: '100%' }}  direction={'vertical'}>
        <Statistic
          title="Date"
          value={
            delivery.questionaire_completed_date
              ? moment(delivery.questionaire_completed_date).format(
                  'DD MMMM YYYY',
                )
              : 'N/A'
          }
        />
        <Divider type="horizontal" />
        <Statistic
          title="Delivery risk rating"
          valueRender={() => <Rating score={delivery.rating} />}
        />

        <Divider type="horizontal" />
        <Statistic
          title={`Self declaration response`}
          valueRender={() => <SurveyData questions={delivery.questions} />}
        />
      </Space>
    </Modal>
  )
}
