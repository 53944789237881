import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import StoreContext from '../../storeContext'
import ImageUploadModal from './ImageUploadModal'
import UserLogo from '../UserLogo'
import { CameraOutlined } from '@ant-design/icons'

export default observer(function ({ id }) {
  const { profile } = useContext(StoreContext)
  const [showUploadModal, setShowUploadModal] = useState(false)

  return (
    profile.profile && (
      <div className="gx-profile-banner">
        <div className="gx-profile-container">
          <div className="gx-profile-banner-top">
            <div className="gx-profile-banner-top-left">
              <div className="gx-profile-banner-avatar">
                <div className="avatar-wrap">
                  <UserLogo id={profile.profile.user_id} size="90" />
                </div>
                <div
                  className="upload-button"
                  onClick={() => setShowUploadModal(true)}
                >
                  <CameraOutlined />
                </div>
              </div>
              <div className="gx-profile-banner-avatar-info">
                <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
                  {profile.profile.name} {profile.profile.surname}
                </h2>
                <p className="gx-mb-0 gx-fs-lg" style={{ color: '#1890FF' }}>
                  {profile.profile.company_name}
                </p>
              </div>
            </div>
          </div>
          <div className="gx-profile-banner-bottom">
            {/* <div className="gx-tab-list">
              <ul className="gx-navbar-nav">
                <li>
                  <span
                    className="gx-link"
                    onClick={() => setShowUploadModal(true)}
                  >
                    {intl.get('uploadPhotoLinkText')}
                  </span>
                </li>
                {profile.profile.user_id === user.user['cognito:username'] && (
                  <li>
                    <Loading blockers={[UserProfileStoreCalls.CHANGE_PASSWORD]}>
                      <Loading.InProgress>
                        <LoadingOutlined />
                      </Loading.InProgress>
                      <Loading.Done>
                        <span
                          onClick={() => profile.changePassword()}
                          className="gx-link"
                        >
                          {intl.get('changePasswordLinkText')}
                        </span>
                      </Loading.Done>
                    </Loading>
                  </li>
                )}
                <li>
                  <span onClick={showConfirm} className="gx-link">
                    {intl.get('deactivateAccountLinkText')}
                  </span>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <ImageUploadModal
          visible={showUploadModal}
          close={() => setShowUploadModal(false)}
        />
      </div>
    )
  )
})
