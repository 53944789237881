import React, { useContext } from 'react'
import StoreContext from '../../../storeContext'
import { Modal } from 'antd'
import { Formik } from 'formik'
import { Form, Input, SubmitButton, Switch } from 'formik-antd'
import intl from "react-intl-universal";
import * as Yup from 'yup'

export default function ({ visible, handleOnCancel }) {
  const { generalCriteria } = useContext(StoreContext)
  const validation = Yup.object().shape({
    code: Yup.string().required(intl.get('industryCodeRequired')),
    category: Yup.string().required(intl.get('industryCodeCategoryRequired')),
    subcategory: Yup.string().required(intl.get('industryCodeSubcategoryRequired'))
  })
  const initialValues = {
    code: '',
    category: '',
    subcategory: '',
    code_visible: true,
    description_de: '',
    description_en: '',
    type: 'add',
  }
  return (
    <Modal
      title={intl.get('addIndustryCode')}
      visible={visible}
      footer={null}
      onCancel={handleOnCancel}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={async (vals, { setSubmitting }) => {
          await generalCriteria.addUpdateIndustryCode(vals)
          setSubmitting(false)
          handleOnCancel()
        }}
      >
        {() => {
          return (
            <Form>
              <Form.Item name="code">
                <Input
                  addonBefore={intl.get('industryCode')}
                  name="code"
                  placeholder={intl.get('industryCode')}
                />
              </Form.Item>
              <Form.Item name="category">
                <Input
                  addonBefore={intl.get('category')}
                  name="category"
                  placeholder={intl.get('category')}
                />
              </Form.Item>
              <Form.Item name="subcategory">
                <Input
                  addonBefore={intl.get('subcategory')}
                  name="subcategory"
                  placeholder={intl.get('subcategory')}
                />
              </Form.Item>

              <Form.Item name="description_en">
                <Input
                  addonBefore={intl.get('descriptionEnglish')}
                  name="description_en"
                  placeholder={intl.get('descriptionEnglish')}
                />
              </Form.Item>
              <Form.Item name="description_de">
                <Input
                  addonBefore={intl.get('descriptionGerman')}
                  name="description_de"
                  placeholder={intl.get('descriptionGerman')}
                />
              </Form.Item>
              <Form.Item name="code_visible">
                <Switch
                  name="code_visible"
                  checkedChildren={intl.get('hide')}
                  unCheckedChildren={intl.get('show')}
                />
              </Form.Item>
              <SubmitButton>{intl.get('save')}</SubmitButton>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
