import React, { useContext } from 'react'
import StoreContext from '../../../storeContext'
import csvExample from '../../../documents/bulk_resource_vetting_request.csv'
import intl from 'react-intl-universal'
import CsvUploader from '../../CsvUploader'

export default function BulkRequest() {
  const { vetting } = useContext(StoreContext)
  return (
    <CsvUploader
      callback={(rows) => {
        console.log(rows)
        vetting.sendRequest({ people: rows })
      }}
      buttonText={intl.get('requestBulkResourceVetting')}
      csvExampleFile={csvExample}
      csvExplanationText={intl.get('requestBulkExplanation')}
    />
  )
}
