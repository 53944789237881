import { Modal, Tabs } from 'antd'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import StoreContext from '../../../storeContext'
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined'
import AddPrivate from './AddPrivate'
import RateCard, { CardContext } from '../index'
import { toJS } from 'mobx'
import intl from 'react-intl-universal'

const { TabPane } = Tabs
const { confirm } = Modal
export default observer(function () {
  const { cards, user } = useContext(StoreContext)
  const [showAddCard, setShowAddCard] = useState(false)

  useEffect(() => {
    cards.loadPrivate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onEdit = async (targetKey, action) => {
    if (action === 'add') {
      setShowAddCard(true)
    } else {
      confirm({
        title: intl.get('removeRateCardNotification'),
        icon: <ExclamationCircleOutlined />,
        onOk: async () => {
          const temp = cards.privateRateCards.filter(
            (card) => card.rate_card_id === targetKey,
          )
          await cards.updatePrivateRateCards({
            remove: {
              rate_card_id: targetKey,
            },
            companies: [temp[0].buyer_id, temp[0].supplier_id],
          })
        },
      })
    }
  }
  return (
    <>
      <Tabs type={'editable-card'} onEdit={onEdit}>
        {cards.privateRateCards.length === 0 ? (
          <TabPane
            tab={intl.get('noRateCards')}
            closable={false}
            key="nocards"
          />
        ) : (
          cards.privateRateCards.map((card) => (
            <TabPane
              tab={
                user.user.company_type === 1
                  ? card.supplier_name
                  : card.buyer_name
              }
              key={card.rate_card_id}
            >
              <CardContext.Provider
                value={{
                  canEdit: !!user.user.claims.update_company,
                  upd: async () => {
                    await cards.updatePrivateRateCards({
                      edit: {
                        rates: cards.getSavedData(toJS(card.allCards)),
                        rate_card_id: card.rate_card_id,
                      },
                      companies: [card.supplier_id, card.buyer_id],
                    })
                    await cards.loadPrivate()
                  },
                  updComment: (data) =>
                    cards.updatePrivateRateCards({
                      edit: {
                        ...data,
                        rate_card_id: card.rate_card_id,
                      },
                      companies: [card.supplier_id, card.buyer_id],
                    }),
                  updSeniority: (data) =>
                    cards
                      .updatePrivateRateCards({
                        edit: {
                          seniority_definition: {
                            ...card.seniority_definition,
                            ...data,
                          },
                          rate_card_id: card.rate_card_id,
                        },
                        companies: [card.supplier_id, card.buyer_id],
                      })
                      .then(() => cards.loadPrivate()),
                }}
              >
                <RateCard.Global
                  titleEdit={false}
                  description={intl.get('rateCardPrivateDescription')}
                  title={intl.get('privateRateCardTitle', {
                    1: card.buyer_name,
                    2: card.supplier_name,
                  })}
                  card={card}
                />
              </CardContext.Provider>
            </TabPane>
          ))
        )}
      </Tabs>
      <AddPrivate
        visible={showAddCard}
        onCancel={() => setShowAddCard(false)}
      />
    </>
  )
})
