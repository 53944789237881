import React, { useContext, useState } from 'react'
import { Card, Col, Modal, Popover, Row, Statistic, Tag } from 'antd'
import EditButton from '../../../EditButton'
import EditModal from '../../../../shared/EditModal'
import EditContractFolder from './EditContractFolder'
import StoreContext from '../../../../../storeContext'
import AddDocument from './AddDocument'
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined'
import EditDocument from './EditDocument'
import intl from 'react-intl-universal'
import { RoleGroups } from '../../../../../utils/roles'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { FormOutlined } from '@ant-design/icons'

const style = {
  folderWrapper: {
    width: 350,
    position: 'relative',
    background: '#FFFFFF',
    border: '1px solid #F0F0F0',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
  },
  folderInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    margin: 15,
    borderBottom: '1px solid #F0F0F0',
  },
  folderTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#FAFAFA',
    padding: 15,
    wordBreak: 'break-all',
  },
  statistic: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    gap: '1em',
  },
}

const DocumentDetails = ({ document }) => {
  return (
    <div style={{ maxWidth: 350 }}>
      {document.is_confidential && (
        <Statistic
          style={style.statistic}
          title={
            <span style={{ color: 'rgba(0,0,0,.45)' }}>
              {intl.get('confidential')}:
            </span>
          }
          valueStyle={{ fontSize: 18 }}
          value={document.is_confidential ? 'Yes' : 'No'}
        />
      )}
      <Statistic
        style={style.statistic}
        title={
          <span
            style={{
              color: 'rgba(0,0,0,.45)',
              whiteSpace: 'break-spaces',
            }}
          >
            {intl.get('description')}:
          </span>
        }
        valueStyle={{
          fontSize: 18,
          alignSelf: 'center',
          textAlign: 'right',
          wordBreak: 'break-all',
        }}
        value={document.description || 'No description'}
      />
      <Statistic
        style={style.statistic}
        title={
          <span style={{ color: 'rgba(0,0,0,.45)' }}>
            {intl.get('createdAt')}:
          </span>
        }
        valueStyle={{ fontSize: 18 }}
        value={new Date(document.date_created).toLocaleDateString()}
      />
      <Statistic
        style={style.statistic}
        title={
          <span style={{ color: 'rgba(0,0,0,.45)' }}>
            {intl.get('updatedAt')}:
          </span>
        }
        valueStyle={{ fontSize: 18 }}
        value={new Date(document.last_updated).toLocaleDateString()}
      />
      <Statistic
        style={style.statistic}
        title={
          <span style={{ color: 'rgba(0,0,0,.45)' }}>
            {intl.get('contractNumber')}:
          </span>
        }
        valueStyle={{ fontSize: 18 }}
        value={document.contract_no}
      />
      <Statistic
        style={style.statistic}
        title={
          <span style={{ color: 'rgba(0,0,0,.45)' }}>
            {intl.get('purchaseOrderNumber')}:
          </span>
        }
        valueStyle={{ fontSize: 18 }}
        value={document.po_number}
      />
    </div>
  )
}

const StyledDocument = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-tag {
    margin: 0;
  }
  .ant-tag a {
    color: #1890ff;
  }
`

const Document = ({ document, folder, update, company_id, disableEdit }) => {
  const [showEdit, setShowEdit] = useState(false)
  const confirmDeleteDocumentModal = () => {
    Modal.confirm({
      title: intl.get('deleteDocumentModal'),
      okText: intl.get('delete'),
      onOk: async () => {
        await update(
          {
            company_id,
            remove_document: {
              contract_folder_id: folder,
              document_id: document.document_id,
            },
          },
          'deleteDocument',
        )
      },
    })
  }
  return (
    <StyledDocument>
      <Tag>
        <Popover
          content={<DocumentDetails document={document} />}
          title={document.document_name}
        >
          <a rel="noopener noreferrer" href={document.file_url} target="_blank">
            {document.document_name}
          </a>
        </Popover>
        {!disableEdit && <CloseOutlined onClick={confirmDeleteDocumentModal} />}
      </Tag>
      {!disableEdit && <FormOutlined onClick={() => setShowEdit(true)} />}
      <EditModal
        handleOnClose={() => setShowEdit(false)}
        visible={showEdit}
        title={intl.get('editContractDocument')}
      >
        <EditDocument
          contract_folder_id={folder}
          document={document}
          update={update}
          company_id={company_id}
        />
      </EditModal>
    </StyledDocument>
  )
}

export const Folder = ({ folder, update, company_id, disableEdit }) => {
  const [showEditFolder, setShowEditFolder] = useState(false)
  const [showAddDocument, setShowAddDocument] = useState(false)
  const confirmDeleteFolderModal = () => {
    Modal.confirm({
      title: intl.get('deleteContractFolderModal'),
      okText: intl.get('delete'),
      onOk: async () => {
        await update(
          {
            company_id,
            remove_folder: {
              contract_folder_id: folder.contract_folder_id,
            },
          },
          'deleteFolder',
        )
      },
    })
  }
  const menu = [
    {
      name: intl.get('editContractFolder'),
      callback: () => setShowEditFolder(true),
    },
    {
      name: intl.get('deleteContractFolder'),
      callback: confirmDeleteFolderModal,
    },
    {
      name: intl.get('addDocument'),
      callback: () => setShowAddDocument(true),
    },
  ]
  return (
    <div style={style.folderWrapper}>
      <div style={style.folderTitle}>
        <h3 style={{ margin: 0 }}>{folder.folder_name}</h3>
        {!disableEdit && (
          <EditButton style={{ top: 45 }} menuItems={menu} show={true} />
        )}
      </div>
      <div style={style.folderInfo}>
        <Statistic
          style={style.statistic}
          title={
            <span style={{ color: 'rgba(0,0,0,.45)' }}>
              {intl.get('startDate')}
            </span>
          }
          valueStyle={{ fontSize: 18 }}
          value={`${new Date(folder.start_date).toLocaleDateString()}`}
        />
        <Statistic
          style={style.statistic}
          title={
            <span style={{ color: 'rgba(0,0,0,.45)' }}>
              {intl.get('endDate')}
            </span>
          }
          valueStyle={{ fontSize: 18 }}
          value={
            folder.end_date
              ? `${new Date(folder.end_date).toLocaleDateString()}`
              : 'Evergreen'
          }
        />
        <Statistic
          style={style.statistic}
          title={
            <span style={{ color: 'rgba(0,0,0,.45)' }}>
              {intl.get('value')}
            </span>
          }
          valueStyle={{ fontSize: 18 }}
          value={folder.contract_value || 'n/a'}
          suffix={folder.currency}
        />
      </div>
      <div style={{ margin: 15, borderBottom: '1px solid #F0F0F0' }}>
        <div style={{ color: 'rgba(29, 42, 61, 0.5)', marginBottom: 10 }}>
          {intl.get('documents')}:
        </div>
        {folder.documents.length > 0
          ? folder.documents.map((document) => (
              <Document
                update={update}
                folder={folder.contract_folder_id}
                document={document}
                company_id={company_id}
                disableEdit={disableEdit}
              />
            ))
          : 'No documents'}
      </div>
      <div style={{ margin: 15, borderBottom: '1px solid #F0F0F0' }}>
        <div style={{ color: 'rgba(29, 42, 61, 0.5)', marginBottom: 10 }}>
          {intl.get('tags')}:
        </div>
        {folder.tags.map((tag) => (
          <Tag style={{ whiteSpace: 'pre-line' }}>{tag}</Tag>
        ))}
      </div>
      <EditModal
        handleOnClose={() => setShowEditFolder(false)}
        visible={showEditFolder}
        title={intl.get('editContractFolder')}
      >
        <EditContractFolder
          folder={folder}
          update={update}
          company_id={company_id}
        />
      </EditModal>
      <EditModal
        handleOnClose={() => setShowAddDocument(false)}
        visible={showAddDocument}
        title={intl.get('addDocument')}
      >
        <AddDocument
          contract_folder_id={folder.contract_folder_id}
          update={update}
          company_id={company_id}
        />
      </EditModal>
    </div>
  )
}

export default observer(function ({ folders }) {
  const { companyProfile, user } = useContext(StoreContext)
  return (
    folders.length > 0 && (
      <Row>
        <Col span={24}>
          <Card title="Contracts" type="inner" bordered={false}>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1em' }}>
              {folders
                .slice()
                .sort((a, b) => {
                  if (a.end_date && b.end_date) {
                    return new Date(b.end_date) - new Date(a.end_date)
                  }
                  if (a.end_date && !b.end_date) {
                    return 1
                  }
                  if (!a.end_date && b.end_date) {
                    return -1
                  }
                  return 0
                })
                .map((folder) => (
                  <Folder
                    disableEdit={
                      !RoleGroups.ADMINS.includes(user.user['custom:role_id'])
                    }
                    folder={folder}
                    company_id={companyProfile.company.company_id}
                    update={companyProfile.contracts}
                  />
                ))}
            </div>
          </Card>
        </Col>
      </Row>
    )
  )
})
