import React, { useContext } from 'react'
import { Layout, Button} from 'antd'
import privacy from '../../documents/privacy.pdf'
import Sidebar from '../Sidebar'
import Routes, { dashboardRoutes } from '../../routes'
import Topbar from '../Topbar'
import TermsAcceptence from '../../components/Terms/TermsAcceptence'
import StoreContext from '../../storeContext'
import intl from 'react-intl-universal'

const { Content, Footer } = Layout
export function Dashboard() {
  const { layout, user } = useContext(StoreContext)
  return (
    <Layout className="gx-app-layout">
      <Sidebar />
      <Layout>
        <Topbar />
        <Content
          className={`gx-layout-content`}
          onScroll={layout.setContentScroll}
        >
          <div className="gx-main-content-wrapper">
            <TermsAcceptence />
            <Routes routes={dashboardRoutes(user)} />
          </div>
          <Footer>
            <Button
              type="text"
              style={{
                margin: '0 8px',
              }}
              onClick={() => {
                window.open(privacy, '_blank')
              }}
            >
              {intl.get('privacyStatement')}
            </Button>
            <div
              className="gx-layout-footer-content"
              style={{
                float: 'right',
                display: 'flex',
                alignItems: 'center',
                height: '36px',
              }}
            >
              Titan © {process.env.REACT_APP_VERSION}
            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  )
}
