import React from "react";
import { Button, Layout, Result } from "antd";
import Topbar from "../../containers/Topbar";
import intl from "react-intl-universal";
import { useHistory, useLocation } from "react-router";

const { Content } = Layout
export default function Success() {
  const location = useLocation()
  const history = useHistory()
  const invited = new URLSearchParams(location.search).get('invited')
  return (
    <Layout style={{ height: '100%' }}>
      <Topbar showCommands={false} />
      <Content>
        <div
          className="gx-login-container"
          style={{ overflow: 'auto', padding: '30px' }}
        >
          <div className="gx-login-content">
            <Result
              status="success"
              title={intl.get('signUpSuccessTitleText')}
              subTitle={invited ? intl.get('signUpSuccessInvitedSubTitleText') : intl.get('signUpSuccessSubTitleText')}
              extra={[
                <Button type="primary" onClick={()=> history.replace('/login')} >
                  {intl.get('signUpSuccessLoginLinkText')}
                </Button>,
              ]}
            />
          </div>
        </div>
      </Content>
    </Layout>
  )
}
