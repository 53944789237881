import Loading3QuartersOutlined from '@ant-design/icons/lib/icons/Loading3QuartersOutlined'
import { Button, Col, Row, Select } from 'antd'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import React, { useContext, useRef, useState } from 'react'
import { useClickOutside } from '../../hooks/useClickOutside'
import StoreContext from '../../storeContext'
import { Loading } from '../loading'
import AddCompaniesToList from './AddCompaniesToList'
import SearchResult from './SearchResult'
import { SearchStoreCalls, SortDropdown } from './store/SearchStore'
import intl from 'react-intl-universal'
import styled from 'styled-components'
import { SearchOutlined } from '@ant-design/icons'

const StyledSearch = styled.div`
  position: relative;
  width: 80%;
  margin: 40px auto;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(29, 42, 61, 0.05);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .anticon {
    color: #8c8c8c;
  }
  input {
    border: 0px !important;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-top: ${(props) => (props.searching ? '85px;' : '30px;')};
  }
`

export default observer(function () {
  const { search: searchStore, lists } = useContext(StoreContext)
  const ref = useRef()
  const [showAdd, setShowAdd] = useState(false)
  const { searching, setSearching, searchTerm, setSearch } = searchStore
  useClickOutside(ref, () => {
    setSearching(false)
  })
  const onSearchChange = (e) => {
    setSearch(e.target.value)
    setSearching(e.target.value !== '')
    if (searchStore.selectedResult)
      action(() => (searchStore.selectedResult = null))()
  }
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} className="search-wrapper">
        <StyledSearch
          searching={searching}
          ref={ref}
          style={{ position: 'relative' }}
        >
          {searching && (
            <div className="sort-wrapper">
              <div className="sort-wrapper-element full-width">
                <Button
                  onClick={() => setShowAdd(true)}
                  size="small"
                  style={{ margin: 0, width: '100%', height: '100%' }}
                  disabled={searchStore.selectedCompanies.length === 0}
                >
                  {intl.get('addSelectedToList')}
                </Button>
              </div>
              <div className="sort-wrapper-element full-width">
                <Select
                  style={{ width: '100%' }}
                  mode="multiple"
                  placeholder={intl.get('selectList')}
                  onChange={(e) => searchStore.setListSort(e, 'advancedSearch')}
                >
                  {lists.lists.map((list) => (
                    <Select.Option value={list.company_list_id}>
                      {list.list_name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="sort-wrapper-element full-width">
                <Select
                  onChange={(e) => searchStore.toggleSort(e, 'advancedSearch')}
                  defaultValue={searchStore.sortItem}
                  style={{ width: '100%' }}
                  placeholder={intl.get('sort')}
                >
                  {SortDropdown.map((s, i) => (
                    <Select.Option value={i}>{s.name}</Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
          <SearchOutlined />
          <input
            onFocus={() => searchTerm && setSearching(true)}
            className="search-input"
            placeholder={intl.get('searchPlaceholder')}
            type="text"
            value={searchTerm}
            onChange={onSearchChange}
          />
          <Loading blockers={[SearchStoreCalls.SEARCH]}>
            <Loading.InProgress>
              <Loading3QuartersOutlined spin className="search-loading" />
            </Loading.InProgress>
          </Loading>
          {searching && <SearchResult />}
        </StyledSearch>
      </Col>
      <AddCompaniesToList
        visible={showAdd}
        handleOnCancel={() => setShowAdd(false)}
      />
    </Row>
  )
})
