import React, { useContext, useState } from 'react'
import { Card, Col, Statistic } from 'antd'
import StoreContext from '../../../../../storeContext'
import intl from 'react-intl-universal'
import { SurveyTypes } from '../../../../../constants/dropdowns'
import { observer } from 'mobx-react'
import moment from 'moment'
import Button from 'antd/es/button'
import PreviewSurvey from '../../../../Surveys/PreviewSurvey'
import EditModal from '../../../../shared/EditModal'
import InfoPopUp from '../../../../InfoPopUp'
import { RoleGroups } from '../../../../../utils/roles'
import { RequestSurvey } from './RequestSurvey'

export default observer(function () {
  const { companyProfile, user } = useContext(StoreContext)
  const [selectedSurvey, setSelectedSurvey] = useState(null)
  const [showPreview, setShowPreview] = useState(false)
  const [showRequestSurvey, setShowRequestSurvey] = useState(false)

  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
      <Card
        type="inner"
        title={intl.get('questionnaires')}
        bordered={false}
        extra={
          <div style={{ display: 'flex' }}>
            <Button
              style={{
                width: '100%',
                margin: 0,
                height: 20,
                marginRight: 5,
                display: 'flex',
                alignItems: 'center',
              }}
              disabled={
                !RoleGroups.ADMINS.includes(user.user['custom:role_id'])
              }
              onClick={() => setShowRequestSurvey(true)}
            >
              {intl.get('requestSurvey')}
            </Button>
            <InfoPopUp
              title={intl.get('survey')}
              html={intl.get('surveyInfoPopup')}
            />
          </div>
        }
      >
        {companyProfile.company.surveys.length > 0 ? (
          <div style={{ display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
            {companyProfile.company.surveys.map((survey) => {
              return (
                <div>
                  <Statistic
                    title={
                      SurveyTypes.find(
                        (type) => type.value === survey.survey_type,
                      ).name
                    }
                    value={moment(survey.updated_date).format('MMM YYYY')}
                  />
                  <Button
                    style={{ marginTop: 16, width: '100%' }}
                    type="primary"
                    onClick={() => {
                      setSelectedSurvey(survey)
                      setShowPreview(true)
                    }}
                  >
                    View
                  </Button>
                </div>
              )
            })}
          </div>
        ) : (
          <span>{intl.get('noSurveysAnswered')}</span>
        )}
      </Card>
      <EditModal
        handleOnClose={() => setShowPreview(false)}
        visible={showPreview}
        width="80vw"
        title="Preview survey"
      >
        <PreviewSurvey mode="display" surveyCode={selectedSurvey} />
      </EditModal>
      <EditModal
        visible={showRequestSurvey}
        handleOnClose={() => setShowRequestSurvey(false)}
        title={intl.get('requestSurvey')}
      >
        <RequestSurvey />
      </EditModal>
    </Col>
  )
})
