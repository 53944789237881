import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Form, Input, Radio, SubmitButton } from 'formik-antd'
import intl from 'react-intl-universal'
import * as Yup from 'yup'
import StoreContext from '../../../../storeContext'

export default function ({ close }) {
  const { companyProfile } = useContext(StoreContext)
  const type = companyProfile.company.risk_classification ? 'edit' : 'add'
  const validation = Yup.object().shape({
    [type]: Yup.object().shape({
      risk_class: Yup.string().required(intl.get('riskClassRequired')),
    }),
  })
  const initial = {
    [type]: {
      risk_class: companyProfile.company.risk_classification
        ? companyProfile.company.risk_classification.risk_class
        : '',
      risk_comment: companyProfile.company.risk_classification
        ? companyProfile.company.risk_classification.risk_comment
        : '',
    },
  }
  if (type === 'add') {
    initial[type].company_id = companyProfile.company.company_id
  } else {
    initial[type].risk_classification_id =
      companyProfile.company.risk_classification.risk_classification_id
  }
  return (
    <Formik
      validationSchema={validation}
      initialValues={initial}
      onSubmit={async (vals) => {
        await companyProfile.riskClassification(vals, 'addEditClassification')
        close()
      }}
    >
      {() => (
        <Form>
          <span>Last edited by admin</span>
          <Form.Item name={`${type}.risk_class`}>
            <Radio.Group
              name={`${type}.risk_class`}
              options={[
                { label: 'Low', value: 'low' },
                { label: 'Moderate', value: 'moderate' },
                { label: 'High', value: 'high' },
                { label: 'Severe', value: 'severe' },
              ]}
            />
          </Form.Item>
          <Form.Item name={`${type}.risk_comment`}>
            <Input.TextArea
              name={`${type}.risk_comment`}
              rows={4}
              placeholder={intl.get('leaveComment')}
            />
          </Form.Item>
          <SubmitButton>{intl.get('save')}</SubmitButton>
        </Form>
      )}
    </Formik>
  )
}
