import React, { useContext, useState } from "react"
import { Button, List, Modal } from 'antd'
import { Link } from "react-router-dom"
import StoreContext from "../../storeContext"
import intl from 'react-intl-universal'

export default function ({ completeness }) {
  const [show, setShow] = useState(false)
  const {user} = useContext(StoreContext)
  return (
    completeness &&
    completeness.profile_complete_percent < 100 && (
      <>
        <Button
          onClick={() => setShow(true)}
          style={{ margin: 0 }}
          size="small"
        >
          {intl.get('strengthenProfile')}
        </Button>
        <Modal
          footer={null}
          title={intl.get('strengthenProfile')}
          visible={show}
          onCancel={() => setShow(false)}
        >
          <List
            size="large"
            bordered={false}
            dataSource={completeness.improvements_list}
            renderItem={(item) => (
              <List.Item><Link to={`/dashboard/company/${user.user.company_id}`}>{item.rule_description.replace('_', ' ').toUpperCase()}</Link></List.Item>
            )}
          />
        </Modal>
      </>
    )
  )
}
