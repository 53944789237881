import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, Skeleton, Table } from 'antd'
import StoreContext from '../../storeContext'
import { observer } from 'mobx-react'
import EditProfileField from '../Profile/EditProfileField'
import { Form, Input, Select } from 'formik-antd'
import EditFileCell from '../shared/EditFileCell'
import ActionButton from '../shared/ActionButton'
import intl from 'react-intl-universal'
import { toJS } from 'mobx'
import { Link } from 'react-router-dom'
import { ProductGroup } from '../../constants/dropdowns'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

const { confirm } = Modal
export const DeleteFile = ({
  fileType,
  productId,
  productFileName,
  companyId,
}) => {
  const { companyProfile } = useContext(StoreContext)
  const deleteFile = () => {
    confirm({
      title: intl.get('deletingFile'),
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        await companyProfile.editProduct(
          {
            removefile: {
              file_type: fileType,
              product_id: productId,
              product_file_name: productFileName,
            },
            company_id: companyId,
          },
          'removefile',
        )
      },
    })
  }
  return (
    <Button onClick={deleteFile}>
      <DeleteOutlined />
    </Button>
  )
}

export default observer(function () {
  const { companyProfile, user } = useContext(StoreContext)
  const [loaded, setLoaded] = useState(false)
  const [partners, setPartners] = useState([])
  const load = async () => {
    await companyProfile.getCompanyData(user.user.company_id)
    await companyProfile.getImplementationPartners()
    setLoaded(true)
  }
  useEffect(() => {
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (companyProfile.partners.length > 0)
      setPartners(toJS(companyProfile.partners))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProfile.partners])
  const columns = [
    {
      title: intl.get('productName'),
      dataIndex: 'product_name',
      width: 300,
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="product_name"
          callback={async (value, setEdit) => {
            await companyProfile.editProduct(
              {
                edit: {
                  ...record,
                  ...value,
                  product_id: record.product_id,
                },
                company_id: companyProfile.company.company_id,
              },
              'edit',
            )
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: intl.get('productUrl'),
      width: 300,
      dataIndex: 'product_url',
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="product_url"
          callback={async (value, setEdit) => {
            await companyProfile.editProduct(
              {
                edit: {
                  ...record,
                  ...value,
                  product_id: record.product_id,
                },
                company_id: companyProfile.company.company_id,
              },
              'edit',
            )
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: intl.get('implementationPartners'),
      width: 500,
      render: (text, record) => (
        <EditProfileField
          value={record.implementation_partners || []}
          valueLabel={
            record.implementation_partners
              ? companyProfile.partners
                  .filter((partner) =>
                    record.implementation_partners.includes(partner.company_id),
                  )
                  .map((partner) => partner.name)
                  .join(', ')
              : 'No data'
          }
          name="implementation_partners"
          component={() => {
            return (
              <Select
                name="implementation_partners"
                mode="multiple"
                notFoundContent={
                  <Link to={'/dashboard/invite-company'}>
                    {intl.get('inviteCompany')}
                  </Link>
                }
                filterOption={false}
                onSearch={(txt) =>
                  setPartners(
                    companyProfile.partners.filter((partner) =>
                      partner.name.toLowerCase().includes(txt.toLowerCase()),
                    ),
                  )
                }
                placeholder={intl.get('selectImplementationPartners')}
              >
                {partners.map((partner) => (
                  <Select.Option value={partner.company_id}>
                    {partner.name}
                  </Select.Option>
                ))}
              </Select>
            )
          }}
          callback={async (value, setEdit) => {
            await companyProfile.editProduct(
              {
                edit: {
                  ...value,
                  product_id: record.product_id,
                },
                company_id: companyProfile.company.company_id,
              },
              'edit',
            )
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: intl.get('productGroup'),
      width: 200,
      dataIndex: 'product_group',
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="product_group"
          component={() => {
            return (
              <Form.Item name="product_group">
                <Select
                  name="product_group"
                  addonBefore="Product group"
                  placeholder={intl.get('selectProductGroup')}
                >
                  {ProductGroup.map((group) => (
                    <Select.Option value={group.value}>
                      {group.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )
          }}
          callback={async (value, setEdit) => {
            await companyProfile.editProduct(
              {
                edit: {
                  ...record,
                  ...value,
                  product_id: record.product_id,
                },
                company_id: companyProfile.company.company_id,
              },
              'edit',
            )
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: intl.get('description'),
      dataIndex: 'description',
      width: 400,
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="description"
          component={() => {
            return (
              <Form.Item name="description">
                <Input.TextArea
                  placeholder={intl.get('description')}
                  name="description"
                />
              </Form.Item>
            )
          }}
          callback={async (value, setEdit) => {
            await companyProfile.editProduct(
              {
                edit: {
                  ...record,
                  ...value,
                  product_id: record.product_id,
                },
                company_id: companyProfile.company.company_id,
              },
              'edit',
            )
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: intl.get('productImage'),
      width: 400,
      render: (text, record) => (
        <>
          <EditFileCell
            url={record.logo_url}
            urlTitle={
              record.logo_file_name &&
              record.logo_file_name.split('/')[
                record.logo_file_name.split('/').length - 1
              ]
            }
            error={intl.get('productImageError')}
            sizeLimit={2}
            extensions={['image/png', 'image/jpeg']}
            selectFileTitle={intl.get('selectProductImage')}
            callback={(file, product_file_name) => {
              return companyProfile.editProduct(
                {
                  addfile: {
                    product_file: file,
                    upload_type: 'logo',
                    product_id: record.product_id,
                    product_file_name,
                  },
                  company_id: companyProfile.company.company_id,
                },
                'addfile',
              )
            }}
          />
          {record.logo_file_name && (
            <DeleteFile
              fileType="logo"
              productId={record.product_id}
              productFileName={record.logo_file_name}
              companyId={companyProfile.company.company_id}
            />
          )}
        </>
      ),
    },
    {
      title: intl.get('productPresentation'),
      width: 400,
      render: (text, record) => (
        <>
          <EditFileCell
            url={record.presentation_url}
            urlTitle={
              record.presentation_file_name &&
              record.presentation_file_name.split('/')[
                record.presentation_file_name.split('/').length - 1
              ]
            }
            error={intl.get('productPresentationError')}
            sizeLimit={10}
            extensions={['application/pdf']}
            selectFileTitle={intl.get('selectProductPresentation')}
            callback={(file, product_file_name) => {
              return companyProfile.editProduct(
                {
                  addfile: {
                    product_file: file,
                    upload_type: 'presentation',
                    product_id: record.product_id,
                    product_file_name,
                  },
                  company_id: companyProfile.company.company_id,
                },
                'addfile',
              )
            }}
          />
          {record.presentation_file_name && (
            <DeleteFile
              fileType="presentation"
              productId={record.product_id}
              productFileName={record.presentation_file_name}
              companyId={companyProfile.company.company_id}
            />
          )}
        </>
      ),
    },
    {
      title: intl.get('productReferenceInstallations'),
      width: 400,
      render: (text, record) => (
        <>
          <EditFileCell
            url={record.reference_installations_url}
            urlTitle={
              record.reference_installations_file_name &&
              record.reference_installations_file_name.split('/')[
                record.reference_installations_file_name.split('/').length - 1
              ]
            }
            error={intl.get('productPresentationError')}
            sizeLimit={10}
            extensions={['application/pdf']}
            selectFileTitle={intl.get('selectReferenceInstallations')}
            callback={(file, product_file_name) => {
              return companyProfile.editProduct(
                {
                  addfile: {
                    product_file: file,
                    upload_type: 'reference_installations',
                    product_id: record.product_id,
                    product_file_name,
                  },
                  company_id: companyProfile.company.company_id,
                },
                'addfile',
              )
            }}
          />
          {record.reference_installations_file_name && (
            <DeleteFile
              fileType="reference_installations"
              productId={record.product_id}
              productFileName={record.reference_installations_file_name}
              companyId={companyProfile.company.company_id}
            />
          )}
        </>
      ),
    },
    {
      title: intl.get('productRoadmap'),
      width: 400,
      render: (text, record) => (
        <>
          <EditFileCell
            url={record.roadmap_url}
            urlTitle={
              record.roadmap_file_name &&
              record.roadmap_file_name.split('/')[
                record.roadmap_file_name.split('/').length - 1
              ]
            }
            error={intl.get('productPresentationError')}
            sizeLimit={10}
            extensions={['application/pdf']}
            selectFileTitle={intl.get('selectRoadmap')}
            callback={(file, product_file_name) => {
              return companyProfile.editProduct(
                {
                  addfile: {
                    product_file: file,
                    upload_type: 'roadmap',
                    product_id: record.product_id,
                    product_file_name,
                  },
                  company_id: companyProfile.company.company_id,
                },
                'addfile',
              )
            }}
          />
          {record.roadmap_file_name && (
            <DeleteFile
              fileType="roadmap"
              productId={record.product_id}
              productFileName={record.roadmap_file_name}
              companyId={companyProfile.company.company_id}
            />
          )}
        </>
      ),
    },
    {
      title: 'Product price list',
      width: 400,
      render: (text, record) => (
        <>
          <EditFileCell
            url={record.price_list_url}
            urlTitle={
              record.price_list_file_name &&
              record.price_list_file_name.split('/')[
                record.price_list_file_name.split('/').length - 1
              ]
            }
            error={intl.get('productPresentationError')}
            sizeLimit={10}
            extensions={['application/pdf']}
            selectFileTitle={intl.get('selectPriceList')}
            callback={(file, product_file_name) => {
              return companyProfile.editProduct(
                {
                  addfile: {
                    product_file: file,
                    upload_type: 'price_list',
                    product_id: record.product_id,
                    product_file_name,
                  },
                  company_id: companyProfile.company.company_id,
                },
                'addfile',
              )
            }}
          />
          {record.price_list_file_name && (
            <DeleteFile
              fileType="price_list"
              productId={record.product_id}
              productFileName={record.price_list_file_name}
              companyId={companyProfile.company.company_id}
            />
          )}
        </>
      ),
    },
    {
      title: intl.get('usersTableColumnActions'),
      render: (text, record) => (
        <ActionButton
          record={record}
          text={intl.get('delete')}
          showModal={true}
          confirmTitle={intl.get('deleteProductModal')}
          onClick={async () =>
            await companyProfile.editProduct(
              {
                remove: {
                  product_id: record.product_id,
                },
                company_id: companyProfile.company.company_id,
              },
              'remove',
            )
          }
        />
      ),
    },
  ]
  return loaded ? (
    <Table
      rowKey="product_id"
      scroll={{ x: 3500 }}
      bordered={true}
      dataSource={companyProfile.company.products}
      columns={columns}
    />
  ) : (
    <Skeleton active={true} />
  )
})
