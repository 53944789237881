import { Layout, Modal, Space } from 'antd'
import { Formik } from 'formik'
import { Checkbox, Form, Input, Radio, Select, SubmitButton } from 'formik-antd'
import { observer } from 'mobx-react'
import { Observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import loginBackground from '../../assets/images/pozadinaHD.png'
import { Countries } from '../../constants/dropdowns'
import StoreContext from '../../storeContext'
import { trimOnBlur } from '../../utils/helpers'
import { Phone } from '../../utils/phoneInput'
import { ValidationFields } from '../../utils/validationFields'
import LanguageBar from '../LanguageBar'
import CompanyInviteEmailField from './CompanyInviteEmailField'
import './style.css'

const { Content } = Layout

function info() {
  Modal.info({
    title: intl.get('vatHelpModalTitle'),
    content: (
      <div>
        <ul>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://ec.europa.eu/taxation_customs/business/vat/eu-vat-rules-topic/vat-identification-numbers_en"
            >
              {intl.get('vatHelpModalEuLinkText')}
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.uid.admin.ch/"
            >
              {intl.get('vatHelpModalVatFinderLinkText')}
            </a>
          </li>
        </ul>
        <span>{intl.get('vatHelpModalVatInfoText')}</span>
      </div>
    ),
    onOk() {},
  })
}

export default observer(function SignUp() {
  const store = useContext(StoreContext)
  useEffect(() => {
    store.user.getTerms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [initialValues, setInitialValues] = useState({
    user: {
      name: '',
      surname: '',
      position: '',
      email: '',
      password: '',
      confirm_password: '',
      phone: '',
    },
    company: {
      type: 0,
      name: '',
      country: '',
      vat: '84826',
      terms: false,
    },
  })
  const invite = new URLSearchParams(store.router.location.search).get('invite')
  const company = new URLSearchParams(store.router.location.search).get(
    'company',
  )
  const currentLocale = intl.determineLocale({
    localStorageLocaleKey: 'lang',
  })

  const loadData = async () => {
    if (invite) {
      const d = await store.user.loadInviteData({ invite })
      setInitialValues({
        user: { ...initialValues, ...d },
        company: { ...initialValues.company },
      })
    }
    if (company) {
      const d = await store.user.loadInviteData({
        invite: company,
        invite_type: 'company',
      })
      setInitialValues({
        user: { ...initialValues.user, email: d.company_invite_email },
        company: {
          ...initialValues,
          name: d.name,
          vat: d.vat,
          company_id: d.company_id,
        },
      })
    }
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validator = Yup.object({
    user: Yup.object({
      name: ValidationFields.name(),
      surname: ValidationFields.surname(),
      position: ValidationFields.position(),
      email: ValidationFields.email(),
      password: ValidationFields.password(),
      confirm_password: ValidationFields.confirmPassword(),
      phone: ValidationFields.phone(),
    }),
    company:
      !invite &&
      Yup.object({
        type: ValidationFields.companyType(),
        name: ValidationFields.companyName(),
        country: ValidationFields.country(),
        vat: ValidationFields.vat(),
        terms: ValidationFields.terms(),
      }),
  })

  return (
    <Layout
      style={{
        height: '100%',
        background: `url(${loginBackground})`,
        backgroundSize: 'auto',
        overflow: 'auto',
      }}
    >
      {/* <Topbar showCommands={false} /> */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 10%',
          minHeight: '60px',
        }}
      >
        <img
          alt=""
          width="140"
          src={require('../../assets/images/LogoSim.svg')}
        />
        <LanguageBar />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h1 style={{ color: 'white' }}>{intl.get('signUpTitleOne')}</h1>
        <h1 style={{ color: 'white' }}>{intl.get('signUpTitleTwo')}</h1>
      </div>
      <Content>
        <div
          className="gx-login-container"
          style={{ padding: '30px', height: 'auto' }}
        >
          <div className="gx-login-content" style={{ borderRadius: '30px' }}>
            <div className="gx-login-header gx-text-center">
              <h1 className="gx-login-title">
                {intl.get('registrationTitle')}
              </h1>
            </div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validator}
              onSubmit={(values, { setSubmitting, setFieldError }) => {
                values.user.language = currentLocale
                store.user.signUp(values, setSubmitting, setFieldError)
              }}
            >
              {({ errors, setFieldValue }) => {
                return (
                  <Observer>
                    {() => (
                      <Form>
                        {!invite && (
                          <Form.Item
                            name="company.type"
                            style={{ textAlign: 'center' }}
                          >
                            <h3 style={{ marginBottom: 10 }}>
                              {intl.get('userTypeLabel')}
                            </h3>
                            <Radio.Group
                              name="company.type"
                              className="sign-up-radio-group"
                              options={[
                                {
                                  label: (
                                    <>
                                      <h4>{intl.get('buyerLabel')}</h4>
                                      <div className="sign-up-radio-description">
                                        {intl.get('buyerInputInfo')}
                                      </div>
                                    </>
                                  ),
                                  value: 1,
                                },
                                {
                                  label: (
                                    <>
                                      <h4>{intl.get('sellerLabel')}</h4>
                                      <div className="sign-up-radio-description">
                                        {intl.get('sellerInputInfo')}
                                      </div>
                                    </>
                                  ),
                                  value: 2,
                                },
                              ]}
                            />
                          </Form.Item>
                        )}
                        <Form.Item name="user.name">
                          <Input
                            name="user.name"
                            placeholder={intl.get('nameLabel')}
                            onBlur={(e) => trimOnBlur(e, setFieldValue)}
                          />
                        </Form.Item>
                        <Form.Item name="user.surname">
                          <Input
                            name="user.surname"
                            placeholder={intl.get('surnameLabel')}
                            onBlur={(e) => trimOnBlur(e, setFieldValue)}
                          />
                        </Form.Item>
                        <Form.Item name="user.position">
                          <Input
                            name="user.position"
                            placeholder={intl.get('positionLabel')}
                            onBlur={(e) => trimOnBlur(e, setFieldValue)}
                          />
                        </Form.Item>
                        {!invite && (
                          <Form.Item name="company.name">
                            <Input
                              disabled={!!company}
                              name="company.name"
                              placeholder={intl.get('companyNameLabel')}
                              onBlur={(e) => trimOnBlur(e, setFieldValue)}
                            />
                          </Form.Item>
                        )}
                        {!invite && (
                          <Form.Item name="company.country">
                            <Select
                              name="company.country"
                              style={{ width: '100%' }}
                              placeholder={intl.get('selectCountryLabel')}
                            >
                              {Countries.map((country) => (
                                <Select.Option value={country.value}>
                                  {country.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                        {!invite && (
                          <Form.Item name="company.vat">
                            <Input
                              disabled={!!company}
                              name="company.vat"
                              placeholder={intl.get('vatLabel')}
                              onBlur={(e) => trimOnBlur(e, setFieldValue)}
                            />

                            <span
                              className="ant-btn-link vat-help-button"
                              onClick={info}
                            >
                              {intl.get('vatHelpButtonText')}
                            </span>
                          </Form.Item>
                        )}
                        <Form.Item name="user.email">
                          {company ? (
                            <CompanyInviteEmailField
                              setField={setFieldValue}
                              email={initialValues.user.email}
                            />
                          ) : (
                            <Input
                              disabled={!!invite}
                              name="user.email"
                              placeholder={intl.get('emailLabel')}
                              onBlur={(e) => trimOnBlur(e, setFieldValue)}
                            />
                          )}
                        </Form.Item>
                        <Form.Item name="user.password">
                          <Input.Password
                            name="user.password"
                            placeholder={intl.get('passwordLabel')}
                          />
                        </Form.Item>
                        <Form.Item name="user.confirm_password">
                          <Input.Password
                            name="user.confirm_password"
                            placeholder={intl.get('passwordConfirmLabel')}
                          />
                        </Form.Item>
                        <Form.Item name="user.phone">
                          <PhoneInput
                            inputComponent={Phone}
                            name="user.phone"
                            value=""
                            className="phone-input-wrapper"
                            onChange={(e) => setFieldValue('user.phone', e)}
                            placeholder={intl.get('phoneLabel')}
                          />
                        </Form.Item>
                        {!invite && (
                          <Form.Item name="company.terms">
                            <Checkbox
                              name="company.terms"
                              className="terms-checkbox"
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <span>{intl.get('signUpTermsOne')}</span>
                                <div>
                                  <a
                                    style={{ textDecoration: 'underline' }}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={store.user.termsOfUse}
                                  >
                                    {intl.get('acceptTermsText')}
                                  </a>
                                  <span> {intl.get('signUpTermsTwo')}</span>
                                </div>
                              </div>
                            </Checkbox>
                          </Form.Item>
                        )}
                        {errors['asyncError'] && (
                          <div className="ant-row ant-form-item ant-form-item-with-help ant-form-item-has-error">
                            <div className="ant-col ant-form-item-control">
                              <div className="ant-form-item-explain">
                                <div>
                                  <li>{intl.get(errors['asyncError'])}</li>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="ant-row">
                          <SubmitButton
                            disabled={false}
                            style={{ width: '100%' }}
                          >
                            {intl.get('registerButtonText')}
                          </SubmitButton>
                        </div>
                        <div className="ant-row">
                          <Space style={{ margin: '0 auto' }}>
                            <span>{intl.get('alreadySignUpText')}</span>
                            <Link to="/login">
                              {intl.get('alreadySignUpLinkText')}
                            </Link>
                          </Space>
                        </div>
                      </Form>
                    )}
                  </Observer>
                )
              }}
            </Formik>
          </div>
        </div>
      </Content>
    </Layout>
  )
})
