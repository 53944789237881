import React, { useState } from "react"
import { Button, Divider, Modal } from 'antd'
import DetailItem from "./DetailItem"

export default function ({ record }) {
  const [show, setShow] = useState(false)
  const detailsModal = () =>(
        <>
          <Divider>Application security</Divider>
          <DetailItem item={record.application_security} />
          <Divider>Botnet infections</Divider>
          <DetailItem item={record.botnet_infections} />
          <Divider>Data breaches</Divider>
          <DetailItem item={record.data_breaches} />
          <Divider>Desktop software</Divider>
          <DetailItem item={record.desktop_software} />
          <Divider>DKIM</Divider>
          <DetailItem item={record.dkim} />
          <Divider>DNSSEC</Divider>
          <DetailItem item={record.dnssec} />
          <Divider>File sharing</Divider>
          <DetailItem item={record.file_sharing} />
          <Divider>Insecure systems</Divider>
          <DetailItem item={record.insecure_systems} />
          <Divider>Malware servers</Divider>
          <DetailItem item={record.malware_servers} />
          <Divider>Mobile application security</Divider>
          <DetailItem item={record.mobile_application_security} />
          <Divider>Mobile software</Divider>
          <DetailItem item={record.mobile_software} />
          <Divider>Open ports</Divider>
          <DetailItem item={record.open_ports} />
          <Divider>Patching cadence</Divider>
          <DetailItem item={record.patching_cadence} />
          <Divider>Potentially exploited</Divider>
          <DetailItem item={record.potentially_exploited} />
          <Divider>Server software</Divider>
          <DetailItem item={record.server_software} />
          <Divider>Spam propagation</Divider>
          <DetailItem item={record.spam_propagation} />
          <Divider>SPF</Divider>
          <DetailItem item={record.spf} />
          <Divider>SSL certificates</Divider>
          <DetailItem item={record.ssl_certificates} />
          <Divider>SSL configurations</Divider>
          <DetailItem item={record.ssl_configurations} />
          <Divider>Unsolicited comm</Divider>
          <DetailItem item={record.unsolicited_comm} />
        </>
      )
  return record ? <>
          <Button onClick={()=> setShow(true)}>Details</Button>
          <Modal
            title="Details"
            visible={show}
            onCancel={()=> setShow(false)}
            footer={[
                    <Button key="back" onClick={()=> setShow(false)}>
                           Ok
                    </Button>,
            ]}
          >
                  {detailsModal()}
          </Modal>
  </> : 'No details'
}
