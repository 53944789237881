import React, { useContext, useState } from 'react'
import { Button, Card, message, Upload } from 'antd'
import { DatePicker, Form, Select, SubmitButton } from 'formik-antd'
import intl from 'react-intl-universal'
import { Formik } from 'formik'
import { UploadOutlined } from '@ant-design/icons'
import StoreContext from '../../storeContext'
import './style.css'
import * as Yup from 'yup'
import * as moment from 'moment'

export default function TermsUpload({ type }) {
  const [fileList, setFileList] = useState([])
  const { terms } = useContext(StoreContext)
  function onRemoveFile(set) {
    set('terms_file', '')
    setFileList([])
  }
  function onFileChange(file, set) {
    if (!file.name.includes('.pdf')) {
      message.error(intl.get('termsNotValidFile'))
      return false
    }
    setFileList([file])
    const reader = new FileReader()
    reader.onload = () => {
      set('terms_file', reader.result.split(',')[1])
    }
    reader.readAsDataURL(file)
    return false
  }
  const initialValues = {
    terms_type: type,
    version_type: '',
    publish_date: '',
    terms_file: '',
  }
  const validator = Yup.object({
    terms_type: Yup.string().required(),
    version_type: Yup.string().required(intl.get('versionTypeRequired')),
    publish_date: Yup.string().required(intl.get('publishDateRequired')),
    terms_file: Yup.string().required(intl.get('termsFileRequired')),
  })
  return (
    <Card title={intl.get('uploadTermsTitle')}>
      <Formik
        initialValues={initialValues}
        validationSchema={validator}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await terms.uploadTerms(values)
          resetForm()
          setFileList([])
          setSubmitting(false)
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <Form.Item name="terms_file">
              <Upload
                onRemove={() => onRemoveFile(setFieldValue)}
                beforeUpload={(file) => onFileChange(file, setFieldValue)}
                fileList={fileList}
                className="upload-terms"
              >
                <Button style={{ width: '100%' }}>
                  <UploadOutlined />
                  {intl.get('selectPDF')}
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item name="version_type">
              <Select
                name="version_type"
                placeholder={intl.get('selectVersion')}
              >
                <Select.Option value="critical">
                  {intl.get('versionCritical')}
                </Select.Option>
                <Select.Option value="major">
                  {intl.get('versionMajor')}
                </Select.Option>
                <Select.Option value="minor">
                  {intl.get('versionMinor')}
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="publish_date">
              <DatePicker
                name="publish_date"
                showTime={true}
                onChange={(date) => {
                  if (!date) return null
                  setFieldValue(
                    'publish_date',
                    moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss.SSS'),
                  )
                  return date
                }}
                placeholder={intl.get('publishDate')}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <div className="ant-row">
              <SubmitButton style={{ width: '100%' }}>
                {intl.get('uploadTermsButton')}
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  )
}
