import React, { useContext, useState } from 'react'
import intl from 'react-intl-universal'
import EditProfileField from '../Profile/EditProfileField'
import { Card, Table } from 'antd'
import MinusOutlined from '@ant-design/icons/lib/icons/MinusOutlined'
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined'
import RateCard, { CardContext } from './index'
import CustomComment from './Public/CustomComment'
import moment from 'moment'
import { Loading } from '../loading'
import { CompanyProfileStoreCalls } from '../CompanyProfile/store/CompanyProfileStore'
import { LoadingOutlined } from '@ant-design/icons'
import { RateCardCalls } from './store/RateCardsStore'

export default function ({ card, title, titleEdit, description }) {
  const [open, setOpen] = useState(false)
  const { updSeniority, updTitle, canEdit } = useContext(CardContext)
  const toggle = () => setOpen((open) => !open)
  if (!card) return null
  const data = [
    {
      seniority_level: 'L1',
      titan_description: 'titan_descriptionL1',
      supplier_description:
        card.seniority_definition && card.seniority_definition.seniority_L1,
    },
    {
      seniority_level: 'L2',
      titan_description: 'titan_descriptionL2',
      supplier_description:
        card.seniority_definition && card.seniority_definition.seniority_L2,
    },
    {
      seniority_level: 'L3',
      titan_description: 'titan_descriptionL3',
      supplier_description:
        card.seniority_definition && card.seniority_definition.seniority_L3,
    },
    {
      seniority_level: 'L4',
      titan_description: 'titan_descriptionL4',
      supplier_description:
        card.seniority_definition && card.seniority_definition.seniority_L4,
    },
    {
      seniority_level: 'L5',
      titan_description: 'titan_descriptionL5',
      supplier_description:
        card.seniority_definition && card.seniority_definition.seniority_L5,
    },
  ]
  const columns = [
    {
      title: intl.get('seniorityLevel'),
      dataIndex: 'seniority_level',
    },
    {
      title: intl.get('titanSeniorityDescription'),
      dataIndex: 'titan_description',
      render: (text, record) => intl.get(text),
    },
    {
      title: intl.get('supplierSeniorityDefinition'),
      dataIndex: 'supplier_description',
      width: 500,
      render: (text, record) =>
        canEdit ? (
          <Loading blockers={[CompanyProfileStoreCalls.UPDATE_COMPANY_PROFILE, RateCardCalls.PRIVATE_RATE_CARDS]}>
            <Loading.InProgress>
              <LoadingOutlined />
            </Loading.InProgress>
            <Loading.Done>
              <EditProfileField
                value={text}
                name={`seniority_${record.seniority_level}`}
                callback={async (value, setEdit) => {
                  await updSeniority(value)
                  setEdit(false)
                }}
                type="cell"
              />
            </Loading.Done>
          </Loading>
        ) : (
          text
        ),
    },
  ]

  const showSeniorityDefinition = (definition) => {
    return (
      definition &&
      Object.keys(definition).filter((key) => {
        return definition[key] !== ''
      }).length > 0
    )
  }

  return (
    <div>
      <Card
        title={
          titleEdit && canEdit ? (
            <EditProfileField
              value={title}
              valueLabel={`${title} ${intl.get('lastUpdate', {
                0: moment
                  .utc(card.last_updated)
                  .local()
                  .format('MM/DD/YYYY, HH:mm:ss'),
              })}`}
              name="rate_card_title"
              callback={async (value, setEdit) => {
                await updTitle(value)
                setEdit(false)
              }}
              type="cell"
            />
          ) : (
            `${title} ${intl.get('lastUpdate', {
              0: moment
                .utc(card.last_updated)
                .local()
                .format('MM/DD/YYYY, HH:mm:ss'),
            })}`
          )
        }
      >
        <Card title="Description" type="inner" bordered={false}>
          {description}
        </Card>
        <Card
          title={intl.get('seniorityDefinition')}
          type="inner"
          bordered={false}
          extra={
            open ? (
              <MinusOutlined onClick={toggle} />
            ) : (
              <PlusOutlined onClick={toggle} />
            )
          }
        >
          {open && (
            <Table
              columns={columns.filter((col) => {
                if (
                  !canEdit &&
                  !showSeniorityDefinition(card.seniority_definition) &&
                  col.dataIndex === 'supplier_description'
                )
                  return false
                return true
              })}
              dataSource={data}
              pagination={false}
            />
          )}
        </Card>
        <Card type="inner" title={intl.get('rateCards')}>
          {card[card.allCards ? 'allCards' : 'rates'].map((card) => (
            <RateCard level={1} rateCard={card} />
          ))}
        </Card>
        <CustomComment desc={card.description} />
      </Card>
    </div>
  )
}
