import React, { useContext } from 'react'
import { Form, Input, SubmitButton } from 'formik-antd'
import { Formik } from 'formik'
import * as Yup from 'yup'
import intl from 'react-intl-universal'
import StoreContext from '../../../storeContext'
import { ValidationFields } from '../../../utils/validationFields'

export default function SingleUser() {
  const { users } = useContext(StoreContext)
  const initialValues = {
    email: '',
    first_name: '',
    last_name: '',
  }

  const validator = Yup.object({
    email: ValidationFields.email(),
    first_name: ValidationFields.name(),
    last_name: ValidationFields.surname(),
  })
  return (
    <div className="single-invite-wrapper">
      <Formik
        initialValues={initialValues}
        validationSchema={validator}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await users.inviteUsers([values])
          resetForm()
          setSubmitting(false)
        }}
      >
        {() => (
          <Form>
            <Form.Item name="email">
              <Input name="email" placeholder={intl.get('emailLabel')} />
            </Form.Item>
            <Form.Item name="first_name">
              <Input name="first_name" placeholder={intl.get('nameLabel')} />
            </Form.Item>
            <Form.Item name="last_name">
              <Input name="last_name" placeholder={intl.get('surnameLabel')} />
            </Form.Item>
            <div className="ant-row">
              <SubmitButton>{intl.get('sendInvite')}</SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
