import React, { useState } from "react";
import { Card } from "antd";
import SingleUser from "./SingleUser";
import BulkUsers from "./BulkUsers";
import intl from "react-intl-universal";

const tabList = ()=>[
  {key: 'tab1', tab: intl.get('singleUserInviteTabTitle')},
  {key: 'tab2', tab: intl.get('bulkUserInviteTabTitle')}
]
const contentList = {
  tab1: <SingleUser/>,
  tab2: <BulkUsers />
}
export default function() {
  const [tabKey, setTabKey] = useState('tab1')

  return  <Card
    style={{ width: '100%' }}
    title={intl.get('addUserCardTitle')}
    tabList={tabList()}
    activeTabKey={tabKey}
    onTabChange={key => {
      setTabKey(key)
    }}
  >
    {contentList[tabKey]}
  </Card>
}