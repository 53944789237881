import React from 'react'
import TwitterOutlined from '@ant-design/icons/lib/icons/TwitterOutlined'
import FacebookOutlined from '@ant-design/icons/lib/icons/FacebookOutlined'
import InstagramOutlined from '@ant-design/icons/lib/icons/InstagramOutlined'
import LinkedinOutlined from '@ant-design/icons/lib/icons/LinkedinOutlined'
import YoutubeOutlined from '@ant-design/icons/lib/icons/YoutubeOutlined'
import { getValidLink } from '../../../../utils/helpers'
import { XingIcon } from '../../../shared/Icons'
export default function ({ links }) {
  return (
    <div className="general-social">
      {links && (
        <>
          {links.twitter_url && (
            <div className="social-wrap">
              <a
                rel="noopener noreferrer"
                href={getValidLink(links.twitter_url)}
                target="_blank"
              >
                <TwitterOutlined />
              </a>
            </div>
          )}
          {links.facebook_url && (
            <div className="social-wrap">
              <a
                rel="noopener noreferrer"
                href={getValidLink(links.facebook_url)}
                target="_blank"
              >
                <FacebookOutlined />
              </a>
            </div>
          )}
          {links.instagram_url && (
            <div className="social-wrap">
              <a
                rel="noopener noreferrer"
                href={getValidLink(links.instagram_url)}
                target="_blank"
              >
                <InstagramOutlined />
              </a>
            </div>
          )}
          {links.linkedin_url && (
            <div className="social-wrap">
              <a
                rel="noopener noreferrer"
                href={getValidLink(links.linkedin_url)}
                target="_blank"
              >
                <LinkedinOutlined />
              </a>
            </div>
          )}
          {links.youtube_url && (
            <div className="social-wrap">
              <a
                rel="noopener noreferrer"
                href={getValidLink(links.youtube_url)}
                target="_blank"
              >
                <YoutubeOutlined />
              </a>
            </div>
          )}
          {links.xing_url && (
            <div className="social-wrap">
              <a
                rel="noopener noreferrer"
                href={getValidLink(links.xing_url)}
                target="_blank"
              >
                <XingIcon style={{ fontSize: '10px' }} />
              </a>
            </div>
          )}
        </>
      )}
    </div>
  )
}
