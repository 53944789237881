import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import StoreContext from '../../../storeContext'
import { useParams } from 'react-router'
import { Checkbox, Modal } from 'antd'
import intl from 'react-intl-universal'
import { RoleGroups } from '../../../utils/roles'
import { PlusOutlined } from '@ant-design/icons'

export default observer(function () {
  const { companyProfile, user } = useContext(StoreContext)
  const [showAdd, setShowAdd] = useState(false)
  const [checked, setChecked] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const company_id = useParams().id
  useEffect(() => {
    company_id && companyProfile.preferredSupplierList(company_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id])
  if (
    companyProfile.company.company_type !== 2 ||
    companyProfile.company.company_id === user.user.company_id
  )
    return null
  return (
    <div>
      {companyProfile.isPreferredSupplier ? (
        <div style={{ color: '#1890FF' }}>Preferred supplier</div>
      ) : (
        RoleGroups.ADMINS.includes(user.user['custom:role_id']) && (
          <div
            style={{ color: '#1890FF', cursor: 'pointer' }}
            onClick={() => setShowAdd(true)}
          >
            <PlusOutlined /> {intl.get('addPreferredSupplier')}
          </div>
        )
      )}
      <Modal
        title={intl.get('addPreferredSupplier')}
        confirmLoading={confirmLoading}
        visible={showAdd}
        onCancel={() => setShowAdd(false)}
        onOk={async () => {
          setConfirmLoading(true)
          await companyProfile.editPreferredSupplier(
            {
              add: { visible: !checked },
              companies: [
                user.user.company_id,
                companyProfile.company.company_id,
              ],
            },
            'add',
          )
          setConfirmLoading(false)
          setShowAdd(false)
        }}
      >
        <div>
          <span>
            {intl.get('addPreferredSupplierInfo', {
              0: companyProfile.company.name,
            })}
          </span>
          <Checkbox
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          >
            {intl.get('sharePreferredSupplierStatus')}
          </Checkbox>
        </div>
      </Modal>
    </div>
  )
})
