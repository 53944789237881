import React, { useContext } from 'react'
import { Button } from 'antd'
import StoreContext from '../../../storeContext'
import { Loading } from '../../loading'
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import { CompanyImportStoreCalls } from "../../CompanyImport/store/CompanyImportStore";

export default function ({ record }) {
  const { companiesImport } = useContext(StoreContext)
  const fetchCMPLK = async () => {
    await companiesImport.importExisting([record])
  }
  return record.duns ? (
    record.duns
  ) : (
    <Loading blockers={[CompanyImportStoreCalls.IMPORT_EXISTING]}>
      <Loading.Done>
      <Button onClick={fetchCMPLK}>Fetch CMPLK</Button>
      </Loading.Done>
      <Loading.InProgress>
        <Button disabled >Fetch CMPLK <LoadingOutlined /></Button>
      </Loading.InProgress>
    </Loading>
  )
}
