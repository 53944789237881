import React from 'react'
import { Card, Typography } from 'antd'
import intl from 'react-intl-universal'
import Map from '../../../Map'
const { Text } = Typography
export default function ({ data = {} }) {
  return (
    <Card
      bordered={false}
      type="inner"
      title={intl.get('registrationAndAddress')}
    >
      {data.visible && (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div>
            {data.latitude && (
              <Map lat={data.latitude} lng={data.longitude} text={'test'} />
            )}
          </div>
          <div style={{ marginTop: 10 }}>
            <div>
              <Text
                strong
              >{`${data.country}, ${data.postal_code} ${data.city}`}</Text>
            </div>
            <div style={{ marginBottom: 10 }}>
              <Text strong>{`${data.address}`}</Text>
            </div>
            <div>{`VAT: ${data.vat}`}</div>
            <div>{`Registration number: ${data.registration_number}`}</div>
          </div>
        </div>
      )}
    </Card>
  )
}
