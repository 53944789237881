import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import SignUp from '../pages/SignUp'
import LogIn from '../pages/LogIn'
import ResetPassword from '../pages/ResetPassword'
import Confirm from '../pages/ResetPassword/Confirmation'
import Confirmation from '../pages/SignUp/Confirmation'
import { Dashboard } from '../containers/Dashboard'
import Users from '../components/Users'
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined'
import Success from '../pages/SignUp/Success'
import Profile from '../components/Profile'
import ProfileOutlined from '@ant-design/icons/lib/icons/ProfileOutlined'
import CompaniesOverview from '../components/CompaniesOverview'
import StoreContext from '../storeContext'
import { RoleGroups, Roles } from '../utils/roles'
import { observer } from 'mobx-react'
import CompanyViewMatrix from '../components/CompanyViewMatrix'
import CompanyProfile from '../components/CompanyProfile'
import CompanyImport from '../components/CompanyImport'
import Terms from '../components/Terms'
import HomeOutlined from '@ant-design/icons/lib/icons/HomeOutlined'
import {
  AuditOutlined,
  BarChartOutlined,
  BlockOutlined,
  FileTextOutlined,
  FundOutlined,
  ImportOutlined,
  LineChartOutlined,
  ProjectOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import Certificates from '../components/GeneralCriteria/Certificates'
import IndustryCodes from '../components/GeneralCriteria/IndustryCodes'
import CompanyInvite from '../components/CompanyInvite'
import Welcome from '../pages/Welcome'
import RateCard from '../components/RateCard'
import PreferredSuppliers from '../components/PreferredSuppliers'
import CompanyLists from '../components/CompanyLists'
import AdvancedSearch from '../components/Search/Advanced'
import FileSearchOutlined from '@ant-design/icons/lib/icons/FileSearchOutlined'
import Products from '../components/Products'
import Contracts from '../components/Contracts'
import FileDoneOutlined from '@ant-design/icons/lib/icons/FileDoneOutlined'
import Surveys from '../components/Surveys'
import Compare from '../components/Compare'
import MyRequests from '../components/MyRequests'
import ScreeningRequest from '../components/ResourceVetting/ScreeningRequest'
import ScreeningReport from '../components/ResourceVetting/ScreeningReport'
import TagsTabs from '../components/GeneralCriteria/Tags/TagsTabs'

export const mainRoutes = [
  {
    path: '/',
    component: () => <LogIn />,
    exact: true,
    auth: RoleGroups.ALL_USERS,
  },
  {
    path: '/dashboard',
    component: () => <Dashboard />,
    auth: RoleGroups.LOGGED_IN,
  },
  {
    path: '/sign-up',
    component: () => <SignUp />,
    exact: true,
    auth: RoleGroups.VISITOR,
  },
  {
    path: '/login',
    component: () => <LogIn />,
    exact: true,
    auth: RoleGroups.VISITOR,
  },
  {
    path: '/reset',
    component: () => <ResetPassword />,
    exact: true,
    auth: RoleGroups.ALL_USERS,
  },
  {
    path: '/confirm',
    component: () => <Confirmation />,
    exact: true,
    auth: RoleGroups.ALL_USERS,
  },
  {
    path: '/confirm-reset-password',
    component: () => <Confirm />,
    exact: true,
    auth: RoleGroups.ALL_USERS,
  },
  {
    path: '/confirm-success',
    component: () => <Success />,
    exact: true,
    auth: RoleGroups.ALL_USERS,
  },
]

const benchmarkRateCardList = (user) => {
  const types = [0, 1]
  if (user.user.company_type === 2 && user.user.benchmark_rate_card_feature) {
    types.push(2)
  }
  return types
}
export const dashboardRoutes = (user) => [
  {
    path: '/dashboard',
    exact: true,
    component: () => <Welcome />,
    name: 'welcome',
    icon: <HomeOutlined />,
    auth: RoleGroups.LOGGED_IN,
    id: 'dashboard_menu_dashboard',
  },
  {
    path: '/dashboard/my-requests',
    exact: true,
    component: () => <MyRequests />,
    name: 'myRequests',
    icon: <HomeOutlined />,
    auth: RoleGroups.LOGGED_IN,
    id: 'my_requests',
    showInMenu: false,
  },
  {
    path: '/dashboard/compare',
    exact: true,
    component: () => <Compare />,
    icon: <LineChartOutlined />,
    name: 'compare',
    id: 'dashboard_compare',
    auth: RoleGroups.LOGGED_IN,
  },
  {
    path: '/dashboard/users',
    exact: true,
    component: () => <Users />,
    name: 'users',
    icon: <UserOutlined />,
    auth: RoleGroups.ADMINS,
    id: 'dashboard_menu_users',
  },
  {
    path: '/dashboard/search',
    exact: true,
    name: 'search',
    showInMenu: true,
    icon: <SearchOutlined />,
    auth: RoleGroups.LOGGED_IN,
    component: () => <AdvancedSearch />,
  },
  {
    path: '/dashboard/profile',
    exact: true,
    component: () => <Profile />,
    name: 'userProfile',
    icon: <ProfileOutlined />,
    showInMenu: false,
    auth: RoleGroups.LOGGED_IN,
    id: 'dashboard_menu_profile',
  },
  {
    path: '/dashboard/companies-overview',
    exact: true,
    component: () => <CompaniesOverview />,
    name: 'companiesOverview',
    icon: <ProfileOutlined />,
    auth: RoleGroups.TITANS,
    id: 'dashboard_menu_companies-overview',
  },
  {
    path: '/dashboard/companies-view-matrix',
    exact: true,
    component: () => <CompanyViewMatrix />,
    icon: <BlockOutlined />,
    name: 'companiesViewMatrix',
    auth: RoleGroups.TITANS,
    id: 'dashboard_menu_companies-view-matrix',
  },
  {
    path: '/dashboard/import-companies',
    exact: true,
    component: () => <CompanyImport />,
    icon: <ImportOutlined />,
    name: 'importCompanies',
    auth: RoleGroups.TITANS,
    id: 'dashboard_menu_import-companies',
  },
  {
    path: '/dashboard/terms',
    exact: true,
    component: () => <Terms />,
    name: 'terms',
    icon: <FileTextOutlined />,
    auth: RoleGroups.TITANS,
    id: 'dashboard_menu_terms',
  },
  {
    path: '/dashboard/company/:id',
    exact: true,
    component: () => <CompanyProfile />,
    showInMenu: false,
    auth: RoleGroups.LOGGED_IN,
  },
  {
    path: '/dashboard/products',
    exact: true,
    name: 'products',
    auth: RoleGroups.ADMINS,
    icon: <ProjectOutlined />,
    showInMenu: user.user.company_type !== 1,
    component: () => <Products />,
  },
  {
    path: '/dashboard/invite-company',
    exact: true,
    name: 'inviteCompany',
    showInMenu: true,
    auth: [Roles.CLIENT_ADMIN, Roles.CLIENT_USER, Roles.TITAN_MASTER_ADMIN],
    component: () => <CompanyInvite />,
  },
  {
    path: '/dashboard/preferred-suppliers',
    exact: true,
    name: 'preferredSuppliers',
    showInMenu: true,
    auth: RoleGroups.LOGGED_IN,
    companyType: [1],
    component: () => <PreferredSuppliers />,
  },
  {
    path: '/dashboard/company-lists',
    exact: true,
    name: 'companyLists',
    showInMenu: true,
    icon: <FileSearchOutlined />,
    auth: RoleGroups.LOGGED_IN,
    component: () => <CompanyLists />,
  },
  {
    path: '/dashboard/surveys',
    exact: true,
    name: 'surveys',
    icon: <FileDoneOutlined />,
    showInMenu: true,
    auth: RoleGroups.LOGGED_IN,
    component: () => <Surveys />,
  },
  {
    auth: RoleGroups.TITANS,
    name: 'generalCriteria',
    key: 'criteria',
    subMenu: [
      {
        path: '/dashboard/general-criteria/tags',
        component: () => <TagsTabs />,
        exact: true,
        name: 'tags',
      },
      {
        path: '/dashboard/general-criteria/certificates',
        component: () => <Certificates />,
        exact: true,
        name: 'certificates',
      },
      {
        path: '/dashboard/general-criteria/industry-codes',
        component: () => <IndustryCodes />,
        exact: true,
        name: 'industryCodes',
      },
    ],
  },
  {
    name: 'contracts',
    key: 'contracts',
    path: '/dashboard/contracts',
    auth: RoleGroups.LOGGED_IN,
    exact: true,
    icon: <AuditOutlined />,
    component: () => <Contracts />,
  },
  {
    auth: RoleGroups.LOGGED_IN,
    name: 'rateCards',
    key: 'ratecards',
    icon: <BarChartOutlined />,
    subMenu: [
      {
        path: '/dashboard/target-rate-cards',
        component: () => <RateCard.Target />,
        exact: true,
        name: 'targetRateCards',
        companyType: [1],
      },
      {
        path: '/dashboard/private-rate-cards',
        component: () => <RateCard.Private />,
        exact: true,
        name: 'privateRateCards',
        companyType: [1, 2],
      },
      {
        path: '/dashboard/titan-benchmarks',
        component: () => <RateCard.Benchmark />,
        exact: true,
        name: 'titanBenchmarks',
        companyType: benchmarkRateCardList(user),
      },
      {
        path: '/dashboard/public-rate-cards',
        component: () => <RateCard.Public />,
        exact: true,
        name: 'publicRateCards',
        companyType: [2],
      },
    ],
  },
  {
    auth: RoleGroups.LOGGED_IN,
    icon: <FundOutlined />,
    name: 'resourceVetting',
    key: 'resourceVetting',
    showInMenu:
      user.user.resource_vetting_feature ||
      RoleGroups.TITANS.includes(user.user['custom:role_id']),
    subMenu: [
      {
        path: '/dashboard/resource-vetting/screening-request',
        component: () => <ScreeningRequest />,
        exact: true,
        name: 'screeningRequests',
      },
      {
        path: '/dashboard/resource-vetting/screening-report',
        component: () => <ScreeningReport />,
        exact: true,
        name: 'screeningReports',
      },
    ],
  },
]

export default observer(function Routes({ routes }) {
  const { user } = useContext(StoreContext)
  console.log(user)
  const renderRoutes = () => {
    return routes.map((route) => {
      if (
        typeof route.subMenu === 'undefined' &&
        route.auth &&
        !route.auth.includes(user.user['custom:role_id'])
      ) {
        return user.user['custom:role_id'] === Roles.VISITOR ? (
          <Route key={route.path} {...route} exact={false}>
            <Redirect to="/login" />
          </Route>
        ) : (
          <Route key={route.path} {...route} exact={false}>
            <Redirect to="/dashboard" />
          </Route>
        )
      }

      return route.subMenu ? (
        route.subMenu.map((sub) => {
          if (
            sub.companyType &&
            !sub.companyType.includes(user.user.company_type)
          ) {
            return null
          }
          return (
            route.auth &&
            route.auth.includes(user.user['custom:role_id']) && (
              <Route key={sub.path} {...sub} />
            )
          )
        })
      ) : (
        <Route key={route.path} {...route} />
      )
    })
  }
  return (
    <Switch>
      {renderRoutes()}
      <Route>
        <div>404</div>
      </Route>
    </Switch>
  )
})
