import React from 'react'
import moment from 'moment'
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer'
import font from '../../../assets/fonts/DMSans/DMSans-Regular.ttf'
import work from '../../../assets/fonts/WorkSans/static/WorkSans-Light.ttf'
import workBold from '../../../assets/fonts/WorkSans/static/WorkSans-SemiBold.ttf'
import workRegular from '../../../assets/fonts/WorkSans/static/WorkSans-Regular.ttf'

const checkDate = moment('07/20/2021')
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#f8f8f8',
    fontFamily: 'Work sans',
    paddingBottom: 20,
  },
  header: {
    margin: '0 20',
    padding: '10 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1pt solid #DADDE6',
  },
  section: {
    padding: '10 20 0 20',
    display: 'flex',
    flexDirection: 'row',
  },
  caseInfoText: {
    marginBottom: '5px',
    fontSize: '12pt',
    fontWeight: '400',
  },
})
Font.register({
  family: 'DM Sans',
  src: font,
})
Font.register({
  family: 'Work sans',
  fonts: [
    { src: work },
    { src: workRegular, fontWeight: 500 },
    { src: workBold, fontWeight: 600 },
  ],
})
export default React.memo(function PDFGenerator({ aml, record }) {
  const calculateScoreColor = () => {
    switch (aml.aml_rating) {
      case 'low':
        return '#00b050'
      case 'moderate':
        return '#ffc000'
      case 'high':
        return '#ff0000'
      case 'severe':
        return '#a90003'
      default:
        return 'grey'
    }
  }
  const getAmlSummary = (sum) => {
    switch (sum) {
      case 'NA':
        return <Text style={{ fontSize: '10pt', color: 'gray' }}>N/A</Text>
      case 'NoMatches':
        return (
          <Text style={{ fontSize: '10pt', color: 'green' }}>No matches</Text>
        )
      case 'HasMatches':
        return <Text style={{ fontSize: '10pt', color: 'red' }}>Matches</Text>
      default:
        return <Text style={{ fontSize: '10pt', color: 'red' }}>Matches</Text>
    }
  }
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header} fixed>
          <Text style={{ fontFamily: 'DM Sans' }}>Screening report</Text>
          <Image
            style={{
              width: '100px',
            }}
            src={'/images/LogoBlack.png'}
          />
        </View>
        <View style={styles.section}>
          <View>
            <Text style={styles.caseInfoText}>
              First name:{' '}
              <Text style={{ fontWeight: 500 }}>{record.first_name}</Text>
            </Text>
            <Text style={styles.caseInfoText}>
              Last name:{' '}
              <Text style={{ fontWeight: 500 }}>{record.last_name}</Text>
            </Text>
            <Text style={styles.caseInfoText}>
              Nationality: {record.nationality}
            </Text>
            <Text style={styles.caseInfoText}>
              Birth date:{' '}
              {moment(record.birth_date, 'DD/MM/YYYY').isValid()
                ? moment(record.birth_date, 'DD/MM/YYYY').format('DD/MM/YYYY')
                : moment(record.birth_date).format('DD/MM/YYYY')}
            </Text>
            <Text style={{ marginBottom: '20px', ...styles.caseInfoText }}>
              Residence: {record.residence}
            </Text>
            <Text style={styles.caseInfoText}>
              Report requester: {record.requester_user}
            </Text>
            <Text style={styles.caseInfoText}>
              Report for client: {record.client_name}
            </Text>
            <Text style={styles.caseInfoText}>
              Report created: {moment(record.created_date).format('DD/MM/YYYY')}
            </Text>
          </View>
          <View style={{ flexGrow: 1, marginLeft: '10pt', display: 'flex' }}>
            <View
              style={{
                padding: '10pt',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: '#ffffff',
                marginBottom: '10pt',
                fontSize: '12pt',
              }}
            >
              <Text>RISK INDICATOR</Text>
              <View
                style={{
                  width: '35pt',
                  height: '25pt',
                  flexGrow: 0.5,
                  fontSize: 10,
                  backgroundColor: calculateScoreColor(),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 50,
                }}
              >
                <Text>{aml.aml_rating.toUpperCase()}</Text>
              </View>
            </View>
            <View
              style={{
                backgroundColor: '#ffffff',
                padding: '10pt',
                fontSize: '12pt',
                display: 'flex',
              }}
            >
              <Text style={{ paddingBottom: 10 }}>RISK REVIEW</Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '2pt',
                }}
              >
                <Text style={{ fontWeight: 500 }}>Date: </Text>
                <Text>
                  {aml.risk_review_date
                    ? moment(aml.risk_review_date).format('DD/MM/YYYY HH:mm')
                    : '--'}
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '2pt',
                }}
              >
                <Text style={{ fontWeight: 500 }}>Reviewer: </Text>
                <Text>{aml.risk_review_user || '--'}</Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '2pt',
                }}
              >
                <Text style={{ fontWeight: 500 }}>Change: </Text>
                <Text>{aml.risk_review_change || '--'}</Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  maxWidth: '300pt',
                  marginBottom: '2pt',
                }}
              >
                <Text style={{ fontWeight: 500 }}>Rational: </Text>
                <Text style={{ flexGrow: 1 }}>
                  {aml.risk_review_description || '--'}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ ...styles.section, justifyContent: 'space-between' }}>
          <View
            style={{ display: 'flex', flexGrow: 0.7, flexDirection: 'row' }}
          >
            <View
              style={{
                backgroundColor: '#ffffff',
                padding: '10pt',
                flexGrow: 1,
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Text>AML RATING</Text>
                <Image
                  style={{
                    width: '100pt',
                  }}
                  src={
                    moment(record.created_date).isAfter(checkDate)
                      ? '/images/4info.png'
                      : '/images/world-check.png'
                  }
                />
              </View>
              <View style={{ marginTop: '5pt', display: 'flex' }}>
                {Object.keys(aml.aml_summary).map((k) => {
                  return (
                    <View style={{ marginBottom: 5 }}>
                      <Text style={{ fontSize: '10pt', marginBottom: 5 }}>
                        {k.replace(/([A-Z])/g, ' $1').toUpperCase()}
                      </Text>
                      <View
                        style={{
                          flexGrow: 1,
                          backgroundColor: '#f5f5f5',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '10pt',
                        }}
                      >
                        {getAmlSummary(aml.aml_summary[k])}
                      </View>
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
          <View
            style={{
              backgroundColor: '#ffffff',
              padding: '10pt',
              flexGrow: '0.3',
              marginLeft: 10,
            }}
          >
            <Text style={{ marginBottom: 25 }}>SCREENING HISTORY</Text>
            {record.aml_details.map((d) => (
              <Text style={{ fontSize: 12, maxWidth: 250, marginBottom: 10 }}>
                {moment
                  .utc(d.report_date)
                  .local()
                  .format('DD/MM/YYYY HH:mm:ss')}{' '}
                AML screening
              </Text>
            ))}
          </View>
        </View>
        <View
          fixed
          style={{
            position: 'absolute',
            bottom: 0,
            padding: '5 20',
            display: 'flex',
            flexDirection: 'row',
            fontWeight: 'thin',
            fontSize: 10,
            justifyContent: 'space-between',
            left: 0,
            right: 0,
          }}
        >
          <Text>www.titan-org.com</Text>

          <Text>
            {record.requester_user},
            {moment(record.created_date).format('DD/MM/YYYY')}
          </Text>
          <Text
            fixed
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.header} fixed>
          <Text>False positive details</Text>
          <Image
            style={{
              width: '100px',
            }}
            src={'/images/LogoBlack.png'}
          />
        </View>
        <View
          style={{
            padding: 20,
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
          }}
        >
          {aml.aml_details.map((d) => (
            <View
              style={{
                backgroundColor: '#ffffff',
                padding: 10,
                flexGrow: 1,
                marginBottom: 5,
              }}
            >
              <Text style={{ marginBottom: 5 }}>{d.aml_type}</Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  borderBottom: '1pt solid #eceef2',
                  marginBottom: 5,
                  paddingBottom: 5,
                }}
              >
                <Text style={{ fontWeight: 'thin', fontSize: 12 }}>
                  {d.category}
                </Text>
                <Text style={{ fontWeight: 'thin', fontSize: 12 }}>
                  {d.first_name} {d.last_name}
                </Text>
                <Text style={{ fontWeight: 'thin', fontSize: 12 }}>
                  Case {record.case_id}
                </Text>
                <Text style={{ fontWeight: 'thin', fontSize: 12 }}>
                  {d.locations}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    fontWeight: 'thin',
                    fontSize: 12,
                    marginBottom: 5,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    Bio:{' '}
                  </Text>
                  {d.biography}
                </Text>

                <Text
                  style={{ fontWeight: 'thin', fontSize: 12, marginBottom: 5 }}
                >
                  <Text
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    Identification:{' '}
                  </Text>
                  {d.identification}
                </Text>
                <Text
                  style={{ fontWeight: 'thin', fontSize: 12, marginBottom: 5 }}
                >
                  <Text
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    Report:{' '}
                  </Text>
                  {d.reports}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View
          style={{
            display: 'flex',
            backgroundColor: '#ffffff',
            margin: '0 20',
            padding: 10,
          }}
        >
          <Text style={{ fontWeight: 500, marginBottom: 5 }}>
            General Titan Risk indicator level description:
          </Text>
          <View
            style={{ display: 'flex', flexDirection: 'row', maxWidth: 165 }}
          >
            <View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: 5,
                  marginBottom: 5,
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    width: '100pt',
                    height: '40pt',
                    fontSize: 10,
                    backgroundColor: '#00b050',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 50,
                    marginRight: 5,
                  }}
                >
                  <Text>LOW</Text>
                </View>
                <Text style={{ fontSize: '12pt' }}>
                  No relevant false positive values within the framework
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: 5,
                  marginBottom: 5,
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    width: '100pt',
                    height: '40pt',
                    fontSize: 10,
                    backgroundColor: '#ffc000',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 50,
                    marginRight: 5,
                  }}
                >
                  <Text>MODERATE</Text>
                </View>
                <Text style={{ fontSize: '12pt' }}>
                  Less critical false positive values identified, closer look at
                  it suggested
                </Text>
              </View>
            </View>
            <View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: 5,
                  marginBottom: 5,
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    width: '100pt',
                    height: '40pt',
                    fontSize: 10,
                    backgroundColor: '#ff0000',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 50,
                    marginRight: 5,
                  }}
                >
                  <Text>HIGH</Text>
                </View>
                <Text style={{ fontSize: '12pt' }}>
                  Noteworthy false positive values highlighted, mitigation
                  activities recommended
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: 5,
                  marginBottom: 5,
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    width: '100pt',
                    height: '40pt',
                    fontSize: 10,
                    backgroundColor: '#a90003',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 50,
                    marginRight: 5,
                  }}
                >
                  <Text>SEVERE</Text>
                </View>
                <Text style={{ fontSize: '12pt' }}>
                  Critical false positive values outlined. Management attention
                  necessary
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ padding: 20, fontSize: 10 }}>
          {moment(record.created_date).isAfter(checkDate) ? (
            <Text>
              The information displayed is based upon info4c data and their
              privacy policy applies. It can be found here:
              https://www.info4c.net/legal-information/data-privacy-policy/
            </Text>
          ) : (
            <Text>
              The information displayed is powered by Refintiv Worldcheck data
              and their privacy policy applies. It can be found here:
              https://www.refinitiv.com/en/products/world-check-kyc-screening/privacy-statement
            </Text>
          )}
        </View>
        <View
          fixed
          style={{
            marginTop: 15,
            position: 'absolute',
            bottom: 0,
            padding: '5 20',
            display: 'flex',
            flexDirection: 'row',
            fontWeight: 'thin',
            fontSize: 10,
            justifyContent: 'space-between',
            left: 0,
            right: 0,
          }}
        >
          <Text>www.titan-org.com</Text>
          <Text>
            {record.requester_user},{' '}
            {moment(record.created_date).format('DD/MM/YYYY')}
          </Text>
          <Text
            fixed
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  )
})
