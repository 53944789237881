import React, { useContext, useEffect, useRef } from 'react'
import { Card, Col, Row } from 'antd'
import { COMPANY_PROFILE_TABS } from '../../index'
import LoadingWrapper from '../LoadingWrapper'
import StoreContext from '../../../../storeContext'
import CompanyPrincipals from './CompanyPrincipals'
import { observer } from 'mobx-react'
import intl from 'react-intl-universal'
import InfoPopUp from '../../../InfoPopUp'

export default React.memo(
  observer(
    React.forwardRef(function (props, ref) {
      const element = useRef()
      const { companyProfile } = useContext(StoreContext)
      useEffect(() => {
        ref.current[COMPANY_PROFILE_TABS.peopleAndOrganization.id] =
          element.current
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [element.current])
      return (
        <Card
          title={intl.get('peopleAndOrganisation')}
          extra={
            <InfoPopUp
              html={intl.get('peoplePopUp')}
              title={intl.get('peopleAndOrganisation')}
            />
          }
        >
          <LoadingWrapper>
            <div
              ref={element}
              id={COMPANY_PROFILE_TABS.peopleAndOrganization.id}
            >
              <Row>
                <Col span={24}>
                  <CompanyPrincipals
                    senior={companyProfile.company.mostSeniorPrincipals}
                    standard={companyProfile.company.currentPrincipals}
                  />
                </Col>
              </Row>
            </div>
          </LoadingWrapper>
        </Card>
      )
    }),
  ),
)
