import { Avatar, Button, List, Modal, Space, Typography } from 'antd'
import moment from 'moment'
import React, { useContext } from 'react'
import ReactHtmlParser from 'react-html-parser'
import StoreContext from '../../storeContext'
import intl from 'react-intl-universal'
const { Text } = Typography

export default function MessageRequest({ request, extra }) {
  const { requests } = useContext(StoreContext)
  const showMessage = () => {
    Modal.info({
      title: intl.get('message'),
      content: <div>{ReactHtmlParser(request.description.message_text)}</div>,
      onOk() {},
    })
  }
  const confirm = () => {
    Modal.confirm({
      title: intl.get('archiveMessage'),
      content: intl.get('archiveMessageQuestion'),
      onOk: () =>
        requests.archiveRequest({
          change_status: 'archived',
          user_request_id: request.user_request_id,
        }),
    })
  }
  const resend = () => {
    Modal.confirm({
      title: intl.get('resendRequest'),
      content: intl.get('resendRequestQuestion'),
      onOk: () =>
        requests.archiveRequest({
          resend_request: true,
          user_request_id: request.user_request_id,
        }),
    })
  }
  const getActions = () => {
    const actions = [
      <Button
        style={{ margin: 0, padding: 0 }}
        type="link"
        onClick={showMessage}
      >
        {intl.get('showMessage')}
      </Button>,
    ]
    if (extra) {
      actions.push(
        <Button onClick={resend} style={{ margin: 0, padding: 0 }} type="link">
          {intl.get('resend')}
        </Button>,
      )
      actions.push(
        <Button
          style={{ margin: 0, padding: 0 }}
          type="link"
          key="archive"
          onClick={confirm}
        >
          Archive
        </Button>,
      )
    }
    return actions
  }
  return (
    <List.Item actions={getActions()}>
      <List.Item.Meta
        avatar={<Avatar>M</Avatar>}
        title={
          <Text type="secondary">To: {request.description.message_to}</Text>
        }
        description={
          <Space>
            <Text type="secondary">
              {intl.get('sentAt', {
                0: moment
                  .utc(request.date_created)
                  .local()
                  .format('MM/DD/YYYY, HH:mm:ss'),
              })}
            </Text>
            <Text type="secondary">
              {intl.get('status')}: {request.description.communication_status}
            </Text>
          </Space>
        }
      />
    </List.Item>
  )
}
