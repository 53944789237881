import React from 'react'
import { useAuthorization } from './Authorization'

const checkPermissions = (permissions, props) => {
  const permModule = permissions[props.module]
  if (!permModule) {
    console.error('Please provide valid nodule')
    return false
  }
  if (props.abilities) {
    if (props.abilities.find(permission =>{
      return !permModule.permissions[permission]
    })) { return false }
  }
  if (props.checks && props.target) {
    if (props.checks.find(check =>{
      return !permModule.checks[check](props.target)
    })) { return false }
  }
  return true
}

export const displayContent = (props, allowed) => {
  if (typeof props.children === 'function') {
    return <React.Fragment>{props.children(allowed)}</React.Fragment>
  }
  const numberOfChildren = React.Children.count(props.children) 
  const filteredChildren = React.Children.map(props.children, child => {
    if (child.type === ConditionPass) {
      if (allowed) { return child }
      return
    }
    if (child.type === ConditionFail) {
      if (!allowed) { return child }
      return
    }
    return child
  })
  if (React.Children.count(filteredChildren) !== numberOfChildren) { return filteredChildren }
  if (!allowed) { return null }
  return <React.Fragment>{props.children}</React.Fragment>
}

const ConditionPass = ({children}) => <React.Fragment>{children}</React.Fragment>
const ConditionFail = ({children}) => <React.Fragment>{children}</React.Fragment>

const Can = props => {
  const permissions = useAuthorization()
  const allowed = checkPermissions(permissions, props)
  return displayContent(props, allowed)
}

const Cannot = props => {
  const permissions = useAuthorization()
  const allowed = checkPermissions(permissions, props)
  return displayContent(props, !allowed)
}

Can.Pass = ConditionPass
Cannot.Pass = ConditionPass
Can.Fail = ConditionFail
Cannot.Fail = ConditionFail

export {Can, Cannot}
