import React, { useContext } from 'react'
import { Modal } from 'antd'
import StoreContext from '../../../../storeContext'
import { observer } from 'mobx-react'
import * as Yup from 'yup'
import { Form, Select, SubmitButton } from 'formik-antd'
import { Formik } from 'formik'
import intl from 'react-intl-universal'

export default observer(function ({ visible, handleOnCancel }) {
  const { lists, user, companyProfile } = useContext(StoreContext)

  const validation = Yup.object().shape({
    edit: Yup.object().shape({
      company_list_id: Yup.string().required(intl.get('listRequired')),
    }),
  })
  const initialValues = {
    edit: {
      companies_add: [companyProfile.company.company_id],
      company_list_id: '',
    },
    company_id: user.user.company_id,
  }

  return (
    <Modal
      destroyOnClose={true}
      title={intl.get('addToList')}
      footer={null}
      visible={visible}
      onCancel={handleOnCancel}
    >
      <Formik
        validationSchema={validation}
        initialValues={initialValues}
        onSubmit={async (vals, { setSubmitting }) => {
          await lists.companyList(vals)
          await companyProfile.getCompanyData(companyProfile.company.company_id)
          setSubmitting(false)
          handleOnCancel()
        }}
      >
        {() => (
          <Form>
            <Form.Item name="edit.company_list_id">
              <Select
                name="edit.company_list_id"
                placeholder={intl.get('selectList')}
              >
                {companyProfile.lists.map((list) => (
                  <Select.Option value={list.company_list_id}>
                    {list.list_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <SubmitButton>{intl.get('addToList')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </Modal>
  )
})
