import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Table } from "antd";
import EditModal from "../shared/EditModal";
import PreviewSurvey from "./PreviewSurvey";
import { observer } from "mobx-react";
import StoreContext from "../../storeContext";
import { SurveyTypes } from "../../constants/dropdowns";

export default observer(function () {
  const [showComplete, setShowComplete] = useState(false)
  const [selectedSurvey, setSelected] = useState(null)
  const { survey } = useContext(StoreContext)
  useEffect(() => {
    survey.getCompanySurveys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const columns = [
    {
      title: 'Name',
      dataIndex: 'survey_name',
    },
    {
      title: 'Type',
      dataIndex: 'survey_type',
      render: (text, record) => {
        return SurveyTypes.find((type) => type.value === record.survey_type)
          .name
      },
    },
    {
      title: 'Answered on',
      dataIndex: 'answered_on',
      render: (text, record) =>
        record.answered_on
          ? new Date(record.answered_on).toLocaleDateString()
          : 'Not answered',
    },
    {
      title: 'Answered by',
      dataIndex: 'answered_by',
      render: (text, record) => text || 'Not answered',
    },
    {
      title: 'Action',
      render: (text, record) => (
        <>
          <Button
            style={{ backgroundColor: 'red', color: 'white' }}
            onClick={() => {
              setSelected(record)
              setShowComplete(true)
            }}
            type={record.answered_on && 'primary'}
          >
            {record.answered_on ? 'Edit survey' : 'Answer survey'}
          </Button>
        </>
      ),
    },
  ]
  return (
    <Card title="Surveys">
      <Table
        bordered={true}
        columns={columns}
        dataSource={survey.companySurveys}
      />
      <EditModal
        handleOnClose={() => setShowComplete(false)}
        visible={showComplete}
        width="80vw"
        title="Complete survey"
      >
        <PreviewSurvey
          mode="edit"
          surveyCode={selectedSurvey}
          callback={(data) =>
            survey.updateCompanySurvey({
              survey_answer: {
                answers: data,
                survey_id: selectedSurvey.survey_id,
              },
            })
          }
        />
      </EditModal>
    </Card>
  )
})
