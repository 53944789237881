import { Button, Col, Layout, Row } from "antd";
import React, { useContext } from "react";
import StoreContext from "../../storeContext";
import Topbar from "../../containers/Topbar";
import { Formik } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import * as Yup from "yup";
import intl from "react-intl-universal";
import { ValidationFields } from "../../utils/validationFields";

const { Content } = Layout
export default function ResetPassword() {
  const { router, user } = useContext(StoreContext)
  const initialValues = {
    username: '',
  }
  const validator = Yup.object({
    username: ValidationFields.email(),
  })
  return (
    <Layout style={{ height: '100%' }}>
      <Topbar showCommands={false} />
      <Content>
        <div
          className="gx-login-container"
          style={{ overflow: 'auto', padding: '30px' }}
        >
          <div className="gx-login-content">
            <div className="gx-login-header gx-text-center">
              <h1 className="gx-login-title">{intl.get('forgotPasswordTitle')}</h1>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validator}
              onSubmit={(values, { setSubmitting, setFieldError }) => {
                  user.resetPassword(values, setSubmitting, setFieldError)
              }}
            >
              {({errors}) => (
                <Form>
                  <Form.Item name="username">
                    <Input name="username" placeholder={intl.get('emailLabel')} />
                  </Form.Item>
                  {errors['asyncError'] && (
                    <div className="ant-row ant-form-item ant-form-item-with-help ant-form-item-has-error">
                      <div className="ant-col ant-form-item-control">
                        <div className="ant-form-item-explain">
                          <div>
                            <li>{intl.get(errors['asyncError'])}</li>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <Row>
                    <Col span={12}>
                      <SubmitButton style={{ width: '100%' }}>{intl.get('submitLabel')}</SubmitButton>
                    </Col>
                    <Col span={12}>
                      <Button
                        style={{ width: '100%' }}
                        type="primary"
                        onClick={() => router.replace('/login')}
                      >
                        {intl.get('cancel')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Content>
    </Layout>
  )
}
