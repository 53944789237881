import { Button, List, Modal } from 'antd'
import React, { useState } from 'react'
import intl from 'react-intl-universal'
import GenerateReportButton from '../GenerateReportButton'
import moment from 'moment'

export default function ReportHistory({ record }) {
  const [visible, showVisible] = useState(false)
  return (
    <div>
      <Button
        onClick={() => showVisible(true)}
        type="link"
        style={{ margin: 0, padding: 0 }}
      >
        {intl.get('showHistory')}
      </Button>
      <Modal
        title={intl.get('reportHistory')}
        visible={visible}
        footer={null}
        destroyOnClose={true}
        onCancel={() => showVisible(false)}
      >
        <List
          itemLayout="horizontal"
          dataSource={record.aml_details}
          renderItem={(item) => (
            <List.Item
              actions={[<GenerateReportButton record={record} aml={item} />]}
            >
              <List.Item.Meta
                title={
                  <span>
                    {moment
                      .utc(item.report_date)
                      .local()
                      .format('DD/MM/YYYY HH:mm:ss')}
                  </span>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </div>
  )
}
