import React, { useContext } from 'react'
import { Modal } from 'antd'
import intl from 'react-intl-universal'
import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import StoreContext from '../../../../storeContext'
import { useParams } from "react-router";

export default function ({
  visible,
  handleOnCancel,
  company_video_en = '',
  company_video_de = '',
}) {
  const { companyProfile } = useContext(StoreContext)
  const company_id = useParams().id
  const initialValues = {
    company_id,
    company_video: true,
    company_video_en,
    company_video_de,
  }
  return (
    <Modal
      title={intl.get('editVideos')}
      visible={visible}
      destroyOnClose={true}
      footer={null}
      onCancel={handleOnCancel}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (vals, { setSubmitting }) => {
          await companyProfile.updateCompany(vals)
          setSubmitting(false)
          handleOnCancel()
        }}
      >
        {() => {
          return (
            <Form>
              <Form.Item name="company_video_en">
                <Input
                  addonBefore={intl.get('companyVideoEnLabel')}
                  name="company_video_en"
                  placeholder={intl.get('companyVideoEnLabel')}
                />
              </Form.Item>
              <Form.Item name="company_video_de">
                <Input
                  addonBefore={intl.get('companyVideoDeLabel')}
                  name="company_video_de"
                  placeholder={intl.get('companyVideoDeLabel')}
                />
              </Form.Item>
              <SubmitButton>{intl.get('save')}</SubmitButton>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
