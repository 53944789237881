import { Formik } from 'formik'
import { Form, Select, SubmitButton } from 'formik-antd'
import React, { useContext } from 'react'
import StoreContext from '../../../../storeContext'
import intl from 'react-intl-universal'
import { useParams } from 'react-router'

export default function EditInvisibleTags({ close }) {
  const { generalCriteria, companyProfile } = useContext(StoreContext)
  const company_id = useParams().id

  const initialValues = {
    invisible_tags: companyProfile.company.invisible_tags.map((t) => t.tag_id),
  }
  const renderTags = () =>
    generalCriteria.invisibleTags.map((tag) => {
      return <Select.Option value={tag.tag_id}>{tag.tag_name}</Select.Option>
    })
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (vals, { setSubmitting }) => {
        await companyProfile.updateCompany({ ...vals, company_id })
        setSubmitting(false)
        close()
      }}
    >
      {() => {
        return (
          <Form>
            <Form.Item name="tags">
              <Select
                mode="multiple"
                name="invisible_tags"
                placeholder={intl.get('selectTags')}
              >
                {renderTags()}
              </Select>
            </Form.Item>
            <SubmitButton>{intl.get('save')}</SubmitButton>
          </Form>
        )
      }}
    </Formik>
  )
}
