import React, { useEffect, useState } from "react";
import { Button, Card } from "antd";

function orderDetails(details) {
  const temp = {}
  Object.keys(details).forEach((key) => {
    if (temp.hasOwnProperty(details[key].category))
      temp[details[key].category].push(details[key])
    else {
      temp[details[key].category] = [details[key]]
    }
  })
  return temp
}

function DetailsItem({ item, ind }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 9,
      }}
    >
      <div style={{ flex: '2 1 0' }}>
        <span style={{ marginRight: 5 }}>{ind}.</span>
        <span>{item.name}</span>
      </div>
      <div
        style={{
          flex: '1 1 0',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span
          style={{ color: item.grade_color, fontWeight: 'bold', flex: '1 1 0' }}
        >
          {item.grade}
        </span>
        <span style={{ flex: '1 1 0' }}>{item.rating}</span>
        <a href={item.display_url} style={{ margin: 0 }} rel="noopener noreferrer" target="_blank">
          URL
        </a>
      </div>
    </div>
  )
}

export default function ({ details }) {
  const [det, setDet] = useState({})
  const [show, setShow] = useState(false)
  useEffect(() => {
    setDet(orderDetails(details))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (Object.keys(details).length === 0) return null
  return (
    <div>
      <Button type="link" onClick={() => setShow((show) => !show)}>
        {show ? 'Hide' : 'Show'} details
      </Button>
      {show &&
        Object.keys(det).map((key) => {
          return (
            <Card title={key}>
              {det[key].map((item, ind) => (
                <DetailsItem item={item} ind={ind + 1} />
              ))}
            </Card>
          )
        })}
    </div>
  )
}
