import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Checkbox, Form, Select, SubmitButton } from 'formik-antd'
import StoreContext from '../../storeContext'
import intl from 'react-intl-universal'
import { Countries } from '../../constants/dropdowns'

export default function ({ selected, resetKeys }) {
  const initialValues = {
    company_type_view: '',
    country_view: [],
    advanced_risk_indicator: false,
    bitsight_view: false,
    cmptcs_view: false,
    standard_risk_indicator: false,
    kyc_view: false,
  }
  const { companiesOverview } = useContext(StoreContext)
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await companiesOverview.updateViewMatrix({
          companies: selected,
          ...values,
        })
        setSubmitting(false)
        resetForm()
        resetKeys()
      }}
    >
      {() => (
        <Form>
          <Form.Item name="company_type_view">
            <Select
              disabled={selected.length === 0}
              name="company_type_view"
              placeholder={intl.get('selectCompanyType')}
            >
              <Select.Option value={0}>All companies</Select.Option>
              <Select.Option value={1}>All buyers</Select.Option>
              <Select.Option value={2}>All supliers</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="country_view">
            <Select
              disabled={selected.length === 0}
              mode="multiple"
              name="country_view"
              placeholder="Select country"
            >
              {Countries.map((country) => (
                <Select.Option key={country.value} value={country.value}>
                  {country.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div style={{ marginBottom: 8 }}>
            <Checkbox
              disabled={selected.length === 0}
              style={{ marginLeft: 0 }}
              name="advanced_risk_indicator"
            >
              {intl.get('advancedRiskView')}
            </Checkbox>
            <Checkbox
              disabled={selected.length === 0}
              style={{ marginLeft: 0 }}
              name="bitsight_view"
            >
              {intl.get('bitsightView')}
            </Checkbox>
            <Checkbox
              disabled={selected.length === 0}
              style={{ marginLeft: 0 }}
              name="cmptcs_view"
            >
              {intl.get('cmptcsView')}
            </Checkbox>
            <Checkbox
              disabled={selected.length === 0}
              style={{ marginLeft: 0 }}
              name="kyc_view"
            >
              {intl.get('kycView')}
            </Checkbox>
            <Checkbox
              disabled={selected.length === 0}
              style={{ marginLeft: 0 }}
              name="standard_risk_indicator"
            >
              {intl.get('riskView')}
            </Checkbox>
          </div>
          <SubmitButton disabled={selected.length === 0}>
            {intl.get('save')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  )
}
