import React from "react";
import { Modal, Space, Statistic } from "antd";
import ShareholdersItem from "./ShareholdersItem";
import intl from "react-intl-universal";

export default function ({ showPreview, setShowPreview, shareholders }) {
  return (
    <Modal
      title={intl.get('shareholders')}
      visible={showPreview}
      footer={null}
      destroyOnClose={true}
      onCancel={() => setShowPreview(false)}
    >
      <Space style={{ width: '100%' }} direction={'vertical'}>
        <Statistic
          title={
            shareholders.length > 0
              ? intl.get('shareholders')
              : intl.get('noShareholders')
          }
          valueRender={() =>
            shareholders.map((item, ind) => (
              <ShareholdersItem item={item} ind={ind} />
            ))
          }
        />
      </Space>
    </Modal>
  )
}
