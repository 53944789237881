import { UserOutlined } from '@ant-design/icons'
import React from 'react'

export default function ({ name = 'N/A', title = 'N/A', type = '' }) {
  return (
    <div className={`principal ${type}`}>
      <UserOutlined />
      <div style={{ marginLeft: 5 }}>
        <div className="title">{name}</div>
        <div style={{ color: '#7E889B' }}>{title}</div>
      </div>
    </div>
  )
}
