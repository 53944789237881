import React, { useContext, useEffect, useState } from 'react'
import UploadOutlined from '@ant-design/icons/lib/icons/UploadOutlined'
import ImageUploader from '../../../shared/ImageUploader'
import { useParams } from 'react-router'
import StoreContext from '../../../../storeContext'
import { observer } from 'mobx-react'
import { CompanyProfileStoreCalls } from "../../store/CompanyProfileStore"

export default observer(function ({showUpload, setShowUpload}) {
  const { companyProfile } = useContext(StoreContext)
  const [showEdit, setShowEdit] = useState(false)
  const company_id = useParams().id
  const [key, setKey] = useState(new Date().getTime())

  useEffect(() => {
    setKey(new Date().getTime())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProfile.company.logo_url])
  const handleHover = (e) => {
    setShowEdit(true)
  }
  const handleLeave = (e) => {
    setShowEdit(false)
  }
  return (
    <div
      onMouseOver={handleHover}
      onMouseLeave={handleLeave}
      className="general-company-logo-holder"
    >
      {showEdit && (
        <div className="logo-overlay" onClick={() => setShowUpload(true)}>
          <UploadOutlined />
        </div>
      )}
      <img
        alt="company-logo"
        src={
          companyProfile.company.logo_url &&
          `http://${companyProfile.company.logo_url}?${key}`
        }
      />
      <ImageUploader
        blockers={[CompanyProfileStoreCalls.UPDATE_COMPANY_PROFILE]}
        formats={['image/jpeg', 'image/png']}
        visible={showUpload}
        close={() => setShowUpload(false)}
        upload={async (company_logo) => {
          await companyProfile.updateCompany({ company_logo, company_id })
        }}
      />
    </div>
  )
})
