import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import packagePng from '../../../../assets/images/package.png'
import StoreContext from '../../../../storeContext'
import ImplementationPartnersList from './ImplementationPartnersList'
import ProductCompany from './ProductCompany'
import ProductDescription from './ProductDescription'

const StyledItem = styled.div`
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 17px;

  .desc-wrapp {
    flex: 0.7 1 0;
    display: flex;

    margin-bottom: 10px;
    & .product-details-wrapp {
      display: flex;
      flex-direction: column;

      > div:first-of-type {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 8px;
        color: #1d2a3d;
      }
    }
  }
  > div:first-of-type {
    display: flex;
    > div:first-of-type {
      border-right: 1px solid #f0f0f0;
      flex: 0.7 1 0;

      margin-bottom: 17px;
      padding-right: 12px;
      margin-right: 12px;
    }
    > div:last-of-type {
      flex: 0.3 1 0;
    }
  }

  .implementation-partners {
    margin-left: -15px;
    margin-right: -15px;
    > div:first-of-type {
      color: black;
      margin-right: 5px;
      margin-bottom: 8px;
      margin-left: 15px;
      cursor: pointer;
    }
    > div:last-of-type {
      display: flex;
      flex-direction: column;
      background: whitesmoke;
      > .partner {
        margin-right: 8px;
        padding-right: 8px;
        margin-bottom: 8px;
      }
    }
  }
`

export default observer(function ProductItem({ product }) {
  const { search } = useContext(StoreContext)
  const [show, setShow] = useState(false)
  return (
    <StyledItem>
      <div>
        <div>
          <div className="desc-wrapp">
            <div
              style={{
                maxWidth: 100,
                maxHeight: 100,
                marginRight: 10,
                overflow: 'hidden',
              }}
            >
              <img
                alt="product"
                style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                src={product.logo_url || packagePng}
              />
            </div>
            <div className="product-details-wrapp">
              <div>{product.product_name}</div>
              <div>
                <ProductDescription item={product} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <ProductCompany
            company={search.results.find((r) => {
              return r.company_id === product.company_id
            })}
          />
        </div>
      </div>
      {product.implementation_partners.length > 0 ? (
        <div className="implementation-partners">
          <div onClick={() => setShow((show) => !show)}>
            Implementation partners {show ? <UpOutlined /> : <DownOutlined />}
          </div>
          <div>
            {show && (
              <ImplementationPartnersList productId={product.product_id} />
            )}
          </div>
        </div>
      ) : null}
    </StyledItem>
  )
})
