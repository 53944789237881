import { Button, Col, Modal, Row, Statistic, Tag } from 'antd'
import intl from 'react-intl-universal'

import React from 'react'

export default function CertificateInfo({ certificate }) {
  const info = () => {
    Modal.info({
      title: intl.get('certificateInfoTitle'),
      content: (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Statistic
              title={intl.get('certificateName')}
              value={certificate.certificate_name}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title={intl.get('certificateNumber')}
              value={certificate.certificate_number}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title={intl.get('authority')}
              value={certificate.certification_authority}
            />
          </Col>
          <Col span={12}>
            <Statistic title={intl.get('certificateFile')} value={''} />
            <Button
              htmlType="link"
              href={certificate.certificate_url}
              style={{ marginTop: -33 }}
              type="primary"
            >
              download
            </Button>
          </Col>
          <Col span={24}>
            <Statistic
              title={intl.get('description')}
              value={certificate.certificate_description || intl.get('noData')}
            />
          </Col>
        </Row>
      ),
      onOk() {},
    })
  }
  return (
    <>
      <Tag style={{ cursor: 'pointer' }} onClick={info} color="blue">
        {certificate.certificate_name}
      </Tag>
    </>
  )
}
