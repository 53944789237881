import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Row, Table } from 'antd'
import intl from 'react-intl-universal'
import EditProfileField from '../../Profile/EditProfileField'
import { observer } from 'mobx-react'
import StoreContext from '../../../storeContext'
import ActionButton from '../../shared/ActionButton'
import AddInvisibleTag from './AddInvisibleTag'

export default observer(function () {
  const [showAdd, setShowAdd] = useState(false)
  const { generalCriteria } = useContext(StoreContext)
  useEffect(() => {
    generalCriteria.getInvisibleTags(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      title: intl.get('tagName'),
      dataIndex: 'tag_name',
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="tag_name"
          callback={async (value, setEdit) => {
            await generalCriteria.addUpdateTag({
              ...value,
              tag_id: record.tag_id,
              invisible_tags: true,
            })
            await generalCriteria.getInvisibleTags(true)
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: intl.get('description'),
      dataIndex: 'tag_description',
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="tag_description"
          callback={async (value, setEdit) => {
            await generalCriteria.addUpdateTag({
              ...value,
              tag_id: record.tag_id,
              invisible_tags: true,
            })
            await generalCriteria.getInvisibleTags(true)
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: intl.get('action'),
      render: (text, record) => (
        <ActionButton
          record={record}
          type={'primary'}
          text={intl.get('delete')}
          onClick={async () => {
            await generalCriteria.addUpdateTag({
              tag_delete: true,
              tag_id: record.tag_id,
              invisible_tags: true,
            })
            await generalCriteria.getInvisibleTags(true)
          }}
        />
      ),
    },
  ]
  return (
    <Row>
      <Col span="24">
        <Card>
          <Button onClick={() => setShowAdd(true)} type="primary">
            {intl.get('addInvisibleTag')}
          </Button>
          <Table
            bordered={true}
            rowKey="tag_id"
            dataSource={generalCriteria.invisibleTags}
            columns={columns}
            scroll={{ x: 1500 }}
          />
        </Card>
      </Col>
      <AddInvisibleTag
        visible={showAdd}
        handleOnCancel={() => setShowAdd(false)}
      />
    </Row>
  )
})
