export const TRANSLATIONS = {
  passwordErrorMin: `Das Passwort muss mindestens {0} Zeichen lang sein.`,
  createdBy: `Erstellt durch: {0} um {1}`,
  internalNoteTitle: `Interner Kommentar ( zu letzt bearbeitet am {1} durch {0} )`,
  editInternalNote: `Interner Kommentar ( zu letzt bearbeitet am {1} durch {0} )`,
  shareholders: `Anteilseigner`,
  userNotFound: `Anfrage für Private Rate Card konnte nicht gesendet werden, Benutzer existiert nicht`,
  deleteInternalNote: `Internen Kommentar löschen`,
  rateCardPrivateDescription: `Private Rate Card Beschreibung`,
  addPreferredSupplier: `Preferred Supplier hinzufügen`,
  addPreferredSupplierInfo: `Sie werden {0} als Preferred Supplier markieren.`,
  sharePreferredSupplierStatus: `Den Preferred Supplier Status nicht mit anderen Käufern teilen.`,
  addedPrivateRateCard: `Private Rate Card erfolgreich hinzugefügt`,
  rateCardUpdated: `Rate Card wurde aktualisiert`,
  implementationPartnerFor: `Implementierungspartner für`,
  preferredSupplierOf: `Preferred Supplier von`,
  lastUpdate: `( Zu letzt aktualisiert um {0} )`,
  deleteCertificateConfirmation: `Sie werden das Zertifikat löschen, sind Sie sicher?`,
  listNoDelete: `Sie können diese Liste nicht löschen`,
  emailFileError: `Datei muss 2mb groß sein`,
  certificateInfoTitle: `Informationen zum Zertifikat`,
  certificateName: `Name des Zertifikats`,
  deleteRow: `Zeilendaten löschen`,
  viewProfiles: `Profile anzeigen`,
  viewRequests: `Anfragen anzeigen`,
  manageLists: `Listen verwalten`,
  searchIconInList: `Unternehmen ist Teil der Liste`,
  searchIconNotInList: `Unternehmen ist nicht Teil der Liste`,
  accept: `Freigabe`,
  done: ``,
  interpolationTest: `Interpolation {0} test DE`,
  cancel: `Abbrechen`,
  interpolationTest2: `Interpolation ({0}, {1},{2}) test DE`,
  error: `Fehler`,
  registrationTitle: `Neu registrieren`,
  userTypeLabel: `Bitte wählen Sie Ihr Benutzerprofil aus:`,
  buyerLabel: `Käufer`,
  sellerLabel: `Verkäufer`,
  buyerInputInfo: `Sie erhalten Premium Firmendaten, Markt Insights, Benchmarks und Service & Produktinformationen. Kostenpflichtige Produktversion.`,
  sellerInputInfo: `Sie stellen für Ihre Firma Markt- und Angebotsinformationen zur Verfügung. Ausgewählte Features sind verfügbar. Basispaket ist kostenfrei.`,
  userTypeError: `Auswahlkriterium`,
  nameLabel: `Vorname`,
  nameErrorRequired: `Vorname ist ein Pflichtfeld`,
  nameErrorMax: `Zeichenbeschränkung von {0}`,
  surnameLabel: `Nachname`,
  surnameErrorRequired: `Nachname ist ein Pflichtfeld`,
  surnameErrorMax: `Zeichenbeschränkung von {0}`,
  companyNameLabel: `Firmenname`,
  companyErrorRequired: `Firmenname ist ein Pflichtfeld`,
  companyErrorMax: `Zeichenbeschränkung von {0}`,
  positionLabel: `Position`,
  positionErrorRequired: `Position ist ein Pflichtfeld`,
  selectCountryLabel: `Bitte wählen Sie Ihr Domizil aus`,
  selectCountryErrorRequired: `Domizil ist ein Pflichtfeld`,
  vatLabel: `Mehrwertsteuernummer`,
  emailLabel: `E-Mail Adresse`,
  emailErrorRequired: `E-Mail Adresse ist ein Pflichtfeld`,
  emailErrorNotValid: `Sie müssen eine gültige E-Mail Adresse eingeben`,
  passwordLabel: `Passwort`,
  passwordErrorRequired: `Passwort ist ein Pflichtfeld`,
  passwordErrorStrength: `Das Passwort muss einen Großbuchstaben, eine Zahl und ein Sonderzeichen beinhalten`,
  passwordConfirmErrorMatch: `Die Passwortbestätigung entspricht nicht dem gesetzten Passwort`,
  phoneLabel: `Telefonnummer (optional)`,
  acceptTermsText: `AGBs`,
  acceptTermsErrorRequired: `Sie müssen die Nutzungsbedingungen bestätigen`,
  registerButtonText: `Registrierung`,
  acceptTermsLinkText: `Nutzungsbedingungen`,
  alreadySignUpText: `Wenn Sie bereits registriert sind:`,
  alreadySignUpLinkText: `Anmeldung`,
  passwordConfirmLabel: `Passwort Bestätigung`,
  passwordConfirmErrorRequired: `Passwort Bestätigung ist ein Pflichtfeld`,
  blacklistDomain: `Die von Ihne gewählte E-Mail Domain ist nicht zugelassen. Bitte wählen Sie eine anderes E-Mail Adresse und Domain`,
  usernameExists: `Ihr Benutzername existiert bereits`,
  invalidPassword: `Ihr Passwort entspricht nicht der Passwort Policy`,
  emailExists: `Ihre E-Mail Adresse existiert bereits`,
  exception: `Ein Fehler ist aufgetreten`,
  confirmEmail: `Bitte überprüfen Sie Ihr E-Mail Postfach für den Verification Code`,
  companyRegistered: `Ihre Firma ist bereits auf unserer Plattform registriert. Ihre Registrierungsanfrage ist weitergeleitet und wir warten auf das Approval Ihres Firmenadministrator`,
  loginIncorrect: `Ihr Benutzername und/oder Passwort ist inkorrekt`,
  userUnconfirmed: `Ihr Benutzer ist nicht bestätigt`,
  waitTitanApproval: `Bitte warten Sie bis der Titan Administrator Ihren Account freigegeben hat`,
  waitAdminApproval: `Bitte warten Sie bis Ihr Firmen Administrator Ihren Account freigegeben hat`,
  success: `Erledigt`,
  tokenExpired: `Ihr Token ist abgelaufen, bitte melden Sie sich erneut an`,
  usernameNotExist: `Ihr Benutzername existiert nicht`,
  invalidCode: `Ihr Verification Code ist ungültig`,
  userConfirmed: `Ihr Benutzer ist bereits bestätigt`,
  passwordChanged: `Ihr Passwort ist erfolgreich geändert`,
  verificationComplete: `Ihre Verification ist abgeschlossen`,
  vatErrorRequired: `Bitte geben Sie Ihre Mehrwertsteuer (VAT) Nummer ein`,
  phoneErrorFormat: `Kein gültiges Telefon-Nummerformat`,
  confirmationCodeLabel: `Code`,
  confirmationCodeErrorRequired: `Bitte geben Sie Ihren Code ein`,
  confirmationCodeErrorNotValid: `Ihr Code muss aus genau 6 Ziffern bestehen`,
  registrationConfirmationTitle: `Bitte überprüfen Sie Ihr E-Mail Postfach`,
  registrationConfirmationInfoText: `Wir haben Ihnen einen 6-Zifferncode per E-Mail zugesendet. Bitte geben Sie diesen ein um Ihren Account zu entsperren und die Registrierung abzuschließen. Bitte überprüfen Sie Ihren Spam Ordner falls Sie keine Code erhalten haben .`,
  codeLabe: `Code`,
  didntReceiveCode: `Haben Sie Ihren Code erhalten?`,
  resend: `Erneut senden`,
  sending: `Senden`,
  confirmLabel: `Bestätigung`,
  forgotPasswordTitle: `Passwort vergessen`,
  submitLabel: `Senden`,
  resetPasswordConfirmTitle: `Wir haben Ihren Verification Code gesendet`,
  resetPasswordConfirmInfoText: `Wir haben Ihren Verification Code gesendet. Wenn Sie die zugehörige E-Mail nicht in Ihrem E-Mail Postfach sehen bitten wir Sie Ihren Spam Ordner zu überprüfen`,
  loginTitle: `Account Anmeldung`,
  loginTermsText: `Wenn Sie fortfahren bestätigen Sie unsere`,
  loginButtonText: `Anmeldung`,
  loginSignUpLinkText: `Neuen Account erstellen`,
  loginResetPasswordLinkText: `Passwort vergessen`,
  signUpSuccessTitleText: `Sie haben erfolgreich Ihre Registrierung bestätigt`,
  signUpSuccessSubTitleText: `Bei weiteren Fragen wenden Sie sich gerne an support@titan-org.com`,
  signUpSuccessLoginLinkText: `Gehen Sie zur Anmeldeseite`,
  resetPasswordSuccessMessage: `Sie haben erfolgreich Ihr Passwort geändert`,
  addNewUserTitle: `Füge neuen Benutzer hinzu`,
  sendInvite: `Einladung senden`,
  addNewUserCSV: `Als Titan Administrator können Sie via CSV-File Upload Masseneinladungen für Benutzer anderer Firmen anstossen`,
  uploadCSVButtonText: `CSV-File hochladen`,
  optionAllUsers: `Alle Benutzer`,
  optionTitanUsers: `Titan Benutzer`,
  optionPendingUsers: `Ausstehende Benutzer`,
  optionDisabledUsers: `Deaktivierte Benutzer`,
  searchLabel: `Suche`,
  usersTableColumnName: `Namen`,
  usersTableColumnCompany: `Firma`,
  usersTableColumnRole: `Rolle`,
  usersTableColumnType: `Typ`,
  usersTableColumnEmail: `Email`,
  usersTableColumnTerms: `AGB Version`,
  usersTableColumnActions: `Aktion`,
  vatHelpButtonText: `VAT Hilfe`,
  vatHelpModalTitle: `Unterstützende Informationen zur Umsatzsteuer`,
  vatHelpModalEuLinkText: `EU VAT Standard`,
  vatHelpModalVatFinderLinkText: `Schweizer VAT Register`,
  vatHelpModalVatInfoText: `Wenn Sie sich als Firma aus einem Land ohne VAT Nummer anmelden, dann geben Sie bitte 84826 ("Titan"). Wir werden Sie dann zurückrufen und mit Ihnen die Registrierung abschließen.`,
  wrongInvite: `Der Einladungs-Code ist nicht gültig`,
  inviteAccepted: `Die Einladung wurde bereits akzeptiert`,
  singleUserInviteTabTitle: `Einzelnutzer`,
  bulkUserInviteTabTitle: `Mehrere Nutzer`,
  bulkUserInviteButtonText: `Benutzer einladen`,
  successInviteUsersTitle: `Sie haben erfolgreich die ausgewählten Benutzer eingeladen`,
  addUserCardTitle: `Benutzer einladen`,
  alreadyInvited: `Die E-Mail Adresse wurde bereits eingeladen`,
  wrongDomain: `Falsche Domain`,
  alreadyRegistered: `Ihre E-mail ist bereits registriert`,
  inviteUsersErrorTitle: `Während des Einladungsprozesses ist ein Fehler aufgetreten`,
  userApprovedSuccessTitle: `Benutzer ist freigegeben`,
  userApprovedSuccessText: `Sie haben den folgenden Benutzer erfolgreich freigegeben: {0}`,
  userApprovedErrorTitle: `Fehler bei Benutzer Freigabe`,
  positionErrorMax: `Die Maximale Dateneingabelänge ist {0}`,
  uploadPhotoLinkText: `Foto hochladen`,
  changePasswordLinkText: `Passwort ändern`,
  deactivateAccountLinkText: `Account deaktivieren`,
  selectImageText: `Bild auswählen`,
  uploadImageErrorNotValid: `Das Bild muss ein JPG Format haben und darf maximal 2 MB groß sein`,
  upload: `Hochladen`,
  uploading: `Hochladen läuft ...`,
  acceptedTermsOfUse: `Bitte akzeptieren Sie unsere Nutzungsbedingungen`,
  save: `Speichern`,
  edit: `Editieren`,
  userInformationText: `Benutzerinformationen`,
  updateUserProfileSuccess: `Sie haben erfolgreich das Benutzerprofil aktualisiert`,
  updateUserProfileError: `Es ist ein Fehler im Benutzerprofil Aktualisierungsprozess aufgetreten`,
  changingPasswordError: `Es ist ein Fehler bei der Passwortänderung aufgetreten`,
  signUpSuccessInvitedSubTitleText: `Sie können sich jetzt an Ihrem Benutzerkonto anmelden`,
  tabDataClassification: `Datenklassifizierung`,
  tabAccountStatus: `Kontoregistrierung`,
  tabDataCompletionStatus: `Vollständigkeitsstatus`,
  tabActivityStatus: `Aktivitätenstatus`,
  tabVisibilityStatus: `Sichtbarkeitsstatus`,
  tabCompanyParameters: `Firmenparameter`,
  selectColumns: `Bitte wählen Sie eine Spalte aus`,
  downloadCSV: `CSV-File herunterladen`,
  columnCompanyName: `Firmenname`,
  columnCompanyType: `Firmentyp`,
  columnCompanySize: `Firmengrösse`,
  columnCompanyCountry: `Firmendomizil`,
  columnEmployeeNumber: `Mitarbeiterzahl`,
  columnCompanyIndustry: `Firmenbranche`,
  uploadCardTitle: `CSV-File hochladen um Firmen zu importieren`,
  uploadExplanation: `Zum Hochladen mehrerer Firmen wählen Sie bitte eine CSV-File aus`,
  tableCardTitle: `Importierte Firmen`,
  columnEmployeeCountReliability: `Mitarbeiterzahlverlässlichkeit`,
  columnVat: `VAT`,
  columnIndustryCodes: `Branchencode`,
  bigFirm: `Grosse Firma`,
  boutique: `Boutique`,
  agency: `Agentur`,
  oneManFirm: `Ein-Mann-Firma`,
  startUp: `Start-up`,
  updateIndustryModalTitle: `Bitte aktualisieren Sie die Branche`,
  industryCode: `Branchencode`,
  description: `Beschreibung`,
  priority: `Priorität`,
  successImportCompany: `Firmen erfolgreich importiert`,
  errorImportCompany: `Es ist ein Fehler beim Firmenimport aufgetreten`,
  successUpdateCompany: `Firmendaten wurde erfolgreich aktualisiert`,
  columnCompanyTypeView: `Firmentypenansicht`,
  columnCountryView: `Firmendomizilansicht`,
  viewMatrixBulkUpdateCardTitle: `Ausgewählte Firmen wurden aktualisiert`,
  viewMatrixTableCardTitle: `Firmenansichtsmatrix`,
  selectCompanyType: `Bitte wählen Sie den Firmentyp aus`,
  selectCountries: `Bitte wählen die das Land aus`,
  bulkUserUploadCsvExplanation: `Sie können neue Benutzer via CSV-File einladen`,
  delete: `Löschen`,
  deleting: `Löschvorgang läuft`,
  successDeletingCompany: `Firma wurde erfolgreich gelöscht`,
  infoImportingCompanies: `Erwarte Importdauer {0} Sekunden`,
  importInProgress: `Bitte zuerst Datenimport abschliessen`,
  changeTermsNotification: `Unsere Allgemeinen Geschäftsbedingungen (AGB) haben sich geändert. Bitte prüfen und akzeptieren Sie die AGBs innerhalb der nächsten 14 Tage.`,
  termsNotAccepted: `Sie haben unsere Nutzungsbedingungen abgelehnt`,
  companiesAlreadyExists: `Ihre Firma ist bereits registriert`,
  dunsNotFound: `DUNS wurde nicht gefunden`,
  importException: `Importierungsausnahme`,
  importing: `Importierung`,
  finishImport: `Import abschließen`,
  companiesImported: `Import beendet`,
  decline: `Ablehnen`,
  declineModalTitle: `Wollen Sie wirklich unsere AGB ablehnen?`,
  declineModalText: `Bei Ablehnung der AGB's ist eine Plattformnutzung nicht mehr möglich`,
  columnVersion: `Version`,
  columnUploadDate: `Hochladedatum`,
  columnPublishDate: `Veröffentlichungsdatum`,
  columnDownload: `Herunterladen`,
  termsAccepted: `Sie haben die AGB akzeptiert`,
  termsDeclined: `Sie haben die AGB abgelehnt`,
  termsAdded: `Sie haben erfolgreich neue AGBs hinzugefügt`,
  versionTypeRequired: `Bitte geben Sie die AGB Kategorie ein`,
  publishDateRequired: `Bitte geben Sie ein AGB Veröffentlichungsdatum ein`,
  termsFileRequired: `Sie müssen ein Dokument auswählen`,
  uploadTermsTitle: `Bitte laden Sie eine neue AGB Version hoch`,
  selectPDF: `Bitte wählen sie das AGB PDF Dokument`,
  selectVersion: `Bitte wählen die AGB Kategorie`,
  versionCritical: `Kritisch`,
  versionMajor: `Wichtig`,
  versionMinor: `Gering`,
  publishDate: `Veröffentlichungsdatum`,
  uploadTermsButton: `AGBs hochladen`,
  companyHasUsers: `Sie können keine Firma mit Usern löschen`,
  termsNotValidFile: `Das AGB Fileformat muss PDF sein`,
  notImportedCompaniesTitle: `Die Firmenliste konnte nicht importiert werden`,
  dateCreatedColumn: `Datumsdatensatz ist erstellt`,
  termsVersionColumn: `Aktuellste akzeptierte AGB Version`,
  dateTermsAcceptedColumn: `AGB Akzeptanzdatum`,
  enable: `Aktivieren`,
  disable: `Deaktivieren`,
  commentColumn: `Kommentierung`,
  companyUserCountColumn: `Benutzer auf der Plattform`,
  setComment: `Kommentar eintragen`,
  addCustomTerms: `Angepasste AGB's hinzufügen`,
  addCustomTermsModalTitle: `Firmenspezifische AGB's hinzufügen`,
  addCommentModalTitle: `Firmenspezifischen Kommentar hinzufügen`,
  notValidCsvFormat: `Kein gültiges CSV Format`,
  duns: `Bisnode DUNS`,
  cmptcs: `Bisnode CMPTCS`,
  reload: `Erneut laden`,
  fetch: `Abruf`,
  cmptcsSuccess: `Bisnode CMPTCS Daten erfolgreich hinzugefügt`,
  totalCompaniesDataImported: `Firmendaten importiert`,
  tabCmptcs: `Bisnode CMPTCS Daten`,
  tabKyc: `KYC Daten`,
  tabBitsight: `BITSIGHT Daten`,
  companyDisabled: `Ihre Firma wurde deaktiviert`,
  successDeleteUser: `Ihre Benutzerdaten wurden erfolgreich gelöscht`,
  errorRemovingYourAccountTryAgain: `Es ist ein Fehler beim löschen Ihrer Benutzerdaten aufgetreten, bitte versuchen Sie es erneut`,
  notAuthorizedToDeleteProfile: `Sie verfügen nicht über die notwendigen Berechtigungen Ihre Benutzerdaten zu löschen`,
  contactTitanAdminToRemoveProfile: `Sie verfügen nicht über die notwendigen Berechtigungen die Firmeneigentümerdaten zu löschen, bitte kontaktieren Sie den Titan Administrator`,
  cannotRemoveActiveCompanyOwner: `Sie verfügen nicht über die notwendigen Berechtigungen die Firmeneigentümerdaten zu löschen, bitte kontaktieren Sie den Titan Administrator`,
  wait_admin_approval: `Sie sind aktuell von Ihrem Firmen Administrator noch nicht freigegeben`,
  certificateNameEnRequired: `Bitte geben Sie einen englischen Zertifikatsnamen ein`,
  certificateNameDeRequired: `Bitte geben Sie einen deutschen Zertifikatsnamen ein`,
  certificateAuthorityRequired: `Bitte geben Sie den Namen der Zertifizierungsstelle ein`,
  certificateNameEnTitle: `Zertifikatsbezeichnung in English`,
  certificateNameDeTitle: `Zertifikatsbezeichnung in Deutsch`,
  authority: `Behörde`,
  certificates: `Zertifikat`,
  addCertificate: `Zertifikat hinzufügen`,
  tagName: `Tag Bezeichnung`,
  visibility: `Sichtbarkeit`,
  addTag: `Tag hinzufügen`,
  show: `Ansicht`,
  hide: `Verbergen`,
  descriptionEnglish: `Englische Beschreibung`,
  descriptionGerman: `Deutsche Beschreibung`,
  category: `Kategorie`,
  subcategory: `Unterkategorie`,
  totalCompanies: `Summe an Firmen`,
  industryCodes: `Industrie Codes`,
  addIndustryCode: `Industrie Code hinzufügen`,
  basic: `Profil`,
  overview: `Expertise`,
  details: `Details`,
  peopleAndOrganisation: `Menschen und Organisation`,
  resources: `Ressourcen`,
  products: `Produkte`,
  procurement: `Einkauf`,
  editBasicInfo: `Profil Informationen anpassen`,
  editDescription: `Beschreibung anpassen`,
  editTags: `Tags anpassen`,
  editLogo: `Logo anpassen`,
  editIndustryCodes: `Industrie Code anpassen`,
  editMediaLinks: `Social Media Links anpassen`,
  editSlidesAndVideos: `Unternehmenspräsentation und -video anpassen`,
  noCompanyUrl: `Keine Firmen-URL`,
  founded: `Gründungsjahr`,
  employees: `Anzahl Mitarbeiter`,
  editHyperlinks: `Hyperlinks anpassen`,
  hyperlinks: `Hyperlinks`,
  mediaRelease: `Pressemitteilungen`,
  annualReport: `Jahresbericht`,
  financialReport: `Finanzbericht`,
  companyDomain: `Firmen Domain`,
  companyUrl: `Firmen-URL`,
  importCanceled: `Import abgebrochen`,
  editBusinessExpertise: `Business Expertise anpassen`,
  editCertificates: `Zertifikate anpassen`,
  businessExpertise: `Business Expertise`,
  requestBusinessExpertise: `Business Expertise anfragen`,
  certificateNumber: `Zertifikatsnummer`,
  certificateValidDate: `Zertifikatsgültigkeitsdauer`,
  certificateFile: `Zertifikatsdokument`,
  othersTitle: `Sonstiges (für eine größere Auswahl an Business Expertises müssen die Industrie Codes im Profil erweitert werden)`,
  selectIndustryCodes: `Industrie Codes auswählen`,
  editIndustryExpertise: `Industrie Expertise anpassen`,
  industryExpertise: `Industrie Expertise`,
  requestIndustryExpertise: `Industrie Expertise anfragen`,
  successCMPLK: `Bisnode CMPLK Data wurden erfolgreich hinzugefügt`,
  companyLink: `Firmen-URL`,
  inviteCompany: `Firmen einladen`,
  companyAlreadyExists: `Firmen existiert bereits`,
  goToCompany: `Gehe zur Firma`,
  inviteSent: `Deine Einladung wurde versendet`,
  add: `Hinzufügen`,
  selectCertificateFile: `Bitte wählen Sie ein Zertifikatsdokument aus`,
  city: `Stadt`,
  editVideos: `Firmenvideo hinzufügen`,
  companyVideoEnLabel: `Englischsprachiges Firmenvideo`,
  companyVideoDeLabel: `Deutschsprachiges Firmenvideo`,
  editPresentation: `Firmenpräsentation anpassen`,
  uploadPresentationEn: `Firmenpräsentation hochladen`,
  presentationInvalidFormat: `Die Firmenpräsentation muss im PDF Format sein`,
  uploadPresentationDe: `Deutsch Firmenpräsentation hochladen`,
  registrationAndAddress: `Registrierung und Adresse`,
  companyPrincipals: `Firmen Principals`,
  showParentCompanies: `Muttergesellschaft ansehen`,
  parent: `Muttergesellschaft`,
  address: `Adresse`,
  domesticUltimate: `Nationale Konzermuttergesellschaft`,
  globalUltimate: `Globale Konzernmuttergesellschaft`,
  editRevenue: `Umsatzzahlen anpassen`,
  addSuccessStory: `Success Stories hinzufügen`,
  editSuccessStory: `Success Stories anpassen`,
  successStories: `Success Stories`,
  successStoryNameRequired: `Success Story Bezeichnung ist notwendig`,
  successStoryDateRequired: `Success Story Jahr und Monat ist notwendig`,
  successStoryTagsRequired: `Success Story Tags sind notwendig`,
  successStoryMaxTags: `Sie können maximal 5 Tags hinzufügen`,
  successStoryTitle: `Titel`,
  successStoryDate: `Jahr und Monat`,
  successStoryTags: `Success Story Tags`,
  successStoryFile: `Success Story Dokument`,
  selectSuccessStoryFile: `Success Story File auswählen`,
  selectStoryTags: `Success Story Tag auswählen`,
  selectStoryDate: `Success Story Jahr und Monat auswählen`,
  importNoIndustryCode: `Industrie Code konnte nicht gefunden werden`,
  selectCompanySize: `Firmengrösse auswählen`,
  cancelImport: `Datenimport stornieren`,
  successStoryMaxTitle: `Maximal 100 Zeichen für den Titel verwenden`,
  storyFileRequired: `Success Story Beschreibung ist notwendig`,
  procurementUrl: `Einkauf Website`,
  supplierRegistration: `Supplier Registrierung`,
  selectOrgChart: `Vertriebsteampräsentation auswählen`,
  editContactPerson: `Kontaktperson anpassen`,
  importConfirmDeleteCompany: `Sind Sie sicher dass Sie die Firma von der Datenimportimportliste entfernen wollen?`,
  industryCodeRequired: `Industrie Code ist ein Pflichtfeld`,
  industryCodeCategoryRequired: `Kategorie ist ein Pflichtfeld`,
  industryCodeSubcategoryRequired: `Sub-Kategorie ist ein Pflichtfeld`,
  contactPerson: `Kontaktperson`,
  salesTeam: `Vertriebsteam`,
  userDisabledSuccessTitle: `Sie haben den User erfolgreich deaktiviert`,
  invite: `Firmen einladen`,
  rateCardDescription: `Ihre öffentliche Rate Card kann von allen Käufern auf TItan eingesehen werden. Bitte geben Sie Ihre Tagessätze je Senioritätstufe für alle relevanten Länder und Jobprofile ein. Nachstehend finden Sie eine Definition der Standard Senioritätsbeschreibungen, zu denen Sie ggf. Anmerkungen machen können (beispielsweise eine Zuordnung zu Ihren hauseigenen Profilbezeichnungen). Die Tagessätze basieren auf einem 8,4-Stunden-Tag in CH/LI und auf einem 8-Stunden-Tag in DE und AT); es handelt sich dabei nur um Tagessätze an Regelarbeitszeiten an Wochentagen. Bitte geben Sie darüber hinaus an, ob Reisekosten in den Tagessätzen innerhalb des jeweiligen Landes enthalten sind oder nicht. Wenn Sie eine Zelle leer lassen, bedeutet dies, dass Sie für ein bestimmtes Profil und Seniorität keine Dienstleistungen als Unternehmen anbieten.`,
  titan_descriptionL1: `Junior (0-2 Jahre relevante Projekterfahrung)`,
  titan_descriptionL2: `Professional (3-5 Jahre relevante Projekterfahrung)`,
  titan_descriptionL3: `Senior (6+ Jahre relevante Projekterfahrung)`,
  titan_descriptionL4: `Expert (8+ Jahre relevante Projekterfahrung)`,
  titan_descriptionL5: `Senior expert (10+ Jahre relevante Projekterfahrung)`,
  multipleEntriesForTheSameYear: `Umsätze für diese Jahr existieren bereits`,
  rateCardTargetDescription: `Ihre Target Rate Card (Richtpreise) kann nur von Benutzern in Ihrer Firma/Organisation auf Titan eingesehen werden. Bitte geben Sie Ihre maximalen Tagessätze pro Seniorität für alle relevanten Länder und Profile ein. Nachstehend finden Sie eine Definition von der Senioritätsstufen, zu denen Sie Stellung nehmen können. Nachstehend finden Sie eine Definition der Standard Senioritätsbeschreibungen, zu denen Sie ggf. Anmerkungen machen können (beispielsweise eine Zuordnung zu Ihren hauseigenen Profilbezeichnungen). Die Tagessätze basieren auf einem 8,4-Stunden-Tag in CH/LI und auf einem 8-Stunden-Tag in DE und AT); es handelt sich dabei nur um Tagessätze an Regelarbeitszeiten an Wochentagen. Bitte geben Sie darüber hinaus an, ob Reisekosten in den Tagessätzen innerhalb des jeweiligen Landes enthalten sind oder nicht. Wenn Sie eine Zelle leer lassen, bedeutet dies, dass Sie für ein bestimmtes Profil und Seniorität keine Richtpreise als Unternehmen angeben wollen.`,
  privateRateCardDescription: `Diese Private Rate Card kann nur von Benutzern von Firma [Firmenname 1] und Firma [Firmenname 2] eingesehen werden. Der Inhalt wird streng vertraulich behandelt. Bitte geben Sie Ihre Tagessätze pro Seniorität für alle relevanten Länder und Stellenprofile ein. Bitte geben Sie Ihre Tagessätze je Senioritätsstufe für alle relevanten Länder und Jobprofile ein. Nachstehend finden Sie eine Definition der Standard Senioritätsbeschreibungen, zu denen Sie ggf. Anmerkungen machen können (beispielsweise eine Zuordnung zu Ihren hauseigenen Profilbezeichnungen). Die Tagessätze basieren auf einem 8,4-Stunden-Tag in CH/LI und auf einem 8-Stunden-Tag in DE und AT); es handelt sich dabei nur um Tagessätze an Regelarbeitszeiten an Wochentagen. Bitte geben Sie darüber hinaus an, ob Reisekosten in den Tagessätzen innerhalb des jeweiligen Landes enthalten sind oder nicht. Wenn Sie eine Zelle leer lassen, bedeutet dies, dass Sie für ein bestimmtes Profil und Seniorität keine Dienstleistungen als Unternehmen anbieten.`,
  privateRateCardRequestSuccess: `Anfrage wurde versendet`,
  personalNotes: `Firmenspezifische Anmerkungen`,
  errorUploadingFile: `Fehler beim Dokument-Upload ist aufgetreten`,
  personalArea: `Persönlicher Bereich`,
  myListsDesc: `Hier können sie Firmenlisten anlegen und verwalten. Listen erlauben Ihnen eine Auswahl von Firmen zu speichern und persönliche Kommentare hinzuzufügen, die jeder in Ihrer Organisation sehen kann.`,
  companyProfileDesc: `Hier können Sie auf Ihr Firmenprofil zugreifen. Als Administrator können Sie Informationen hinzufügen oder ändern. Ihr Firmenprofil ist entweder sichtbar für Supplier (wenn Sie ein Buyer sind) oder für Buyer (wenn Sie ein Supplier sind).`,
  welcomeToTitan: `Titan SIM`,
  myLists: `Meine Listen`,
  companyProfile: `Unternehmensprofil`,
  tutorials: `Tutorials`,
  feedback: `Feedback`,
  support: `Support`,
  privacyStatement: `Datenschutzerklärung`,
  privacyStatementUrl: `https://titan-org.com/de/privacy-statement/`,
  supportTitle: `Titan Support Center`,
  strengthenProfile: `Profilstärke`,
  profileStrength: `Profilausprägung:`,
  seniorityLevel: `Erfahrungsstufe`,
  titanSeniorityDescription: `Titan Erfahrungsstufenbeschreibung`,
  supplierSeniorityDefinition: `Lieferanten Erfahrungsstufenbeschreibung`,
  seniorityDefinition: `Erfahrungstufendefinition`,
  rateCards: `Rate Card`,
  rateCardTitleRequired: `Rate Card Bezeichnung ist notwendig`,
  addRateCard: `Rate Card hinzufügen`,
  rateCardTitle: `Rate Card Bezeichnung`,
  removeRateCardNotification: `Wollen Sie diese Rate Card wirklich löschen?`,
  noRateCards: `Sie haben aktuell keine Rate Cards hinterlegt`,
  profileEn: `Profile`,
  profileDe: `Profile`,
  currency: `Währung`,
  copyRow: `Zeile kopieren`,
  pasteToRow: `Kopierte Zeile hinzufügen`,
  travelCostIncluded: `Reisekosten enthalten`,
  yes: `Ja`,
  no: `Nein`,
  notSet: `undefiniert`,
  rateCardDescriptionPlaceholder: `Bitte fügen Sie zusätzliche Informationen hinzu (max. 1'000 Zeichen)`,
  privateRateCardTitle: `Private Rate Card zwischen {1} und {2}`,
  addSelectedToList: `Ausgewähltes wird zur Liste hinzugefügt`,
  matchCount: `Anzahl Treffer`,
  distance: `Entfernung`,
  searchPlaceholder: `Suche nach Unternehmen, Personen, Expertise, Produkte oder weiterem`,
  listRequired: `Liste ist notwendig`,
  addCompanyToList: `Unternehmen zur Liste hinzufügen`,
  selectList: `Liste auswählen`,
  hitDetailsFor: `Treffer Details`,
  filters: `Filter`,
  applyFilters: `Filter anwenden`,
  deleteRateCardNotification: `Wollen Sie die Liste wirklich löschen?`,
  removeCompanyFromListNotification: `Wollen Sie die Firma wirklich von der Liste entfernen?`,
  listDescription: `Listenbeschreibung`,
  addCompanies: `Unternehmen hinzufügen`,
  listNameRequired: `Listenname muss hinzugefügt werden`,
  addNewList: `Neue Liste hinzufügen`,
  listName: `Listenname`,
  selectCompanies: `Unternehmen auswählen`,
  addCompaniesToList: `Unternehmen zur Liste hinzufügen`,
  feedbackPopupText: `Das Titan-Team freut sich über Ihr Feedback!`,
  provideFeedback: `Feedback geben`,
  feedbackPopupTextL1: `Wenn Sie einen Fehler (bug) melden erklären Sie möglichst genau wo und wie dieser aufgetreten ist. Fügen Sie auch einen Screenshot hinzu, falls möglich.`,
  feedbackPopupTextL2: `Falls Sie sich neue Funktionalitäten wünschen (feature request) können Sie auch kurz den Nutzen (use case) beschreiben oder Beispiele nennen.`,
  addProduct: `Produkt hinzufügen`,
  editProducts: `Produkt anpassen`,
  manual: `Anleitung`,
  requestSuccessStory: `Success story anfragen`,
  industryExpertiseMostRevenue: `In welcher Branche machen Sie den meisten Umsatz {0} ?`,
  selectIndustry: `Bitte wählen Sie die Branche aus`,
  selectHighestRevenueIndustry: `Bitte wählen Sie die Branch in der Sie den meisten Umsatz machen aus`,
  yearNumberValidation: `Bitte geben Sie eine Zahl für die Jahreszahl an`,
  employeeCountValidation: `Bitte geben Sie eine Zahl für die Anzahl an Mitarbeitern an`,
  rateCardBenchmarkDescription: `Anbei finden Sie die Titan Benchmark Tagessätze. Diese sind basierend auf 8.4 Stunden in der Schweiz bzw. mit 8 Stunden  für Deutschland und Österreich kalkuliert und gelten für reguläre Tagesarbeitszeiten innerhalb einer Arbeitswoche.`,
  deleteContactPerson: `Kontaktperson löschen`,
  deleteContactPersonModal: `Wollen Sie die Kontaktperson wirklich löschen?`,
  firsAndLastName: `Vorname und Nachname`,
  searchIconPreferredSupplier: `Das ist Ihr Preferred Supplier`,
  searchIconNotPreferredSupplier: `Dieser Firma ist nicht Ihr Preferred Supplier`,
  searchIconRateCard: `Diese Firma hat eine Rate Card`,
  searchIconNoRateCard: `Diese Firma hat keine sichtbare Rate Card`,
  deleteCompanyConfirmation: `Sie löschen die Firma, sind Sie sicher das Sie das tun wollen?`,
  openAll: `Alle Rubriken öffnen`,
  closeAll: `Alle Rubriken schliessen`,
  trendsExpertise: `Trend Expertise`,
  addInternalNote: `Internen Kommentar hinterlassen`,
  addToList: `Zur Liste hinzufügen`,
  addContractFolder: `Vertragsordner hinzufügen`,
  confidential: `Vertraulich`,
  createdAt: `Erstellt am`,
  updatedAt: `Akualisiert am`,
  contractNumber: `Vertragsnummer`,
  purchaseOrderNumber: `Einkaufsbestellnummer`,
  deleteDocumentModal: `Sie werden das Dokument dauerhaft löschen, sind Sie sicher?`,
  editContractDocument: `Editieren des Vertragsdokuments`,
  deleteContractFolderModal: `Sie werden den Vertragsordner inkl. Inhalt dauerhaft löschen, sind Sie sicher?`,
  editContractFolder: `Editieren des Vertragsorder`,
  deleteContractFolder: `Löschen des Vertragsordner`,
  addDocument: `Dokument hinzufügen`,
  startDate: `Startdatum`,
  endDate: `Enddatum`,
  value: `Wert`,
  documentNameRequired: `Dokumentenname is notwendig`,
  documentTypeRequired: `Dokumententyp ist notwendig`,
  selectDocumentType: `Dokumententyp auswählen`,
  documentName: `Dokumentenname`,
  folderNameRequired: `Vertragsordnername ist notwendig`,
  startDateRequired: `Startdatum ist notwendig`,
  contractValueError: `Wert mus eine Nummer sein`,
  contractFolderName: `Vertragsordnername ist notwendig`,
  selectStartDate: `Startdatum auswählen`,
  selectEndDate: `Enddatum auswählen`,
  contractValue: `Vetragswert`,
  selectCurrency: `Währung auswählen`,
  selectTags: `Tag auswählen`,
  documentFileRequired: `Dokument ist notwendig`,
  selectDocumentFile: `Dokument auswählen`,
  documentFiIeError: `Dokument muss ein PDF mit max. 20 MB sein`,
  internalNote: `Interne Notiz`,
  visible: `Sichtbar`,
  productName: `Produktname`,
  productUrl: `Produkt URL`,
  implementationPartners: `Implementierungspartner`,
  selectImplementationPartners: `Implementierungspartner auswählen`,
  productGroup: `Produkt Gruppe`,
  selectProductGroup: `Produkt Gruppe auswählen`,
  productImage: `Produktbild`,
  selectProductImage: `Produktbild auswählen`,
  productImageError: `Dokument muss ein PNG oder JPEG mit maximal 2 MB sein`,
  productPresentation: `Produktpräsentation`,
  productPresentationError: `Dokument muss ein PDF mit max. 10 MB sein`,
  selectProductPresentation: `Produktpräsentation auswählen`,
  productReferenceInstallations: `Produktreferenzinstallation`,
  selectReferenceInstallations: `Produktreferenzinstallation auswählen`,
  productRoadmap: `Produkt-Roadmap`,
  selectRoadmap: `Produkt-Roadmap auswählen`,
  productPriceList: `Produkt-Preisliste`,
  selectPriceList: `Produkt-Preisliste auswählen`,
  deleteProductModal: `Sie wollen ein Produkt dauerhaft löschen, sind Sie sicher?`,
  noContracts: `Sie haben aktuell keine Verträge`,
  addPersonalNote: `Persönliche Notiz hinzufügen`,
  personalNoteTitle: `Persönliche Notiz`,
  deleteUserConfimation: `Sie wollen die ausgewählten Benutzerdaten dauerhaft löschen, sind Sie sicher?`,
  errorFindingYourCompany: `Ihr Firma wurde nicht gefunden`,
  notAllowedToEditContracts: `Sie haben nicht die Berechtigungen Verträge zu editieren`,
  confidentialDocumentsExist: `Hier ist ein Vertrauliches Dokument`,
  wrongCompany: `Falsche Firma`,
  contractFolderNotFound: `Vertrag nicht gefunden`,
  wrongUploadType: `Falscher Upload Type`,
  errorDeletingDocument: `Fehler während der Dokumentenlöschung`,
  invalidRequest: `Keine gültige Anfrage`,
  unknownError: `Fehler sind aufgetreten`,
  productNameRequired: `Produktname ist notwendig`,
  productGroupRequired: `Produktgruppe ist notwendig`,
  productDescription: `Produktbeschreibung`,
  riskAndCompliance: `Risk & Compliance`,
  questionnaires: `Fragebogen`,
  cmptcsView: `CMPTCS Ansicht`,
  bitsightView: `BitSight Ansicht`,
  riskView: `Risiko Ansicht`,
  kycView: `KYC Ansicht`,
  advancedRiskView: `Erweiterte Risk Ansicht`,
  riskIndicator: `Risikoindikator`,
  editRiskClass: `Risikoklasse editieren`,
  leaveComment: `Bitte Kommentierung hinzufügen`,
  riskClassRequired: `Risikoklasse ist ein Pflichtfeld`,
  successRequestPremiumRiskData: `Sie haven Erfolgreich Premium-Risikodaten bestellt`,
  requestPremiumRiskData: `Premium-Risikodaten bestellen`,
  financialRisks: `Finanzielles Risiko`,
  financialRisksEmptyText: `Finanzielles Risiko ist die Möglichkeit, bei einem Investitions- oder Geschäftsvorhaben Geld auf Grund von Zahlungsschwierigkeiten des Geschäftspartners zu verlieren. Finanziellen Risiken sind beispielsweise das Kreditrisiko und das Liquiditätsrisiko.`,
  requestSurvey: `Fragebogen anfragen`,
  survey: `Fragebogen`,
  surveyInfoPopup: `Unsere Titan-Fragebögen zur Risiko-Toleranz besteht aus ausgewählten Fragen je Risikokategorie, die Ihren (zukünftigen) und unseren Kunden helfen, dass Risiko des Lieferanten (also von Ihnen) im Zusammenhang mit der Lieferantenbeziehung besser zu verstehen.`,
  complianceRisks: `Compliance & Legal Risiko`,
  reputationalRisks: `Reputational & Sustainability Risiko`,
  contractualRisks: `Vertragsrisiko`,
  riskIndicatorInfo: `Der Titan Risiko Indikator misst das Lieferantenrisiko basierend auf dem Titan Risk Rating Framework. Die Resultate klassifizieren das Risiko in der Geschäftsaktivität mit der Legal Entity. Die Berechnung des Titan Risiko Indikator erfolgt auf Basis der Lieferanten Selbstdekleration und Daten-Feeds von den aus Titan Sicht besten Risiko Datenfeed Lieferanten im Markt.`,
  operationalRisks: `Operational Risiko`,
  complianceRisksInfo: `Das Compliance & Legal Risiko umfasst die Verfehlungen des Lieferanten im Zusammenhang mit internen und externen Regularien in den Märkten in denen der Lieferant tätig ist.  `,
  reputationalRisksInfo: `Die Reputational- and Sustainability Risiko ist eine teilweise neu entstandende Risikokategorie. Sie umfasst Themen aus der Business Strategie und im Zusammnspiel mit der Geschäftsleitung die in engem Zusammenhang mit Sozialen und Umweltaspekten sowie zugehörigen Gesetzen stehen.`,
  contractualRisksInfo: `Das Vertragsrisiko umfasst insbesondere die Verluste für den Käufer Aufgrund von Teil-/Nichterfüllung des Vetrages.`,
  operationalRisksInfo: `Das Operational Risiko umfasst das Lieferantenrisiko im Zusammenhang mit den Daily Business der Firma. Es ist dabei massgeblich abhängig von Faktor Mensch. Fehler treten hier am häufigsten auf. Ein weiterer wichtiger Faktor ist das Cyber Risiko mit den zugehörigen zu erwartenden Verlusten in den Bereichen Reputation, monetäre Einbussen und Schäden für die Gesamte IT des Unternehmens.`,
  noSurveysAnswered: `Fragebögen wurden noch nicht beantwortet`,
  profileInfoPopUp: `<div>Der Firmenprofilbereich <b>"Profile"</b> gibt eine Kurzzusammenfassung des Unternehmens mit allen relevanten Informationen. Es wird dabei zwischen einem Kernbereich mit Logo, Beschreibung, Unternehmensstruktur, Firmengrösse und Social Media Links unterscheiden und einem weiterführenden Bereich mit beispielsweise der Unternehmenspräsentation, Unternehmensvideo oder dem Preferred Supplier Status. Das Profil ist Interaktiv gestalltet. Sollte der weiterführende Bereich nicht gepflegt sein, dann kann der Anwender die Firma "einladen". Dann wird das zugehörige ausfüllen der fehlenden Informationen getriggert. Wichtig ist dabei zu Wissen das die Titan Anwendung für Lieferanten kostenfrei ist.</div>`,
  personalInfoPopUp: `<div>Der Firmenprofilbereich <b>"Personal area"</b> zeigt drei zentrale Informationen auf. Diese sind das Vertagsmanagement, die Notizen welche für Firmen hinterlegt werden (persönlich oder als Interne Information) sowie einen Listenbereich aus dem ersichtlich wird in welchen Listen die Firma für den User hinterlegt ist. Diese persönliche und firmenspezifische Nutzungsfunktion kommt stellt eine CRM Funktion für Firmen dar um Ihre Lieferanten besser zu managen. Die Vertragsablage ist dabei der i-Punkt und rundet das Unternehmenprofil und die Suche ab.</div>`,
  capabilitiesInfoPopUp: `<div>Der Firmenprofilbereich Capabilities zeigt die zwei zentralen Themenblöcke <b>"Business Expertises"</b> (in welchen Themen macht das Unternehmen Business) und "Industry Expertise" (in welchen Industrien ist es dabei tätig) auf. Das darunterliegende Framework von mehr als 300 Capability Items ermöglicht eine detailierte Suche von Firmen nach Capabilities. Das Framework ist voll n die Titan Suche integriert und stellt in Verbindung mit den anderen Firmenprofilbereichen eine Kernfunktion von Titan dar. Kein anderer Anbieter im Markt geht dabei so tief wie wir.</div>`,
  detailsPopUp: `<div>Der Firmenprofilbereich <b>"Details"</b> zeigt neben den Firmenregistrierungs-, (modelierten) Umsatz- und Publikationsdaten den Standort der Firma sowie den Hauptansprechpartner für Kunden an. Ein Sales Orga Chart der Firma ist ebenfalls abrufbar, soweit von der Firma bereitgetellt.</div>`,
  peoplePopUp: `<div>Der Firmenprofilbereich <b>"People & Organsiation"</b> zeigt die im offiziellen Unternehmensregister angezeigten Key Prinziples des Unternehmen s an. Darüber hinaus soweit als Information verfügbar den Auditor des Unternehmens. Der jeweilige Most Senior Principle des Unternehmens ist dabei als erstes hervorgehoben dargestellt.</div>`,
  riskInfoPopUp: `<div>Der Firmenprofilbereich <b>"Risk & Compliance"</b> und zeigt das Titan Risiko Profil der Firma an. Dies besteht aus dem Risk Indicator, der Risiko-Klasse des jeweiligen Unternehmen bei der eigenen Firma, den Daten Feeds und den Selbstdeklerationsfragebögen. Die Daten Feeds und die Fragebögen berechnen dabei den Titan Risk Indicator. Grundsätzlich wird bei den Datenfeeds zwischen Basic Data und Payable Data unterschieden. Basic Data werden für rund 50-100 Firmen von Titan für die bedeutensten Firmen im Markt zur Verfügung gestellt. Weitere Datenfeeds die weiter ins Detail gehen oder für andere Firmen benötigt werden sind bestellbar.</div>`,
  procurementInfoPopUp: `<div>Der Firmenprofilbereich <b>"Procurement"</b> zeigt kumuliert alle kommerziellen Informationen des Unternehmens an. Dies bezieht sich vor allem auf die Tagessätze und Preislisten, jedoch darüber hinaus auch auf weiterführende Einkaufsbezogenen Informationen wie den Standard Einkaufsbedingungen des Unternehmens. Wichtig ist dabei, dass die Informationen in diesem Bereich in zweierlei Arten angezeigt werden können (a) Public oder (b) Private. Je nach Art wie z.B. die Rate Card eingestellt wurde. Private bedeutet ausschliesslich 1:1 in der Geschäftsbeziehung zwischen der Firma und dem eigenen Unternehmen .</div>`,
  productInfoPopUp: `<div>Der Firmenprofilbereich <b>"Produkte"</b> stellt die IT und Non-IT Produkte des Unternehmen auf. Im ersten Schritt werden die Produkte vom Unternehmen angelegt und anschliessend in einem zweiten Schritt angepasst. Die Anpassung bezieht sich dabei auf die Detailinformationen zum Produkt wie beispielsweise Logo, Beschreibung, Produktpräsentation oder Technology Roadmap. Das wichtigste hingegen sind dabei die "Implementierungspartner" der (Software) Produkte. Diese sind in die Suche mit eingebunden. </div>`,
  deletePersonalNote: `Persönlichen Kommentar löschen`,
  editPersonalNote: `Persönlichen Kommentar editieren`,
  requestPremiumRiskQuestion: `<p>Hallo Anforderer</p> <p> Premium Risiko Daten sind eine kostenpflichtige Option innerhalb der Titan SIM Plattform. Das Basis Risiko Datenpaket kostet einmalig je Abruf 50 CHF. Das zweite BitSight Premium Datenpaket basiert auf Ihrem Preisabschluss mit BitSight selbst. Wir helfen Ihnen hier gerne weiter. In jedem Fall muss dieses Datenpaket extra und zusätzlich von Ihnen auf der Titan SIM Plattform über uns bestellt werden. Es entstehen keine zusätzlichen Kosten (da es auf Ihrem BitSight Abschluss basiert). Sobald die Bestellung vorliegt ordnen wir Ihnen das Datenpaket zu. </p> <span>Bitte beachten Sie, dass jede finale kostenpflichtige Bestellung einen zugehörige Rechnung an Ihr Unternehmen auslösen wird.</span> `,
  order: `Bestellung`,
  view: `Details`,
  showMore: `Mehr anzeigen`,
  credit: `Kredit`,
  payment: `Payment`,
  control: `Control`,
  date: `Datum`,
  paydexRating: `Paydex Rating`,
  paymentBehaviorDays: `Bezahlverhalten in Tagen`,
  paymentBehaviorDescription: `Beschreibung des Bezahlverhaltens`,
  selfDeclarationResponse: `Selbstdeklerationsantwort`,
  creditRiskRating: `Kredit-Risiko Rating`,
  dAndBRational: `Dun & Bradstreet Begründung:`,
  recommendedCreditLimit: `Empfohlens Kreditlimit:`,
  controlStandardRating: `Control Standard Rating:`,
  governance: `Governance`,
  amlRating: `AML Rating`,
  matches: `Treffer`,
  noMatches: `Keine Treffer`,
  noShareholders: `Shareholders are not available for this company DE`,
  rdParty: `Drittparteien`,
  dataProtection: `Datenschutz`,
  subContractorRiskRating: `Sub-contractor Risiko Rating`,
  lastLoginDate: `Letzer Login Datum`,
  noData: `Keine Daten`,
  lastLoginUser: `Letzer Login User`,
  numberOfProducts: `Anzahl Produkte`,
  cmptcsImportDate: `CMPTCS importiert am`,
  cmpelkImportDate: `CMPELK importiert am`,
  kycImportDate: `KYC importiert am`,
  successfullySentMessage: `Ihre Nachricht wurde erfolgreich versendet`,
  sendMail: `Sende E-Mail`,
  myRequests: `Meine Anfragen`,
  myRequestsDesc: `Hier können Sie die Anfragen an Ihre Lieferanten und Partner einsehen und verwalten. Darüber hinaus stellen wir Ihnen hier noch unsere erweiterten Kollaborationsfunktionalitäten zur Verfügungen.`,
  requestType: `Anfragentyp:`,
  internalNoteAdded: `Interne Notiz hinzugefügt`,
  internalNoteDeleted: `Interne Notiz gelöscht`,
  internalNoteEdited: `Interne Notiz angepasst`,
  contractFolderChanged: `Vertragsorder geändert`,
  changedBy: `Geändert von {0} `,
  companyInvite: `Firmeneinladung`,
  contractInfo: `Vertragsinformation:`,
  premiumRiskDataRequest: `Premium Risiko Daten angefragt`,
  selfDeclarationRequested: `Selbstdekleration angefragt`,
  privateRateCardRequested: `Private Rate Card angefragt`,
  addRiskClass: `Risikoklasse hinzugefügt`,
  riskOverRule: `Risiko Indikator Anpassung`,
  riskOverRuleCommentRequired: `Anpassungskommentierung muss hinzugefügt werden`,
  riskClassificationDescription: `Risiko Klassifizierungsbeschreibung`,
  riskReviewComment: `Risko Review Kommentierung`,
  riskOverRuleDelete: `Löschen der Risiko Indikator Anpasssung`,
  riskReview: `Risiko Review`,
  riskClass: `Risiko Klasse`,
  myCompany: `Meine Firma`,
  addNewMember: `Neue FTE Ressource hinzufügen`,
  otherValueRequired: `Andere Ressourcen Wertangabe ist notwendig`,
  teamMemberTitleRequired: `FTE Ressource Bezeichnung ist notwendig`,
  teamMemberPorifileRequired: `Job Profile ist notwendig`,
  teamMemberSeniorityRequired: `Senioritätsauswahl ist notwendig`,
  teamMemberDaysRequired: `Anzahl Personentage ist notwendig`,
  teamMemberRequired: `Gültige FTE Ressourcen sind notwendig`,
  other: `Andere Ressourcen`,
  otherComment: `Andere Ressourcen Beschreibung`,
  teamMembers: `Team Ressourcen`,
  missingJobProfileForCompany: `Hier fehlt das ausgwählte Job Profil bei der ausgwählten Firma`,
  companyListRequired: `Bitte wählen Sie die Firma aus`,
  publicRateCardAvailable: `Öffentliche Rate Card ist verfügbar für`,
  noPublicRateCard: `Keine öffentliche Rate Card ist verfügbar`,
  publicRateCardFrom: `Öffentliche Rate Card von`,
  privateRateCardAvailableFor: `Private Rate Card ist verfügbar für`,
  requestPrivateRateCardFrom: `Sie beabsichtigen eine Anfrage für eine Private Rate Card an die Firma {0} zu senden`,
  requestPrivateRateCardQuestion: `Die E-Mail wird an die ausgewählte Firma gesendet. Bitte bestätigen.`,
  requetPrivateRateCard: `Private Rate Card Anfrage`,
  privateRateCardBetween: `Private Rate Card zwischen {0} und {1}`,
  premiumDataType: `Bitte wählen Sie den Premium Datentyp`,
  chooseDataType: `Datentyp auswählen`,
  signUpTitleOne: `Exceptional supplier`,
  signUpTitleTwo: `data intelligence - unlocked.`,
  signUpTermsOne: `Im Rahmen der Accounterstellung bestätigen Sie`,
  signUpTermsTwo: `und unsere Datenschutzerklärung`,
  validNumber: `Der Feldeintrag muss eine gültiges Nummernformat sein`,
  members: `FTE Ressources`,
  others: `Andere Ressourcen`,
  searchIconPremiumData: `Premium Firnendaten verfügbar`,
  searchIconNoPremiumData: `Keine Premium Firmendaten verfügbar`,
  bitsightPremium: `BitSight Premium Daten`,
  titanBasic: `Titan Basic Risiko Daten`,
  printSurvey: `Fragebogen drucken`,
  addAttachemnt: `Anlage hinzufügen`,
  subject: `Thema`,
  messageRequired: `Nachricht ist ein Pflichtfeld`,
  message: `Nachricht`,
  archiveMessage: `Nachricht archivieren`,
  archiveMessageQuestion: `Wollen Sie diese Nachricht wirklich archivieren?`,
  showMessage: `Nachricht anzeigen`,
  sentAt: `Versendet am: {0}`,
  status: `Status`,
  compareCompanies: `Firmen vergleichen`,
  companies: `Firmen`,
  personalNoteAdded: `Persönliche Notiz hinzugefügt`,
  personalNoteDelete: `Persönliche Notiz gelöscht`,
  personalNoteEdited: `Persönliche Notiz editiert`,
  listChanged: `Liste aktualisiert`,
  company_update: `Firma aktualisiert`,
  message_sent: `Nachricht versendet`,
  compare_companies: `Firmen vergleichen`,
  invite_company: `Firmen einladen`,
  company_list_change: `Firmenliste geändert`,
  survey_answer: `Fragenbogen beantwortet`,
  contract_folder_change: `Vertragsordner geändert`,
  internal_note_add: `Interne Notiz hinzugefügt`,
  internal_note_delete: `Interne Notiz gelöscht`,
  internal_note_edit: `Interne Notiz editiert`,
  personal_note_add: `Persönliche Notiz hinzugefügt`,
  personal_note_delete: `Persönliche Notiz gelöscht`,
  personal_note_edit: `Personliche Notiz editiert`,
  request_premium_risk_data: `Premium Risiko Daten angefragt`,
  request_self_declaration: `Lieferantenselbstdekleration angefragt`,
  private_rate_card_request: `Private Rate Card angefragt`,
  standardTermsEnName: `Standard Terms - englische Version`,
  selectEnglishTerms: `Englische Standard Terms auswählen`,
  standardTermsDeName: `Standared Terms - deutsche Version`,
  selectGermanTerms: `Deutsche Standard Terms auswählen`,
  companiesView: `Firmenansicht`,
  archiveRequest: `Request archivieren`,
  archiveRequestQuestion: `Wollen Sie den Request wirklich archivieren?`,
  resendRequest: `Request erneut senden`,
  resendRequestQuestion: `Wollen Sie den Request wirklich erneut versenden?`,
  archive: `Archiv`,
  requestResended: `Request erfolgreich versendet`,
  allCompanies: `Alle Firmen`,
  allBuyers: `Alle Buyer`,
  allSupliers: `Alle Supplier`,
  noCompanyType: `Kein Firmentyp`,
  columnCompanyResourceVetting: `Resource vetting`,
  remove: `Entfernen`,
  requestResourceVetting: `Resource Vetting Anfrage`,
  requestSingleResourceVetting: `Resource Vetting Einzelanfrage`,
  requestBulkResourceVetting: `Resource Vetting Massenmutation`,
  nationalityLabel: `Nationalität`,
  sendRequest: `Anfrage senden`,
  nationalityRequired: `Bitte Nationalität auswählen`,
  birthDateRequired: `Geburtsdatum ist notwendig`,
  birthDateLabel: `Geburtsdatum`,
  requestBulkExplanation: `Sie können einen Massenmutation starten, bitte fügen Sie die notwendigen Informationen ins CSV File ein`,
  screeningReportTable: `Screening Berichte`,
  clientName: `Kundenname`,
  report: `Bericht`,
  noReport: `Kein Bericht`,
  lastVettingDate: `Letztes Vetting Datum`,
  vettingNumber: `Vetting #`,
  reportHistory: `Berichtshistorie`,
  showHistory: `Historie zeigen`,
  requestSuccessfull: `Bericht erfolgreich angefragt`,
  reportUpdated: `Bericht erfolgreich aktualisiert`,
  refetchReport: `Re-fetch Bericht`,
  refetchReportText: `Wollen Sie den Bericht erneut anfragen? Sind Sie sicher?`,
  refetch: `Re-fetch`,
  generatePdf: `PDF erstellen`,
  generatingReport: `Bericht erstellen ...`,
  downloadReport: `Bericht herunterladen`,
  reportStatus: `Berichtsstatus`,
  caseCreated: `In Arbeit`,
  caseReady: `Erstellt`,
  updateExists: `Update ist verfügbar`,
  requestResourceVettingText: `Wollen Sie einen Bericht anfragen? Sind Sie sicher?`,
  request: `Anfragen`,
  columnCompanyBenchmark: `Benchmark Rate Card`,
  resourceId: `Resource ID`,
  residenceLabel: `Land des Wohnorts`,
  deleteReportQuestion: `Wollen Sie diesen Screening Bericht wirklich löschen?`,
  reportDeleteSuccess: `Screening Bericht wurde erfolgreich gelöscht`,
  reportVettingDescriptionTitle: `Screening Berichtsbeschreibung`,
  reportVettingDescriptionContent: `Screening Berichtssektionsbeschreibung; aktuell noch nicht augefüllt. Der Bereich wird in Kürze ausgefüllt.`,
  lastName: `Nachname`,
  lastNameRequired: `Nachname ist ein Pflichtfeld`,
  documents: `Dokumente`,
  tags: `Tags`,
  viewCompanyProfile: `Firmenprofil ansehen`,
  contracts: `Verträge`,
  welcome: `Home`,
  compare: `Compare`,
  users: `Nutzer`,
  search: `Suche`,
  companiesViewMatrix: `Firmenansicht Matrix`,
  importCompanies: `Firmen Unternehmen`,
  terms: `Konditionen`,
  preferredSuppliers: `Bevorzugte Lieferanten`,
  companyLists: `Firmenlisten`,
  surveys: `Umfragen`,
  generalCriteria: `Allgemeine Kriterien`,
  targetRateCards: `Target Rate Cards`,
  privateRateCards: `Private Rate Cards`,
  titanBenchmarks: `Titan-Benchmarks`,
  publicRateCards: `Öffentliche Rate Cards`,
  resourceVetting: `Ressourcenüberprüfung`,
  screeningRequests: `Screening-Anfragen`,
  screeningReports: `Screening-Berichte`,
  companiesOverview: `Firmen Übersicht`,
  deletingFile: `Sind Sie sicher, dass Sie die Produktdatei löschen wollen?`,
  invitedUsers: `Eingeladene Benutzer`,
  inviteDate: `Eingeladen am`,
  requestSuccessStoryTitle: `Sie werden eine Anfrage für eine Erfolgsgeschichte von {0} senden. Bitte wählen Sie unten die Art der Anfrage aus. Eine E-Mail wird an das Unternehmen mit Ihrer Anfrage gesendet, bitte bestätigen Sie Ihre Auswahl.`,
  general_success_story: `Erfolgsgeschichte`,
  business_expertise_specific: `Spezialgebiets spezifische `,
  sort: `Sortieren`,
  addInvisibleTag: `Unsichtbaren Tag hinzufügen`,
  editInvisibleTags: `Unsichtbare Tags bearbeiten`,
  globalList: `Global Liste`,
  cantEditDomain: `Domain kann nicht geändert werden, da die Firma Benutzer hat!`,
  editPersonalRiskNote: `Edit personal risk note DE`,
  deletePersonalRiskNote: `Delete personal risk note DE`,
}
