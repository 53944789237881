import { Card, Col, Row } from 'antd'
import React, { useState } from 'react'
import intl from 'react-intl-universal'
import BulkRequest from './BulkRequest'
import SingleRequest from './SingleRequest'

const tabList = () => [
  { key: 'tab1', tab: intl.get('requestSingleResourceVetting') },
  { key: 'tab2', tab: intl.get('requestBulkResourceVetting') },
]
const contentList = {
  tab1: <SingleRequest />,
  tab2: <BulkRequest />,
}
export default function ScreeningRequest() {
  const [tabKey, setTabKey] = useState('tab1')

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={14}>
        <Card
          style={{ width: '100%' }}
          title={intl.get('requestResourceVetting')}
          tabList={tabList()}
          activeTabKey={tabKey}
          onTabChange={(key) => {
            setTabKey(key)
          }}
        >
          {contentList[tabKey]}
        </Card>
      </Col>
    </Row>
  )
}
