import React, { useContext, useEffect, useState } from "react";
import { Modal, Tabs } from "antd";
import RateCard, { CardContext } from "../index";
import { toJS } from "mobx";
import intl from "react-intl-universal";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import StoreContext from "../../../storeContext";
import { RoleGroups } from "../../../utils/roles";
import { observer } from "mobx-react";

const { TabPane } = Tabs
const { confirm } = Modal
export default observer(function () {
  const { cards, user } = useContext(StoreContext)
  const [showAddCard, setShowAddCard] = useState(false)

  useEffect(() => {
    cards.loadBenchmark()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onEdit = async (targetKey, action) => {
    if (action === 'add') {
      setShowAddCard(true)
    } else {
      confirm({
        title: intl.get('removeRateCardNotification'),
        icon: <ExclamationCircleOutlined />,
        onOk: async () => {
          await cards.updateRest({
            rate_card_benchmark_remove: { rate_card_id: targetKey },
            company_id: user.user.company_id,
          })
          cards.loadBenchmark()
        },
      })
    }
  }
  return (
    <>
      <Tabs
        type={
          RoleGroups.TITANS.includes(user.user['custom:role_id']) &&
          'editable-card'
        }
        hideAdd={cards.benchmarkRateCards.length === 5}
        onEdit={onEdit}
      >
        {cards.benchmarkRateCards.length === 0 ? (
          <TabPane
            tab={intl.get('noRateCards')}
            closable={false}
            key="nocards"
          />
        ) : (
          cards.benchmarkRateCards.map((card) => (
            <TabPane tab={card.rate_card_title} key={card.rate_card_id}>
              <CardContext.Provider
                value={{
                  canEdit: RoleGroups.TITANS.includes(
                    user.user['custom:role_id'],
                  ),
                  upd: async () => {
                    await cards.update({
                      rate_card_benchmark_edit: {
                        rates: cards.getSavedData(toJS(card.allCards)),
                        rate_card_id: card.rate_card_id,
                      },
                      company_id: user.user.company_id,
                    })
                    await cards.loadBenchmark()
                  },
                  updComment: (data) =>
                    cards.update({
                      rate_card_benchmark_edit: {
                        ...data,
                        rate_card_id: card.rate_card_id,
                      },
                      company_id: user.user.company_id,
                    }),
                  updSeniority: (data) =>
                    cards
                      .update({
                        rate_card_benchmark_edit: {
                          seniority_definition: {
                            ...card.seniority_definition,
                            ...data,
                          },
                          rate_card_id: card.rate_card_id,
                        },
                        company_id: user.user.company_id,
                      })
                      .then(() => cards.loadBenchmark()),
                  updTitle: (data) =>
                    cards
                      .update({
                        rate_card_benchmark_edit: {
                          ...data,
                          rate_card_id: card.rate_card_id,
                        },
                        company_id: user.user.company_id,
                      })
                      .then(() => cards.loadBenchmark()),
                }}
              >
                <RateCard.Global
                  titleEdit={true}
                  description={intl.get('rateCardBenchmarkDescription')}
                  title={`${card.rate_card_title}`}
                  card={card}
                />
              </CardContext.Provider>
            </TabPane>
          ))
        )}
      </Tabs>
      <RateCard.Add
        callback={(vals) =>
          cards
            .updateRest({
              company_id: user.user.company_id,
              rate_card_benchmark_add: vals,
            })
            .then(() => cards.loadBenchmark())
        }
        visible={showAddCard}
        onCancel={() => setShowAddCard(false)}
      />
    </>
  )
})
