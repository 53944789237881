import { Card, Descriptions, Table } from 'antd'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import StoreContext from '../../storeContext'
import intl from 'react-intl-universal'

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Job profile',
    dataIndex: 'job_profile',
  },
  {
    title: 'Seniority',
    dataIndex: 'seniority',
  },
  {
    title: 'Person days',
    dataIndex: 'person_days',
  },
]
export default observer(function TeamTable() {
  const { compare } = useContext(StoreContext)
  return (
    <Card title={intl.get('teamMembers')}>
      <Card type="inner" title={intl.get('members')}>
        <Table
          dataSource={compare.compareFilters.team_members}
          columns={columns}
        />
      </Card>
      <Card type="inner" title={intl.get('other')}>
        <Descriptions bordered>
          <Descriptions.Item label={intl.get('value')}>
            {compare.compareFilters.other_value}{' '}
            {compare.compareFilters.currency}
          </Descriptions.Item>
          <Descriptions.Item label={intl.get('otherComment')}>
            {compare.compareFilters.comment}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </Card>
  )
})
