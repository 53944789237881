import React from "react";
import { Modal } from "antd";

export default function ({
  visible,
  handleOnClose,
  title,
  children,
  width = '30vw',
}) {
  return (
    <Modal
      title={title}
      visible={visible}
      footer={null}
      width={width}
      destroyOnClose={true}
      onCancel={handleOnClose}
    >
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, { close: handleOnClose }, null)
      })}
    </Modal>
  )
}
