import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import StoreContext from '../../../../storeContext'
import { COMPANY_PROFILE_TABS } from '../../index'
import { Card } from 'antd'
import intl from 'react-intl-universal'
import EditButton from '../../EditButton'
import AddProduct from './AddProduct'
import ProductView from './ProductView'
import EditProducts from './EditProducts'
import InfoPopUp from '../../../InfoPopUp'

export default React.memo(
  observer(
    React.forwardRef(function (props, ref) {
      const element = useRef()
      const [showAddProduct, setShowAddProduct] = useState(false)
      const [showEditProducts, setShowEditProducts] = useState(false)
      const menuItems = [
        {
          name: intl.get('addProduct'),
          callback: () => setShowAddProduct(true),
        },
        {
          name: intl.get('editProducts'),
          callback: () => setShowEditProducts(true),
        },
      ]
      const { companyProfile } = useContext(StoreContext)
      useEffect(() => {
        ref.current[COMPANY_PROFILE_TABS.products.id] = element.current
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [element.current])
      return (
        <Card
          title={intl.get('products')}
          bodyStyle={{
            padding: !companyProfile.company.products.length && 0,
          }}
          extra={
            <div style={{ display: 'flex' }}>
              <EditButton menuItems={menuItems} />
              <InfoPopUp
                html={intl.get('products')}
                title={intl.get('productInfoPopUp')}
              />
            </div>
          }
        >
          <div ref={element} id={COMPANY_PROFILE_TABS.products.id}>
            {companyProfile.company.products.length > 0 && (
              <div style={{ display: 'flex', gap: '2em', flexWrap: 'wrap' }}>
                {companyProfile.company.products.map((product) => (
                  <ProductView product={product} />
                ))}
              </div>
            )}
          </div>
          <AddProduct
            visible={showAddProduct}
            handleOnCancel={() => setShowAddProduct(false)}
          />
          <EditProducts
            visible={showEditProducts}
            handleOnCancel={() => setShowEditProducts(false)}
          />
        </Card>
      )
    }),
  ),
)
