import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Modal } from 'antd'
import StoreContext from '../../../../storeContext'
import { RoleGroups } from '../../../../utils/roles'
import { Formik } from 'formik'
import { Form, SubmitButton, Input } from 'formik-antd'
import * as Yup from 'yup'
import { ValidationFields } from '../../../../utils/validationFields'
import intl from 'react-intl-universal'

export default observer(function () {
  const { user, companyProfile } = useContext(StoreContext)
  const [showInvite, setShowInvite] = useState(false)
  const validation = Yup.object().shape({
    invite: Yup.object().shape({
      email: ValidationFields.email(),
    }),
  })
  const initialValues = {
    invite: {
      company_id: companyProfile.company.company_id,
      email: companyProfile.company.contact_person
        ? companyProfile.company.contact_person.email
        : '',
    },
  }
  return (
    RoleGroups.TITANS.includes(user.user['custom:role_id']) &&
    companyProfile.company.company_status === 'basic' && (
      <>
        <Button
          onClick={() => setShowInvite(true)}
          style={{ margin: 0, alignSelf: 'flex-end' }}
          type="primary"
        >
          {intl.get('invite')}
        </Button>
        <Modal
          title={intl.get('invite')}
          destroyOnClose={true}
          visible={showInvite}
          onCancel={() => setShowInvite(false)}
          footer={null}
        >
          <Formik
            validationSchema={validation}
            initialValues={initialValues}
            onSubmit={async (vals, { setSubmitting }) => {
              await companyProfile.inviteBasicCompany(vals)
              setSubmitting(false)
              setShowInvite(false)
            }}
          >
            {() => (
              <Form>
                <Form.Item name="invite.email">
                  <Input name="invite.email" addonBefore="Email" />
                </Form.Item>
                <SubmitButton>{intl.get('sendInvite')}</SubmitButton>
              </Form>
            )}
          </Formik>
        </Modal>
      </>
    )
  )
})
