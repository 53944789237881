import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import StoreContext from '../../../storeContext'
import { action } from 'mobx'
import { Avatar, Checkbox, Empty, Skeleton } from 'antd'
import { Link } from 'react-router-dom'
import SelectedResult from './SelectedResult'
import { SearchStoreCalls } from '../store/SearchStore'
import { Loading } from '../../loading'
import intl from 'react-intl-universal'
import { PreferredSupplierIcon, RateCardIcon } from '../../shared/Icons'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'

export default observer(function () {
  const { search, compare } = useContext(StoreContext)

  useEffect(() => {
    return () => {
      action(() => {
        search.selectedCompanies = []
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderResults = () =>
    search.results.map((r) => (
      <div
        onMouseEnter={() => {
          action(() => {
            search.selectedResult = r
          })()
        }}
        className={`item ${
          search.selectedResult &&
          search.selectedResult.name === r.name &&
          'selected'
        }`}
      >
        <Avatar
          style={{
            cursor: 'pointer',
            background: 'transparent',
            color: 'black',
          }}
          icon={
            compare.companies.find((i) => r.company_id === i.company_id) ? (
              <MinusOutlined onClick={() => compare.removeCompany(r)} />
            ) : (
              <PlusOutlined
                style={{ color: compare.totalCompanies === 3 && '#d9d9d9' }}
                onClick={() => compare.addCompany(r)}
              />
            )
          }
        />
        <Checkbox
          checked={search.selectedCompanies.indexOf(r.company_id) !== -1}
          onChange={() => search.toggleSearchItem(r.company_id)}
        />
        <Link
          style={{ marginRight: 'auto', marginLeft: '1em' }}
          to={`/dashboard/company/${r.company_id}`}
        >
          {r.name}
        </Link>
        <div style={{ display: 'flex', gap: '1em' }}>
          {r.preferred_supplier && (
            <PreferredSupplierIcon className="search-icon" />
          )}
          {r.has_rate_card && <RateCardIcon className="search-icon" />}
          <span>{`(${r.match_list.length} hits)`}</span>
        </div>
      </div>
    ))
  return (
    <div className="result-wrapper">
      <Loading blockers={[SearchStoreCalls.SEARCH]}>
        <Loading.InProgress>
          <Skeleton active />
        </Loading.InProgress>
        <Loading.Done>
          {search.results.length > 0 ? (
            <>
              <div>
                <div className="item" style={{ background: '#fafafa' }}>
                  {intl.get('totalCompanies')}: {search.results.length}
                </div>
                <div className="results">{renderResults()}</div>
              </div>

              <SelectedResult
                result={search.selectedResult}
                search={search.searchTerm}
              />
            </>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Loading.Done>
      </Loading>
    </div>
  )
})
