import { Avatar, Button, Divider, Modal, Space, Statistic } from "antd";
import moment from "moment";
import Rating from "../../Rating";
import React, { useState } from "react";
import AmlDetailsItem from "./AmlDetailsItem";
import intl from "react-intl-universal";

export default function ({ showPreview, setShowPreview, aml }) {
  const [show, setShow] = useState(false)
  return (
    <Modal
      title="AML"
      visible={showPreview}
      footer={null}
      destroyOnClose={true}
      onCancel={() => setShowPreview(false)}
    >
      <Space style={{ width: '100%' }} direction={'vertical'}>
        <Statistic
          title={intl.get('date')}
          value={
            aml.created_date
              ? moment(aml.created_date).format('DD MMMM YYYY')
              : 'N/A'
          }
        />
        <Divider type="horizontal" />
        <Statistic
          title={intl.get('amlRating')}
          valueRender={() => <Rating score={aml.rating} />}
        />
        <Divider type="horizontal" />
        {Object.keys(aml.aml_summary).map((key) => (
          <Statistic
            title={<span style={{ textTransform: 'uppercase' }}>{key}</span>}
            valueRender={() => (
              <Avatar
                style={{
                  backgroundColor: '#80808045',
                  color:
                    aml.aml_summary[key] === 'HasMatches' ? 'red' : 'green',
                  verticalAlign: 'middle',
                  width: '100%',
                  textTransform: 'uppercase',
                }}
                shape="square"
                size="default"
              >
                <span>
                  {aml.aml_summary[key] === 'HasMatches'
                    ? intl.get('matches')
                    : intl.get('noMatches')}
                </span>
              </Avatar>
            )}
          />
        ))}

        {aml.details.length > 0 && (
          <>
            <Divider type="horizontal" />
            <Statistic
              title={
                <Button type="link" onClick={() => setShow((show) => !show)}>
                  {show ? 'Hide details' : 'Show details'}
                </Button>
              }
              valueRender={() => {
                return (
                  show &&
                  aml.details.map((item, ind) => (
                    <AmlDetailsItem item={item} ind={ind + 1} />
                  ))
                )
              }}
            />
          </>
        )}
      </Space>
    </Modal>
  )
}
