import React, { useContext } from 'react'
import { Formik } from 'formik'
import { action } from 'mobx'
import { Form, Select, SubmitButton } from 'formik-antd'
import { observer } from 'mobx-react'
import { CardContext } from '../index'
import intl from 'react-intl-universal'

export default observer(function ({ record }) {
  const { canEdit } = useContext(CardContext)
  const initialValue = {
    profile_travel_cost:
      typeof record.profile_travel_cost === 'undefined'
        ? ''
        : record.profile_travel_cost,
  }
  const change = (val, submit) => {
    submit()
  }
  return canEdit ? (
    <Formik
      initialValues={initialValue}
      enableReinitialize={true}
      onSubmit={async (vals, { setSubmitting }) => {
        action(() => {
          record.profile_travel_cost = vals.profile_travel_cost
        })()
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form style={{ position: 'relative' }}>
          <Form.Item style={{ margin: 0 }} name="profile_travel_cost">
            <Select
              name="profile_travel_cost"
              style={{ width: '100%' }}
              placeholder="Select"
              onChange={(val) => change(val, submitForm)}
            >
              <Select.Option value={true}>{intl.get('yes')}</Select.Option>
              <Select.Option value={false}>{intl.get('no')}</Select.Option>
            </Select>
          </Form.Item>
          {isSubmitting && (
            <SubmitButton
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                width: '100%',
                margin: 0,
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  ) : typeof record.profile_travel_cost === 'undefined' ? (
    intl.get('notSet')
  ) : record.profile_travel_cost ? (
    intl.get('yes')
  ) : (
    intl.get('no')
  )
})
