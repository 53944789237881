import React, { useContext } from 'react'
import { Card } from 'antd'
import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import * as Yup from 'yup'
import { CardContext } from '../index'
import intl from 'react-intl-universal'

export default function ({ desc }) {
  const { updComment, canEdit } = useContext(CardContext)
  const initialValues = {
    description: desc || '',
  }
  const validation = Yup.object().shape({
    description: Yup.string().max(1000, intl.get('nameErrorMax', { 0: 1000 })),
  })
  if (!canEdit && !desc) return null
  return (
    <Card type="inner" title={intl.get('commentColumn')}>
      {canEdit ? (
        <Formik
          validationSchema={validation}
          initialValues={initialValues}
          onSubmit={async (vals, { setSubmitting }) => {
            await updComment(vals)
            setSubmitting(false)
          }}
        >
          {() => (
            <Form>
              <Form.Item name="description">
                <Input.TextArea
                  name="description"
                  placeholder={intl.get('rateCardDescriptionPlaceholder')}
                />
              </Form.Item>
              <SubmitButton>{intl.get('add')}</SubmitButton>
            </Form>
          )}
        </Formik>
      ) : (
        desc || ''
      )}
    </Card>
  )
}
