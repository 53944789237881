import React, { useContext } from 'react'
import * as Survey from 'survey-react'
import 'survey-react/survey.css'
import { Button } from 'antd'
import intl from 'react-intl-universal'
import { PrinterOutlined } from '@ant-design/icons'
import { useReactToPrint } from 'react-to-print'
import { PrintContext } from '../../App'

let defaultThemeColors = Survey.StylesManager.ThemeColors['default']
defaultThemeColors['$main-color'] = '#4190F7'
defaultThemeColors['$main-hover-color'] = '#f59f47'
defaultThemeColors['$text-color'] = '#4a4a4a'
defaultThemeColors['$header-color'] = '#4190F7'

defaultThemeColors['$header-background-color'] = '#f8f8f8'
defaultThemeColors['$body-container-background-color'] = '#f8f8f8'

Survey.StylesManager.applyTheme()
export default function ({ mode = 'display', surveyCode, callback }) {
  const currentLocale = intl.determineLocale({
    localStorageLocaleKey: 'lang',
  })
  const survey = new Survey.Model(JSON.parse(surveyCode.survey_code.trim()))
  survey.ignoreValidation = mode === 'display'
  survey.mode = mode
  survey.locale = currentLocale === 'en-US' ? 'en' : 'de'
  survey.data = surveyCode.answers
  survey.showPreview()
  survey.onComplete.add((survey) => {
    callback(survey.data)
  })
  const { printRef } = useContext(PrintContext)
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })
  return (
    <div>
      <Button
        onClick={() => printRef.current && handlePrint()}
        style={{
          marginLeft: 5,
          cursor: 'pointer',
          color: '#038fde',
        }}
      >
        <PrinterOutlined />
        {intl.get('printSurvey')}
      </Button>
      {mode === 'display' && (
        <Button
          onClick={() => {
            if (survey.state === 'running') survey.showPreview()
            else survey.cancelPreview(0)
          }}
        >
          {survey.state === 'running' ? 'Show preview' : 'Cancel preview'}
        </Button>
      )}
      <Survey.Survey model={survey} ref={printRef} />
    </div>
  )
}
