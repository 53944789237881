import React, { useContext, useEffect, useState } from 'react'
import { FieldArray, Formik } from 'formik'
import { SubmitButton, Form, Select, Input } from 'formik-antd'
import { observer } from 'mobx-react'
import { Observer } from 'mobx-react-lite'
import StoreContext from '../../storeContext'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import * as Yup from 'yup'
import { ValidationFields } from '../../utils/validationFields'
import FileField from '../shared/FileField'
import intl from 'react-intl-universal'

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
  ],
}
const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
]
export default observer(function SendEmailForm({ ids, close }) {
  const { email } = useContext(StoreContext)
  const [companies, setCompanies] = useState([])
  const validation = Yup.object().shape({
    email_body: Yup.string().required(intl.get('messageRequired')),
    emails: Yup.array().of(
      Yup.object().shape({
        contact_email: ValidationFields.email(),
      }),
    ),
  })
  useEffect(() => {
    email.loadCompanies()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    setCompanies(email.companies)
  }, [email.companies])
  const initEmails = (idsArray) => {
    const temp = []
    if (email.companies.length > 0)
      email.companies.forEach((c) => {
        if (idsArray.includes(c.company_id)) temp.push(c)
      })
    return temp
  }
  const initialValues = {
    companies: ids,
    emails: initEmails(ids),
    email_body: '',
    email_subject: '',
    email_attachments: [],
    file_name: '',
  }

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validation}
      initialValues={initialValues}
      onSubmit={(vals) =>
        email
          .sendMail({
            email_body: vals.email_body,
            companies: vals.emails,
            email_attachments: vals.email_attachments,
            email_subject: vals.email_subject,
          })
          .then(() => close())
      }
    >
      {({ values, setFieldValue }) => (
        <Observer>
          {() => (
            <Form>
              <Form.Item
                style={{ marginLeft: 0, marginRight: 0 }}
                name="companies"
              >
                <Select
                  mode="multiple"
                  name="companies"
                  placeholder={intl.get('selectCompanies')}
                  onDropdownVisibleChange={(visible) => {
                    if (!visible) setCompanies(email.companies)
                  }}
                  filterOption={false}
                  onSearch={(txt) =>
                    setCompanies(
                      email.companies.filter((company) =>
                        company.name.toLowerCase().includes(txt.toLowerCase()),
                      ),
                    )
                  }
                  onChange={(e) => {
                    setFieldValue('emails', initEmails(e))
                  }}
                >
                  {companies.map((comp) => (
                    <Select.Option
                      key={comp.company_id}
                      value={comp.company_id}
                    >
                      {comp.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <FieldArray
                name="emails"
                render={() => (
                  <div>
                    {values.emails &&
                      values.emails.map((o, i) => (
                        <div key={i}>
                          <Form.Item
                            style={{ marginLeft: 0, marginRight: 0 }}
                            name={`emails.${i}.contact_email`}
                          >
                            <Input
                              placeholder={intl.get('usersTableColumnEmail')}
                              addonBefore={values.emails[i].name}
                              name={`emails.${i}.contact_email`}
                            />
                          </Form.Item>
                        </div>
                      ))}
                  </div>
                )}
              />
              <Form.Item
                style={{ marginLeft: 0, marginRight: 0 }}
                name="email_subject"
              >
                <Input
                  placeholder={intl.get('subject')}
                  addonBefore={intl.get('subject')}
                  name="email_subject"
                />
              </Form.Item>
              <Form.Item
                style={{ marginLeft: 0, marginRight: 0 }}
                name="email_attachments"
              >
                <FileField
                  maxSize={2}
                  errorMessage={intl.get('emailFileError')}
                  withName
                  onChange={(e) => {
                    setFieldValue('email_attachments', [e])
                  }}
                  onRemove={() => {
                    setFieldValue('email_attachments', [])
                  }}
                  uploadTitle={intl.get('addAttachemnt')}
                />
              </Form.Item>
              <Form.Item
                style={{ marginLeft: 0, marginRight: 0 }}
                name="email_body"
              >
                <div className="text-editor">
                  <ReactQuill
                    style={{ height: '20vh' }}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    onChange={(value, delta, source, editor) => {
                      if (editor.getLength() > 1) {
                        setFieldValue('email_body', value)
                      } else {
                        setFieldValue('email_body', '')
                      }
                    }}
                  />
                </div>
              </Form.Item>
              <SubmitButton style={{ marginTop: 40 }} disabled={false}>
                {intl.get('sendMail')}
              </SubmitButton>
            </Form>
          )}
        </Observer>
      )}
    </Formik>
  )
})
