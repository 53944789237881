import React from 'react'
import intl from 'react-intl-universal'

//#0f60ac;
const langConf = require('../utils/lang/lang-config').languages
export default function LanguageBar({ black }) {
  const currentLocale = intl.determineLocale({
    localStorageLocaleKey: 'lang',
  })
  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',
        cursor: 'pointer',
      }}
    >
      {langConf.map((language) => (
        <span
          key={language.code}
          onClick={() => {
            localStorage.setItem('lang', language.code)
            window.location.reload()
          }}
          style={{
            color:
              currentLocale === language.code
                ? '#0f60ac'
                : black
                ? 'black'
                : 'white',
          }}
        >
          {language.name}
        </span>
      ))}
    </div>
  )
}
