import { Modal } from 'antd'
import { Formik } from 'formik'
import { Form, Input, Select, SubmitButton } from 'formik-antd'
import React, { useContext } from 'react'
import { useParams } from 'react-router'
import StoreContext from '../../../../storeContext'
import * as Yup from 'yup'
import intl from 'react-intl-universal'
import { Countries } from '../../../../constants/dropdowns'

export default function (props) {
  const {
    visible,
    handleOnCancel,
    name,
    city,
    country,
    year_founded,
    employee_count,
    company_url = '',
    vat,
  } = props
  const company_id = useParams().id
  const { companyProfile } = useContext(StoreContext)
  const validator = Yup.object({
    year_founded: Yup.number().typeError(intl.get('yearNumberValidation')),
    employee_count: Yup.number().typeError(intl.get('employeeCountValidation')),
  })
  const initialValues = {
    company_id,
    company_basic_details: true,
    name,
    city,
    country,
    year_founded,
    employee_count,
    company_url,
    vat,
  }
  return (
    <Modal
      title={intl.get('editBasicInfo')}
      visible={visible}
      footer={null}
      onCancel={handleOnCancel}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validator}
        onSubmit={async (vals, { setSubmitting }) => {
          const temp = {}
          Object.keys(initialValues).forEach((key) => {
            if (props.hasOwnProperty(key)) {
              if (props[key] !== vals[key]) temp[key] = vals[key]
            } else {
              temp[key] = vals[key]
            }
          })
          await companyProfile.updateCompany(vals)
          setSubmitting(false)
          handleOnCancel()
        }}
      >
        {() => {
          return (
            <Form>
              <Form.Item name="name">
                <Input
                  addonBefore={intl.get('companyNameLabel')}
                  name="name"
                  placeholder={intl.get('companyNameLabel')}
                />
              </Form.Item>
              <Form.Item name="vat">
                <Input
                  addonBefore={intl.get('vatLabel')}
                  name="vat"
                  placeholder={intl.get('vatLabel')}
                />
              </Form.Item>
              <Form.Item name="company_url">
                <Input
                  addonBefore={intl.get('companyLink')}
                  name="company_url"
                  placeholder={intl.get('companyLink')}
                />
              </Form.Item>
              <Form.Item name="country">
                <Select
                  name="country"
                  placeholder={intl.get('selectCountryLabel')}
                >
                  {Countries.map((country) => (
                    <Select.Option value={country.value}>
                      {country.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="city">
                <Input
                  name="city"
                  addonBefore={intl.get('city')}
                  placeholder={intl.get('city')}
                />
              </Form.Item>
              <Form.Item name="year_founded">
                <Input
                  addonBefore={intl.get('founded')}
                  name="year_founded"
                  placeholder={intl.get('founded')}
                />
              </Form.Item>
              <Form.Item name="employee_count">
                <Input
                  addonBefore={intl.get('employees')}
                  name="employee_count"
                  placeholder={intl.get('employees')}
                />
              </Form.Item>
              <SubmitButton disabled={false}>{intl.get('save')}</SubmitButton>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
