import React, { useEffect, useRef, useState } from 'react'
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined'
import { Button, Modal } from 'antd'
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'

const { confirm } = Modal
export default function ActionButton({
  record,
  onClick,
  text,
  showModal,
  confirmTitle,
  type = '',
}) {
  const [action, setAction] = useState(false)
  const isLive = useRef(true)

  useEffect(() => {
    return () => {
      isLive.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function showConfirm() {
    confirm({
      title: confirmTitle,
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        setAction(true)
        await onClick()
        if (isLive.current) setAction(false)
      },
    })
  }
  return (
    <Button
      style={{ marginBottom: 0 }}
      disabled={action}
      type={type}
      onClick={async () => {
        if (showModal) {
          showConfirm()
          return
        }
        setAction(true)
        await onClick()
        setAction(false)
      }}
    >
      {action ? (
        <>
          <LoadingOutlined /> {text}
        </>
      ) : (
        text
      )}
    </Button>
  )
}
