import React, { useContext } from "react"
import {Avatar, Popover} from "antd";
import StoreContext from "../../storeContext"

const UserInfo = () => {
  const store = useContext(StoreContext)
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={()=>store.router.push('/dashboard/profile')}>My Account</li>
      <li onClick={store.user.logout}>Log out</li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
             trigger="click">
      <Avatar src='https://via.placeholder.com/150x150'
              className="gx-avatar gx-pointer" alt=""/>
    </Popover>
  )
};


export default UserInfo;
