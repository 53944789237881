import { Layout } from 'antd'
import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import { Observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import intl from 'react-intl-universal'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import loginBackground from '../../assets/images/pozadinaHD.png'
import StoreContext from '../../storeContext'
import { ValidationFields } from '../../utils/validationFields'
import LanguageBar from '../LanguageBar'

const { Content } = Layout
export default function LogIn() {
  const { user } = useContext(StoreContext)
  useEffect(() => {
    user.getTerms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const initialValues = {
    username: '',
    password: '',
  }
  const validator = Yup.object({
    username: ValidationFields.email(),
    password: ValidationFields.password(),
  })
  return (
    <Layout
      style={{
        height: '100%',
        background: `url(${loginBackground})`,
        backgroundSize: 'cover',
      }}
    >
      {/* <Topbar showCommands={false} /> */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 10%',
          minHeight: '60px',
        }}
      >
        <img
          alt=""
          width="140"
          src={require('../../assets/images/LogoSim.svg')}
        />
        <LanguageBar />
      </div>

      <Content>
        <div
          className="gx-login-container"
          style={{
            overflow: 'auto',
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h1 style={{ color: 'white' }}>{intl.get('signUpTitleOne')}</h1>
            <h1 style={{ color: 'white' }}>{intl.get('signUpTitleTwo')}</h1>
          </div>
          <div className="gx-login-content">
            <div className="gx-login-header gx-text-center">
              <h1 className="gx-login-title">{intl.get('loginTitle')}</h1>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validator}
              onSubmit={(values, { setSubmitting, setFieldError }) => {
                user.logIn(values, setSubmitting, setFieldError)
              }}
            >
              {({ errors }) => (
                <Observer>
                  {() => (
                    <Form>
                      <Form.Item name="username">
                        <Input
                          name="username"
                          placeholder={intl.get('emailLabel')}
                        />
                      </Form.Item>
                      <Form.Item name="password">
                        <Input.Password
                          name="password"
                          placeholder={intl.get('passwordLabel')}
                        />
                      </Form.Item>
                      {errors['asyncError'] && (
                        <div className="ant-row ant-form-item ant-form-item-with-help ant-form-item-has-error">
                          <div className="ant-col ant-form-item-control">
                            <div className="ant-form-item-explain">
                              <div>
                                <li>{intl.get(errors['asyncError'])}</li>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="ant-row">
                        <SubmitButton style={{ width: '100%' }}>
                          {intl.get('loginButtonText')}
                        </SubmitButton>
                      </div>
                      <div className="ant-row">
                        <span>
                          <Link to="/sign-up">
                            {intl.get('loginSignUpLinkText')}
                          </Link>
                        </span>
                      </div>
                      <div className="ant-row">
                        <span>
                          <Link to="/reset">
                            {intl.get('loginResetPasswordLinkText')}
                          </Link>
                        </span>
                      </div>
                      <div className="ant-row">
                        <span>
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={user.termsOfUse}
                          >
                            {intl.get('acceptTermsLinkText')}
                          </a>
                        </span>
                      </div>
                    </Form>
                  )}
                </Observer>
              )}
            </Formik>
          </div>
        </div>
      </Content>
    </Layout>
  )
}
