import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import StoreContext from '../../../../storeContext'
import { Tag } from 'antd'
import MinusCircleOutlined from '@ant-design/icons/lib/icons/MinusCircleOutlined'
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined'

export default observer(function () {
  const { companyProfile } = useContext(StoreContext)
  const renderTrends = () => {
    return (
      companyProfile.company.trend_expertise &&
      companyProfile.company.trend_expertise.map((trend) => {
        return (
          trend.trend_expertise_items.length > 0 && <TrendGroup trend={trend} />
        )
      })
    )
  }
  return renderTrends()
})

const TrendGroup = ({ trend }) => {
  const [show, setShow] = useState(false)

  return (
    <>
      <Tag
        style={{
          color: '#fafafa',
          background: '#0061bb',
          borderColor: '#0061bb',
        }}
        onClick={() => setShow((show) => !show)}
      >
        <span>
          {`${trend.trend_area}(${trend.trend_expertise_items.length}) `}{' '}
        </span>
        {trend.trend_expertise_items.length > 0 && show ? (
          <MinusCircleOutlined style={{ fontSize: 13 }} />
        ) : (
          <PlusCircleOutlined style={{ fontSize: 13 }} />
        )}
      </Tag>

      {show &&
        trend.trend_expertise_items.map((g, i) => {
          return (
            <Tag
              key={g}
              style={{
                color: ' #0061bb',
                background: ' #fafafa00',
                borderColor: '#0061bb52',
              }}
            >
              {g}
            </Tag>
          )
        })}
    </>
  )
}
