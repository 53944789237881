import { useState } from 'react'
import { CompaniesOverviewTabs } from '../components/CompaniesOverview/store/CompaniesOverviewStore'

export default function useRowSelect(tabKey) {
  const [selectedRowKeys, setSelected] = useState([])
  const onSelectChange = (selectedRowKeys) => {
    setSelected(selectedRowKeys)
  }

  const reset = () => {
    setSelected([])
  }

  const getSelection = () =>
    tabKey === CompaniesOverviewTabs.API_IMPORT && {
      selectedRowKeys,
      onChange: onSelectChange,
      reset,
    }

  return getSelection()
}
