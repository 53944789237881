import { StoreBase } from '../../../store/StoreBase'
import { NetworkCall } from '../../../store/models/NetworkModels'
import { decorate, flow, observable } from 'mobx'

export const CompanyListsCalls = Object.freeze({
  COMPANY_LIST: Object.freeze('companyList'),
})
export class CompanyListsStore extends StoreBase {
  lists = []
  companies = []
  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
    this.companyListCall = new NetworkCall(rootStore, {
      path: 'companylists',
      secured: true,
      id: CompanyListsCalls.COMPANY_LIST,
    })
  }

  companyList = flow(function* (data, type) {
    if (this.companyListCall.callInProgress) return
    const res = yield this.companyListCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          if (type === 'edit') {
            this.lists = this.lists.map((list) => {
              if (list.company_list_id === data.edit.company_list_id)
                return { ...list, ...result.data.company_lists }
              return list
            })
            //this.getListCompanies(data.edit.company_list_id)
          }
          if (type === 'add') {
            /* this.lists.push(result.data.company_lists) */
            //this.getListCompanies(data.edit.company_list_id)
          }
          if (type === 'remove') {
            this.lists = this.lists.filter(
              (list) => list.company_list_id !== data.remove.company_list_id,
            )
          }
          if (type === 'removeCompany') {
            this.lists = this.lists.map((list) => {
              if (list.company_list_id === data.edit.company_list_id)
                list.companies = list.companies.filter(
                  (company) =>
                    company.company_id !== data.edit.companies_remove[0],
                )
              return list
            })
          }
          break
        default:
      }
    })
  })

  getCompanies = flow(function* (data) {
    const res = yield this.rootStore.search.searchCall.call(
      'get',
      null,
      `?search_keyword=${''}`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.companies = result.data.companies
          break
        case 400:
          break
        default:
      }
    })
  })

  getListCompanies = flow(function* (data) {
    if (this.companyListCall.callInProgress) return
    const res = yield this.companyListCall.call('get', null, `?list_id=${data}`)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.lists = this.lists.map((list) => {
            if (list.company_list_id === data) {
              list = result.data.company_lists
            }
            return list
          })
          break
        default:
      }
    })
  })

  getCompanyList = flow(function* () {
    const res = yield this.companyListCall.call('get')
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.lists = result.data.company_lists
          break
        default:
      }
    })
  })
}

decorate(CompanyListsStore, {
  lists: observable,
  companies: observable,
})
