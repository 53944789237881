import React from 'react'
import { getValidLink } from '../../../../utils/helpers'
import intl from 'react-intl-universal'
import { Card } from 'antd'
import {
  AnnualReportIcon,
  FinancialReportIcon,
  MediaReleaseIcon,
} from '../../../shared/Icons'

const HyperLink = ({ link, icon, name }) => {
  return (
    <a href={getValidLink(link)} rel="noopener noreferrer" target="_blank">
      <div
        style={{
          fontSize: '1.5em',
          gap: '0.5em',
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row-reverse',
        }}
      >
        {name}
        {icon}
      </div>
    </a>
  )
}

export default function ({ links }) {
  return (
    <Card bordered={false}>
      {links && (
        <div>
          {links.media_release_url && (
            <HyperLink
              link={links.media_release_url}
              icon={<MediaReleaseIcon />}
              name={intl.get('mediaRelease')}
            />
          )}
          {links.annual_report_url && (
            <HyperLink
              link={links.annual_report_url}
              icon={<AnnualReportIcon />}
              name={intl.get('annualReport')}
            />
          )}
          {links.financial_report_url && (
            <HyperLink
              link={links.financial_report_url}
              icon={<FinancialReportIcon />}
              name={intl.get('financialReport')}
            />
          )}
        </div>
      )}
    </Card>
  )
}
