import { Divider, Modal, Space, Statistic, Typography } from "antd";
import Rating from "../../Rating";
import React from "react";

const { Paragraph } = Typography
export default function ({ showPreview, setShowPreview, country }) {
  return (
    <Modal
      title="Country risk rating"
      visible={showPreview}
      footer={null}
      destroyOnClose={true}
      onCancel={() => setShowPreview(false)}
    >
      <Space style={{ width: '100%' }}  direction={'vertical'}>
        <Statistic title="Value" value={country.risk_value || 'N/A'} />
        <Divider type="horizontal" />
        <Statistic
          title="Titan rating"
          valueRender={() => <Rating score={country.rating} />}
        />
        <Typography>
          <Paragraph>
            The Basel AML Index is an independent annual ranking that assesses
            the risk of money laundering and terrorist financing (ML/TF) around
            the world.
          </Paragraph>
          <Paragraph>
            Published by the Basel Institute on Governance since 2012, it
            provides risk scores based on data from 16 publicly available
            sources such as the Financial Action Task Force (FATF), Transparency
            International, the World Bank and the World Economic Forum. The risk
            scores cover five domains:
          </Paragraph>
          <Paragraph>
            <ul>
              <li>
                <span>Quality of AML/CFT Framework</span>
              </li>
              <li>
                <span>Bribery and Corruption</span>
              </li>
              <li>
                <span>Financial Transparency and Standards</span>
              </li>
              <li>
                <span>Public Transparency and Accountability</span>
              </li>
              <li>
                <span>Legal and Political Risks</span>
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            The Public Edition of the Basel AML Index 2020 covers 141 countries
            with sufficient data to calculate a reliable ML/TF risk score.
          </Paragraph>
        </Typography>
      </Space>
    </Modal>
  )
}
