import { StoreBase } from '../../../store/StoreBase'
import { NetworkCall } from '../../../store/models/NetworkModels'
import { decorate, flow, observable } from 'mobx'
import intl from 'react-intl-universal'

const MyRequestsCalls = Object.freeze({
  LOAD_REQUESTS: 'loadRequests',
})

export class MyRequestsStore extends StoreBase {
  requests = []
  requestTypes = []
  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
    this.loadRequestsCall = new NetworkCall(rootStore, {
      id: MyRequestsCalls.LOAD_REQUESTS,
      secured: true,
      path: 'myrequests',
    })
  }

  loadRequests = flow(function* () {
    if (this.loadRequestsCall.callInProgress) return
    const res = yield this.loadRequestsCall.call('get', null)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.requests = result.data.requests_list
          const types = []
          result.data.requests_list.forEach((t) => {
            if (t.request_type === 'contract_folder_change') console.log(t)
            if (!types.includes(t.request_type)) {
              types.push(t.request_type)
            }
          })
          this.requestTypes = types.map((t) => ({ value: t, selected: true }))
          break
        default:
      }
    })
  })
  archiveRequest = flow(function* (data) {
    const res = yield this.loadRequestsCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.loadRequests()
          if (data.resend_request) {
            this.rootStore.toast.setNotification(
              {
                message: intl.get('requestResended'),
                description: '',
                placement: 'topRight',
              },
              'success',
            )
          }
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              description: '',
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })
}

decorate(MyRequestsStore, {
  requests: observable,
  requestTypes: observable,
})
