import React from 'react'
import { Formik } from 'formik'
import { Checkbox, Form, Input, Select, SubmitButton } from 'formik-antd'
import intl from 'react-intl-universal'
import * as Yup from 'yup'
import FileField from '../../../../shared/FileField'
import { DocumentType } from '../../../../../constants/dropdowns'

export default function ({ contract_folder_id, close, update, company_id }) {
  const initialValues = {
    company_id,
    add_document: {
      contract_folder_id,
      document_file: '',
      document_name: '',
      document_type: '',
      is_confidential: false,
      description: '',
      contract_no: '',
      po_number: '',
    },
  }

  const validation = Yup.object().shape({
    add_document: Yup.object().shape({
      document_name: Yup.string().required(intl.get('documentNameRequired')),
      document_file: Yup.string().required(intl.get('documentFileRequired')),
      document_type: Yup.string().required(intl.get('documentTypeRequired')),
      description: Yup.string().max(200, intl.get('nameErrorMax', { 0: 200 })),
    }),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={async (vals) => {
        await update(vals, 'add_document')
        close()
      }}
    >
      {({ setFieldValue, errors }) => {
        return (
          <Form>
            <Form.Item name="add_document.document_type">
              <Select
                name="add_document.document_type"
                placeholder={intl.get('selectDocumentType')}
                onChange={(val, option) =>
                  setFieldValue('add_document.document_name', option.children)
                }
              >
                {DocumentType.map((type) => {
                  return (
                    <Select.Option value={type.value}>
                      {type.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item name="add_document.document_name">
              <Input
                name="add_document.document_name"
                addonBefore={intl.get('documentName')}
              />
            </Form.Item>
            <Form.Item name="add_document.contract_no">
              <Input
                name="add_document.contract_no"
                addonBefore={intl.get('contractNumber')}
              />
            </Form.Item>
            <Form.Item name="add_document.po_number">
              <Input
                name="add_document.po_number"
                addonBefore={intl.get('purchaseOrderNumber')}
              />
            </Form.Item>
            <Form.Item name="add_document.is_confidential">
              <Checkbox name="add_document.is_confidential">
                {intl.get('confidential')}
              </Checkbox>
            </Form.Item>
            <Form.Item name="add_document.document_file">
              <FileField
                alowedType={['application/pdf']}
                maxSize={19}
                onRemove={() => setFieldValue('add_document.document_file', '')}
                onChange={(file) =>
                  setFieldValue('add_document.document_file', file)
                }
                uploadTitle={intl.get('selectDocumentFile')}
                errorMessage={intl.get('documentFiIeError')}
              />
            </Form.Item>
            <Form.Item name="add_document.description">
              <Input.TextArea
                name="add_document.description"
                addonBefore="Description"
                placeholder={intl.get('description')}
              />
            </Form.Item>
            <SubmitButton>{intl.get('add')}</SubmitButton>
          </Form>
        )
      }}
    </Formik>
  )
}
