import { StoreBase } from './StoreBase'
import { action, decorate, flow, observable } from 'mobx'
import { NetworkCall } from './models/NetworkModels'
import decode from 'jwt-decode'
import intl from 'react-intl-universal'

export const UserStoreCalls = Object.freeze({
  CREATE_USER: Object.freeze('createUser'),
  CONFIRM_REGISTRATION: Object.freeze('confirmRegistration'),
  LOGIN: Object.freeze('login'),
  RESET_PASSWORD: Object.freeze('resetPassword'),
  CONFIRM_RESET_PASSWORD: Object.freeze('confirmResetPassword'),
  RESEND_REGISTRATION_CONFIRMATION_CODE: Object.freeze(
    'resendRegistrationConfirmationCode',
  ),
  LOAD_INVITE_DATA: Object.freeze('loadInviteData'),
  GET_TERMS_OF_USE: Object.freeze('getTermsOfUse'),
  UPDATE_TERMS: Object.freeze('updateTerms'),
})

export default class UserStore extends StoreBase {
  user = { 'custom:role_id': '0' }
  termsOfUse = ''
  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
    this.checkToken()
    this.createUserCall = new NetworkCall(rootStore, {
      path: 'createuser',
      secured: true,
      id: UserStoreCalls.CREATE_USER,
    })

    this.confirmRegistrationCall = new NetworkCall(rootStore, {
      path: 'confirmregistration',
      secured: true,
      id: UserStoreCalls.CREATE_USER,
    })

    this.loginCall = new NetworkCall(rootStore, {
      path: 'login',
      secured: true,
      id: UserStoreCalls.LOGIN,
    })

    this.resetPasswordCall = new NetworkCall(rootStore, {
      path: 'resetpassword',
      secured: true,
      id: UserStoreCalls.RESET_PASSWORD,
    })

    this.confirmResetPasswordCall = new NetworkCall(rootStore, {
      path: 'changepassword',
      secured: true,
      id: UserStoreCalls.CONFIRM_RESET_PASSWORD,
    })

    this.resendRegistrationConfirmationCodeCall = new NetworkCall(rootStore, {
      path: 'resendverification',
      secured: true,
      id: UserStoreCalls.RESEND_REGISTRATION_CONFIRMATION_CODE,
    })
    this.loadInviteDataCall = new NetworkCall(rootStore, {
      path: 'inviteinfo',
      secured: false,
      id: UserStoreCalls.LOAD_INVITE_DATA,
    })
    this.getTermsCall = new NetworkCall(rootStore, {
      path: 'activeterms?terms_type=terms_of_use',
      secured: false,
      id: UserStoreCalls.GET_TERMS_OF_USE,
    })
  }

  checkToken = () => {
    const token = localStorage.getItem('token')
    if (token) {
      this.user = decode(token)
    }
  }

  logout = () => {
    localStorage.setItem('token', '')
    this.user = { 'custom:role_id': '0' }
  }

  getTerms = flow(function* () {
    if (this.getTermsCall.callInProgress) return
    const res = yield this.getTermsCall.call('get')
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.termsOfUse = result.data.message.terms_url
          break
        default:
      }
    })
  })

  logIn = flow(function* (values, setSubmitting, setFieldError) {
    if (this.loginCall.callInProgress) return

    const res = yield this.loginCall.call('post', values)

    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.checkToken()
          this.rootStore.router.replace(`/dashboard`)
          break
        case 400:
        case 500:
          setSubmitting(false)
          setFieldError('asyncError', result.data.body)
          break
        default:
          setSubmitting(false)
          setFieldError('asyncError', 'exception')
      }
    })
  })

  confirmRegistration = flow(function* (values, setSubmitting, setFieldError) {
    if (this.confirmRegistrationCall.callInProgress) {
      return
    }
    const res = yield this.confirmRegistrationCall.call('post', values)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.router.replace(`/confirm-success`)
          break
        case 201:
          this.rootStore.router.replace('/confirm-success?invited=true')
          break
        case 400:
        case 500:
          setSubmitting(false)
          setFieldError('asyncError', result.data.body)
          break
        default:
          setSubmitting(false)
          setFieldError('asyncError', 'exception')
      }
    })
  })

  signUp = flow(function* signUp(values, setSubmitting, setFieldError) {
    if (this.createUserCall.callInProgress) {
      return
    }
    const res = yield this.createUserCall.call('post', values)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.router.replace(`/confirm?email=${values.user.email}`)
          break
        case 400:
          setSubmitting(false)
          setFieldError('asyncError', result.data.body)
          break
        default:
          setSubmitting(false)
          setFieldError('asyncError', 'exception')
      }
    })
  })

  resetPassword = flow(function* (values, setSubmitting, setFieldError) {
    if (this.resetPasswordCall.callInProgress) return
    const res = yield this.resetPasswordCall.call('post', values)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.router.replace(
            `/confirm-reset-password?email=${values.username}`,
          )
          break
        case 400:
        case 500:
          setSubmitting(false)
          setFieldError('asyncError', result.data.body)
          break
        default:
          setSubmitting(false)
          setFieldError('asyncError', 'exception')
      }
    })
  })

  confirmResetPassword = flow(function* (values, setSumbiting, setFieldError) {
    if (this.confirmResetPasswordCall.callInProgress) return
    const res = yield this.confirmResetPasswordCall.call('post', values)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.router.replace('/login')
          this.rootStore.toast.setNotification(
            {
              message: intl.get('resetPasswordSuccessMessage'),
              description: '',
              placement: 'topRight',
            },
            'success',
          )
          break
        case 500:
        case 400:
          setSumbiting(false)
          setFieldError('asyncError', result.data.body)
          break
        default:
          setSumbiting(false)
          setFieldError('asyncError', 'exception')
      }
    })
  })

  resendRegistrationConfirmationCode = flow(function* (email) {
    if (this.resendRegistrationConfirmationCodeCall.callInProgress) return
    const res = yield this.resendRegistrationConfirmationCodeCall.call('post', {
      username: email,
    })
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message: 'Resend code success',
              description: intl.get(result.data.body),
              placement: 'topRight',
            },
            'success',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: 'Resend code error',
              description: intl.get(result.data.body),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  loadInviteData = flow(function* (data) {
    if (this.loadInviteDataCall.callInProgress) return
    const res = yield this.loadInviteDataCall.call('post', data)
    let returnData = null
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          returnData = result.data.body
          break
        case 400:
          this.rootStore.toast.setNotification(
            {
              message: 'Invite error',
              description: intl.get(result.data.body),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: 'Invite error',
              description: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
    return returnData
  })
}

decorate(UserStore, {
  loggedIn: observable,
  logIn: action,
  user: observable,
  checkToken: action,
  logout: action,
  termsOfUse: observable,
})
