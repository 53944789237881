import React from 'react'

export default function ({ record }) {
  return record ? (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>Score date: {record.failureScore.scoreDate}</span>
      <span>Raw score: {record.failureScore.rawScore}</span>
      <span>National percentile: {record.failureScore.nationalPercentile}</span>
      <span>
        Risk incidence percentage: {record.failureScore.riskIncidencePercentage}
      </span>
    </div>
  ) : (
    'No data'
  )
}
