import React, { useContext, useRef, useState } from "react";
import StoreContext from "../../../storeContext";
import { CSVLink } from "react-csv";
import { Button } from "antd";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";

export default function ({ record }) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const link = useRef()
  const { survey } = useContext(StoreContext)
  const download = async () => {
    setLoading(true)
    await survey.surveyExport(record.survey_id, setData)
    if (link.current) link.current.link.click()
    setLoading(false)
  }
  return (
    <>
      <CSVLink
        ref={link}
        data={data}
        style={{ display: 'none' }}
        filename={`${record.survey_name}.csv`}
        asyncOnClick={true}
      />
      <Button disabled={loading} onClick={download} type="primary">
        Export {loading && <LoadingOutlined />}
      </Button>
    </>
  )
}
