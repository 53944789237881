import React, { useContext, useRef } from 'react'
import { Formik } from 'formik'
import { Form, Input } from 'formik-antd'
import * as Yup from 'yup'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { CardContext } from '../index'
import NumberFormat from 'react-number-format'

export default React.memo(
  observer(function ({ record, name }) {
    const { canEdit } = useContext(CardContext)

    const wrapp = useRef()
    const initialValue = {
      [name]: record[name] || '',
    }

    const validation = Yup.object().shape({
      [name]: Yup.number().typeError('must be a number'),
    })

    return (
      <div ref={wrapp}>
        {canEdit ? (
          <Formik
            validationSchema={validation}
            initialValues={initialValue}
            enableReinitialize={true}
            onSubmit={async (vals) => {}}
          >
            {({ isSubmitting, values, errors, setFieldValue }) => (
              <Form style={{ position: 'relative' }}>
                <Form.Item style={{ margin: 0 }} name={name}>
                  <NumberFormat
                    name={name}
                    value={values[name]}
                    customInput={Input}
                    onBlur={() => {
                      if (errors[name]) return
                      action(() => {
                        record[name] = values[name]
                      })()
                    }}
                    onValueChange={(values) => {
                      setFieldValue(name, values.value)
                    }}
                    thousandSeparator={true}
                  />
                </Form.Item>
              </Form>
            )}
          </Formik>
        ) : (
          record[name] || ''
        )}
      </div>
    )
  }),
)
