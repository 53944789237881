import React, { useContext, useEffect } from 'react'
import { Card, Col,  Row,  Table } from 'antd'
import { observer } from 'mobx-react'
import moment from 'moment'
import StoreContext from '../../storeContext'
import intl from 'react-intl-universal'

export default observer(function TermsTable(props) {
  const { terms } = useContext(StoreContext)
  useEffect(() => {
    terms.getAllTermsOfType(props.type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type])
  const cols = [
    {
      title: intl.get('columnVersion'),
      dataIndex: 'file_name',
      key: 'verison',
    },
    {
      title: intl.get('columnUploadDate'),
      dataIndex: 'created_date',
      key: 'created_date',
      render: (date) => <span>{moment(date).format('lll')}</span>,
    },
    {
      title: intl.get('columnPublishDate'),
      dataIndex: 'publish_date',
      key: 'publish_date',
      render: (date) => <span>{moment(date).format('lll')}</span>,
    },
    {
      title: intl.get('columnDownload'),
      dataIndex: 'terms_url',
      key: 'terms_url',
      render: (text, record) => (
        <a href={text} rel="noopener noreferrer" target="_blank">
          Version {record.critical}.{record.major}.{record.minor}
        </a>
      ),
    },
  ]
  return (
    <Card>
      <Row>
        <Col span="24">
          <Table
            rowKey="terms_id"
            columns={cols}
            dataSource={terms[props.type]}
            pagination={{
              total: terms[props.type].length,
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '20', '50', '100'],
            }}
          />
        </Col>
      </Row>
    </Card>
  )
})
