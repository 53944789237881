import { StoreBase } from '../../../store/StoreBase'
import { NetworkCall } from '../../../store/models/NetworkModels'
import { action, decorate, flow, observable } from 'mobx'
import { Roles } from '../../../utils/roles'
import intl from 'react-intl-universal'
import React from 'react'

export const UsersStoreCalls = Object.freeze({
  GET_USERS: Object.freeze('getUsers'),
  APPROVE_USER: Object.freeze('approveUser'),
  INVITE_USERS: Object.freeze('inviteUsers'),
})

export class UsersStore extends StoreBase {
  users = []
  invitedUsers = []
  filteredUsers = []
  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore

    this.getUsersListCall = new NetworkCall(rootStore, {
      path: 'listusers',
      secured: true,
      id: UsersStoreCalls.GET_USERS,
    })
    this.approveUserCall = new NetworkCall(rootStore, {
      path: 'updateuser',
      secured: true,
      id: UsersStoreCalls.APPROVE_USER,
    })
    this.inviteUsersCall = new NetworkCall(rootStore, {
      path: 'inviteusers',
      secured: true,
      id: UsersStoreCalls.INVITE_USERS,
    })
  }

  inviteUsers = flow(function* (data) {
    if (this.inviteUsersCall.callInProgress) return
    const res = yield this.inviteUsersCall.call('post', { invites: data })
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message:
                result.data.invite_count > 0
                  ? intl.get('successInviteUsersTitle', {
                      0: result.data.invite_count,
                    })
                  : intl.get('inviteUsersErrorTitle'),
              description: (
                <div>
                  {result.data.already_invited.length > 0 && (
                    <div>
                      <h3>{intl.get('alreadyInvited')}</h3>
                      <ul>
                        {result.data.already_invited.map((u) => {
                          return <li key={u}>{u}</li>
                        })}
                      </ul>
                    </div>
                  )}
                  {result.data.wrong_domain.length > 0 && (
                    <div>
                      <h3>{intl.get('wrongDomain')}</h3>
                      <ul>
                        {result.data.wrong_domain.map((u) => {
                          return <li key={u}>{u}</li>
                        })}
                      </ul>
                    </div>
                  )}
                  {result.data.already_registered.length > 0 && (
                    <div>
                      <h3>{intl.get('alreadyRegistered')}</h3>
                      <ul>
                        {result.data.already_registered.map((u) => {
                          return <li key={u}>{u}</li>
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              ),
              placement: 'topRight',
              duration: 0,
            },
            result.data.invite_count > 0 ? 'success' : 'error',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('inviteUsersErrorTitle'),
              description: res.data.message,
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  getUsersList = flow(function* () {
    if (this.getUsersListCall.callInProgress) return
    const res = yield this.getUsersListCall.call('get')
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.users = result.data.users
          this.filteredUsers = result.data.users
          this.invitedUsers = result.data.invited_users
          break
        case 401:
        case 400:
        case 500:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  approveUser = flow(function* (data, type) {
    if (this.approveUserCall.callInProgress) return
    const res = yield this.approveUserCall.call('post', {
      email: data.email,
      user_id: data.user_id,
      [type]: data[type] === '1' ? '0' : '1',
    })
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message:
                data[type] === '0'
                  ? intl.get('userApprovedSuccessTitle')
                  : intl.get('userDisabledSuccessTitle'),
              description:
                data[type] === '0' &&
                intl.get('userApprovedSuccessText', { 0: data.email }),
              placement: 'topRight',
            },
            'success',
          )
          break
        case 400:
        case 500:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('userApprovedError'),
              description: res.data.body,
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  searchUsers = (search) => {
    this.filteredUsers = this.users.filter((user) => {
      const allValues = Object.values(user).join(' ').toLowerCase()
      return allValues.includes(search.toLowerCase())
    })
  }
  filterUsers = (filter) => {
    if (filter === 'allUsers') this.filteredUsers = [...this.users]
    if (filter === 'pendingUsers') {
      this.filteredUsers = this.users.filter((user) => {
        if (
          this.rootStore.user.user['custom:role_id'] === Roles.TITAN_ADMIN ||
          this.rootStore.user.user['custom:role_id'] ===
            Roles.TITAN_MASTER_ADMIN
        ) {
          return user.titan_approved === '0'
        } else {
          return user.client_admin_approved === '0'
        }
      })
    }
    if (filter === 'titanUsers') {
      this.filteredUsers = this.users.filter((user) => {
        return (
          user.role_id === Roles.TITAN_ADMIN ||
          user.role_id === Roles.TITAN_MASTER_ADMIN
        )
      })
    }
    if (filter === 'disabledUsers') {
      this.filteredUsers = this.users.filter((user) => {
        return user.titan_approved === '0' || user.client_admin_approved === '0'
      })
    }
  }
}

decorate(UsersStore, {
  users: observable,
  filteredUsers: observable,
  invitedUsers: observable,
  getUsersList: action,
  filterUsers: action,
  searchUsers: action,
  approveUser: action,
})
