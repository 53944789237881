import React, { useContext } from "react";
import { Popover } from "antd";
import StoreContext from "../../storeContext";
import { observer } from "mobx-react";
import UserLogo from "../../components/UserLogo";

const UserProfile = observer(() => {
  const store = useContext(StoreContext)
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={()=>store.router.history.push('/dashboard/profile')}>My Account</li>
      <li onClick={()=>store.router.history.push(`/dashboard/company/${store.user.user.company_id}`)}>My company</li>
      <li onClick={store.user.logout}>Log out</li>
    </ul>
  )

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <UserLogo id={store.user.user && store.user.user['cognito:username']} size="40" />
        <span className="gx-avatar-name">
          {store.user.user ? store.user.user.name : 'NOT LOGED'}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  )
})

export default UserProfile
