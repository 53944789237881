import React from 'react'
import { action, decorate, observable } from 'mobx'
import { StoreBase } from './StoreBase'
import { EVENTS, STATUS } from 'react-joyride'

export default class JoyrideStore extends StoreBase {
  running = false
  steps = [
    {
      content: <h2>Welcome to Add/Manage Users tutorial</h2>,
      placement: 'center',
      target: 'body',
    },
    {
      content: <h2>To invite/manage users click on User menu item</h2>,
      placement: 'top',
      target: '#dashboard_menu_users',
    },
    {
      content: <h2>You can add signle used by clicking on this tab</h2>,
      placement: 'top',
      target: '.ant-tabs-tab:nth-of-type(1)',
    },
    {
      content: (
        <h2>...or you can add multiple users on this tab, by uploading csv</h2>
      ),
      placement: 'top',
      target: '.ant-tabs-tab:nth-of-type(2)',
    },
    {
      content: <h2>Here you can view and manage users</h2>,
      placement: 'top',
      target: '#users_table',
    },
    {
      content: <h2>You can filter users by status</h2>,
      placement: 'top',
      target: '#users_table_filter-users',
    },
    {
      content: <h2>...and search users</h2>,
      placement: 'top',
      target: '#users_table_search-users',
    },
    {
      content: <h2>To view user profile click on user name</h2>,
      placement: 'top',
      target: '.ant-table-row:nth-of-type(1)>td:nth-of-type(1)',
    },
    {
      content: <h2>To view Enable/Disable user click action button</h2>,
      placement: 'top',
      target: '.ant-table-row:nth-of-type(1)>td:last-of-type',
    },
  ]
  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
  }

  run() {
    this.steps = [
      {
        content: <h2>Welcome to Add/Manage Users tutorial</h2>,
        placement: 'center',
        target: 'body',
      },
      {
        content: <h2>To invite/manage users click on User menu item</h2>,
        placement: 'top',
        target: '#dashboard_menu_users',
      },
      {
        content: <h2>You can add signle used by clicking on this tab</h2>,
        placement: 'top',
        target: '.ant-tabs-tab:nth-of-type(1)',
      },
      {
        content: (
          <h2>
            ...or you can add multiple users on this tab, by uploading csv
          </h2>
        ),
        placement: 'top',
        target: '.ant-tabs-tab:nth-of-type(2)',
      },
      {
        content: <h2>Here you can view and manage users</h2>,
        placement: 'top',
        target: '#users_table',
      },
      {
        content: <h2>You can filter users by status</h2>,
        placement: 'top',
        target: '#users_table_filter-users',
      },
      {
        content: <h2>...and search users</h2>,
        placement: 'top',
        target: '#users_table_search-users',
      },
      {
        content: <h2>To view user profile click on user name</h2>,
        placement: 'top',
        target: '.ant-table-row:nth-of-type(1)>td:nth-of-type(1)',
      },
      {
        content: <h2>To view Enable/Disable user click action button</h2>,
        placement: 'top',
        target: '.ant-table-row:nth-of-type(1)>td:last-of-type',
      },
    ]
    this.running = true
  }
  stop() {
    this.running = false
  }
  callback = (data) => {
    const { status, type, step } = data
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]
    if (finishedStatuses.includes(status)) {
      this.stop()
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      switch (step.target) {
        case '#dashboard_menu_users':
          this.rootStore.router.push('/dashboard/users')
          break
        default:
      }
    }
  }
}
decorate(JoyrideStore, {
  running: observable,
  steps: observable,
  callback: action,
  run: action,
  stop: action,
})
