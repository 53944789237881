import React, { useContext, useState } from 'react'
import { Button, Modal } from 'antd'
import { Form, Select, SubmitButton } from 'formik-antd'
import intl from 'react-intl-universal'
import { Formik } from 'formik'
import StoreContext from '../../storeContext'
import { observer } from 'mobx-react'
import * as Yup from 'yup'

export default observer(function ({ record }) {
  const { companiesImport, generalCriteria } = useContext(StoreContext)
  const [show, setShow] = useState(false)
  const validation = Yup.object({
    industry_codes: Yup.array().required(
      'At least one industry code is required',
    ),
  })
  const renderCodes = () =>
    generalCriteria.codes.map((code) => {
      return (
        <Select.Option
          value={code.code}
        >{`${code.category} - ${code.subcategory} (${code.description_en} ${code.code}) `}</Select.Option>
      )
    })
  /*const [initialValues, setInitialValues] = useState()*/
  const initialValues = {
    industry_codes: record.industryCodes.map((code) => code.code),
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {record.industryCodes.map((ind, index) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'space-between',
            }}
          >
            {`Code: ${ind.code}. Category: ${ind.category}. Subcategory: ${ind.subcategory}`}
          </div>
        )
      })}
      <Button
        style={{ marginBottom: 0 }}
        type="link"
        onClick={() => {
          setShow(true)
        }}
      >
        Add new
      </Button>
      <Modal
        title={intl.get('updateIndustryModalTitle')}
        visible={show}
        footer={null}
        onCancel={() => {
          setShow(false)
        }}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validation}
          onSubmit={async (vals, { setSubmitting }) => {
            await companiesImport.update({
              company_id: record.company_id,
              industryCodes: generalCriteria.codes.filter((code) =>
                vals.industry_codes.includes(code.code),
              ),
            })
            setSubmitting(false)
            setShow(false)
          }}
        >
          {({ isSubmitting, setFieldValue, resetForm }) => (
            <Form>
              <Form.Item name="industry_codes">
                <Select
                  mode="multiple"
                  name="industry_codes"
                  placeholder="Select industry codes"
                >
                  {renderCodes()}
                </Select>
              </Form.Item>
              <SubmitButton>{intl.get('save')}</SubmitButton>
              <Button
                onClick={() => {
                  resetForm()
                  setShow(false)
                }}
              >
                {intl.get('cancel')}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  )
})
