import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import StoreContext from '../../../../storeContext'
import intl from 'react-intl-universal'
import { COMPANY_PROFILE_TABS } from '../../index'
import { Button, Card, Col, Modal, Row } from 'antd'
import LoadingWrapper from '../LoadingWrapper'
import RateCardView from './RateCardView'
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined'
import EditModal from '../../../shared/EditModal'
import EditSTC from './EditSTC'
import EditButton from '../../EditButton'
import StandardTermsView from './StandardTermsView'
import InfoPopUp from '../../../InfoPopUp'

const { confirm } = Modal
export default React.memo(
  observer(
    React.forwardRef(function (props, ref) {
      const element = useRef()
      const { companyProfile } = useContext(StoreContext)
      const [showEditTerms, setShowEditTerms] = useState(false)

      const confirmModal = () => {
        Modal.confirm({
          title: 'You are going to delete terms and conditions, are you sure ?',
          okText: intl.get('delete'),
          onOk: async () => {
            await companyProfile.updateCompany(
              {
                company_id: companyProfile.company.company_id,
                standard_terms_remove: {
                  terms_en: !!companyProfile.company.standard_terms?.terms_en,
                  terms_de: !!companyProfile.company.standard_terms?.terms_de,
                },
              },
              'deleteTerms',
            )
          },
        })
      }

      useEffect(() => {
        ref.current[COMPANY_PROFILE_TABS.procurement.id] = element.current
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [element.current])
      const menuItems = [
        {
          name: 'Add standard terms',
          callback: () => setShowEditTerms(true),
        },
        {
          name: 'Delete standard terms',
          callback: confirmModal,
          show: !!companyProfile.company.standard_terms,
        },
      ]
      return (
        <Card
          title={intl.get('procurement')}
          extra={
            <div style={{ display: 'flex' }}>
              <EditButton menuItems={menuItems} />
              <InfoPopUp
                html={intl.get('procurementInfoPopUp')}
                title={intl.get('procurement')}
              />
            </div>
          }
        >
          <LoadingWrapper>
            <div ref={element} id={COMPANY_PROFILE_TABS.procurement.id}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Card
                    type="inner"
                    bordered={false}
                    title={
                      companyProfile.company.rate_card_public
                        ? intl.get('publicRateCardAvailable')
                        : intl.get('noPublicRateCard')
                    }
                  >
                    {companyProfile.company.rate_card_public && (
                      <RateCardView
                        description={intl.get('rateCardDescription')}
                        title={`${intl.get('publicRateCardFrom')} ${
                          companyProfile.company && companyProfile.company.name
                        }`}
                        card={companyProfile.company.rate_card_public}
                      />
                    )}
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Card
                    type="inner"
                    bordered={false}
                    title={
                      companyProfile.privateRateCard ? (
                        intl.get('privateRateCardAvailableFor')
                      ) : (
                        <Button
                          style={{ margin: 0 }}
                          size="small"
                          onClick={() =>
                            confirm({
                              title: `${intl.get('requestPrivateRateCardFrom', {
                                0: companyProfile.company.name,
                              })}`,
                              icon: <ExclamationCircleOutlined />,
                              content: intl.get(
                                'requestPrivateRateCardQuestion',
                              ),
                              onOk: async () => {
                                await companyProfile.sendPrivateRateCardRequest(
                                  companyProfile.company.company_id,
                                )
                              },
                            })
                          }
                        >
                          {intl.get('requetPrivateRateCard')}
                        </Button>
                      )
                    }
                  >
                    {companyProfile.privateRateCard &&
                      companyProfile.privateRateCard.rates && (
                        <RateCardView
                          description={intl.get('privateRateCardDescription', {
                            0: companyProfile.privateRateCard.buyer_name,
                            1: companyProfile.privateRateCard.supplier_name,
                          })}
                          title={`${intl.get('privateRateCardBetween', {
                            0: companyProfile.privateRateCard.buyer_name,
                            1: companyProfile.privateRateCard.supplier_name,
                          })}  `}
                          card={companyProfile.privateRateCard}
                        />
                      )}
                  </Card>
                </Col>
              </Row>
              <StandardTermsView />
            </div>
            <EditModal
              handleOnClose={() => setShowEditTerms(false)}
              visible={showEditTerms}
              title="Add standard terms & conditions"
            >
              <EditSTC />
            </EditModal>
          </LoadingWrapper>
        </Card>
      )
    }),
  ),
)
