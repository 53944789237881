import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'antd'
import { observer } from 'mobx-react'
import StoreContext from '../../storeContext'
import { Formik } from 'formik'
import { Form, Select, SubmitButton } from 'formik-antd'
import intl from 'react-intl-universal'
import { toJS } from 'mobx'

export default observer(function ({
  visible,
  handleOnCancel,
  company_list_id,
}) {
  const { lists, user } = useContext(StoreContext)
  const [companies, setCompanies] = useState([])
  useEffect(() => {
    if (lists.companies.length > 0) setCompanies(toJS(lists.companies))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lists.companies])

  const initialValues = {
    edit: {
      companies_add: [],
      company_list_id,
    },
    company_id: user.user.company_id,
  }
  useEffect(() => {
    if (lists.companies.length === 0) {
      lists.getCompanies()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      onCancel={handleOnCancel}
      title={intl.get('addCompaniesToList')}
      footer={null}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (vals) => {
          await lists.companyList(vals, 'edit')
          await lists.getListCompanies(vals.edit.company_list_id)
          handleOnCancel()
        }}
      >
        {() => (
          <Form>
            <Form.Item name="edit.companies_add">
              <Select
                mode="multiple"
                filterOption={false}
                placeholder={intl.get('selectCompanies')}
                onSearch={(txt) =>
                  setCompanies(
                    lists.companies.filter((company) =>
                      company.name
                        .toLowerCase()
                        .includes(txt.toLocaleLowerCase()),
                    ),
                  )
                }
                name="edit.companies_add"
              >
                {companies.map((company) => (
                  <Select.Option value={company.company_id}>
                    {company.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <SubmitButton>{intl.get('add')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </Modal>
  )
})
