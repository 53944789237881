import React, { useContext } from "react";
import { Drawer, Layout } from "antd";
import SidebarContent from "./SidebarContent";
import StoreContext from "../../storeContext";
import { observer } from "mobx-react";
import { TAB_SIZE } from "../../constants/LayoutSetting";

const { Sider } = Layout

const Sidebar = observer(() => {
  const store = useContext(StoreContext)

  return (
    <Sider
      trigger={null}
      collapsed={store.layout.width > TAB_SIZE && store.layout.sideBarCollapsed }
      theme={'lite'}
      collapsible
    >
      {
        store.layout.width < TAB_SIZE ?
          <Drawer
            className={`gx-drawer-sidebar`}
            placement="left"
            onClose={()=> {
              store.layout.sideBarCollapsed = !store.layout.sideBarCollapsed
            }}
            visible={store.layout.sideBarCollapsed}>
            <SidebarContent/>
          </Drawer> :
          <SidebarContent/>
      }
    </Sider>
  )
})
export default Sidebar
