import React, { useState } from 'react'
import { Modal } from 'antd'
import SendEmailForm from './SendEmailForm'

export default function Email({ ids, button }) {
  const [show, setShow] = useState(false)

  return (
    <>
      {button(() => setShow(true))}
      <Modal
        width="40%"
        visible={show}
        destroyOnClose={true}
        footer={null}
        onCancel={() => setShow(false)}
        title="Send email"
      >
        <SendEmailForm ids={ids} close={() => setShow(false)} />
      </Modal>
    </>
  )
}
