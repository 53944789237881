import React, { useContext, useState } from "react"
import { observer } from 'mobx-react'
import { Button, Modal } from "antd"
import intl from "react-intl-universal"
import { Formik } from "formik"
import { Form, Input, SubmitButton } from "formik-antd"
import StoreContext from "../../../storeContext"

export default observer(function ({ record }) {
  const [show, setShow] = useState(false)
  const {companiesOverview} = useContext(StoreContext)
  const initialValues = {
    comment: record.comment
  }
  return (
    <>
      <Button
        style={{
          marginBottom: 0,
          maxWidth: 200,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        onClick={()=> setShow(true)}
        type="link"
      >
        {record.comment ? record.comment : intl.get('setComment')}
      </Button>
      <Modal
        title={intl.get('addCommentModalTitle')}
        visible={show}
        footer={null}
        onCancel={() => {
          setShow(false)
        }}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={async (vals, {setSubmitting}) => {
            await companiesOverview.updateCompany(record.company_id, vals, 'comment', vals.comment)
            setSubmitting(false)
            setShow(false)
          }}
        >
          {({ isSubmitting, setFieldValue, resetForm }) => (
            <Form>
              <Form.Item name="comment">
                <Input.TextArea disabled={isSubmitting} name="comment" rows={10} placeholder="Comment"/>
              </Form.Item>
              <SubmitButton>{intl.get('save')}</SubmitButton>
              <Button
                onClick={() => {
                  resetForm()
                  setShow(false)
                }}
              >
                {intl.get('cancel')}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
})
