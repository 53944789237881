import React, { useContext } from 'react'
import { Modal } from 'antd'
import { Formik } from 'formik'
import { Form, Input, SubmitButton, Checkbox } from 'formik-antd'
import * as Yup from 'yup'
import StoreContext from '../../storeContext'
import intl from 'react-intl-universal'
import { RoleGroups } from '../../utils/roles'

export default function ({ visible, handleOnCancel }) {
  const { user, lists } = useContext(StoreContext)
  const validation = Yup.object().shape({
    add: Yup.object().shape({
      list_name: Yup.string().required(intl.get('listNameRequired')),
      description: Yup.string().max(800, intl.get('nameErrorMax', { 0: 800 })),
    }),
  })
  const initialValues = {
    add: {
      list_name: '',
      description: '',
      is_global: false,
    },
    company_id: user.user.company_id,
  }
  return (
    <Modal
      title={intl.get('addNewList')}
      visible={visible}
      footer={null}
      destroyOnClose={true}
      onCancel={handleOnCancel}
    >
      <Formik
        validationSchema={validation}
        initialValues={initialValues}
        onSubmit={async (vals, { setSubmitting }) => {
          await lists.companyList(vals, 'add')
          setSubmitting(false)
          handleOnCancel()
        }}
      >
        {() => (
          <Form>
            <Form.Item name="add.list_name">
              <Input name="add.list_name" addonBefore={intl.get('listName')} />
            </Form.Item>
            <Form.Item name="add.description">
              <Input.TextArea
                name="add.description"
                placeholder={intl.get('listDescription')}
              />
            </Form.Item>
            {RoleGroups.TITANS.includes(user.user['custom:role_id']) && (
              <Form.Item name="add.is_global">
                <Checkbox name="add.is_global">
                  {intl.get('globalList')}
                </Checkbox>
              </Form.Item>
            )}
            <SubmitButton>{intl.get('add')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
