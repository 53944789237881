import React, { useContext } from 'react'
import { Formik } from 'formik'
import { SubmitButton, Form, Select } from 'formik-antd'
import * as Yup from 'yup'
import { SurveyTypes } from '../../../../../constants/dropdowns'
import StoreContext from '../../../../../storeContext'
import { Space } from 'antd'

export function RequestSurvey({ close }) {
  const { companyProfile } = useContext(StoreContext)
  const initialValues = {
    survey_type: '',
  }

  const validation = Yup.object().shape({
    survey_type: Yup.string().required('Please select survey type'),
  })
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(vals) =>
        companyProfile
          .requestSurvey({
            request_survey: {
              company_id: companyProfile.company.company_id,
              ...vals,
            },
          })
          .then(close)
      }
      validationSchema={validation}
    >
      {() => (
        <Form style={{ display: 'flex', justifyContent: 'center' }}>
          <Space direction="vertical">
            <span>Dear requester,</span>
            <span>You are going to send request for survey.</span>
            <span>Please select survey type? </span>
            <Form.Item style={{ margin: 0 }} name="survey_type">
              <Select name="survey_type" placeholder="Select survey type">
                {SurveyTypes.filter((s) =>
                  companyProfile.company.unanswered_survey_types.includes(
                    s.value,
                  ),
                ).map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <SubmitButton style={{ width: '100%' }}>Request</SubmitButton>
          </Space>
        </Form>
      )}
    </Formik>
  )
}
