import React from 'react'
import { Col, Row } from 'antd'
import { observer } from 'mobx-react'
import UsersTable from './UsersTable/index'
import AddUser from './AddUser'
import './style.css'
import InvitedUsersTable from './InvitedUsersTable'

export default observer(function Users() {
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <AddUser />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <InvitedUsersTable />
          <UsersTable />
        </Col>
      </Row>
    </>
  )
})
