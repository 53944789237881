import { StoreBase } from '../../../store/StoreBase'
import { action, computed, decorate, flow, observable, reaction } from 'mobx'
import React from 'react'
import CompareNotification from '../CompareNotification'
import { NetworkCall } from '../../../store/models/NetworkModels'

const CompareStoreCalls = Object.freeze({
  GET_RATE_CARDS: 'getRateCards',
  COMPARE_COMPANIES: 'compareCompanies',
})
export class CompareStore extends StoreBase {
  companies = []
  profiles = []
  allCompanies = []
  benchmark = null
  key = null
  compareFilters = {
    company_list: [],
    team_members: [],
    other_value: 0,
    comment: '',
    currency: 'CHF',
  }
  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
    this.getRateCardsCall = new NetworkCall(rootStore, {
      id: CompareStoreCalls.GET_RATE_CARDS,
      secured: true,
      path: 'ratecards',
    })
    this.compareCompaniesCall = new NetworkCall(rootStore, {
      path: 'companycompare',
      secured: true,
      id: CompareStoreCalls.COMPARE_COMPANIES,
    })
    reaction(
      () => this.totalCompanies,
      (total) => {
        if (total > 0) {
          if (this.key === null) {
            this.key = new Date().toTimeString()
            this.rootStore.toast.setNotification(
              {
                message: 'Compare',
                description: <CompareNotification />,
                placement: 'topRight',
                onClose: () => {
                  this.key = null
                },
                key: this.key,
                duration: 0,
              },
              'info',
            )
          }
        } else {
          this.rootStore.toast.closeNotification(this.key)
          this.key = null
        }
      },
    )
  }

  updateCompanies = (selected) => {
    const temp = []
    this.allCompanies.forEach((c) => {
      if (selected.includes(c.company_id)) {
        const t = this.companies.find((o) => o.company_id === c.company_id)
        if (t) {
          temp.push(t)
        } else {
          temp.push(c)
        }
      }
    })
    this.compareFilters.company_list = temp.map((c) => c.company_id)
    this.companies = temp
  }
  loadCompanies = flow(function* () {
    const res = yield this.rootStore.search.searchCall.call(
      'get',
      null,
      `?search_keyword=`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.allCompanies = result.data.companies
          break
        case 400:
          break
        default:
      }
    })
  })

  getCompanyList = (companies) => {
    let param = ''
    companies.forEach((comp) => {
      param += `company_list=${comp}&`
    })
    return param
  }
  compareCompanies = flow(function* (data) {
    if (this.compareCompaniesCall.callInProgress) return
    const res = yield this.compareCompaniesCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.benchmark = result.data.benchmark
          this.companies = this.companies.map((comp) => {
            result.data.companies.forEach((c) => {
              if (c.company_id === comp.company_id) comp = { ...comp, ...c }
            })
            return comp
          })
          break
        default:
      }
    })
  })

  getJobProfiles = (data, arr) => {
    data.forEach((d) => {
      if (d.child_type === 'item') {
        d.children.forEach((item) => {
          arr.push(item.profile_en)
        })
      } else {
        this.getJobProfiles(d.children, arr)
      }
    })
    return arr
  }
  getRateCards = flow(function* () {
    if (this.getRateCardsCall.callInProgress) return
    const res = yield this.getRateCardsCall.call('get')
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.profiles = this.getJobProfiles(
            result.data.rate_cards[0].children,
            [],
          )
          break
        default:
      }
    })
  })
  addCompany = (company) => {
    this.companies.length < 3 && this.companies.push(company)
  }
  removeCompany = (company) => {
    this.companies = this.companies.filter(
      (i) => i.company_id !== company.company_id,
    )
  }
  get totalCompanies() {
    return this.companies.length
  }
}

decorate(CompareStore, {
  companies: observable,
  addCompany: action,
  removeCompany: action,
  totalCompanies: computed,
  profiles: observable,
  benchmark: observable,
  allCompanies: observable,
  compareFilters: observable,
})
