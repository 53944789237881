import React, { useContext, useEffect, useState } from 'react'
import { Card, Checkbox } from 'antd'
import MinusOutlined from '@ant-design/icons/lib/icons/MinusOutlined'
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import StoreContext from '../../../../storeContext'

export const ExpertiseGroup = observer(function ({
  group,
  type = '',
  onCheck,
}) {
  const [show, setShow] = useState(false)
  const { companyProfile } = useContext(StoreContext)
  useEffect(() => {
    if (companyProfile.toggleAll !== null) {
      setShow(companyProfile.toggleAll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProfile.toggleAll])
  const renderGroups = () => {
    return group.children.map((g) => {
      return (
        <RecursiveGroup
          key={g.item_name}
          group={g}
          type="inner"
          onCheck={onCheck}
        />
      )
    })
  }
  const renderItems = () =>
    group.children.map((g, i) => (
      <GroupItem key={JSON.stringify(g) + i} item={g} onCheck={onCheck} />
    ))
  return (
    <>
      <Card
        bodyStyle={{
          padding: !show && 0,
          height: !show && 0,
          overflow: !show && 'hidden',
        }}
        type={type}
        title={
          <h3
            style={{ cursor: 'pointer' }}
            onClick={() => setShow((show) => !show)}
          >{`${
            group.item_name
          } ( selected: ${companyProfile.getTotalItemsSelected(
            group,
            false,
          )})`}</h3>
        }
        extra={
          !show ? (
            <PlusOutlined onClick={() => setShow((show) => !show)} />
          ) : (
            <MinusOutlined onClick={() => setShow((show) => !show)} />
          )
        }
      >
        {group.child_type === 'group' ? renderGroups() : renderItems()}
      </Card>
    </>
  )
})
const GroupItem = observer(({ item, onCheck }) => {
  return (
    <Checkbox
      checked={item.selected}
      onChange={(e) => {
        action(() => {
          item.selected = e.target.checked
        })()
        onCheck && onCheck()
      }}
      style={{ margin: 8 }}
    >
      {item.name}
    </Checkbox>
  )
})
const RecursiveGroup = (props) => <ExpertiseGroup {...props} />

export default ExpertiseGroup
