import React, { useContext } from 'react'
import { Button, Table, Tooltip } from 'antd'
import LevelCell from './LevelCell'
import TravelCostCell from './TravelCostCell'
import StoreContext from '../../../storeContext'
import { Loading } from '../../loading'
import { CompanyProfileStoreCalls } from '../../CompanyProfile/store/CompanyProfileStore'
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'
import intl from 'react-intl-universal'
import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined'
import InsertRowAboveOutlined from '@ant-design/icons/lib/icons/InsertRowAboveOutlined'
import { observer } from 'mobx-react'
import { CardContext } from '../index'
import { RateCardCalls } from '../store/RateCardsStore'
import { DeleteOutlined } from '@ant-design/icons'

export default React.memo(
  observer(function ({ items }) {
    const currentLocale = intl.determineLocale({
      localStorageLocaleKey: 'lang',
    })
    const showDelete = (record) => Object.keys(record).length > 3
    const { upd, canEdit } = useContext(CardContext)
    const { cards } = useContext(StoreContext)
    const { copy } = cards
    const columns = [
      {
        title:
          currentLocale === 'en-US'
            ? intl.get('profileEn')
            : intl.get('profileDe'),
        dataIndex: currentLocale === 'en-US' ? 'profile_en' : 'profile_de',
        width: 250,
        render: (text, record) => ({
          props: {
            style: {
              background: '#fafafa',
            },
          },
          children: <div>{text}</div>,
        }),
      },
      {
        title: intl.get('currency'),
        dataIndex: 'profile_currency',
        width: 100,
        render: (text, record) => ({
          props: {
            style: {
              background: '#fafafa',
            },
          },
          children: (
            <div style={{ display: 'flex', fontSize: 15, gap: '0.5em' }}>
              {text}
              {canEdit && (
                <>
                  <Tooltip title={intl.get('copyRow')}>
                    <CopyOutlined onClick={() => cards.setCopyData(record)} />
                  </Tooltip>
                  {copy && (
                    <Tooltip title={intl.get('pasteToRow')}>
                      <InsertRowAboveOutlined
                        onClick={() => cards.pasteData(record)}
                      />
                    </Tooltip>
                  )}
                  {showDelete(record) && (
                    <Tooltip title={intl.get('deleteRow')}>
                      <DeleteOutlined onClick={() => cards.deleteRow(record)} />
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          ),
        }),
      },
      {
        title: intl.get('travelCostIncluded'),
        width: 100,
        dataIndex: 'profile_travel_cost',
        render: (text, record) => <TravelCostCell record={record} />,
      },
      {
        title: 'L1',
        dataIndex: 'profile_l1',
        width: 150,
        render: (text, record) => (
          <LevelCell record={record} name="profile_l1" />
        ),
      },
      {
        title: 'L2',
        dataIndex: 'profile_l2',
        width: 150,
        render: (text, record) => (
          <LevelCell record={record} name="profile_l2" />
        ),
      },
      {
        title: 'L3',
        dataIndex: 'profile_l3',
        width: 150,
        render: (text, record) => (
          <LevelCell record={record} name="profile_l3" />
        ),
      },
      {
        title: 'L4',
        dataIndex: 'profile_l4',
        width: 150,
        render: (text, record) => (
          <LevelCell record={record} name="profile_l4" />
        ),
      },
      {
        title: 'L5',
        dataIndex: 'profile_l5',
        width: 150,
        render: (text, record) => (
          <LevelCell record={record} name="profile_l5" />
        ),
      },
    ]
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
        <Table
          pagination={false}
          scroll={{ x: 1000 }}
          bordered={true}
          columns={columns}
          dataSource={items}
        />
        {canEdit && (
          <Loading
            blockers={[
              CompanyProfileStoreCalls.UPDATE_COMPANY_PROFILE,
              RateCardCalls.PRIVATE_RATE_CARDS,
            ]}
          >
            <Loading.Done>
              <Button
                style={{ width: '8em', alignSelf: 'flex-end' }}
                type={'primary'}
                onClick={() => upd()}
              >
                Save
              </Button>
            </Loading.Done>
            <Loading.InProgress>
              <Button
                style={{ width: '8em', alignSelf: 'flex-end' }}
                disabled={true}
                type={'primary'}
              >
                Save <LoadingOutlined />
              </Button>
            </Loading.InProgress>
          </Loading>
        )}
      </div>
    )
  }),
)
