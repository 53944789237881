import React from 'react'
import UsersTableActions from './UsersTableActions'
import { RolesName } from '../../../utils/roles'
import intl from 'react-intl-universal'
import { Link } from 'react-router-dom'
import { sort } from '../../CompaniesOverview/TableColumns'

export const cols = (id) => [
  {
    title: intl.get('usersTableColumnName'),
    render: (text, record) => {
      return (
        <Link
          to={`/dashboard/profile${
            id !== record.user_id ? '?user=' + record.user_id : ''
          }`}
        >
          {record.name} {record.surname}
        </Link>
      )
    },
    width: 200,
    sorter: (a, b) => sort(a, b, 'name'),
  },
  {
    title: intl.get('usersTableColumnCompany'),
    dataIndex: 'company_name',
    width: 200,
    sorter: (a, b) => sort(a, b, 'company_name'),
  },
  {
    title: intl.get('usersTableColumnRole'),
    render: (text, record) => RolesName[record.role_id],
    width: 100,
    sorter: (a, b) => sort(a, b, 'role_id'),
  },
  {
    title: intl.get('usersTableColumnType'),
    width: 100,
    dataIndex: 'company_type',
    sorter: (a, b) => sort(a, b, 'company_type'),
  },
  {
    title: intl.get('usersTableColumnEmail'),
    width: 200,
    dataIndex: 'email',
    sorter: (a, b) => sort(a, b, 'email'),
  },
  {
    title: intl.get('usersTableColumnTerms'),
    width: 150,
    render: (text, record) => (
      <a href={record.terms_url} rel="noopener noreferrer" target="_blank">
        {record.terms_version}
      </a>
    ),
    sorter: (a, b) => sort(a, b, 'terms_version'),
  },
  {
    title: intl.get('usersTableColumnActions'),
    key: 'action',
    fixed: 'right',
    width: 150,
    render: (text, record) => <UsersTableActions record={record} />,
  },
]
