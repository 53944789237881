import React from 'react'
import { Loading } from '../../loading'
import { CompaniesOverviewStoreCalls } from '../store/CompaniesOverviewStore'
import { Button, Modal } from 'antd'
import intl from 'react-intl-universal'
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined'

const { confirm } = Modal
export default function ({ record, isReload, onClick, disabled = false }) {
  function showConfirm() {
    confirm({
      title: 'You are reloading data, are you sure?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        await onClick()
      },
    })
  }
  return (
    <Loading
      blockers={[
        CompaniesOverviewStoreCalls.FETCH_CMPTCS,
        CompaniesOverviewStoreCalls.FETCH_KYC,
        CompaniesOverviewStoreCalls.FETCH_BITSIGHT,
      ]}
    >
      <Loading.Done>
        <Button
          disabled={disabled}
          onClick={() => {
            if (isReload) {
              showConfirm()
              return
            }
            onClick()
          }}
        >
          {isReload ? intl.get('reload') : intl.get('fetch')}
        </Button>
      </Loading.Done>
      <Loading.InProgress>
        <Button disabled>
          <LoadingOutlined />
          {isReload > 0 ? intl.get('reload') : intl.get('fetch')}
        </Button>
      </Loading.InProgress>
    </Loading>
  )
}
