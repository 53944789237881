import React, { useContext, useEffect, useState } from 'react'
import StoreContext from '../../storeContext'
import { observer } from 'mobx-react'
import { Button, Card, Col, Row, Skeleton } from 'antd'
import AddCompanies from './AddCompanies'
import CompanyListTable from './CompanyListTable'
import CompanyListDescription from './CompanyListDescription'
import intl from 'react-intl-universal'
import EditProfileField from '../Profile/EditProfileField'
import { CompanyListsCalls } from './store/CompanyListsStore'
import Email from '../Email'
import ShowHideCell from '../GeneralCriteria/ShowHideCell'

export default observer(function ({ list }) {
  const { user, lists, network } = useContext(StoreContext)
  const [showAdd, setShowAdd] = useState(false)
  useEffect(() => {
    lists.getListCompanies(list.company_list_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !network.checkRunning(CompanyListsCalls.COMPANY_LIST) ? (
    <>
      <Card
        title={
          list.personal ? (
            list.list_name
          ) : (
            <EditProfileField
              value={list.list_name}
              valueLabel={`${list.list_name} (${intl.get('createdBy', {
                0: list.created_by,
                1: new Date(list.created_date).toLocaleDateString(),
              })})`}
              name="list_name"
              callback={async (value, setEdit) => {
                await lists.companyList(
                  {
                    company_id: user.user.company_id,
                    edit: {
                      ...value,
                      company_list_id: list.company_list_id,
                    },
                  },
                  'edit',
                )
                setEdit(false)
              }}
              type="cell"
            />
          )
        }
        extra={
          <>
            {!list.personal && (
              <ShowHideCell
                visible={list.is_global}
                checked="globalList"
                unchecked="globalList"
                callback={(checked) =>
                  lists.companyList(
                    {
                      company_id: user.user.company_id,
                      edit: {
                        is_global: checked,
                        company_list_id: list.company_list_id,
                      },
                    },
                    'edit',
                  )
                }
              />
            )}
            <Email
              button={(onClick) => (
                <Button
                  style={{ margin: 0, marginRight: 5 }}
                  onClick={onClick}
                  size="small"
                >
                  {intl.get('sendMail')}
                </Button>
              )}
              ids={
                list.companies ? list.companies.map((c) => c.company_id) : []
              }
            />
            {list.personal || (
              <Button
                style={{ margin: 0 }}
                onClick={() => setShowAdd(true)}
                size="small"
              >
                {intl.get('addCompanies')}
              </Button>
            )}
          </>
        }
      >
        <Row>
          <Col span={24}>
            <CompanyListTable list={list} />
          </Col>
        </Row>
        {list.personal || (
          <Row>
            <Col span={24}>
              <CompanyListDescription list={list} />
            </Col>
          </Row>
        )}
      </Card>
      {list.personal || (
        <AddCompanies
          company_list_id={list.company_list_id}
          visible={showAdd}
          handleOnCancel={() => setShowAdd(false)}
        />
      )}
    </>
  ) : (
    <Skeleton active />
  )
})
