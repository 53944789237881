import React, { useContext } from 'react'
import { Formik } from 'formik'
import { SubmitButton, Form, Select } from 'formik-antd'
import * as Yup from 'yup'
import StoreContext from '../../../../../storeContext'
import { Space } from 'antd'
import intl from 'react-intl-universal'

export function RequestSuccessStory({ close }) {
  const { companyProfile } = useContext(StoreContext)
  const initialValues = {
    story_type: '',
  }

  const validation = Yup.object().shape({
    story_type: Yup.string().required('Please select story type'),
  })
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(vals) =>
        companyProfile
          .requestSuccessStory({
            request_story: {
              company_id: companyProfile.company.company_id,
              ...vals,
            },
          })
          .then(close)
      }
      validationSchema={validation}
    >
      {() => (
        <Form style={{ display: 'flex', justifyContent: 'center' }}>
          <Space direction="vertical">
            <span>
              {intl.get('requestSuccessStoryTitle', {
                0: companyProfile.company.name,
              })}
            </span>
            <Form.Item style={{ margin: 0 }} name="story_type">
              <Select name="story_type" placeholder="Select success story type">
                <Select.Option value="general_success_story">
                  {intl.get('general_success_story')}
                </Select.Option>
                <Select.Option value="business_expertise_specific">
                  {intl.get('business_expertise_specific')}
                </Select.Option>
              </Select>
            </Form.Item>
            <SubmitButton style={{ width: '100%' }}>Request</SubmitButton>
          </Space>
        </Form>
      )}
    </Formik>
  )
}
