import React from "react";
import { Space } from "antd";
import intl from "react-intl-universal";

export default function ({ result, search }) {
  const highlight = (hit) => {

    const withoutHelperWords = search.replace(/and\s|or\s|not\s/gi, '')

    const reg = new RegExp(`(${withoutHelperWords.split(/\s/).join('|')})`, 'gi')
    return hit.replace(reg, match=>{
      return `<span class="highlight">${match}</span>`
    })
  }

  const renderHits = () =>
    result.match_list.map((h, i) => (
      <div className="hit" dangerouslySetInnerHTML={{__html: highlight(h)}} />
    ))
  return (
    <div className="selected-result">
      <div className="item title">
        {intl.get('hitDetailsFor')} {result ? result.name : 'selected company'}
      </div>
      {result && <Space className="hits">{renderHits()}</Space>}
    </div>
  )
}
