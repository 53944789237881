import React, { useContext, useEffect, useRef, useState } from 'react'
import Routes, { mainRoutes } from './routes'
import intl from 'react-intl-universal'
import StoreContext from './storeContext'
import { observer } from 'mobx-react'
import Joyride from 'react-joyride'

import './assets/vendors/style'
import './styles/wieldy.less'
import { notification } from 'antd'

require('intl/locale-data/jsonp/en.js')
const langConf = require('./utils/lang/lang-config.json').languages

export const PrintContext = React.createContext({ printRef: null })

export default observer(function App() {
  const store = useContext(StoreContext)
  const [showPrint, setShowPrint] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const printRef = useRef()
  store.toast.api = api
  const [initDone, setInitDone] = useState(false)
  useEffect(() => {
    let currentLocale = intl.determineLocale({
      localStorageLocaleKey: 'lang',
    })
    if (!langConf.find((el) => el.code === currentLocale)) {
      currentLocale = 'en-US'
      localStorage.setItem('lang', currentLocale)
    }
    changeLocale(currentLocale)
    setInitDone(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => {
      store.layout.width = window.innerWidth
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const changeLocale = (code) => {
    const translations = {}
    translations[code] = require(`./utils/lang/lang_${code}`).TRANSLATIONS
    intl.init({
      currentLocale: code,
      locales: translations,
    })
  }

  return (
    initDone && (
      <>
        {contextHolder}
        <Joyride
          continuous={true}
          run={store.joyride.running}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={store.joyride.steps}
          debug={true}
          callback={store.joyride.callback}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
        <PrintContext.Provider value={{ printRef, showPrint, setShowPrint }}>
          <Routes routes={mainRoutes} />
        </PrintContext.Provider>
      </>
    )
  )
})
