import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Row, Table } from 'antd'
import StoreContext from '../../../storeContext'
import { observer } from 'mobx-react'
import AddIndustryCode from './AddIndustryCode'
import EditProfileField from '../../Profile/EditProfileField'
import ShowHideCell from '../ShowHideCell'
import { sort } from "../../CompaniesOverview/TableColumns"
import intl from "react-intl-universal";

export default observer(function () {
  const [showAdd, setShowAdd] = useState(false)
  const { generalCriteria } = useContext(StoreContext)
  const columns = [
    {
      title: intl.get('industryCode'),
      dataIndex: 'code',
      sorter: (a, b) => sort(a, b, 'code'),
    },
    {
      title: intl.get('descriptionEnglish'),
      dataIndex: 'description_en',
    },
    {
      title: intl.get('descriptionGerman'),
      dataIndex: 'description_de',
    },
    {
      title: intl.get('category'),
      dataIndex: 'category',
      width: 300,
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="category"
          callback={async (value, setEdit) => {
            await generalCriteria.addUpdateIndustryCode(
              { ...value, code: record.code, type: 'edit' },
              record,
            )
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: intl.get('subcategory'),
      width: 300,
      dataIndex: 'subcategory',
      render: (text, record) => (
        <EditProfileField
          value={record.subcategory}
          name="subcategory"
          callback={async (value, setEdit) => {
            await generalCriteria.addUpdateIndustryCode(
              { ...value, code: record.code, type: 'edit' },
              record,
            )
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: intl.get('totalCompanies'),
      dataIndex: 'company_count',
      sorter: (a, b) => {
        return a.company_count - b.company_count
      },
    },
    {
      title: intl.get('visibility'),
      dataIndex: 'code_visible',
      render: (text, record) =>{
        return (
        <ShowHideCell
          visible={record.code_visible}
          callback={(check) =>
            generalCriteria.addUpdateIndustryCode({
              code: record.code,
              code_visible: check,
              type: 'edit'
            }, record)
          }
        />
      )},
    },
  ]
  useEffect(() => {
    generalCriteria.getCodes(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Row>
      <Col span="24">
        <Card title={intl.get('industryCodes')}>
          <Button onClick={() => setShowAdd(true)} type="primary">
            {intl.get('addIndustryCode')}
          </Button>
          <Table
            bordered={true}
            columns={columns}
            rowKey="code"
            dataSource={generalCriteria.codes}
            scroll={{ x: 1500 }}
          />
        </Card>
      </Col>
      <AddIndustryCode
        visible={showAdd}
        handleOnCancel={() => setShowAdd(false)}
      />
    </Row>
  )
})
