import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import StoreContext from '../../../../../../storeContext'
import { Card, Col, Space } from 'antd'
import intl from 'react-intl-universal'
import InfoPopUp from '../../../../../InfoPopUp'
import IndicatorItem from '../IndicatorItem'
import GovernanceInfo from './GovernanceInfo'
import AmlInfo from './AmlInfo'
import ShareholdersInfo from './ShareholdersInfo'
import Rating from '../../Rating'

export default observer(function () {
  const { companyProfile } = useContext(StoreContext)
  if (!companyProfile.company.compliance_legal_risks) return null
  return (
    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
      <Card
        type="inner"
        bordered={false}
        title={intl.get('complianceRisks')}
        extra={
          <div style={{ display: 'flex' }}>
            <InfoPopUp
              title={intl.get('complianceRisks')}
              html={intl.get('complianceRisksInfo')}
            />
            <div style={{ marginRight: 5 }} />
            <Rating
              score={companyProfile.company.compliance_legal_risks.risk_rating}
              trafficLight={true}
            />
          </div>
        }
      >
        {/* {intl.get('financialRisksEmptyText')}*/}
        <Space direction="vertical" style={{ width: '100%' }}>
          {companyProfile.company.compliance_legal_risks.governance && (
            <IndicatorItem
              name={intl.get('governance')}
              rating={companyProfile.company.compliance_legal_risks.governance}
              modal={(show, setShow) => (
                <GovernanceInfo
                  gov={companyProfile.company.compliance_legal_risks.governance}
                  setShowPreview={setShow}
                  showPreview={show}
                />
              )}
            />
          )}
          {companyProfile.company.compliance_legal_risks.aml && (
            <IndicatorItem
              name="AML"
              showView={companyProfile.company.kyc_view}
              rating={companyProfile.company.compliance_legal_risks.aml}
              modal={(show, setShow) => (
                <AmlInfo
                  aml={companyProfile.company.compliance_legal_risks.aml}
                  setShowPreview={setShow}
                  showPreview={show}
                />
              )}
            />
          )}
          {companyProfile.company.compliance_legal_risks.shareholders && (
            <IndicatorItem
              name={intl.get('shareholders')}
              showView={companyProfile.company.kyc_view}
              rating={
                companyProfile.company.compliance_legal_risks.shareholders
              }
              modal={(show, setShow) => (
                <ShareholdersInfo
                  shareholders={
                    companyProfile.company.compliance_legal_risks.shareholders
                  }
                  setShowPreview={setShow}
                  showPreview={show}
                />
              )}
            />
          )}
        </Space>
      </Card>
    </Col>
  )
})
