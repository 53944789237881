import React from "react";
import { Divider, Modal, Space, Statistic } from "antd";
import moment from "moment";
import Rating from "../../Rating";
import SurveyData from "../SurveyData";
import intl from "react-intl-universal";

export default function ({ showPreview, setShowPreview, payment }) {
  return (
    <Modal
      title={intl.get('payment')}
      visible={showPreview}
      footer={null}
      destroyOnClose={true}
      onCancel={() => setShowPreview(false)}
    >
      <Space style={{ width: '100%' }} direction={'vertical'}>
        <Statistic
          title={intl.get('date')}
          value={
            payment.summary_date
              ? moment(payment.summary_date).format('DD MMMM YYYY')
              : 'N/A'
          }
        />
        <Divider type="horizontal" />
        <Statistic
          title={intl.get('paydexRating')}
          valueRender={() => <Rating score={payment.rating} />}
        />
        <Divider type="horizontal" />
        <Statistic
          title={intl.get('paymentBehaviorDays')}
          value={payment.payment_behavior_days || 'N/A'}
        />
        <Divider type="horizontal" />
        <Statistic
          title={intl.get('paymentBehaviorDescription')}
          value={payment.payment_behavior_description || 'N/A'}
        />
        <Divider type="horizontal" />
        <Statistic
          title={`${intl.get('selfDeclarationResponse')} ${
            payment.questionaire_completed_date
              ? moment(payment.questionaire_completed_date).format(
                  'DD MMMM YYYY',
                )
              : 'N/A'
          }`}
          valueRender={() => <SurveyData questions={payment.questions} />}
        />
      </Space>
    </Modal>
  )
}
