import React, { useContext, useEffect, useRef, useState } from 'react'
import FilePdfOutlined from '@ant-design/icons/lib/icons/FilePdfOutlined'
import VideoCameraOutlined from '@ant-design/icons/lib/icons/VideoCameraOutlined'
import { Card } from 'antd'
import { COMPANY_PROFILE_TABS } from '../../index'
import CompanyLogo from './CompanyLogo'
import CompanyTags from './CompanyTags'
import EditButton from '../../EditButton'
import EditCompanyTags from './EditCompanyTags'
import EditCompanyDescription from './EditCompanyDescription'
import { observer } from 'mobx-react'
import StoreContext from '../../../../storeContext'
import LoadingWrapper from '../LoadingWrapper'
import EditCompanyInfo from './EditCompanyInfo'
import EditCompanySocialMediaLinks from './EditCompanySocialMediaLinks'
import CompanySocialMediaLinks from './CompanySocialMediaLinks'
import EditIndustryCodes from './EditIndustryCodes'
import intl from 'react-intl-universal'
import { getValidLink } from '../../../../utils/helpers'
import EditCompanyVideo from './EditCompanyVideo'
import EditCompanyPresentation from './EditCompanyPresentation'
import CompanyStructure from './CompanyStructure'
import CompanyInviteButton from './CompanyInviteButton'
import InfoPopUp from '../../../InfoPopUp'
import styled from 'styled-components'
import { GlobalOutlined } from '@ant-design/icons'
import EditModal from '../../../shared/EditModal'
import EditInvisibleTags from './EditInvisibleTags'
import { RoleGroups } from '../../../../utils/roles'

const StyledDiv = styled.div`
  .info-wrapper.first {
    justify-content: end;
  }
  .company-url {
    margin-bottom: 20px;
  }
  .social-wrap {
    border-radius: 50%;
    width: fit-content;
    background: #f0f0f0;
    font-size: 10px;
    height: 24px;
    padding: 7px;
    margin-bottom: 8px;
  }
  .general-social {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
  }
  .ant-typography.ant-typography-secondary {
    color: rgba(29, 42, 61, 0.5);
  }

  .tags-wrapp {
    margin-top: 30px;
  }
`

export default React.memo(
  observer(
    React.forwardRef(function (props, ref) {
      const element = useRef()
      const currentLocale = intl.determineLocale({
        localStorageLocaleKey: 'lang',
      })

      const { companyProfile, user } = useContext(StoreContext)
      const [showEditTags, setShowEditTags] = useState(false)
      const [showEditDesc, setShowEditDesc] = useState(false)
      const [showInfoEdit, setShowInfoEdit] = useState(false)
      const [showLogo, setShowLogo] = useState(false)
      const [showMediaLinksEdit, setShowMediaLinksEdit] = useState(false)
      const [showIndustryCodes, setShowIndustryCodes] = useState(false)
      const [showEditVideos, setShowEditVideos] = useState(false)
      const [showEditPresentation, setShowEditPresentation] = useState(false)
      const [showEditInvisibleTags, setShowEditInvisibleTags] = useState(false)
      const menuItems = [
        {
          name: intl.get('editBasicInfo'),
          callback: () => setShowInfoEdit(true),
        },
        {
          name: intl.get('editDescription'),
          callback: () => setShowEditDesc(true),
        },
        {
          name: intl.get('editInvisibleTags'),
          callback: () => setShowEditInvisibleTags(true),
          show: RoleGroups.TITANS.includes(user.user['custom:role_id']),
        },
        { name: intl.get('editTags'), callback: () => setShowEditTags(true) },
        { name: intl.get('editLogo'), callback: () => setShowLogo(true) },
        {
          name: intl.get('editIndustryCodes'),
          callback: () => setShowIndustryCodes(true),
        },
        {
          name: intl.get('editMediaLinks'),
          callback: () => setShowMediaLinksEdit(true),
        },
        {
          name: intl.get('editVideos'),
          callback: () => setShowEditVideos(true),
        },
        {
          name: intl.get('editPresentation'),
          callback: () => setShowEditPresentation(true),
        },
      ]
      const { generalCriteria } = useContext(StoreContext)
      useEffect(() => {
        generalCriteria.getInvisibleTags(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
      useEffect(() => {
        ref.current[COMPANY_PROFILE_TABS.basic.id] = element.current
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [element.current])
      return (
        <Card
          className="edit-card"
          title={intl.get('basic')}
          bodyStyle={{ display: 'flex', flexDirection: 'column' }}
          extra={
            <div style={{ display: 'flex' }}>
              {/*  <CompanyInviteButton /> */}
              <EditButton menuItems={menuItems} />
              <InfoPopUp
                html={intl.get('profileInfoPopUp')}
                title={intl.get('basic')}
              />
            </div>
          }
        >
          <LoadingWrapper>
            <CompanyInviteButton />
            <StyledDiv
              ref={element}
              className="card-wrapper"
              id={COMPANY_PROFILE_TABS.basic.id}
            >
              <CompanyLogo showUpload={showLogo} setShowUpload={setShowLogo} />
              <div className="info-wrapper first">
                <h3>{companyProfile.company.name}</h3>
                <span className="company-url">
                  {!companyProfile.company.company_url ? (
                    intl.get('noCompanyUrl')
                  ) : (
                    <div>
                      <GlobalOutlined
                        style={{
                          color: 'rgba(29, 42, 61, 0.5)',
                          marginRight: 10,
                        }}
                      />
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={getValidLink(companyProfile.company.company_url)}
                      >
                        {companyProfile.company.company_url}
                      </a>
                    </div>
                  )}
                </span>
                <span>{`${companyProfile.company.city}, ${companyProfile.company.country}`}</span>
                <span>
                  {intl.get('founded')} {companyProfile.company.year_founded}
                </span>
                <span>
                  {intl.get('employees')}{' '}
                  {companyProfile.company.employee_count_range}
                </span>

                <CompanySocialMediaLinks
                  links={companyProfile.company.social_media}
                />
                <CompanyStructure />
              </div>
              <div className="info-wrapper second">
                <span
                  style={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}
                >
                  {currentLocale === 'en-US'
                    ? companyProfile.company.company_description_en
                      ? companyProfile.company.company_description_en
                      : companyProfile.company.company_description_de
                    : companyProfile.company.company_description_de
                    ? companyProfile.company.company_description_de
                    : companyProfile.company.company_description_en}
                </span>
                {companyProfile.company.tags && (
                  <CompanyTags tags={companyProfile.company.tags} />
                )}
              </div>
              <div className="info-wrapper third">
                {(companyProfile.company.presentation_en ||
                  companyProfile.company.presentation_de) && (
                  <a
                    rel="noopener noreferrer"
                    style={{ display: 'flex', flexDirection: 'column' }}
                    target="_blank"
                    href={
                      currentLocale === 'en-US'
                        ? companyProfile.company.presentation_en_url
                          ? getValidLink(
                              companyProfile.company.presentation_en_url,
                            )
                          : getValidLink(
                              companyProfile.company.presentation_de_url,
                            )
                        : companyProfile.company.presentation_de_url
                        ? getValidLink(
                            companyProfile.company.presentation_de_url,
                          )
                        : getValidLink(
                            companyProfile.company.presentation_en_url,
                          )
                    }
                  >
                    <FilePdfOutlined style={{ fontSize: '3rem' }} />
                    <span>Slides</span>
                  </a>
                )}
                {(companyProfile.company.company_video_en ||
                  companyProfile.company.company_video_de) && (
                  <a
                    style={{ display: 'flex', flexDirection: 'column' }}
                    rel="noopener noreferrer"
                    href={
                      currentLocale === 'en-US'
                        ? companyProfile.company.company_video_en
                          ? getValidLink(
                              companyProfile.company.company_video_en,
                            )
                          : getValidLink(
                              companyProfile.company.company_video_de,
                            )
                        : companyProfile.company.company_video_de
                        ? getValidLink(companyProfile.company.company_video_de)
                        : getValidLink(companyProfile.company.company_video_en)
                    }
                    target="_blank"
                  >
                    <VideoCameraOutlined style={{ fontSize: '3rem' }} />
                    Video
                  </a>
                )}
              </div>
            </StyledDiv>
          </LoadingWrapper>
          <EditCompanyTags
            visible={showEditTags}
            handleOnCancel={() => setShowEditTags(false)}
            tags={
              companyProfile.company.tags &&
              companyProfile.company.tags.map((tag) => tag.tag_id)
            }
          />
          <EditCompanyDescription
            visible={showEditDesc}
            handleOnCancel={() => setShowEditDesc(false)}
            company_description_en={
              companyProfile.company.company_description_en
            }
            company_description_de={
              companyProfile.company.company_description_de
            }
          />
          <EditCompanyInfo
            visible={showInfoEdit}
            handleOnCancel={() => setShowInfoEdit(false)}
            city={companyProfile.company.city}
            country={companyProfile.company.country}
            employee_count={companyProfile.company.employee_count}
            year_founded={companyProfile.company.year_founded}
            name={companyProfile.company.name}
            company_url={companyProfile.company.company_url}
            vat={companyProfile.company.vat}
          />
          <EditCompanySocialMediaLinks
            visible={showMediaLinksEdit}
            handleOnCancel={() => setShowMediaLinksEdit(false)}
            links={companyProfile.company.social_media}
          />
          <EditIndustryCodes
            visible={showIndustryCodes}
            handleOnCancel={() => setShowIndustryCodes(false)}
            industry_codes={
              companyProfile.company.industry_codes
                ? companyProfile.company.industry_codes.map((code) => code.code)
                : []
            }
          />
          <EditCompanyVideo
            visible={showEditVideos}
            handleOnCancel={() => setShowEditVideos(false)}
            company_video_de={companyProfile.company.company_video_de}
            company_video_en={companyProfile.company.company_video_en}
          />
          <EditCompanyPresentation
            english={companyProfile.company.presentation_en}
            german={companyProfile.company.presentation_de}
            handleOnCancel={() => setShowEditPresentation(false)}
            visible={showEditPresentation}
          />
          <EditModal
            handleOnClose={() => setShowEditInvisibleTags(false)}
            visible={showEditInvisibleTags}
            title={intl.get('editInvisibleTags')}
          >
            <EditInvisibleTags />
          </EditModal>
        </Card>
      )
    }),
  ),
)
