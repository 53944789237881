import { Formik } from 'formik'
import { Form, Select, SubmitButton } from 'formik-antd'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import StoreContext from '../../../../../storeContext'
import * as Yup from 'yup'
import intl from 'react-intl-universal'
import { Button } from 'antd'
import ReactHtmlParser from 'react-html-parser'

export default observer(function RequestPremiumData({ close }) {
  const { companyProfile } = useContext(StoreContext)
  const validation = Yup.object().shape({
    request_premium_risk_data: Yup.object().shape({
      data_request_type: Yup.string().required(intl.get('premiumDataType')),
    }),
  })
  const initValues = {
    request_premium_risk_data: {
      company_id: companyProfile.company.company_id,
      data_request_type: '',
    },
  }
  return (
    <Formik
      validationSchema={validation}
      initialValues={initValues}
      onSubmit={(vals) =>
        companyProfile
          .riskClassification(vals, 'requestPremiumRisk')
          .then(() => close())
      }
    >
      {() => (
        <Form>
          {ReactHtmlParser(intl.get('requestPremiumRiskQuestion'))}
          <Form.Item
            style={{ margin: '20px 0' }}
            name="request_premium_risk_data.data_request_type"
          >
            <Select
              placeholder={intl.get('chooseDataType')}
              name="request_premium_risk_data.data_request_type"
            >
              <Select.Option value="titan_basic_risk">
                {intl.get('titanBasic')}
              </Select.Option>
              <Select.Option value="bitsight_premium">
                {intl.get('bitsightPremium')}
              </Select.Option>
            </Select>
          </Form.Item>
          <SubmitButton>{intl.get('order')}</SubmitButton>
          <Button onClick={close}>{intl.get('cancel')}</Button>
        </Form>
      )}
    </Formik>
  )
})
