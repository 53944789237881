import React, { useContext } from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import SidebarLogo from './SidebarLogo'
import UserProfile from './UserProfile'
import CustomScrollbars from '../../utils/CustomScrollbars'
import { dashboardRoutes } from '../../routes'
import StoreContext from '../../storeContext'
import { observer } from 'mobx-react'
import { SmallDashOutlined, TableOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import intl from 'react-intl-universal'

const StyledContent = styled.div`
  .ant-avatar {
    border: 2px solid;
  }
  .gx-sidebar-content {
    background: #1d2a3d;
    color: white;
  }
  .gx-sidebar-notifications {
    border-bottom: 0px;
  }
  .gx-mb-4 {
    margin-bottom: 0px !important;
  }
  .ant-menu {
    background: #1d2a3d;
  }
  .ant-menu-item-selected {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }
  .ant-menu-item-selected > a {
    color: white !important;
    & .anticon,
    .icon {
      color: #1890ff;
    }
  }
  .ant-menu-item > a {
    color: white !important;
  }
  .ant-menu-submenu-title {
    color: white;
    & .ant-menu-item > a {
      color: white !important;
    }
  }
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow:before {
    background-image: linear-gradient(to right, white, white);
  }
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow:after {
    background-image: linear-gradient(to right, white, white);
  }
  .ant-menu-inline .ant-menu-item:after {
    border-right: 5px solid #038fde;
  }
`

const { SubMenu } = Menu
const SidebarContent = observer(() => {
  const store = useContext(StoreContext)
  const { pathname } = useLocation()
  function renderMenu() {
    return dashboardRoutes(store.user).map((route) => {
      if (route.showInMenu === false) return null
      if (
        (route.auth &&
          !route.auth.includes(store.user.user['custom:role_id'])) ||
        (route.companyType &&
          !route.companyType.includes(store.user.user.company_type))
      )
        return null

      if (route.subMenu)
        return (
          <SubMenu
            key={route.key}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TableOutlined
                  style={{ marginRight: store.layout.sideBarCollapsed && 34 }}
                />
                {intl.get(route.name)}
              </div>
            }
          >
            {route.subMenu.map((sub) => {
              if (
                sub.companyType &&
                !sub.companyType.includes(store.user.user.company_type)
              )
                return null
              return (
                <Menu.Item key={sub.path}>
                  <Link to={sub.path}>
                    <SmallDashOutlined />
                    <span>{intl.get(sub.name)}</span>
                  </Link>
                </Menu.Item>
              )
            })}
          </SubMenu>
        )
      return (
        <Menu.Item
          key={route.path}
          id={route.id}
          onClick={() => {
            if (store.layout.sideBarCollapsed)
              store.layout.sideBarCollapsed = false
          }}
        >
          <Link to={route.path}>
            {route.icon ? route.icon : <i className="icon icon-widgets" />}
            <span>{intl.get(route.name)}</span>
          </Link>
        </Menu.Item>
      )
    })
  }
  return (
    <StyledContent>
      <SidebarLogo />
      <div className="gx-sidebar-content" style={{ borderRight: 0 }}>
        <div className={`gx-sidebar-notifications`}>
          <UserProfile />
          {/*<AppsNavigation />*/}
        </div>
        <CustomScrollbars autoHeightMin={window.innerHeight - 72 - 80}>
          <Menu defaultSelectedKeys={[pathname]} theme={'lite'} mode="inline">
            {renderMenu()}
          </Menu>
        </CustomScrollbars>
      </div>
    </StyledContent>
  )
})

SidebarContent.propTypes = {}

export default SidebarContent
