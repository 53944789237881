import { Pagination } from 'antd'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import StoreContext from '../../../../storeContext'
import ProductItem from './ProductItem'

export default observer(function Products() {
  const { search } = useContext(StoreContext)
  return (
    <div className="search-items">
      {search.products.map((p) => (
        <ProductItem product={p} />
      ))}
      <Pagination
        defaultCurrent={1}
        pageSize={search.perPage}
        onShowSizeChange={(current, size) => {
          action(() => {
            search.perPage = size
          })()
        }}
        onChange={(page) => {
          action(() => {
            search.currentPage = page
          })()
        }}
        current={search.currentPage}
        total={search.products.length}
      />
    </div>
  )
})
