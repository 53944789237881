import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'antd'
import { Formik } from 'formik'
import { Form, FormItem, Select, SubmitButton } from 'formik-antd'
import * as Yup from 'yup'
import StoreContext from '../../../storeContext'
import { observer } from 'mobx-react'
import intl from 'react-intl-universal'
import { toJS } from 'mobx'

export default observer(function ({ visible, onCancel }) {
  const initialValue = { company: '' }

  const validation = Yup.object().shape({
    company: Yup.string().required('Please select company'),
  })
  const { cards, user } = useContext(StoreContext)
  const [companies, setCompanies] = useState([])
  useEffect(() => {
    cards.loadPrivateRateCardCompanies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setCompanies(toJS(cards.companies))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards.companies])

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      destroyOnClose={true}
      title={intl.get('addRateCard')}
      footer={null}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={validation}
        onSubmit={async (vals) => {
          await cards
            .updatePrivateRateCards({
              add: true,
              companies: [vals.company, user.user.company_id],
            })
            .then(() => cards.loadPrivate())
          onCancel()
        }}
      >
        {() => (
          <Form>
            <FormItem name="company">
              <Select
                showSearch
                name="company"
                filterOption={false}
                onSearch={(txt) =>
                  setCompanies(
                    cards.companies.filter((partner) =>
                      partner.name.toLowerCase().includes(txt.toLowerCase()),
                    ),
                  )
                }
                addonBefore={intl.get('rateCardTitle')}
              >
                {companies.map((company) => (
                  <Select.Option value={company.company_id}>
                    {company.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
            <SubmitButton>{intl.get('add')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </Modal>
  )
})
