import { Divider, Modal, Space, Statistic } from "antd";
import moment from "moment";
import Rating from "../../Rating";
import SurveyData from "../SurveyData";
import React from "react";
import intl from "react-intl-universal";

export default function ({ showPreview, setShowPreview, gov }) {
  return (
    <Modal
      title={intl.get('governance')}
      visible={showPreview}
      footer={null}
      destroyOnClose={true}
      onCancel={() => setShowPreview(false)}
    >
      <Space style={{ width: '100%' }} direction={'vertical'}>
        <Statistic
          title={intl.get('date')}
          value={
            gov.questionaire_completed_date
              ? moment(gov.questionaire_completed_date).format('DD MMMM YYYY')
              : 'N/A'
          }
        />
        <Divider type="horizontal" />
        <Statistic
          title={intl.get('controlStandardRating')}
          valueRender={() => <Rating score={gov.rating} />}
        />

        <Divider type="horizontal" />
        <Statistic
          title={intl.get('selfDeclarationResponse')}
          valueRender={() => <SurveyData questions={gov.questions} />}
        />
      </Space>
    </Modal>
  )
}
