import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Form, Select, SubmitButton } from 'formik-antd'
import { Countries } from '../../constants/dropdowns'
import { observer } from 'mobx-react'
import StoreContext from '../../storeContext'

export default observer(function ({ editSurvey, close }) {
  const { survey } = useContext(StoreContext)

  const init = {
    countries: editSurvey.target_audience?.countries || [],
    company_type: editSurvey.target_audience?.company_type || [],
    user_types: editSurvey.target_audience?.user_types || [],
    industry_expertise: editSurvey.target_audience?.industry_expertise || [],
    tags: editSurvey.target_audience?.tags || [],
    invisible_tags: editSurvey.target_audience?.invisible_tags || [],
  }

  return (
    <Formik
      initialValues={init}
      onSubmit={async (vals) => {
        await survey.survey(
          {
            edit: {
              survey_id: editSurvey.survey_id,
              target_audience: vals,
            },
          },
          'edit',
        )
        close()
      }}
    >
      {() => (
        <Form>
          <Form.Item name="countries">
            <Select
              placeholder="Select countries"
              name="countries"
              mode="multiple"
            >
              {Countries.map((c) => (
                <Select.Option value={c.value}>{c.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="company_type">
            <Select
              placeholder="Select company type"
              name="company_type"
              mode="multiple"
            >
              <Select.Option value={1}>Buyer</Select.Option>
              <Select.Option value={2}>Supplier</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="user_types">
            <Select
              placeholder="Select user type"
              name="user_types"
              mode="multiple"
            >
              <Select.Option value="owner">Owner</Select.Option>
              <Select.Option value="admin">Admin</Select.Option>
              <Select.Option value="user">User</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="tags">
            <Select placeholder="Select tags" name="tags" mode="multiple">
              {survey.tags.map((tag) => (
                <Select.Option value={tag.tag_id}>{tag.tag_name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="invisible_tags">
            <Select
              placeholder="Select invisible tags"
              name="invisible_tags"
              mode="multiple"
            >
              {survey.invisibleTags.map((tag) => (
                <Select.Option value={tag.tag_id}>{tag.tag_name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="industry_expertise">
            <Select
              placeholder="Select industry expertises"
              name="industry_expertise"
              mode="multiple"
            >
              {survey.industryCodes.map((expertise) => (
                <Select.Option value={expertise}>{expertise}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <SubmitButton>Save</SubmitButton>
        </Form>
      )}
    </Formik>
  )
})
