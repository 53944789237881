import React from "react";
import { Modal } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input, SubmitButton } from "formik-antd";
import * as Yup from "yup";
import intl from "react-intl-universal";

export default function ({ visible, onCancel, callback }) {
  const initialValue = { rate_card_title: '' }
  const validation = Yup.object().shape({
    rate_card_title: Yup.string().required(intl.get('rateCardTitleRequired')),
  })
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      destroyOnClose={true}
      title={intl.get('addRateCard')}
      footer={null}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={validation}
        onSubmit={async (vals) => {
          await callback(vals)
          onCancel()
        }}
      >
        {() => (
          <Form>
            <FormItem name="rate_card_title">
              <Input
                name="rate_card_title"
                addonBefore={intl.get('rateCardTitle')}
              />
            </FormItem>
            <SubmitButton>{intl.get('add')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
