import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import StoreContext from "../../../storeContext";
import RateCard, { CardContext } from "../index";
import { toJS } from "mobx";
import intl from "react-intl-universal";

export default observer(function () {
  const { cards, user } = useContext(StoreContext)
  useEffect(() => {
    cards
      .loadRateCards(user.user.company_id)
      .then(() => cards.loadPublicRateCard())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <CardContext.Provider
      value={{
        canEdit: !!user.user.claims.update_company,
        upd: async () =>{
          await cards.update({
            rate_card_public: cards.getSavedData(
              toJS(cards.publicRateCard.allCards),
            ),
            company_id: cards.company.company_id,
          })
          await cards.loadPublicRateCard()
        },
        updComment: (data) =>
          cards.update({
            rate_card_public_description: data.description,
            company_id: cards.company.company_id,
          }),
        updSeniority: (data) =>
          cards
            .update({
              rate_card_public_seniority: {
                ...cards.publicRateCard.seniority_definition,
                ...data,
              },
              company_id: cards.company.company_id,
            })
            .then(() => cards.loadPublicRateCard()),
      }}
    >
      <RateCard.Global
        description={intl.get('rateCardDescription')}
        title={`Public rate card from ${cards.company && cards.company.name}`}
        card={cards.publicRateCard}
      />
    </CardContext.Provider>
  )
})
