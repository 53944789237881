import React, { useContext, useEffect, useRef, useState } from 'react'
import { Card, Col, Modal, Row } from 'antd'
import { COMPANY_PROFILE_TABS } from '../../index'
import StoreContext from '../../../../storeContext'
import { observer } from 'mobx-react'
import LoadingWrapper from '../LoadingWrapper'
import EditButton from '../../EditButton'
import CompanyHyperlinks from './CompanyHyperlinks'
import EditCompanyHyperlinks from './EditCompanyHyperlinks'
import intl from 'react-intl-universal'
import CompanyRegistration from './CompanyRegistration'
import CompanyRevenue from './CompanyRevenue'
import EditCompanyRevenue from './EditCompanyRevenue'
import EditCompanyContactPerson from './EditCompanyContactPerson'
import ContactPerson from './EditCompanyContactPerson/ContactPerson'
import InfoPopUp from '../../../InfoPopUp'

export default React.memo(
  observer(
    React.forwardRef(function (props, ref) {
      const element = useRef()
      const [showUpdateHyperlinks, setShowUpdateHyperlinks] = useState(false)
      const [showEditRevenue, setShowEditRevenue] = useState(false)
      const [showEditContactPerson, setShowEditContactPerson] = useState(false)
      const { companyProfile } = useContext(StoreContext)

      const confirmModal = () => {
        Modal.confirm({
          title: intl.get('deleteContactPersonModal'),
          okText: intl.get('delete'),
          onOk: async () => {
            await companyProfile.updateCompany(
              {
                contact_person: {
                  delete_contact_person: true,
                },
                company_id: companyProfile.company.company_id,
              },
              'deleteContactPerson',
            )
          },
        })
      }

      const menuItems = [
        {
          name: intl.get('editHyperlinks'),
          callback: () => setShowUpdateHyperlinks(true),
        },
        {
          name: intl.get('editRevenue'),
          callback: () => setShowEditRevenue(true),
        },

        {
          name: intl.get('editContactPerson'),
          callback: () => setShowEditContactPerson(true),
        },
        {
          name: intl.get('deleteContactPerson'),
          callback: confirmModal,
          show: !!companyProfile.company.contact_person,
        },
      ]
      useEffect(() => {
        ref.current[COMPANY_PROFILE_TABS.details.id] = element.current
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [element.current])
      return (
        <Card
          title={intl.get('details')}
          extra={
            <div style={{ display: 'flex' }}>
              <EditButton menuItems={menuItems} />
              <InfoPopUp
                html={intl.get('detailsPopUp')}
                title={intl.get('details')}
              />
            </div>
          }
        >
          <LoadingWrapper>
            <div ref={element} id="details">
              <Row>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <CompanyRegistration
                    data={companyProfile.company.registration_data}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <CompanyRevenue />
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <ContactPerson />
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <CompanyHyperlinks
                    links={companyProfile.company.company_reports}
                  />
                </Col>
              </Row>
            </div>
          </LoadingWrapper>

          <EditCompanyHyperlinks
            visible={showUpdateHyperlinks}
            handleOnCancel={() => setShowUpdateHyperlinks(false)}
            links={companyProfile.company.company_reports}
          />
          <EditCompanyRevenue
            revenues={companyProfile.company.financials}
            visible={showEditRevenue}
            handleOnCancel={() => setShowEditRevenue(false)}
          />
          <EditCompanyContactPerson
            visible={showEditContactPerson}
            handleOnCancel={() => setShowEditContactPerson(false)}
          />
        </Card>
      )
    }),
  ),
)
