import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import * as Yup from 'yup'
import StoreContext from '../../storeContext'
import { observer } from 'mobx-react'
import { Card } from 'antd'
import intl from 'react-intl-universal'

export default observer(function ({ list }) {
  const { user, lists } = useContext(StoreContext)
  const validation = Yup.object().shape({
    edit: Yup.object().shape({
      description: Yup.string().max(800, intl.get('nameErrorMax', { 0: 800 })),
    }),
  })
  const initialValues = {
    edit: {
      description: list.description || '',
      company_list_id: list.company_list_id,
    },
    company_id: user.user.company_id,
  }
  return (
    <Card title="List description" type="inner" bordered={false}>
      <Formik
        enableReinitialize={true}
        validationSchema={validation}
        initialValues={initialValues}
        onSubmit={async (vals, { setSubmitting }) => {
          await lists.companyList(vals, 'edit')
          setSubmitting(false)
        }}
      >
        {() => (
          <Form>
            <Form.Item name="edit.description">
              <Input.TextArea
                name="edit.description"
                placeholder={intl.get('listDescription')}
              />
            </Form.Item>
            <SubmitButton>{intl.get('save')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </Card>
  )
})
