import React, { useEffect, useState } from "react";
import { Input } from "formik-antd";
import { useFormikContext } from "formik";

export default function ({ email }) {
  const { setFieldValue } = useFormikContext();
  const [domain, setDomain] = useState('')
  const [base, setBase ] = useState('')
  useEffect(() => {
    if(email) {
      setBase(email.split('@')[0])
      setDomain(`@${email.split('@')[1]}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])
  return (
    <div style={{position: 'realtive'}}>
      <Input name="user.email" value={base} onChange={(e)=> {
        setBase(e.target.value)
        setFieldValue('user.email', e.target.value+domain)
      }} />
      <div style={{position: 'absolute', top: 10, right: 7}}>{domain}</div>
    </div>
  )
}
