import { RouterStore } from 'mobx-react-router'
import mobxRemotedev from 'mobx-remotedev'

import { NetworkCallList } from './models/NetworkModels'
import ToastStore from './ToastStore'
import TestStore from './TestStore'
import UserStore from './UserStore'
import LayoutStore from './LayoutStore'
import NetworkStore from './NetworkStore'
import { UsersStore } from '../components/Users/store/UsersStore'
import { UserProfileStore } from '../components/Profile/store/UserProfileStore'
import { CompaniesOverviewStore } from '../components/CompaniesOverview/store/CompaniesOverviewStore'
import { CompanyImportStore } from '../components/CompanyImport/store/CompanyImportStore'
import { TermsStore } from '../components/Terms/store/TermsStore'
import { CompanyProfileStore } from '../components/CompanyProfile/store/CompanyProfileStore'
import { GeneralCriteriaStore } from '../components/GeneralCriteria/store/GeneralCriteriaStore'
import { SharedStore } from './SharedStore'
import { CompanyListsStore } from '../components/CompanyLists/store/CompanyListsStore'
import { SearchStore } from '../components/Search/store/SearchStore'
import { RateCardsStore } from '../components/RateCard/store/RateCardsStore'
import JoyrideStore from './JoyrideStore'
import { ContractsStore } from '../components/Contracts/store/ContractsStore'
import { SurveyStore } from '../components/Surveys/store/SurveyStore'
import { CompareStore } from '../components/Compare/store/CompareStore'
import { EmailStore } from '../components/Email/store/EmailStore'
import { MyRequestsStore } from '../components/MyRequests/store/MyRequestsStore'
import ResourceVettingStore from '../components/ResourceVetting/store/ResourceVettingStore'
class RootStore {
  constructor() {
    const _router = mobxRemotedev(RouterStore)
    this.router = new _router()

    const _runningCalls = mobxRemotedev(NetworkCallList)
    this.runningCalls = new _runningCalls(this)

    const _toastStore = mobxRemotedev(ToastStore)
    this.toast = new _toastStore(this)

    const _testStore = mobxRemotedev(TestStore)
    this.test = new _testStore(this)

    const _userStore = mobxRemotedev(UserStore)
    this.user = new _userStore(this)

    const _layoutStore = mobxRemotedev(LayoutStore)
    this.layout = new _layoutStore(this)

    const _networkStore = mobxRemotedev(NetworkStore)
    this.network = new _networkStore(this)

    const _usersStore = mobxRemotedev(UsersStore)
    this.users = new _usersStore(this)

    const _userProfile = mobxRemotedev(UserProfileStore)
    this.profile = new _userProfile(this)

    const _companiesOverview = mobxRemotedev(CompaniesOverviewStore)
    this.companiesOverview = new _companiesOverview(this)

    const _companiesImport = mobxRemotedev(CompanyImportStore)
    this.companiesImport = new _companiesImport(this)

    const _termsStore = mobxRemotedev(TermsStore)
    this.terms = new _termsStore(this)

    const _companyProfileStore = mobxRemotedev(CompanyProfileStore)
    this.companyProfile = new _companyProfileStore(this)

    const _generalCriteria = mobxRemotedev(GeneralCriteriaStore)
    this.generalCriteria = new _generalCriteria(this)

    const _shared = mobxRemotedev(SharedStore)
    this.shared = new _shared(this)

    const _search = mobxRemotedev(SearchStore)
    this.search = new _search(this)

    const _cards = mobxRemotedev(RateCardsStore)
    this.cards = new _cards(this)

    const _lists = mobxRemotedev(CompanyListsStore)
    this.lists = new _lists(this)

    const _joyrideStore = mobxRemotedev(JoyrideStore)
    this.joyride = new _joyrideStore(this)

    const _contractsStore = mobxRemotedev(ContractsStore)
    this.contracts = new _contractsStore(this)

    const _surveyStore = mobxRemotedev(SurveyStore)
    this.survey = new _surveyStore(this)

    const _compareStore = mobxRemotedev(CompareStore)
    this.compare = new _compareStore(this)

    const _emailStore = mobxRemotedev(EmailStore)
    this.email = new _emailStore(this)

    const _myRequests = mobxRemotedev(MyRequestsStore)
    this.requests = new _myRequests(this)

    const _resourceVetting = mobxRemotedev(ResourceVettingStore)
    this.vetting = new _resourceVetting(this)
  }

  reset() {
    this.runningCalls.reset()
  }
}

export default RootStore
