import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import intl from 'react-intl-universal'
import FileField from '../../../shared/FileField'
import StoreContext from '../../../../storeContext'
import { useParams } from 'react-router'
import * as Yup from 'yup'

export default function ({ close }) {
  const { companyProfile } = useContext(StoreContext)
  const company_id = useParams().id
  const initialValues = {
    en: {
      terms_en: '',
      terms_en_name: '',
    },
    de: {
      terms_de: '',
      terms_de_name: '',
    },
  }

  const validation = Yup.object().shape({
    en: Yup.object().shape(
      {
        terms_en: Yup.string().when('terms_en_name', {
          is: (field) => field && field.length > 0,
          then: Yup.string().required('File is required'),
          otherwise: Yup.string(),
        }),
        terms_en_name: Yup.string().when('terms_en', {
          is: (field) => field && field.length > 0,
          then: Yup.string().required('File is required'),
          otherwise: Yup.string(),
        }),
      },
      ['terms_en', 'terms_en_name'],
    ),
    de: Yup.object().shape(
      {
        terms_de: Yup.string().when('terms_de_name', {
          is: (field) => field && field.length > 0,
          then: Yup.string().required('File is required'),
          otherwise: Yup.string(),
        }),
        terms_de_name: Yup.string().when('terms_de', {
          is: (field) => field && field.length > 0,
          then: Yup.string().required('File name is required'),
          otherwise: Yup.string(),
        }),
      },
      ['terms_de', 'terms_de_name'],
    ),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={async (vals) => {
        await companyProfile.updateCompany({
          company_id,
          standard_terms_add: {
            ...vals.en,
            ...vals.de,
          },
        })
        close()
      }}
    >
      {({ setFieldValue, errors }) => {
        return (
          <Form>
            <Form.Item name="en.terms_en_name">
              <Input
                name="en.terms_en_name"
                addonBefore={intl.get('standardTermsEnName')}
              />
            </Form.Item>
            <Form.Item name="en.terms_en">
              <FileField
                onChange={(file) => setFieldValue('en.terms_en', file)}
                errorMessage="File must be pdf, and 2MB max size"
                onRemove={() => setFieldValue('en.terms_en', '')}
                maxSize={2}
                alowedType={['application/pdf']}
                uploadTitle={intl.get('selectEnglishTerms')}
                error={errors.en?.terms_en}
              />
            </Form.Item>
            <Form.Item name="de.terms_de_name">
              <Input
                name="de.terms_de_name"
                addonBefore={intl.get('standardTermsDeName')}
              />
            </Form.Item>
            <Form.Item name="de.terms_de">
              <FileField
                onChange={(file) => setFieldValue('de.terms_de', file)}
                errorMessage="File must be pdf, and 2MB max size"
                onRemove={() => setFieldValue('de.terms_de', '')}
                maxSize={2}
                alowedType={['application/pdf']}
                uploadTitle={intl.get('selectGermanTerms')}
                error={errors.de?.terms_de}
              />
            </Form.Item>
            <SubmitButton>{intl.get('add')}</SubmitButton>
          </Form>
        )
      }}
    </Formik>
  )
}
