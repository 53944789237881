/**
 * Safly reads object and if encounter undefined will return default value
 * @param {Object} o Object from which we are reading values
 * @param {string} p String in format 'a.b.3.c' where numbers are array indexes
 * @param {Any} d Deafault value
 */
export const oc = (o, p, d = null) =>
  p.split('.').reduce((xs, x) => (xs && xs[x] ? xs[x] : d), o)

export const textTruncate = (text, length) => {
  let ending = '...'

  if (length == null) {
    length = 20
  }

  if (text.length > length) {
    return text.substring(0, length - ending.length) + ending
  } else {
    return text
  }
}

export function formatCurrency(currency, amount) {
  let number = 0

  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  switch (currency) {

    case 'CAD':
      formatter = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
      })
      number = formatter.format(amount)
      break

    case 'EUR':
      formatter = new Intl.NumberFormat('en-EU', {
        style: 'currency',
        currency: 'EUR',
      })
      number = formatter.format(amount)
      break

    default:
      number = formatter.format(amount)
      break
  }
  return number
}

export function currencySymbol(currency) {
  let symbol = '$'

  switch (currency) {

    case 'EUR':
      symbol = '€'
      break

    default:
      symbol = '$'
      break
  }

  return symbol
}

export function checkPermissions(user, permissionGroup, permissionType, object) {
  if (user.userRole === 'ROLE_COMPANY_ADMIN') {return true}
  let permissionGroupObject = oc(user.permissions, permissionGroup, {})
  if (!object) {return oc(permissionGroupObject, permissionType, false)}
  const ownerIdKey = Object.keys(object).find(el => el.includes('owner_id'))
  if (oc(permissionGroupObject, `${permissionType}_my_teams`,  false) && user.team === object.team) {return true}
  //proveriti sa Stefanom za my_team i only_mine
  if (oc(permissionGroupObject, `${permissionType}_only_mine`,  false) && user.userID === object[ownerIdKey]) {return true}
  return false
}

export function trimOnBlur(event, setFieldValue){
  setFieldValue(event.target.name, event.target.value.trim())
}
export function getValidLink(link){
  const patern = /^((http|https|ftp):\/\/)/
  return patern.test(link) ? link : `http://${link}`
}