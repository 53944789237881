import { action, decorate, observable } from "mobx";
import { StoreBase } from "./StoreBase";

export default class TestStore extends StoreBase {
  bla = "Bla"
  anotherCheck = [1,2,3]
  testingArray = [
      {id: 0, count: 0, array: [1,2,3]},
      {id: 1, count: 1, array: [2,3,4]},
      {id: 2, count: 1, array: [4,5,6]},
  ]

  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
  }

  pushToArray(num) {
    this.testingArray[1].array.push(num)
  }
  incrementArrayEl() {
    this.anotherCheck[2] ++
  }
}
decorate(TestStore, {
  testingArray: observable,
  anotherCheck: observable,
  bla: observable,
  pushToArray: action,
  incrementArrayEl: action,
})
