import React, { useContext } from 'react'
import intl from 'react-intl-universal'
import { Card, Typography } from 'antd'
import { observer } from 'mobx-react'
import StoreContext from '../../../../../storeContext'
import Nouser from '../../../../../assets/images/no-user.jpg'
import { getValidLink } from '../../../../../utils/helpers'
const { Text } = Typography
export default observer(function () {
  const { companyProfile } = useContext(StoreContext)
  const { contact_person: contact } = companyProfile.company
  return (
    contact && (
      <Card title={intl.get('contactPerson')} type="inner" bordered={false}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              flex: '0.5 1 0',
              padding: 10,
              display: 'flex',
              maxWidth: 100,
              maxHeight: 100,
            }}
          >
            <img
              style={{ borderRadius: '50%' }}
              alt="contact person"
              src={contact.image_file_url || Nouser}
            />
          </div>
          <div
            style={{
              flex: '0.5 1 0',
              display: 'flex',
              flexDirection: 'column',
              padding: 10,
            }}
          >
            <Text strong>{contact.name}</Text>
            <div style={{ marginBottom: 5 }}>{contact.email}</div>
            <div style={{ marginBottom: 5 }}>{contact.telephone}</div>
            {contact.procurement_url && (
              <a
                rel="noopener noreferrer"
                href={getValidLink(contact.procurement_url)}
                target="_blank"
              >
                {intl.get('procurementUrl')}
              </a>
            )}
            {contact.supplier_registration_url && (
              <a
                rel="noopener noreferrer"
                href={getValidLink(contact.supplier_registration_url)}
                target="_blank"
              >
                {intl.get('supplierRegistration')}
              </a>
            )}
            {contact.sales_org_chart_file_url && (
              <a
                rel="noopener noreferrer"
                href={getValidLink(contact.sales_org_chart_file_url)}
                target="_blank"
              >
                {intl.get('supplierRegistration')}
              </a>
            )}
          </div>
        </div>
      </Card>
    )
  )
})
