import React, { useState } from 'react'
import { Modal, Tooltip } from 'antd'
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined'
import ReactHtmlParser from 'react-html-parser'

export default function ({ html, title }) {
  const [show, setShow] = useState(false)
  return (
    <div
      style={{
        display: 'flex',
        color: 'rgba(29, 42, 61, 0.5)',
        alignItems: 'center',
      }}
    >
      <Tooltip title="Learn more">
        <QuestionCircleOutlined onClick={() => setShow(true)} />
      </Tooltip>
      <Modal
        visible={show}
        footer={null}
        title={title}
        onCancel={() => setShow(false)}
      >
        {ReactHtmlParser(html)}
      </Modal>
    </div>
  )
}
