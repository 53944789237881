import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Modal, Tabs } from "antd";
import StoreContext from "../../../storeContext";
import RateCard, { CardContext } from "../index";
import { toJS } from "mobx";
import intl from "react-intl-universal";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";

const { TabPane } = Tabs
const { confirm } = Modal
export default observer(function () {
  const { cards, user } = useContext(StoreContext)
  const [showAddCard, setShowAddCard] = useState(false)
  useEffect(() => {
    cards.loadRateCards(user.user.company_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (!cards.company) return null

  const onEdit = async (targetKey, action) => {
    if (action === 'add') {
      setShowAddCard(true)
    } else {
      confirm({
        title: intl.get('removeRateCardNotification'),
        icon: <ExclamationCircleOutlined />,
        onOk: async () => {
          await cards.updateRest({
            company_id: cards.company.company_id,
            rate_card_target_remove: { rate_card_id: targetKey },
          })
          cards.loadTargetRateCards(cards.company.rate_card_target)
        },
      })
    }
  }
  return (
    <>
      <Tabs
        type={!!user.user.claims.update_company && 'editable-card'}
        hideAdd={
          cards.targetRateCards.length === 5 || !user.user.claims.update_company
        }
        onEdit={onEdit}
      >
        {cards.targetRateCards.length === 0 ? (
          <TabPane
            tab={intl.get('noRateCards')}
            closable={false}
            key="nocards"
          />
        ) : (
          cards.targetRateCards.map((card) => (
            <TabPane tab={card.rate_card_title} key={card.rate_card_id}>
              <CardContext.Provider
                value={{
                  canEdit: !!user.user.claims.update_company,
                  upd: async () => {
                    await cards.update({
                      rate_card_target_edit: {
                        rates: cards.getSavedData(toJS(card.allCards)),
                        rate_card_id: card.rate_card_id,
                      },
                      company_id: cards.company.company_id,
                    })
                    await cards.loadRateCards(user.user.company_id)
                  },
                  updComment: (data) =>
                    cards.update({
                      rate_card_target_edit: {
                        ...data,
                        rate_card_id: card.rate_card_id,
                      },
                      company_id: cards.company.company_id,
                    }),
                  updSeniority: (data) =>
                    cards
                      .update({
                        rate_card_target_edit: {
                          seniority_definition: {
                            ...card.seniority_definition,
                            ...data,
                          },
                          rate_card_id: card.rate_card_id,
                        },
                        company_id: cards.company.company_id,
                      })
                      .then(() =>
                        cards.loadTargetRateCards(
                          cards.company.rate_card_target,
                        ),
                      ),
                  updTitle: (data) =>
                    cards
                      .update({
                        rate_card_target_edit: {
                          ...data,
                          rate_card_id: card.rate_card_id,
                        },
                        company_id: cards.company.company_id,
                      })
                      .then(() =>
                        cards.loadTargetRateCards(
                          cards.company.rate_card_target,
                        ),
                      ),
                }}
              >
                <RateCard.Global
                  titleEdit={true}
                  description={intl.get('rateCardTargetDescription')}
                  title={`${card.rate_card_title}`}
                  card={card}
                />
              </CardContext.Provider>
            </TabPane>
          ))
        )}
      </Tabs>
      <RateCard.Add
        visible={showAddCard}
        onCancel={() => setShowAddCard(false)}
        callback={(vals) =>
          cards
            .updateRest({
              company_id: cards.company.company_id,
              rate_card_target_add: vals,
            })
            .then(() =>
              cards.loadTargetRateCards(cards.company.rate_card_target),
            )
        }
      />
    </>
  )
})
