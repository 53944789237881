import { StoreBase } from './StoreBase'
import { NetworkCall } from './models/NetworkModels'
import { GeneralCriteriaStoreCalls } from '../components/GeneralCriteria/store/GeneralCriteriaStore'
import { decorate, flow, observable } from 'mobx'

export class SharedStore extends StoreBase {
  storeName = 'SharedStore'
  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
    this.getAllTagsCall = new NetworkCall(rootStore, {
      path: 'taglist',
      secured: true,
      id: GeneralCriteriaStoreCalls.GET_ALL_TAGS,
    })

    this.getAllIndustryCodesCall = new NetworkCall(rootStore, {
      path: 'industrycodeslist',
      secured: true,
      id: GeneralCriteriaStoreCalls.GET_ALL_INDUSTRY_CODES,
    })
  }

  getInvisibleTags = flow(function* () {
    // if (this.getAllTagsCall.callInProgress) return
    const res = yield this.getAllTagsCall.call(
      'get',
      null,
      `?invisible_tags=true`,
    )
    let tags = []
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          tags = result.data.tags
          break
        default:
      }
    })
    return tags
  })
  getAllTags = flow(function* (type) {
    // if (this.getAllTagsCall.callInProgress) return
    const res = yield this.getAllTagsCall.call(
      'get',
      null,
      `?show_hidden=${type}`,
    )
    let tags = []
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          tags = result.data.tags
          break
        default:
      }
    })
    return tags
  })
  getAllIndustryCodes = flow(function* (type) {
    if (this.getAllIndustryCodesCall.callInProgress) return
    const res = yield this.getAllIndustryCodesCall.call(
      'get',
      null,
      `?show_hidden=${type}`,
    )
    let codes = []
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          codes = result.data.industry_codes
          break
        default:
      }
    })
    return codes
  })
}

decorate(SharedStore, {
  storeName: observable,
})
