import React, {  useState } from "react";
import EditProducts from "./Edit";
import { observer } from "mobx-react";
import intl from "react-intl-universal";

import { Button, Card } from "antd";
import AddProduct from "../CompanyProfile/Sections/Products/AddProduct";

export default observer(function () {
  const [showAddProduct, setShowAddProduct] = useState(false)
  return (
    <Card title={intl.get('products')}>
      <Button onClick={() => setShowAddProduct(true)} type="primary">
        {intl.get('addProduct')}
      </Button>
      <EditProducts />
      <AddProduct
        visible={showAddProduct}
        handleOnCancel={() => setShowAddProduct(false)}
      />
    </Card>
  )
})
