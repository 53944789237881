import { StoreBase } from '../../../store/StoreBase'
import { NetworkCall } from '../../../store/models/NetworkModels'
import { action, decorate, flow, observable } from 'mobx'
import intl from 'react-intl-universal'
import { UserStoreCalls } from '../../../store/UserStore'
import { Roles } from '../../../utils/roles'

export const TermsStoreCalls = Object.freeze({
  GET_ALL_TERMS: Object.freeze('getAllTerms'),
  UPLOAD_TERMS: Object.freeze('uploadTerms'),
  CHECK_ACTIVE_TERMS: Object.freeze('uploadTerms'),
  ACCEPT_TERMS: Object.freeze('acceptTerms'),
})

export const TermsTypes = Object.freeze({
  OF_USE: Object.freeze('terms_of_use'),
  OF_SERVICE: Object.freeze('terms_of_service'),
  AND_CONDITIONS: Object.freeze('terms_and_conditions'),
})

export class TermsStore extends StoreBase {
  acceptTerms = {
    show: false,
    version: '',
  }
  terms_of_use = []
  terms_of_service = []
  terms_and_conditions = []
  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore

    this.getAllTermsOfUseCall = new NetworkCall(rootStore, {
      path: 'listterms',
      secured: true,
      id: TermsStoreCalls.GET_ALL_TERMS,
    })
    this.uploadTermsCall = new NetworkCall(rootStore, {
      path: 'createterms',
      secured: true,
      id: TermsStoreCalls.UPLOAD_TERMS,
    })
    this.checkActiveTermsCall = new NetworkCall(rootStore, {
      path: 'activetermscompany',
      secured: true,
      id: TermsStoreCalls.CHECK_ACTIVE_TERMS,
    })
    this.acceptTermsUpdateCall = new NetworkCall(rootStore, {
      path: 'companyupdate',
      secured: true,
      id: UserStoreCalls.UPDATE_TERMS,
    })
  }

  acceptTermsUpdate = flow(function* (terms_accepted, terms_version) {
    if (this.acceptTermsUpdateCall.callInProgress) return
    const res = yield this.acceptTermsUpdateCall.call('post', {
      terms_accepted,
      terms_version,
      company_id: this.rootStore.user.user.company_id,
    })
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.rootStore.toast.setNotification(
            {
              message: terms_accepted
                ? intl.get('termsAccepted')
                : intl.get('termsDeclined'),
              placement: 'topRight',
            },
            'success',
          )
          this.acceptTerms.show = false
          break
        default:
      }
    })
  })

  checkActiveTerms = flow(function* (data) {
    if (this.checkActiveTermsCall.callInProgress) return
    const res = yield this.checkActiveTermsCall.call('get')
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          if (this.rootStore.user.user['custom:role_id'] !== Roles.CLIENT_ADMIN)
            return
          if (
            result.data.terms.active_terms_version ===
            result.data.terms.company_terms_version
          )
            return
          const activeTerms = result.data.terms.active_terms_version.split('.')
          const companyTerms = result.data.terms.company_terms_version.split(
            '.',
          )
          if (
            activeTerms.length !== companyTerms.length ||
            activeTerms[0] !== companyTerms[0] ||
            activeTerms[1] !== companyTerms[1]
          )
            this.acceptTerms = {
              show: true,
              version: result.data.terms.active_terms_version,
            }
          break
        default:
      }
    })
  })

  uploadTerms = flow(function* (data) {
    if (this.uploadTermsCall.callInProgress) return
    const res = yield this.uploadTermsCall.call('post', data)
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this.getAllTermsOfType(TermsTypes.OF_USE)
          this.rootStore.toast.setNotification(
            {
              message: intl.get('termsAdded'),
              placement: 'topRight',
            },
            'success',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })

  getAllTermsOfType = flow(function* (type) {
    if (this.getAllTermsOfUseCall.callInProgress) return
    const res = yield this.getAllTermsOfUseCall.call(
      'get',
      null,
      `?terms_type=${type}`,
    )
    res.mapResult((result) => {
      switch (result.data.statusCode) {
        case 200:
          this[type] = result.data.terms
          break
        case 401:
        case 400:
        case 500:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
          break
        default:
          this.rootStore.toast.setNotification(
            {
              message: intl.get('exception'),
              placement: 'topRight',
            },
            'error',
          )
      }
    })
  })
}

decorate(TermsStore, {
  terms_of_use: observable,
  terms_of_service: observable,
  terms_and_conditions: observable,
  getAllTermsOfUse: action,
  acceptTerms: observable,
})
