import React, { useContext, useEffect, useRef, useState } from 'react'
import StoreContext from '../../../storeContext'
import { COMPANY_PROFILE_TABS } from '../index'
import { observer } from 'mobx-react'
import ScrollableTab from '../ScrollableTab'
import Basic from '../Sections/Basic'
import PersonalNotes from '../Sections/PersonalNotes'
import Overview from '../Sections/Overview'
import CompanyDetails from '../Sections/Details'
import PeopleAndOrganization from '../Sections/PeopleAndOrganisation'
import Procurement from '../Sections/Procurement'
import { Result } from 'antd'
import Products from '../Sections/Products'
import Risk from '../Sections/Risk'
import { PrintContext } from '../../../App'

export default observer(function () {
  const { layout, companyProfile } = useContext(StoreContext)
  const [goTo, setGoTo] = useState(null)
  const [currentTab, setCurrentTab] = useState(null)
  const refs = useRef({
    [COMPANY_PROFILE_TABS.basic.id]: null,
    [COMPANY_PROFILE_TABS.personal.id]: null,
    [COMPANY_PROFILE_TABS.overview.id]: null,
    [COMPANY_PROFILE_TABS.details.id]: null,
    [COMPANY_PROFILE_TABS.peopleAndOrganization.id]: null,
    [COMPANY_PROFILE_TABS.products.id]: null,
    [COMPANY_PROFILE_TABS.procurement.id]: null,
  })

  useEffect(() => {
    if (!goTo) {
      const calculatedTab = Object.keys(refs.current).reduce((key, curr) => {
        if (!refs.current[key]) return curr
        if (!refs.current[curr]) return key
        const keyRect = refs.current[key].getBoundingClientRect().top
        const currRect = refs.current[curr].getBoundingClientRect().top
        return keyRect >= 120 && keyRect < currRect ? key : curr
      })
      if (currentTab !== calculatedTab) setCurrentTab(calculatedTab)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout.contentScrollPosition, goTo])

  useEffect(() => {
    if (goTo && refs.current[goTo]) {
      document.getElementsByClassName('gx-layout-content')[0].scrollTop =
        refs.current[goTo].offsetParent.offsetTop -
        (document.getElementsByClassName('gx-layout-content')[0].scrollTop > 10
          ? 150
          : 270)
    }
    setTimeout(() => {
      setGoTo(null)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goTo])
  const { printRef, setShowPrint } = useContext(PrintContext)
  useEffect(() => {
    setShowPrint(true)
    return () => {
      setShowPrint(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return companyProfile.loadStatus === 200 ? (
    <div ref={printRef}>
      <ScrollableTab
        tab={currentTab}
        setActive={(id) => {
          setGoTo(id)
          setCurrentTab(id)
        }}
      />
      <Basic ref={refs} />
      <div className="pagebreak" />
      <PersonalNotes ref={refs} />
      <div className="pagebreak" />
      <Overview ref={refs} />
      <div className="pagebreak" />
      <CompanyDetails ref={refs} />
      <div className="pagebreak" />
      <PeopleAndOrganization ref={refs} />
      <div className="pagebreak" />
      <Risk ref={refs} />
      <div className="pagebreak" />
      {companyProfile.company.company_type !== 1 && <Products ref={refs} />}
      <div className="pagebreak" />
      <Procurement ref={refs} />
    </div>
  ) : (
    <Result
      status="warning"
      title={
        companyProfile.loadStatus === 403
          ? 'You dont have permission to see this'
          : 'There are some problems with your operation.'
      }
    />
  )
})
