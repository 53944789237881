import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import StoreContext from '../../storeContext'
import { Card, Result, Skeleton } from 'antd'
import { Link } from 'react-router-dom'
import { Folder } from '../CompanyProfile/Sections/PersonalNotes/Contracts'
import intl from 'react-intl-universal'
import { Loading } from '../loading'
import { ContractsStoreCalls } from './store/ContractsStore'
import { RoleGroups } from '../../utils/roles'

export default observer(function () {
  const { contracts, user } = useContext(StoreContext)
  useEffect(() => {
    contracts.getContracts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Loading blockers={[ContractsStoreCalls.GET_CONTRACTS]}>
      <Loading.Done>
        {contracts.contracts.length > 0 ? (
          <div>
            <div
              style={{
                fontWeight: '500',
                fontSize: '24px',
                color: '#1D2A3D',
                marginBottom: 30,
              }}
            >
              {intl.get('contracts')}
            </div>
            {contracts.contracts.map(
              (contract) =>
                contract.contracts.length > 0 && (
                  <Card
                    bodyStyle={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '1em',
                    }}
                    title={
                      <div style={{ color: '#1D2A3D' }}>
                        {contract.company_name}
                      </div>
                    }
                    extra={
                      <Link to={`/dashboard/company/${contract.company_id}`}>
                        {intl.get('viewCompanyProfile')}
                      </Link>
                    }
                  >
                    {contract.contracts
                      .slice()
                      .sort((a, b) => {
                        if (a.end_date && b.end_date) {
                          return new Date(b.end_date) - new Date(a.end_date)
                        }
                        if (a.end_date && !b.end_date) {
                          return 1
                        }
                        if (!a.end_date && b.end_date) {
                          return -1
                        }
                        return 0
                      })
                      .map((folder) => (
                        <Folder
                          folder={folder}
                          disableEdit={
                            !RoleGroups.ADMINS.includes(
                              user.user['custom:role_id'],
                            )
                          }
                          update={contracts.updContracts}
                          company_id={contract.company_id}
                        />
                      ))}
                  </Card>
                ),
            )}
          </div>
        ) : (
          <Result status="info" title={intl.get('noContracts')} />
        )}
      </Loading.Done>
      <Loading.InProgress>
        <Skeleton active />
      </Loading.InProgress>
    </Loading>
  )
})
