import React, { useContext } from "react";
import CsvUploader from "../../CsvUploader";
import intl from "react-intl-universal";
import StoreContext from "../../../storeContext";
import csvExample from "../../../documents/bulk_user_upload_example.csv";

export default function BulkUsers() {
  const { users } = useContext(StoreContext)

  return (
    <CsvUploader
      callback={(rows) => users.inviteUsers(rows)}
      buttonText={intl.get('bulkUserInviteButtonText')}
      csvExampleFile={csvExample}
      csvExplanationText={intl.get('bulkUserUploadCsvExplanation')}
    />
  )
}
