import React, { useContext } from 'react'
import { Modal } from 'antd'
import { Formik } from 'formik'
import { Switch, Form, SubmitButton, Input } from 'formik-antd'
import StoreContext from '../../../storeContext'
import intl from 'react-intl-universal'

export default function ({ visible, handleOnCancel }) {
  const { generalCriteria } = useContext(StoreContext)
  const initialValues = {
    tag_name: '',
    tag_description: '',
    tag_visible: true,
  }
  return (
    <Modal
      title={intl.get('addTag')}
      destroyOnClose={true}
      visible={visible}
      footer={null}
      onCancel={handleOnCancel}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (vals, { setSubmitting }) => {
          await generalCriteria.addUpdateTag(vals)
          await generalCriteria.getTags(true)
          setSubmitting(false)
          handleOnCancel()
        }}
      >
        {() => {
          return (
            <Form>
              <Form.Item name="tag_name">
                <Input
                  addonBefore={intl.get('tagName')}
                  name="tag_name"
                  placeholder={intl.get('tagName')}
                />
              </Form.Item>
              <Form.Item name="tag_description">
                <Input
                  addonBefore={intl.get('description')}
                  name="tag_description"
                  placeholder={intl.get('description')}
                />
              </Form.Item>
              <Form.Item name="tag_visible">
                <Switch
                  name="tag_visible"
                  checkedChildren={intl.get('hide')}
                  unCheckedChildren={intl.get('show')}
                />
              </Form.Item>
              <SubmitButton>{intl.get('show')}</SubmitButton>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
