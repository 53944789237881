import React, { useContext, useEffect, useRef, useState } from 'react'
import { COMPANY_PROFILE_TABS } from '../../index'
import { Card, Col, Row } from 'antd'
import EditButton from '../../EditButton'
import AddCompanyCertificate from './AddCompanyCertificate'
import EditCompanyCertificates from './EditCompanyCertificates'
import StoreContext from '../../../../storeContext'
import EditBusinessExpertise from './EditBusinessExpertise'
import BusinessExpertise, { Group } from './BusinessExpertise'
import { observer } from 'mobx-react'
import intl from 'react-intl-universal'
import TrendExpertise from './TrendExpertise'
import EditIndustryExpertise from './EditIndustryExpertise'
import LoadingWrapper from '../LoadingWrapper'
import AddSuccessStory from './SuccessStory/AddSuccessStory'
import SuccessStory from './SuccessStory'
import EditSuccessStories from './SuccessStory/EditSuccessStories'
import PreferredSupplier from './PreferredSupplier'
import InfoPopUp from '../../../InfoPopUp'
import ImplementationPartner from './ImplementationPartner'
import CertificateInfo from './CertificateInfo'

export default React.memo(
  observer(
    React.forwardRef(function (props, ref) {
      const element = useRef()
      const { companyProfile } = useContext(StoreContext)
      const [
        showEditBusinessExpertise,
        setShowEditBusinessExpertise,
      ] = useState(false)
      const [showAddCertificate, setAddCertificate] = useState(false)
      const [showUpdateCertificates, setShowUpdateCertificates] = useState(
        false,
      )
      const [
        showUpdateIndustryExpertise,
        setShowUpdateIndustryExpertise,
      ] = useState(false)
      const [showAddStory, setShowAddStory] = useState(false)
      const [showEditStory, setShowEditStory] = useState(false)
      const menuItems = [
        {
          name: intl.get('editBusinessExpertise'),
          callback: () => setShowEditBusinessExpertise(true),
        },
        {
          name: intl.get('editIndustryExpertise'),
          callback: () => setShowUpdateIndustryExpertise(true),
        },
        {
          name: intl.get('addCertificate'),
          callback: () => setAddCertificate(true),
        },
        {
          name: intl.get('editCertificates'),
          callback: () => setShowUpdateCertificates(true),
          show: companyProfile.company.certificates.length > 0,
        },
        {
          name: intl.get('addSuccessStory'),
          callback: () => setShowAddStory(true),
          show: companyProfile.company.company_type !== 1,
        },
        {
          name: intl.get('editSuccessStory'),
          callback: () => setShowEditStory(true),
          show:
            companyProfile.company.company_type !== 1 &&
            companyProfile.company.success_story.length > 0,
        },
      ]
      useEffect(() => {
        ref.current[COMPANY_PROFILE_TABS.overview.id] = element.current
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [element.current])
      return (
        <Card
          title={intl.get('overview')}
          extra={
            <div style={{ display: 'flex' }}>
              <EditButton menuItems={menuItems} />
              <InfoPopUp
                html={intl.get('capabilitiesInfoPopUp')}
                title={intl.get('overview')}
              />
            </div>
          }
        >
          <LoadingWrapper>
            <div ref={element} id={COMPANY_PROFILE_TABS.overview.id}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Card
                    type="inner"
                    bordered={false}
                    title={intl.get('businessExpertise')}
                    bodyStyle={{
                      padding:
                        companyProfile.expertiseView.length === 0 &&
                        companyProfile.getSelectedTrends().length === 0 &&
                        0,
                    }}
                  >
                    <BusinessExpertise />
                    <TrendExpertise />
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Card
                    bordered={false}
                    type="inner"
                    title={intl.get('industryExpertise')}
                    bodyStyle={{
                      padding: companyProfile.industryView.length === 0 && 0,
                    }}
                  >
                    {companyProfile.industryView.map((e) => (
                      <div>
                        <Group key={e.item_name} group={e} level={1} />
                      </div>
                    ))}
                  </Card>
                </Col>
                <PreferredSupplier />
                <ImplementationPartner />
                {companyProfile.company.certificates &&
                  companyProfile.company.certificates.length > 0 && (
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Card bordered={false} type="inner" title="Certificates">
                        {companyProfile.company.certificates.map((cert) => (
                          <CertificateInfo certificate={cert} />
                        ))}
                      </Card>
                    </Col>
                  )}
                {companyProfile.company.company_type !== 1 && (
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <SuccessStory
                      stories={
                        companyProfile.company.success_story
                          ? companyProfile.company.success_story
                          : []
                      }
                    />
                  </Col>
                )}
              </Row>
            </div>
            <AddCompanyCertificate
              handleOnCancel={() => setAddCertificate(false)}
              visible={showAddCertificate}
            />
            <EditCompanyCertificates
              visible={showUpdateCertificates}
              handleOnCancel={() => setShowUpdateCertificates(false)}
              certificates={
                companyProfile.company.certificates
                  ? companyProfile.company.certificates
                  : []
              }
            />
            <EditBusinessExpertise
              visible={showEditBusinessExpertise}
              handleOnCancel={() => setShowEditBusinessExpertise(false)}
            />
            <EditIndustryExpertise
              highest_revenue_industry={
                companyProfile.company.highest_revenue_industry
              }
              visible={showUpdateIndustryExpertise}
              handleOnCancel={() => setShowUpdateIndustryExpertise(false)}
            />
            {companyProfile.company.company_type !== 1 && (
              <>
                <AddSuccessStory
                  visible={showAddStory}
                  handleOnCancel={() => setShowAddStory(false)}
                />
                <EditSuccessStories
                  visible={showEditStory}
                  handleOnCancel={() => setShowEditStory(false)}
                  stories={
                    companyProfile.company.success_story
                      ? companyProfile.company.success_story
                      : []
                  }
                />
              </>
            )}
          </LoadingWrapper>
        </Card>
      )
    }),
  ),
)
