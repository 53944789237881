import { observer } from "mobx-react"
import React, { useContext, useEffect } from "react"
import StoreContext from "../../../../storeContext"
import { Form, Select, SubmitButton } from "formik-antd"
import { Modal } from "antd"
import { Formik } from "formik"
import { useParams } from "react-router"
import * as Yup from 'yup'
import intl from "react-intl-universal";

export default observer(function ({ visible, handleOnCancel, industry_codes }) {
  const {generalCriteria, companyProfile} = useContext(StoreContext)
  const company_id = useParams().id
  const validation = Yup.object({
    industry_codes: Yup.array().required('At least one industry code is required')
  })
  useEffect(()=>{
    generalCriteria.getCodes(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const initialValues = {
    industry_codes,
  }
  const renderCodes = ()=> generalCriteria.codes.map(code=>{
    return <Select.Option value={code.code}>{`${code.category} - ${code.subcategory} (${code.description_en} ${code.code}) `}</Select.Option>
  })
  return (
    <Modal
      destroyOnClose={true}
      title={intl.get('editIndustryCodes')}
      visible={visible}
      width="80%"
      footer={null}
      onCancel={handleOnCancel}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        enableReinitialize={true}
        onSubmit={async (vals, {setSubmitting}) => {
          const codes = generalCriteria.codes.filter((c)=> vals.industry_codes.includes(c.code))
          await companyProfile.updateCompany({industry_codes: codes, company_id})
          setSubmitting(false)
          handleOnCancel(false)
        }}
      >
        {() => {
          return (
            <Form>
              <Form.Item name="industry_codes">
                <Select mode="multiple" name="industry_codes" placeholder={intl.get('selectIndustryCodes')}>
                  {renderCodes()}
                </Select>
              </Form.Item>
              <SubmitButton>{intl.get('save')}</SubmitButton>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
})
