import React, { useContext } from "react"
import StoreContext from "../../../../storeContext"
import { Modal } from "antd"
import { Formik } from "formik"
import { Form, Input, SubmitButton } from "formik-antd"
import { useParams } from "react-router"
import intl from "react-intl-universal";

export default function(props) {
  const {
    visible,
    handleOnCancel,
    links = {
      media_release_url: '',
      annual_report_url: '',
      financial_report_url: '',
    },
  } = props
  const company_id = useParams().id
  const { companyProfile } = useContext(StoreContext)

  const initialValues = {
    company_id,
    company_reports: {
      media_release_url: links.media_release_url,
      annual_report_url: links.annual_report_url,
      financial_report_url: links.financial_report_url,
    },
  }
  return (
    <Modal
      title={intl.get('editHyperlinks')}
      visible={visible}
      footer={null}
      onCancel={handleOnCancel}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (vals, { setSubmitting }) => {
          await companyProfile.updateCompany(vals)
          setSubmitting(false)
          handleOnCancel()
        }}
      >
        {() => {
          return (
            <Form>
              <Form.Item name="company_reports.media_release_url">
                <Input
                  addonBefore={intl.get('mediaRelease')}
                  name="company_reports.media_release_url"
                  placeholder={intl.get('mediaRelease')}
                />
              </Form.Item>
              <Form.Item name="company_reports.annual_report_url">
                <Input
                  addonBefore={intl.get('annualReport')}
                  name="company_reports.annual_report_url"
                  placeholder={intl.get('annualReport')}
                />
              </Form.Item>
              <Form.Item name="company_reports.financial_report_url">
                <Input
                  addonBefore={intl.get('financialReport')}
                  name="company_reports.financial_report_url"
                  placeholder={intl.get('financialReport')}
                />
              </Form.Item>

              <SubmitButton>{intl.get('save')}</SubmitButton>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}