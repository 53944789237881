import React, { useContext } from "react";
import intl from "react-intl-universal";
import { Formik } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import * as Yup from "yup";
import StoreContext from "../../../../storeContext";

export default function ({ close, note }) {
  const {companyProfile} = useContext(StoreContext)
  const initialValue = {
    note: note ? note.note : '',
  }
  const validation = Yup.object().shape({
    note: Yup.string().required('Note is required'),
  })
  return (
    <Formik
      initialValues={initialValue}
      validationSchema={validation}
      onSubmit={async (vals) =>{
        if(note){
          await companyProfile.personalNote({
            edit: {...vals, note_id: note.note_id},
            company_id: companyProfile.company.company_id
          })
        }else{
          await companyProfile.personalNote({
            add: vals,
            company_id: companyProfile.company.company_id
          })
        }
        close()
      }}
    >
      {() => (
        <Form>
          <Form.Item name="note">
            <Input.TextArea
              placeholder={intl.get('addPersonalNote')}
              name="note"
            />
          </Form.Item>
          <SubmitButton>{intl.get('save')}</SubmitButton>
        </Form>
      )}
    </Formik>
  )
}
