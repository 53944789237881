import { Button, Card, Input, Table, Typography } from 'antd'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import StoreContext from '../../../storeContext'
import intl from 'react-intl-universal'
import moment from 'moment'
import EditProfileField from '../../Profile/EditProfileField'
import { Form, Select } from 'formik-antd'
import RiskReview from '../RiskReview'
import GenerateReportButton from '../GenerateReportButton'
import ReportHistory from '../ReportHistory'
import Refetch from './Refetch'
import { CSVLink } from 'react-csv'
import ActionButton from '../../shared/ActionButton'
import InfoPopUp from '../../InfoPopUp'

export default observer(function ScreeningReport() {
  const { vetting, compare } = useContext(StoreContext)
  const [loading, setLoading] = useState(true)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setIsReady(true)
  }, [])
  const [search, setSearch] = useState('')
  const [companies, setCompanies] = useState([])
  useEffect(() => {
    compare.loadCompanies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setCompanies(compare.allCompanies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compare.allCompanies])
  const columns = [
    {
      title: intl.get('nameLabel'),
      dataIndex: 'first_name',
      fixed: true,
      sorter: (a, b) => a['first_name'].localeCompare(b['first_name']),
      sortDirections: ['descend', 'ascend'],
      width: 200,
    },
    {
      title: intl.get('surnameLabel'),
      dataIndex: 'last_name',
      sorter: (a, b) => a['last_name'].localeCompare(b['last_name']),
      sortDirections: ['descend', 'ascend'],
      width: 200,
    },
    {
      title: intl.get('nationalityLabel'),
      dataIndex: 'nationality',
      sorter: (a, b) => a['nationality'].localeCompare(b['nationality']),
      sortDirections: ['descend', 'ascend'],
      width: 120,
    },

    {
      title: intl.get('resourceId'),
      dataIndex: 'resource_id',
      sorter: (a, b) => a['resource_id'].localeCompare(b['resource_id']),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <EditProfileField
          value={text}
          icon="resource_id"
          type="cell"
          callback={async (value, setEdit) => {
            await vetting.sendRequest({
              ...value,
              resource_vetting_request_id: record.resource_vetting_request_id,
            })
            setEdit(false)
          }}
          label={intl.get('resourceId')}
          name="resource_id"
        />
      ),
      width: 250,
    },
    {
      title: intl.get('clientName'),
      dataIndex: 'client_name',
      sorter: (a, b) => a['client_name'].localeCompare(b['client_name']),
      sortDirections: ['descend', 'ascend'],
      width: 300,
      render: (text, record) => (
        <EditProfileField
          value={record.client_name}
          component={() => {
            return (
              <Form.Item name="client_name">
                <Select
                  name="client_name"
                  showSearch={true}
                  filterOption={false}
                  placeholder="Select client"
                  onSearch={(txt) => {
                    setCompanies(
                      compare.allCompanies.filter((company) =>
                        company.name.toLowerCase().includes(txt.toLowerCase()),
                      ),
                    )
                  }}
                >
                  <Select.Option value={'N/A'}>N/A</Select.Option>
                  {companies.map((c) => (
                    <Select.Option key={c.company_id} value={c.name}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )
          }}
          name="client_name"
          callback={async (value, setEdit) => {
            await vetting.sendRequest({
              ...value,
              resource_vetting_request_id: record.resource_vetting_request_id,
            })
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: intl.get('report'),
      width: 150,
      render: (text, record) =>
        record.aml_details.length
          ? isReady && (
              <GenerateReportButton
                record={record}
                aml={record.aml_details[0]}
              />
            )
          : intl.get('noReport'),
    },
    {
      title: intl.get('birthDateLabel'),
      dataIndex: 'birth_date',
      sorter: (a, b) => moment(a['birth_date']) - moment(b['birth_date']),
      render: (text) => {
        const d = moment(text, 'DD/MM/YYYY')
        return d.isValid()
          ? d.format('DD/MM/YYYY')
          : moment(text).format('DD/MM/YYYY')
      },
      width: 200,
    },
    {
      title: intl.get('riskIndicator'),
      dataIndex: 'riskIndicator',
      render: (text, record) =>
        record.aml_details.length ? record.aml_details[0].aml_rating : 'N/A',
      width: 150,
      sorter: (a, b) => {
        let first = a.aml_details[0].aml_rating || ''
        let second = b.aml_details[0].aml_rating || ''

        if (first < second) {
          return 1
        }
        if (first > second) {
          return -1
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: intl.get('riskReview'),
      dataIndex: 'riskReview',
      render: (text, record) => {
        return record.aml_details.length ? (
          <RiskReview
            aml={record.aml_details[0]}
            id={record.resource_vetting_request_id}
          />
        ) : (
          'N/A'
        )
      },
      width: 150,
      sorter: (a, b) => {
        let first = a.aml_details[0].risk_review_rating || ''
        let second = b.aml_details[0].risk_review_rating || ''

        if (first > second) return 1
        if (second > first) return -1
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: intl.get('lastVettingDate'),
      dataIndex: 'lastVettingDate',
      render: (text, record) =>
        record.aml_details.length ? (
          <Refetch record={record} />
        ) : (
          moment(record.created_date).format('DD/MM/YYYY')
        ),
      width: 200,
      sorter: (a, b) => moment(a['last_updated']) - moment(b['last_updated']),
    },
    {
      title: intl.get('updateExists'),
      dataIndex: 'updateExists',
      render: (text, record) =>
        record.updateExists ? (
          <div
            style={{
              width: 30,
              height: 30,
              borderRadius: '100%',
              margin: '0 auto',
              backgroundColor: '#067fed',
            }}
          />
        ) : (
          '--'
        ),
      width: 150,
      sorter: (a, b) =>
        a.updateExists === b.updateExists ? 0 : a.updateExists ? -1 : 1,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.get('reportStatus'),
      dataIndex: 'status',
      render: (text, record) =>
        record.aml_details.length
          ? intl.get('caseReady')
          : intl.get('caseCreated'),
      width: 150,
      sorter: (a, b) => a.aml_details.length - b.aml_details.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.get('vettingNumber'),
      dataIndex: 'vetting_number',
      render: (text, record) =>
        record.aml_details.length ? record.aml_details.length : 'N/A',
      width: 150,
      sorter: (a, b) => a.aml_details.length - b.aml_details.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.get('reportHistory'),
      dataIndex: 'history',
      render: (text, record) =>
        record.aml_details.length ? <ReportHistory record={record} /> : 'N/A',
      width: 150,
    },

    {
      title: intl.get('usersTableColumnActions'),
      render: (text, record) => (
        <ActionButton
          record={record}
          text={intl.get('delete')}
          showModal={true}
          confirmTitle={intl.get('deleteReportQuestion')}
          onClick={() =>
            vetting.sendRequest({
              delete_request: true,
              resource_vetting_request_id: record.resource_vetting_request_id,
            })
          }
        />
      ),
      width: 150,
    },
  ]
  useEffect(() => {
    vetting.loadReport().then(() => setLoading(false))
    // eslint-disable-next-line
  }, [])
  const headers = [
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Nationality', key: 'nationality' },
    { label: 'Birth date', key: 'birth_date' },
    { label: 'Client name', key: 'client_name' },
    { label: 'Rating', key: 'aml_details.0.aml_rating' },
    { label: 'Risk review', key: 'aml_details.0.risk_review_rating' },
    { label: 'Risk description', key: 'aml_details.0.risk_review_description' },
    {
      label: 'Last vetting date',
      key: 'aml_details.0.report_date',
    },
  ]
  return (
    <Card
      title={intl.get('screeningReportTable')}
      extra={
        <InfoPopUp
          html={intl.get('reportVettingDescriptionContent')}
          title={intl.get('reportVettingDescriptionTitle')}
        />
      }
    >
      <Input.Search
        placeholder="Search"
        onSearch={setSearch}
        style={{ width: 200 }}
      />
      <Button disabled={vetting.report.length === 0} style={{ margin: 16 }}>
        {vetting.report.length > 0 && (
          <CSVLink
            filename="screening_table.csv"
            data={vetting.report.filter((r) => r.aml_details.length > 0)}
            headers={headers}
          >
            {intl.get('downloadCSV')}
          </CSVLink>
        )}
      </Button>
      <Table
        rowKey="resource_vetting_request_id"
        bordered
        loading={loading}
        columns={columns}
        dataSource={vetting.report.filter((comp) => {
          const allValues = Object.values(comp).join(' ').toLowerCase()
          return allValues.includes(search.toLowerCase())
        })}
        scroll={{ x: 1100 }}
      />
      <Typography>
        Requests made prior to the 20th July 2021 are powered by Refintiv
        Worldcheck data and their privacy policy applies. It can be found here:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.refinitiv.com/en/products/world-check-kyc-screening/privacy-statement"
        >
          https://www.refinitiv.com/en/products/world-check-kyc-screening/privacy-statement
        </a>
        . <br />
        All requests made starting 20th of July 2021 are based upon info4c data
        and their privacy policy applies. It can be found here:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.info4c.net/legal-information/data-privacy-policy/"
        >
          https://www.info4c.net/legal-information/data-privacy-policy/
        </a>
      </Typography>
    </Card>
  )
})
