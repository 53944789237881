import React, { useContext, useState } from 'react'
import { Button, Modal, Space } from 'antd'
import intl from 'react-intl-universal'
import FileUpload from '../../../FileUpload'
import StoreContext from '../../../../storeContext'
import { useParams } from 'react-router'
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'

export default function ({ visible, handleOnCancel, english, german }) {
  const [presentation_en, setEnglish] = useState('')
  const [presentation_de, setGerman] = useState('')
  const company_id = useParams().id
  const { companyProfile } = useContext(StoreContext)
  const [editing, setEditing] = useState(false)
  return (
    <Modal
      title={intl.get('editPresentation')}
      visible={visible}
      destroyOnClose={true}
      footer={null}
      onCancel={()=>{
        handleOnCancel()
        setEnglish('')
        setGerman('')
      }}
    >
      <div>
        <FileUpload
          errorMessage={intl.get('presentationInvalidFormat')}
          buttonText={intl.get('uploadPresentationEn')}
          remove={() => setEnglish('')}
          setFile={(file) => setEnglish(file)}
        />
        <Space>
          <Button
            type="primary"
            onClick={async () => {
              setEditing(true)
             await companyProfile.updateCompany({
                company_id,
                presentation_add: { presentation_en },
              })
              setEditing(false)
              setEnglish('')
            }}
            disabled={!presentation_en || editing}
          >
            {intl.get('save')}
            {editing && <LoadingOutlined />}
          </Button>
          {english && (
            <Button
              onClick={async () => {
                setEditing(true)
                await companyProfile.updateCompany({
                  company_id,
                  presentation_remove: {
                    presentation_en: english,
                  },
                })
                setEditing(false)
              }}
              type="primary"
              disabled={editing}
            >
              {intl.get('delete')}
              {editing && <LoadingOutlined />}
            </Button>
          )}
        </Space>
      </div>
      <div>
        <FileUpload
          errorMessage={intl.get('presentationInvalidFormat')}
          buttonText={intl.get('uploadPresentationDe')}
          remove={() => setGerman('')}
          setFile={(file) => setGerman(file)}
        />
        <Space>
          <Button
            type="primary"
            onClick={async () => {
              setEditing(true)
              await companyProfile.updateCompany({
                company_id,
                presentation_add: { presentation_de },
              })
              setGerman('')
              setEditing(false)
            }}
            disabled={!presentation_de || editing}
          >
            {intl.get('save')}
            {editing && <LoadingOutlined />}
          </Button>
          {german && (
            <Button
              onClick={async () => {
                setEditing(true)
                await companyProfile.updateCompany({
                  company_id,
                  presentation_remove: {
                    presentation_de: german,
                  },
                })
                setEditing(false)
              }}
              type="primary"
              disabled={editing}
            >
              {intl.get('delete')}
              {editing && <LoadingOutlined />}
            </Button>
          )}
        </Space>
      </div>
    </Modal>
  )
}
