import React, { useContext, useState } from 'react'
import { Button, Divider, List, Modal, Table } from 'antd'
import StoreContext from '../../storeContext'
import { observer } from 'mobx-react'
import EditProfileField from '../Profile/EditProfileField'
import IndustryCodeCell from './IndustryCodeCell'
import { Form, Select } from 'formik-antd'
import intl from 'react-intl-universal'
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'
import { CompanyImportStoreCalls } from './store/CompanyImportStore'
import { Loading } from '../loading'
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
const {confirm} = Modal
const RenderListItem = (item) => (
  <List.Item>
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <span>{item.name}</span>
      <span>{item.vat}</span>
    </div>
  </List.Item>
)

export default observer(function () {
  const { companiesImport } = useContext(StoreContext)
  const [deleting, setDeleting] = useState('')

  const notImportedModal = () => {
    Modal.info({
      title: intl.get('notImportedCompaniesTitle'),
      content: (
        <>
          {companiesImport.importStatus.company_already_exists.length > 0 && (
            <>
              <Divider>{intl.get('companiesAlreadyExists')}</Divider>
              <List
                size="small"
                dataSource={companiesImport.importStatus.company_already_exists}
                renderItem={RenderListItem}
              />
            </>
          )}
          {companiesImport.importStatus.duns_not_found.length > 0 && (
            <>
              <Divider>{intl.get('dunsNotFound')}</Divider>
              <List
                size="small"
                dataSource={companiesImport.importStatus.duns_not_found}
                renderItem={RenderListItem}
              />
            </>
          )}
          {companiesImport.importStatus.exception.length > 0 && (
            <>
              <Divider>{intl.get('importException')}</Divider>
              <List
                size="small"
                dataSource={companiesImport.importStatus.exception}
                renderItem={RenderListItem}
              />
            </>
          )}
          {companiesImport.importStatus.no_industry_code_list.length > 0 && (
            <>
              <Divider>{intl.get('importNoIndustryCode')}</Divider>
              <List
                size="small"
                dataSource={companiesImport.importStatus.no_industry_code_list}
                renderItem={RenderListItem}
              />
            </>
          )}
        </>
      ),
      onOk: async () => {},
    })
  }

  const cols = () => {
    return [
      {
        title: intl.get('columnCompanyName'),
        dataIndex: 'name',
        fixed: true,
        width: 350,
        render: (text, record) => (
          <EditProfileField
            value={text}
            name="name"
            callback={async (value, setEdit) => {
              await companiesImport.update({
                company_id: record.company_id,
                ...value,
              })
              setEdit(false)
            }}
            type="cell"
          />
        )
      },{
        title: intl.get('columnCompanyType'),
        width: 250,
        render: (text, record) => {
          return (
            <EditProfileField
              value={record.company_type}
              valueLabel={record.company_type === 0 ? 'Both' : record.company_type === 1 ? 'Buyer' : 'Supplier'}
              noValueLabel="No data"
              component={() => {
                return (
                  <Form.Item name="company_type">
                    <Select
                      name="company_type"
                      placeholder="Select company size"
                    >
                      <Select.Option value={0}>
                        Both
                      </Select.Option>
                      <Select.Option value={1}>
                        {intl.get('buyerLabel')}
                      </Select.Option>
                      <Select.Option value={2}>
                        {intl.get('sellerLabel')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                )
              }}
              name="company_type"
              callback={async (value, setEdit) => {
                await companiesImport.update({
                  company_id: record.company_id,
                  ...value,
                })
                setEdit(false)
              }}
              type="cell"
            />
          )
        },
      },
      {
        title: intl.get('columnEmployeeNumber'),
        dataIndex: 'employeeCount',
        width: 250,
        render: (text, record) => (
          <EditProfileField
            value={text}
            name="employeeCount"
            callback={async (value, setEdit) => {
              await companiesImport.update({
                company_id: record.company_id,
                ...value,
              })
              setEdit(false)
            }}
            type="cell"
          />
        ),
      },
      {
        title: intl.get('companyDomain'),
        dataIndex: 'company_domain',
        width: 400,
        render: (text, record) => (
          <EditProfileField
            value={text}
            name="company_domain"
            callback={async (value, setEdit) => {
              await companiesImport.update({
                company_id: record.company_id,
                ...value,
              })
              setEdit(false)
            }}
            type="cell"
          />
        ),
      },
      {
        title: intl.get('companyUrl'),
        dataIndex: 'company_url',
        width: 400,
        render: (text, record) => (
          <EditProfileField
            value={text}
            name="company_url"
            callback={async (value, setEdit) => {
              await companiesImport.update({
                company_id: record.company_id,
                ...value,
              })
              setEdit(false)
            }}
            type="cell"
          />
        ),
      },
      {
        title: intl.get('columnEmployeeCountReliability'),
        width: 150,
        dataIndex: 'employeeCountReliability',
        render: (text, record) =>
          !record.employeeCountReliability
            ? 'No data'
            : record.employeeCountReliability,
      },
      {
        title: intl.get('columnVat'),
        width: 200,
        dataIndex: 'vat',
      },
      {
        title: intl.get('columnIndustryCodes'),
        width: 450,
        render: (text, record) => {
          return <IndustryCodeCell record={record} />
        },
      },
      {
        title: intl.get('columnCompanySize'),
        width: 250,
        render: (text, record) => {
          return (
            <EditProfileField
              value={record.companySizeType}
              noValueLabel="No data"
              component={() => {
                return (
                  <Form.Item name="companySizeType">
                    <Select
                      name="companySizeType"
                      placeholder={intl.get('selectCompanySize')}
                    >
                      <Select.Option value={'big_firm'}>
                        {intl.get('bigFirm')}
                      </Select.Option>
                      <Select.Option value={'boutique'}>
                        {intl.get('boutique')}
                      </Select.Option>
                      <Select.Option value={'agency'}>
                        {intl.get('agency')}
                      </Select.Option>
                      <Select.Option value={'one_man'}>
                        {intl.get('oneManFirm')}
                      </Select.Option>
                      <Select.Option value={'start_up'}>
                        {intl.get('startUp')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                )
              }}
              name="companySizeType"
              callback={async (value, setEdit) => {
                await companiesImport.update({
                  company_id: record.company_id,
                  ...value,
                })
                setEdit(false)
              }}
              type="cell"
            />
          )
        },
      },
      {
        title: intl.get('usersTableColumnActions'),
        fixed: 'right',
        width: 200,
        render: (text, record) => (
          <Button
            disabled={record.company_id === deleting}
            onClick={async () => {
              confirm({
                title: intl.get('importConfirmDeleteCompany'),
                icon: <ExclamationCircleOutlined />,
                onOk: async () => {
                  setDeleting(record.company_id)
                  await companiesImport.delete(record.company_id)
                  setDeleting('')
                },
              })
            }}
          >
            {deleting === record.company_id ? (
              <>
                <LoadingOutlined /> {intl.get('deleting')}
              </>
            ) : (
              intl.get('delete')
            )}
          </Button>
        ),
        dataIndex: 'action',
      },
    ]
  }
  return (
    <>
      <div
        style={{
          marginTop: 8,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        <Button
          type="link"
          onClick={notImportedModal}
          style={{ marginBottom: 15, marginRight: 15 }}
        >
          {companiesImport.importStatus.company_already_exists.length > 0 &&
            `${intl.get('companiesAlreadyExists')}: ${
              companiesImport.importStatus.company_already_exists.length
            } `}
          {companiesImport.importStatus.duns_not_found.length > 0 &&
            `${intl.get('dunsNotFound')}: ${
              companiesImport.importStatus.duns_not_found.length
            } `}
          {companiesImport.importStatus.exception.length > 0 &&
            `${intl.get('importException')}: ${
              companiesImport.importStatus.exception.length
            } `}
          {companiesImport.importStatus.no_industry_code_list.length > 0 &&
          `${intl.get('importNoIndustryCode')}: ${
            companiesImport.importStatus.no_industry_code_list.length
          } `}
        </Button>
        {(companiesImport.importStatus.company_already_exists.length ||
          companiesImport.importStatus.duns_not_found.length ||
          companiesImport.importStatus.exception.length ||
          companiesImport.importStatus.no_industry_code_list.length ||
          companiesImport.companies.length > 0) && (
          <Loading blockers={[CompanyImportStoreCalls.FINISH_IMPORT]}>
            <Loading.InProgress>
              <Button>
                <LoadingOutlined />
                {intl.get('finishImport')}
              </Button>
              <Button>
                <LoadingOutlined />
                {intl.get('cancelImport')}
              </Button>
            </Loading.InProgress>
            <Loading.Done>
              <Button type="primary" onClick={() => companiesImport.finish(false)}>
                {intl.get('finishImport')}
              </Button>
              <Button type="primary" onClick={() => companiesImport.finish(true)}>
                {intl.get('cancelImport')}
              </Button>
            </Loading.Done>
          </Loading>
        )}
      </div>
      <Table
        rowKey="company_id"
        columns={cols(companiesImport.update)}
        dataSource={companiesImport.companies}
        bordered={true}
        scroll={{ x: 1500 }}
        pagination={{
          total: companiesImport.companies.length,
          showSizeChanger: true,
          defaultPageSize: 10,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    </>
  )
})
