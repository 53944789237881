import { Button, Card, Divider, List, Modal } from 'antd'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import StoreContext from '../../storeContext'
import GenericRequest from './GenericRequest'
import MessageRequest from './MessageRequest'
import intl from 'react-intl-universal'

export default observer(function ActiveRequests({ type }) {
  const { requests } = useContext(StoreContext)
  const [data, setData] = useState([])
  useEffect(() => {
    if (requests.requests.length > 0) {
      const types = requests.requestTypes
        .filter((t) => t.selected)
        .map((t) => t.value)
      setData(
        requests.requests
          .filter((r) => types.includes(r.request_type))
          .filter((r) => r.request_status === type),
      )
    }
    // eslint-disable-next-line
  }, [requests.requests, requests.requestTypes])
  const showNote = (note, title) => {
    Modal.info({
      title: title,
      content: <div>{note}</div>,
      onOk() {},
    })
  }
  return (
    <Card
      type="inner"
      bordered={false}
      title={type === 'active' ? 'Active' : 'Archived'}
    >
      <List
        itemLayout="horizontal"
        dataSource={data}
        pagination={true}
        renderItem={(item) => {
          if (item.request_type === 'message_sent')
            return <MessageRequest request={item} extra={type === 'active'} />
          if (item.request_type === 'compare_companies') {
            return (
              <GenericRequest
                avatar="CC"
                date={item.date_created}
                title={
                  <>
                    {`${intl.get('requestType')} ${intl.get(
                      'compareCompanies',
                    )}`}{' '}
                    <Divider type="vertical" />{' '}
                    {`${intl.get(
                      'companies',
                    )}: ${item.description.companies
                      .map((c) => c.name)
                      .join(',')}`}
                  </>
                }
                extra={type === 'active'}
                id={item.user_request_id}
              />
            )
          }
          if (item.request_type === 'private_rate_card_request')
            return (
              <GenericRequest
                avatar="PRC"
                date={item.date_created}
                title={
                  <>
                    {`${intl.get('requestType')} ${intl.get(
                      'privateRateCardRequested',
                    )}`}{' '}
                    <Divider type="vertical" />{' '}
                    {`Company: ${item.description.company_name}`}
                  </>
                }
                extra={type === 'active'}
                id={item.user_request_id}
              />
            )
          if (item.request_type === 'request_self_declaration')
            return (
              <GenericRequest
                avatar="SD"
                date={item.date_created}
                title={
                  <>
                    {`${intl.get('requestType')} ${intl.get(
                      'selfDeclarationRequested',
                    )}`}{' '}
                    <Divider type="vertical" />{' '}
                    {`Company: ${item.description.company_name}`}
                  </>
                }
                secondary={`Type: ${item.description.survey_type}`}
                extra={type === 'active'}
                id={item.user_request_id}
              />
            )
          if (item.request_type === 'request_premium_risk_data')
            return (
              <GenericRequest
                avatar="RD"
                date={item.date_created}
                title={
                  <>
                    {`${intl.get('requestType')} ${intl.get(
                      'premiumRiskDataRequest',
                    )}`}{' '}
                    <Divider type="vertical" />{' '}
                    {`Company: ${item.description.company_name}`}
                  </>
                }
                extra={type === 'active'}
                id={item.user_request_id}
              />
            )
          if (
            item.request_type === 'internal_note_add' ||
            item.request_type === 'personal_note_add'
          )
            return (
              <GenericRequest
                extra={type === 'active'}
                id={item.user_request_id}
                actions={[
                  <Button
                    style={{ margin: 0, padding: 0 }}
                    type="link"
                    key="archive"
                    onClick={() =>
                      showNote(item.description.note, 'Note added')
                    }
                  >
                    Show note
                  </Button>,
                ]}
                avatar={item.request_type === 'internal_note_add' ? 'IN' : 'PN'}
                date={item.date_created}
                title={`${intl.get('requestType')} 
                    ${
                      item.request_type === 'internal_note_add'
                        ? intl.get('internalNoteAdded')
                        : intl.get('personalNoteAdded')
                    }`}
              />
            )
          if (
            item.request_type === 'internal_note_delete' ||
            item.request_type === 'personal_note_delete'
          )
            return (
              <GenericRequest
                extra={type === 'active'}
                id={item.user_request_id}
                actions={[
                  <Button
                    style={{ margin: 0, padding: 0 }}
                    type="link"
                    key="archive"
                    onClick={() =>
                      showNote(item.description.note, 'Note deleted')
                    }
                  >
                    Show note
                  </Button>,
                ]}
                avatar={
                  item.request_type === 'internal_note_delete' ? 'IN' : 'PN'
                }
                date={item.date_created}
                title={`${intl.get('requestType')} 
                    ${
                      item.request_type === 'internal_note_delete'
                        ? intl.get('internalNoteDeleted')
                        : intl.get('personalNoteDelete')
                    }`}
              />
            )
          if (
            item.request_type === 'internal_note_edit' ||
            item.request_type === 'personal_note_edit'
          )
            return (
              <GenericRequest
                extra={type === 'active'}
                id={item.user_request_id}
                actions={[
                  <Button
                    style={{ margin: 0, padding: 0 }}
                    type="link"
                    key="archive"
                    onClick={() =>
                      showNote(item.description.note, 'Note edited')
                    }
                  >
                    Show note
                  </Button>,
                ]}
                avatar={
                  item.request_type === 'internal_note_edit' ? 'IN' : 'PN'
                }
                date={item.date_created}
                title={`${intl.get('requestType')} 
                ${
                  item.request_type === 'internal_note_edit'
                    ? intl.get('internalNoteEdited')
                    : intl.get('personalNoteEdited')
                }`}
              />
            )
          if (item.request_type === 'contract_folder_change') {
            return (
              <GenericRequest
                extra={type === 'active'}
                id={item.user_request_id}
                avatar="CF"
                date={item.date_created}
                title={
                  <>
                    {`${intl.get('requestType')} ${intl.get(
                      'contractFolderChanged',
                    )}`}{' '}
                    <Divider type="vertical" />{' '}
                    {`${intl.get('changedBy', {
                      0: item.description.updated_by,
                    })}`}
                  </>
                }
                secondary={`${intl.get('contractFolderName')}: ${
                  item.description.folder_name
                }`}
              />
            )
          }
          if (item.request_type === 'invite_company')
            return (
              <GenericRequest
                extra={type === 'active'}
                id={item.user_request_id}
                avatar="CI"
                date={item.date_created}
                title={
                  <>
                    {`${intl.get('requestType')} ${intl.get('companyInvite')}`}{' '}
                    <Divider type="vertical" />{' '}
                    {`${intl.get('changedBy', {
                      0: item.description.updated_by,
                    })}`}
                  </>
                }
                secondary={`${intl.get('contractInfo')} ${
                  item.description.company_country
                }, ${item.description.contact_email}`}
              />
            )
          if (item.request_type === 'company_list_change') {
            return (
              <GenericRequest
                extra={type === 'active'}
                id={item.user_request_id}
                avatar="LC"
                date={item.date_created}
                title={
                  <>{`${intl.get('requestType')} ${intl.get('listChanged')}`}</>
                }
              />
            )
          }
          if (item.request_type === 'company_update') {
            return (
              <GenericRequest
                extra={type === 'active'}
                id={item.user_request_id}
                avatar="CU"
                date={item.date_created}
                title={
                  <>{`${intl.get('requestType')} ${intl.get(
                    'company_update',
                  )}`}</>
                }
              />
            )
          }
          return null
        }}
      />
    </Card>
  )
})
