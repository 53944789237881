import React from "react";
import { Col, Row } from "antd";
import { observer } from "mobx-react";
import TermsTable from "./TermsTable";
import { TermsTypes } from "./store/TermsStore";
import TermsUpload from "./TermsUpload";

export default observer(function Users() {
  return (
    <>
      <Row>
        <Col xl={8} lg={12} md={24} sm={24}>
          <TermsUpload type={TermsTypes.OF_USE} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TermsTable type={TermsTypes.OF_USE} />
        </Col>
      </Row>
    </>
  )
})
