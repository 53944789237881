export const Roles = Object.freeze(
  {
    TITAN_ADMIN: '0dee4bda-1542-470a-b41d-b3d2da461537',
    TITAN_MASTER_ADMIN: 'b5c03114-c060-4bd8-89e3-bbc188933553',
    CLIENT_USER: '207bf36b-1523-44ba-9bec-0fff21b5d9e0',
    CLIENT_ADMIN: 'e59e0989-1d68-413d-8ef0-d2f7eadd5561',
    VISITOR: '0'
  }
)

export const RoleGroups = Object.freeze({
    ALL_USERS: [Roles.CLIENT_ADMIN, Roles.TITAN_MASTER_ADMIN, Roles.TITAN_ADMIN, Roles.VISITOR, Roles.CLIENT_USER],
    ADMINS: [Roles.CLIENT_ADMIN, Roles.TITAN_ADMIN, Roles.TITAN_MASTER_ADMIN],
    LOGGED_IN: [Roles.CLIENT_ADMIN, Roles.CLIENT_USER, Roles.TITAN_MASTER_ADMIN, Roles.TITAN_ADMIN],
    VISITOR: [Roles.VISITOR],
    TITANS: [Roles.TITAN_ADMIN, Roles.TITAN_MASTER_ADMIN]
})

export const RolesName = Object.freeze(
  {
    '0dee4bda-1542-470a-b41d-b3d2da461537': 'Titan admin',
    'b5c03114-c060-4bd8-89e3-bbc188933553': 'Titan master admin',
    '207bf36b-1523-44ba-9bec-0fff21b5d9e0': 'Client user',
    'e59e0989-1d68-413d-8ef0-d2f7eadd5561': 'Client admin'
  }
)