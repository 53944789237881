import React, { useContext, useEffect, useState } from 'react'
import { Button, message, Modal, Upload } from 'antd'
import { Formik } from 'formik'
import { DatePicker, Form, Input, Select, SubmitButton } from 'formik-antd'
import StoreContext from '../../../../storeContext'
import { UploadOutlined } from '@ant-design/icons'
import intl from 'react-intl-universal'
import { useParams } from 'react-router'
import * as moment from 'moment'

export default function ({ handleOnCancel, visible }) {
  const company_id = useParams().id
  const { generalCriteria, companyProfile } = useContext(StoreContext)
  const [disableName, setDisableName] = useState(true)
  const [fileList, setFileList] = useState([])
  function onRemoveFile(set) {
    set('certificate_file', '')
    setFileList([])
  }
  function onFileChange(file, set) {
    if (!file.name.includes('.pdf')) {
      message.error('File must be pdf')
      return false
    }
    setFileList([file])
    const reader = new FileReader()
    reader.onload = () => {
      set('certificate_file', reader.result.split(',')[1])
    }
    reader.readAsDataURL(file)
    return false
  }
  useEffect(() => {
    generalCriteria.getAllCertificates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const initialValues = {
    certificate: '',
    certificate_name: '',
    certificate_description: '',
    certification_authority: '',
    certificate_number: '',
    certificate_file: '',
    certificate_valid_until: null,
  }
  const renderCertificates = () =>
    generalCriteria.certificates.map((cert) => {
      return (
        <Select.Option value={cert.certificate_id}>
          {cert.certificate_name_en}
        </Select.Option>
      )
    })
  const onChangeCertificate = (val, setVal) => {
    if (val) {
      const cert = generalCriteria.certificates.find(
        (cert) => cert.certificate_id === val,
      )
      setVal('certificate_name', cert.certificate_name_en)
      setDisableName(true)
    } else {
      setVal('certificate_name', '')
      setDisableName(false)
    }
  }
  return (
    <Modal
      title={intl.get('addCertificate')}
      visible={visible}
      destroyOnClose={true}
      footer={null}
      onCancel={handleOnCancel}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (vals, { setSubmitting, resetForm }) => {
          await companyProfile.updateCompany({
            certificateadd: vals,
            company_id,
          })
          setSubmitting(false)
          resetForm()
          handleOnCancel()
        }}
      >
        {({ setFieldValue }) => {
          return (
            <Form>
              <Form.Item name="certificate">
                <Select
                  onChange={(val) => onChangeCertificate(val, setFieldValue)}
                  name="certificate"
                  placeholder="Select certificate"
                >
                  {renderCertificates()}
                  <Select.Option value={0}>Custom certificate</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="certificate_name">
                <Input
                  addonBefore={intl.get('nameLabel')}
                  disabled={disableName}
                  name="certificate_name"
                  placeholder={intl.get('nameLabel')}
                />
              </Form.Item>
              <Form.Item name="certification_authority">
                <Input
                  addonBefore={intl.get('authority')}
                  name="certification_authority"
                  placeholder={intl.get('authority')}
                />
              </Form.Item>
              <Form.Item name="certificate_number">
                <Input
                  addonBefore={intl.get('certificateNumber')}
                  name="certificate_number"
                  placeholder={intl.get('certificateNumber')}
                />
              </Form.Item>
              <Form.Item name="certificate_valid_until">
                <DatePicker
                  name="certificate_valid_until"
                  onChange={(date) => {
                    if (!date) return null
                    setFieldValue(
                      'certificate_valid_until',
                      moment(date, 'YYYY-MM-DD'),
                    )
                    return date
                  }}
                  placeholder={intl.get('certificateValidDate')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item name="certificate_description">
                <Input.TextArea
                  name="certificate_description"
                  placeholder={intl.get('description')}
                />
              </Form.Item>
              <Form.Item name="certificate_file">
                <Upload
                  onRemove={() => onRemoveFile(setFieldValue)}
                  beforeUpload={(file) => onFileChange(file, setFieldValue)}
                  fileList={fileList}
                >
                  <Button style={{ width: '100%' }}>
                    <UploadOutlined />
                    {intl.get('selectCertificateFile')}
                  </Button>
                </Upload>
              </Form.Item>
              <SubmitButton>{intl.get('add')}</SubmitButton>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
