import React, { useContext, useState } from 'react'
import { Button, Space } from 'antd'
import StoreContext from '../../../storeContext'
import { RoleGroups } from '../../../utils/roles'
import { observer } from 'mobx-react'

export default observer(function ({ record }) {
  const { user, users } = useContext(StoreContext)
  const [disabling, setDisabling] = useState(false)
  const renderText = (record) => {
    if (RoleGroups.TITANS.includes(user.user['custom:role_id'])) {
      return record.titan_approved === '1' ? 'Disable' : 'Enable'
    }
    return record.client_admin_approved === '1' ? 'Disable' : 'Enable'
  }
  return (
    <Space size="middle">
      <Button
        type="primary"
        disabled={disabling}
        onClick={async () => {
          setDisabling(true)
          await users.approveUser(
            record,
            RoleGroups.TITANS.includes(user.user['custom:role_id'])
              ? 'titan_approved'
              : 'client_admin_approved',
          )
          await users.getUsersList()
          setDisabling(false)
        }}
      >
        {renderText(record)}
      </Button>

      {/*  {(user.user['custom:role_id'] === Roles.TITAN_MASTER_ADMIN ||
        user.user['custom:role_id'] === Roles.TITAN_ADMIN) &&
        record.titan_approved === '0' && (
          <Loading blockers={[UsersStoreCalls.APPROVE_USER]}>
            <Loading.InProgress>
              <Button type="primary" disabled>
                Approving
              </Button>
            </Loading.InProgress>
            <Loading.Done>
              <Button
                type="primary"
                onClick={async () => {
                  await users.approveUser(record, 'titan_approved')
                  await users.getUsersList()
                }}
              >
                Approve
              </Button>
            </Loading.Done>
          </Loading>
        )}
      {user.user['custom:role_id'] === Roles.CLIENT_ADMIN &&
        record.client_admin_approved === '0' && (
          <Loading blockers={[UsersStoreCalls.APPROVE_USER]}>
            <Loading.InProgress>
              <Button type="primary" disabled>
                Approving
              </Button>
            </Loading.InProgress>
            <Loading.Done>
              <Button
                type="primary"
                onClick={async () => {
                  await users.approveUser(record, 'client_admin_approved')
                  await users.getUsersList()
                }}
              >
                Approve
              </Button>
            </Loading.Done>
          </Loading>
        )} */}
    </Space>
  )
})
