import React, { useContext, useEffect } from "react";
import { Button, Card, Modal } from "antd";
import intl from "react-intl-universal";
import StoreContext from "../../storeContext";
import { observer } from "mobx-react";
import { Roles } from "../../utils/roles";

export default observer(function TermsAcceptence() {
  const { user, terms } = useContext(StoreContext)
  useEffect(() => {
    terms.checkActiveTerms()
    if (user.termsOfUse === '') user.getTerms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const confirmModal = (version) => {
    Modal.confirm({
      title: intl.get('declineModalTitle'),
      okText: intl.get('delete'),
      content: (
        <div>
          <p>{intl.get('declineModalText')}</p>
        </div>
      ),
      onOk: async () => {
        await terms.acceptTermsUpdate(false, version)
      },
    })
  }
  return (
    user.user['custom:role_id'] === Roles.CLIENT_ADMIN &&
    terms.acceptTerms.show && (
      <Card title="Terms of use is changed">
        <div className="acceptance-wrapper">
          <div className="acceptance-text-wrapper">
            <h3>{intl.get('changeTermsNotification')}</h3>
            <a rel="noopener noreferrer" target="_blank" href={user.termsOfUse}>
              {intl.get('acceptTermsLinkText')}
            </a>
          </div>
          <div className="acceptance-buttons-wrapper">
            <Button
              onClick={async () => {
                await terms.acceptTermsUpdate(true, terms.acceptTerms.version)
              }}
              type="primary"
            >
              {intl.get('accept')}
            </Button>
            <Button
              onClick={async () => {
                confirmModal(terms.acceptTerms.version)
              }}
            >
              {intl.get('decline')}
            </Button>
          </div>
        </div>
      </Card>
    )
  )
})
