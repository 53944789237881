import React from "react";
import { Divider, Modal, Space, Statistic } from "antd";
import moment from "moment";
import SurveyData from "../SurveyData";
import Rating from "../../Rating";
import intl from "react-intl-universal";

export default function ({ showPreview, setShowPreview, credit }) {
  return (
    <Modal
      title={intl.get('credit')}
      visible={showPreview}
      footer={null}
      destroyOnClose={true}
      onCancel={() => setShowPreview(false)}
    >
      <Space style={{ width: '100%' }} direction={'vertical'}>
        <Statistic
          title={intl.get('date')}
          value={
            credit.score_date
              ? moment(credit.score_date).format('DD MMMM YYYY')
              : 'N/A'
          }
        />
        <Divider type="horizontal" />
        <Statistic
          title={intl.get('creditRiskRating')}
          valueRender={() => <Rating score={credit.rating} />}
        />
        <Divider type="horizontal" />
        <Statistic
          title={intl.get('dAndBRational')}
          valueRender={() => (
            <Space direction={'vertical'}>
              {credit.rating_reason.map((reason, i) => (
                <span>{`${i + 1}. ${reason.description}`}</span>
              ))}
              {credit.rating_reason.length === 0 && <span>N/A</span>}
            </Space>
          )}
        />
        <Divider type="horizontal" />
        <Statistic
          title={intl.get('recommendedCreditLimit')}
          value={credit.credit_limit.trim() ? credit.credit_limit : 'N/A'}
        />
        <Divider type="horizontal" />
        <Statistic
          title={`${intl.get('selfDeclarationResponse')} ${
            credit.questionaire_completed_date
              ? moment(credit.questionaire_completed_date).format(
                  'DD MMMM YYYY',
                )
              : 'N/A'
          }`}
          valueRender={() => <SurveyData questions={credit.questions} />}
        />
      </Space>
    </Modal>
  )
}
