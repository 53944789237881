import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Modal, Select, Tooltip } from 'antd'
import intl from 'react-intl-universal'
import ExpertiseGroup from './ExpertiseGroup'
import StoreContext from '../../../../storeContext'
import { observer } from 'mobx-react'
import { useParams } from 'react-router'
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined'
import UpOutlined from '@ant-design/icons/lib/icons/UpOutlined'

const { Option } = Select
export default observer(function ({
  visible,
  handleOnCancel,
  highest_revenue_industry = '',
}) {
  const { companyProfile } = useContext(StoreContext)
  const [saving, setSaving] = useState(false)
  const [highest, setHighest] = useState('')
  const [highestRevenue, setHighestRevenue] = useState([])
  const company_id = useParams().id

  useEffect(() => {
    if (highest_revenue_industry) setHighest(highest_revenue_industry)
    getTotals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highest_revenue_industry])

  const getTotals = () => {
    const temp = []
    companyProfile.industryExpertises.forEach((e) => {
      if (companyProfile.getTotalItemsSelected(e, false) > 0)
        temp.push(e.item_name)
    })
    if (highest !== '' && !temp.includes(highest)) {
      setHighest('')
    }
    setHighestRevenue(temp)
  }
  const handleChange = (val) => {
    setHighest(val)
  }
  return (
    <Modal
      width="50%"
      title={
        <>
          {intl.get('editIndustryExpertise')}
          <Tooltip title={intl.get('openAll')}>
            <DownOutlined
              style={{ marginLeft: '1em' }}
              onClick={() => companyProfile.setToggle(true)}
            />
          </Tooltip>
          <Tooltip title={intl.get('closeAll')}>
            <UpOutlined
              style={{ marginLeft: '1em' }}
              onClick={() => companyProfile.setToggle(false)}
            />
          </Tooltip>
        </>
      }
      visible={visible}
      onCancel={handleOnCancel}
      footer={
        <>
          <Button
            type="primary"
            disabled={!highest || saving}
            onClick={async () => {
              setSaving(true)
              await companyProfile.saveIndustryExpertise(company_id, highest)
              setSaving(false)
              handleOnCancel()
            }}
          >
            {intl.get('save')}
            {saving && <LoadingOutlined />}
          </Button>
          <Button
            disabled={saving}
            onClick={() => {
              companyProfile.loadBusinessExpertise()
              handleOnCancel()
              setHighest(highest_revenue_industry)
              getTotals()
            }}
          >
            {intl.get('cancel')}
          </Button>
        </>
      }
    >
      {companyProfile.industryExpertises.map((d) => {
        return <ExpertiseGroup group={d} onCheck={() => getTotals()} />
      })}
      <Card
        title={intl.get('industryExpertiseMostRevenue', {
          0: companyProfile.company.name,
        })}
      >
        <Select
          value={highest}
          style={{ width: '100%' }}
          placeholder={intl.get('selectIndustry')}
          onChange={handleChange}
        >
          {highestRevenue.map((h) => (
            <Option value={h}>{h}</Option>
          ))}
        </Select>
        {!highest && <span>{intl.get('selectHighestRevenueIndustry')}</span>}
      </Card>
    </Modal>
  )
})
