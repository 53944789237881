import React from 'react'
import { ReactComponent as Xing } from '../../assets/images/xing-logo.svg'
import { ReactComponent as MediaRelease } from '../../assets/svgs/newspaper-report-svgrepo-com.svg'
import { ReactComponent as FinancialReport } from '../../assets/svgs/report-svgrepo-com.svg'
import { ReactComponent as AnnualReport } from '../../assets/svgs/book-svgrepo-com .svg'
import { ReactComponent as City } from '../../assets/svgs/City.svg'
import { ReactComponent as Employee } from '../../assets/svgs/Employee.svg'
import { ReactComponent as Hits } from '../../assets/svgs/Hits.svg'
import { ReactComponent as PreferredSupplier } from '../../assets/svgs/PreferredSupplier.svg'
import { ReactComponent as Ratecard } from '../../assets/svgs/Ratecard.svg'
import { ReactComponent as Survey } from '../../assets/svgs/Survey.svg'
import { ReactComponent as Distance } from '../../assets/svgs/Km.svg'
import { ReactComponent as Logo } from '../../assets/images/LogoSim.svg'
import { ReactComponent as LogoBlack } from '../../assets/images/LogoBlack.svg'
import { ReactComponent as Request } from '../../assets/svgs/request.svg'
import Icon from '@ant-design/icons'

export const XingIcon = (props) => <Icon component={Xing} {...props} />

export const MediaReleaseIcon = (props) => (
  <Icon component={MediaRelease} {...props} />
)

export const FinancialReportIcon = (props) => (
  <Icon component={FinancialReport} {...props} />
)

export const AnnualReportIcon = (props) => (
  <Icon component={AnnualReport} {...props} />
)

export const CityIcon = (props) => <Icon component={City} {...props} />
export const EmployeeIcon = (props) => <Icon component={Employee} {...props} />
export const HitsIcon = (props) => <Icon component={Hits} {...props} />
export const PreferredSupplierIcon = React.forwardRef((props, ref) => (
  <Icon component={PreferredSupplier} {...props} ref={ref} />
))
export const RateCardIcon = React.forwardRef((props, ref) => (
  <Icon component={Ratecard} {...props} ref={ref} />
))
export const SurveyIcon = (props) => <Icon component={Survey} {...props} />
export const DistanceIcon = (props) => <Icon component={Distance} {...props} />
export const LogoIcon = (props) => <Icon component={Logo} {...props} />
export const LogoIconBlack = (props) => (
  <Icon component={LogoBlack} {...props} />
)
export const RequestIcon = (props) => <Icon component={Request} {...props} />
