import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import StoreContext from '../../../../../../storeContext'
import { Card, Col, Space } from 'antd'
import intl from 'react-intl-universal'
import InfoPopUp from '../../../../../InfoPopUp'
import IndicatorItem from '../IndicatorItem'
import { DollarCircleOutlined } from '@ant-design/icons'
import SubcontractorInfo from './SubcontractorInfo'
import DataProtectionInfo from './DataProtectionInfo'
import Rating from '../../Rating'

export default observer(function () {
  const { companyProfile } = useContext(StoreContext)
  if (!companyProfile.company.contractual_risks) return null
  return (
    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
      <Card
        type="inner"
        bordered={false}
        title={intl.get('contractualRisks')}
        extra={
          <div style={{ display: 'flex' }}>
            <InfoPopUp
              title={intl.get('contractualRisks')}
              html={intl.get('contractualRisksInfo')}
            />

            <div style={{ marginRight: 5 }} />
            <Rating
              score={companyProfile.company.contractual_risks.risk_rating}
              trafficLight={true}
            />
          </div>
        }
      >
        {/* {intl.get('financialRisksEmptyText')}*/}
        <Space direction="vertical" style={{ width: '100%' }}>
          {companyProfile.company.contractual_risks.subcontractor && (
            <IndicatorItem
              icon={<DollarCircleOutlined />}
              rating={companyProfile.company.contractual_risks.subcontractor}
              name={intl.get('rdParty')}
              modal={(show, setShow) => (
                <SubcontractorInfo
                  subcontractor={
                    companyProfile.company.contractual_risks.subcontractor
                  }
                  setShowPreview={setShow}
                  showPreview={show}
                />
              )}
            />
          )}
          {companyProfile.company.contractual_risks.data_protection && (
            <IndicatorItem
              icon={<DollarCircleOutlined />}
              name={intl.get('dataProtection')}
              rating={companyProfile.company.contractual_risks.data_protection}
              modal={(show, setShow) => (
                <DataProtectionInfo
                  data={
                    companyProfile.company.contractual_risks.data_protection
                  }
                  setShowPreview={setShow}
                  showPreview={show}
                />
              )}
            />
          )}
        </Space>
      </Card>
    </Col>
  )
})
