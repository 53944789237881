import React from "react";
import { Divider, Space } from "antd";
import moment from "moment";

const styles = {
  root: {
    width: '100%',
    padding: '20px',
    border: '1px solid #e8e8e8',
    justifyContent: 'space-between',
    fontSize: 18,
    marginBottom: '1em',
  },
  wrapp: { display: 'flex', gap: '1em', justifyContent: 'space-between' },
}

export default function ({ item, ind }) {
  return (
    <Space style={styles.root} direction={'vertical'}>
      <div style={styles.wrapp}>
        <span>{ind}</span>
        <span>{item.category}</span>
        <span>{item.first_name}</span>
        <span>{item.last_name}</span>
        <span>{moment(item.date_entered[0]).format('MM-DD-YYYY')}</span>
      </div>
      <Divider type="horizontal" />
      <div style={styles.wrapp}>
        <span style={{ minWidth: 112 }}>Biography:</span>
        <span>{item.biography}</span>
      </div>
      <Divider type="horizontal" />
      <div style={styles.wrapp}>
        <span style={{ minWidth: 112 }}>Identification:</span>
        <span>{item.identification}</span>
      </div>
      <Divider type="horizontal" />
      <div style={styles.wrapp}>
        <span style={{ minWidth: 112 }}>Report:</span>
        <span>{item.reports}</span>
      </div>
    </Space>
  )
}
