import React, { useContext } from 'react'
import { Button } from 'antd'
import intl from 'react-intl-universal'
import StoreContext from '../../../storeContext'
import { Loading } from '../../loading'
import { CompaniesOverviewStoreCalls } from '../store/CompaniesOverviewStore'
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined"

export default function ({ selectedCompanies, reset }) {
  const { companiesOverview } = useContext(StoreContext)
  const fetchCMPTCS = async () => {
    const companies = companiesOverview.filteredCompanies
      .filter((comp) => selectedCompanies.includes(comp.company_id))
      .map((comp) => {
        return { company_id: comp.company_id, duns: comp.duns }
      })
    await companiesOverview.fetchCMPTCS(companies)
    reset()
  }
  const fetchKYC = async () => {
    const companies = companiesOverview.filteredCompanies
      .filter((comp) => selectedCompanies.includes(comp.company_id))
      .map((comp) => {
        return { company_id: comp.company_id}
      })
    await companiesOverview.fetchKYC(companies)
    reset()
  }

  const fetchBitsight = async () => {
    const companies = companiesOverview.filteredCompanies
      .filter((comp) => selectedCompanies.includes(comp.company_id))
      .map((comp) => {
        return { company_id: comp.company_id}
      })
    await companiesOverview.fetchBitsight(companies)
    reset()
  }


  return (
    <div>
      <Loading blockers={[CompaniesOverviewStoreCalls.FETCH_CMPTCS, CompaniesOverviewStoreCalls.FETCH_KYC, CompaniesOverviewStoreCalls.FETCH_BITSIGHT]}>
        <Loading.InProgress>
          <Button disabled={true} style={{ margin: 16 }}>
            <LoadingOutlined /> {intl.get('cmptcs')}
          </Button>
          <Button disabled={true} style={{ margin: 16 }}>
            <LoadingOutlined /> KYC
          </Button>
          <Button disabled={true} style={{ margin: 16 }}>
            <LoadingOutlined /> Bitsight
          </Button>
        </Loading.InProgress>
        <Loading.Done>
          <Button
            disabled={selectedCompanies.length === 0}
            onClick={fetchCMPTCS}
            style={{ margin: 16 }}
          >
            {intl.get('cmptcs')}
          </Button>
          <Button
            onClick={fetchKYC}
            disabled={selectedCompanies.length === 0}
            style={{ margin: 16 }}
          >
            KYC
          </Button>
          <Button
            onClick={fetchBitsight}
            disabled={selectedCompanies.length === 0}
            style={{ margin: 16 }}
          >
            Bitsight
          </Button>
        </Loading.Done>
      </Loading>
    </div>
  )
}
