import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import StoreContext from '../../../../storeContext'
import intl from 'react-intl-universal'

export default observer(function EditPersonalRiskNote({ close }) {
  const { companyProfile } = useContext(StoreContext)
  const initialValues = {
    note: companyProfile.company.personal_risk_note
      ? companyProfile.company.personal_risk_note.note
      : '',
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (vals) => {
        if (companyProfile.company.personal_risk_note) {
          await companyProfile.personalRiskNote({
            edit: {
              ...vals,
              note_id: companyProfile.company.personal_risk_note.note_id,
            },
            company_id: companyProfile.company.company_id,
          })
        } else {
          await companyProfile.personalRiskNote({
            add: vals,
            company_id: companyProfile.company.company_id,
          })
        }
        close()
      }}
    >
      {() => (
        <Form>
          <Form.Item name="note">
            <Input.TextArea
              placeholder={intl.get('editPersonalRiskNote')}
              name="note"
            />
          </Form.Item>
          <SubmitButton>{intl.get('save')}</SubmitButton>
        </Form>
      )}
    </Formik>
  )
})
