import React, { useContext } from 'react'
import StoreContext from '../../../storeContext'
import { SortDropdown } from '../store/SearchStore'
import { observer } from 'mobx-react'
import intl from 'react-intl-universal'
import { Button, Select } from 'antd'

export default observer(function ({ setShowAdd }) {
  const { search, lists } = useContext(StoreContext)
  return (
    <div className="search-sort">
      <div className="sort-action">
        <div className="sort-wrapper-element full-width">
          <Button
            onClick={() => setShowAdd(true)}
            size="small"
            style={{ margin: 0, width: '100%', height: '100%' }}
            disabled={search.selectedCompanies.length === 0}
          >
            {intl.get('addSelectedToList')}
          </Button>
        </div>
        <div className="sort-wrapper-element full-width">
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            defaultValue={search.sortLists}
            placeholder={intl.get('selectList')}
            onChange={(e) => search.setListSort(e, 'advancedSearch')}
          >
            {lists.lists.map((list) => (
              <Select.Option value={list.company_list_id}>
                {list.list_name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="sort-wrapper-element full-width">
          <Select
            onChange={(e) => search.toggleSort(e, 'advancedSearch')}
            defaultValue={search.sortItem}
            style={{ width: '100%' }}
            placeholder={intl.get('sort')}
          >
            {SortDropdown.map((s, i) => (
              <Select.Option value={i}>{s.name}</Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="sort-wrapper-element total-items">
        <span>Companies found: {search.results.length}</span>
      </div>
    </div>
  )
})
