import { Button, Layout, Popover } from 'antd'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import intl from 'react-intl-universal'
import { Link } from 'react-router-dom'
import UserInfo from '../../components/UserInfo'
import { TAB_SIZE } from '../../constants/LayoutSetting'
import buyerEn from '../../documents/TITAN Buyer Manual_Oct-2020_EN_vf.pdf'
import buyerDe from '../../documents/TITAN Buyer Manual_Oct-2020_vf.pdf'
import supplierEn from '../../documents/TITAN Supplier Manual_Sept-2020_EN_vf.pdf'
import supplierDe from '../../documents/TITAN Supplier Manual_Sept-2020_vf.pdf'
import LanguageBar from '../../pages/LanguageBar'
import StoreContext from '../../storeContext'
import { RoleGroups } from '../../utils/roles'
const { Header } = Layout
const Topbar = observer(({ showCommands = true }) => {
  const store = useContext(StoreContext)
  const currentLocale = intl.determineLocale({
    localStorageLocaleKey: 'lang',
  })

  const manualsMenu = () => {
    if (!RoleGroups.ADMINS.includes(store.user.user['custom:role_id']))
      return null
    return (
      <Button
        type="text"
        style={{
          margin: '0 8px',
        }}
      >
        <a
          rel="noopener noreferrer"
          href={
            currentLocale === 'en-US'
              ? store.user.user.company_type === 1
                ? buyerEn
                : supplierEn
              : store.user.user.company_type === 1
              ? buyerDe
              : supplierDe
          }
          target="_blank"
        >
          {intl.get('manual')}
        </a>
      </Button>
    )
  }
  /* const helpMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        <li
          className="gx-media gx-pointer"
          onClick={() => {
            store.joyride.run()
          }}
        >
          Add/Manage Users
        </li>
      </ul>
    </CustomScrollbars>
  )*/

  return (
    <Header>
      {store.layout.width < TAB_SIZE && showCommands ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu showHideMenu"
            id="showHideMenu"
            onClick={() => {
              store.layout.sideBarCollapsed = !store.layout.sideBarCollapsed
            }}
          />
        </div>
      ) : null}
      {!showCommands && store.layout.width > TAB_SIZE && (
        <Link
          to={`/${store.user.user && 'dashboard'}`}
          className="gx-site-logo"
        >
          <img
            alt=""
            width="100"
            src={require('../../assets/images/LogoBlack.svg')}
          />
        </Link>
      )}
      {!showCommands && (
        <h4 style={{ marginLeft: '10px' }}>Supplier Information Management</h4>
      )}

      <Link
        to={`/${store.user.user && 'dashboard'}`}
        className="gx-d-block gx-d-lg-none gx-pointer"
      >
        {/* <img
          width={50}
          alt=""
          src={require('../../assets/images/titan-logo.png')}
        /> */}
        <img
          alt=""
          width="100"
          src={require('../../assets/images/LogoBlack.svg')}
        />
      </Link>
      <ul className="gx-header-notifications gx-ml-auto">
        {/* {showCommands && <li className="gx-language">
          <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={helpMenu()}
            trigger="click"
          >
            <QuestionCircleTwoTone style={{fontSize: '20px'}}/>
          </Popover>
        </li>
        } */}
        {showCommands && store.layout.width > TAB_SIZE && (
          <>
            {/* <Popover
              overlayClassName="gx-popover-horizontal"
              placement="bottomRight"
              content={helpMenu()}
              trigger="click"
            >
              <Button type="text" style={{
                margin: '0 8px'
              }}>{intl.get('tutorials')}</Button>
            </Popover> */}
            {manualsMenu()}
            <Popover
              overlayClassName="gx-popover-horizontal"
              placement="bottomRight"
              content={
                <div>
                  <h4>{intl.get('feedbackPopupText')}</h4>
                  <ul>
                    <li>{intl.get('feedbackPopupTextL1')}</li>
                    <li>{intl.get('feedbackPopupTextL2')}</li>
                  </ul>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <a
                      href={
                        'mailto:support@titan-org.com?subject=' +
                        intl.get('provideFeedback')
                      }
                    >
                      {intl.get('provideFeedback')}
                    </a>
                  </div>
                </div>
              }
              trigger="click"
            >
              <Button
                type="text"
                style={{
                  margin: '0 8px',
                }}
              >
                {intl.get('feedback')}
              </Button>
            </Popover>
            <Popover
              overlayClassName="gx-popover-horizontal"
              placement="bottomRight"
              content={
                <div>
                  <h4>{intl.get('supportTitle')}</h4>
                  <p>
                    + 41 71 558 8369 <br />
                    <a href="mailkto:support@titan-org.com">
                      support@titan-org.com
                    </a>
                    <br />
                    9-18:00 CET, Mon-Fri, DE/EN
                  </p>
                </div>
              }
              trigger="click"
            >
              <Button
                type="text"
                style={{
                  margin: '0 8px',
                }}
              >
                {intl.get('support')}
              </Button>
            </Popover>
          </>
        )}
        <li className="gx-language">
          {/* <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={languageMenu()}
            trigger="click"
          >
            <span className="gx-pointer gx-flex-row gx-align-items-center">
              <span className="gx-pl-2 gx-language-name">
                {currentLocale === 'en-US' ? 'EN' : 'DE'}
              </span>
              <i className="icon icon-chevron-down gx-pl-2" />
            </span>
          </Popover> */}
          <LanguageBar black />
        </li>
        {showCommands &&
          (store.layout.width >= TAB_SIZE ? null : (
            <li className="gx-user-nav">
              <UserInfo />
            </li>
          ))}
      </ul>
    </Header>
  )
})

export default Topbar
