import React, { useContext, useState } from 'react'
import StoreContext from '../../../storeContext'
import { observer } from 'mobx-react'
import { List, Modal, Popover, Space } from 'antd'
import RightOutlined from '@ant-design/icons/lib/icons/RightOutlined'
import intl from 'react-intl-universal'
import { EyeOutlined } from '@ant-design/icons'

export default observer(function () {
  const { companyProfile } = useContext(StoreContext)
  const [visible, setVisible] = useState(false)
  return (
    companyProfile.company.industry_codes &&
    companyProfile.company.industry_codes.length > 0 && (
      <div
        style={{
          marginLeft: 'auto',
          color: 'rgba(29, 42, 61, 0.5)',
          marginRight: 10,
        }}
      >
        <Space>
          {companyProfile.company.industry_codes.length > 1 ? (
            <>
              <Popover content="Show all industry codes">
                <EyeOutlined onClick={() => setVisible(true)} />
              </Popover>
              {intl.get('industryCodes')}
            </>
          ) : (
            <>
              {`${companyProfile.company.industry_codes[0].category}`}
              <RightOutlined />
              {`${companyProfile.company.industry_codes[0].subcategory}`}
            </>
          )}
        </Space>
        <Modal
          title="Company industry codes"
          visible={visible}
          footer={null}
          onCancel={() => setVisible(false)}
        >
          <List
            itemLayout="horizontal"
            dataSource={companyProfile.company.industry_codes}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <Space>
                      {item.category}
                      <RightOutlined />
                      {item.subcategory}
                    </Space>
                  }
                  description={
                    <>
                      {item.description_en}
                      {` (${item.code})`}
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </Modal>
      </div>
    )
  )
})
