import React from 'react'
import { Tabs } from 'antd'
import Tags from '.'
import InvisibleTags from './InvisibleTags'

const { TabPane } = Tabs

export default function TagsTabs() {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Global tags" key="1">
        <Tags />
      </TabPane>
      <TabPane tab="Invisible tags" key="2">
        <InvisibleTags />
      </TabPane>
    </Tabs>
  )
}
