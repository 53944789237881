import React, { useContext, useEffect, useState } from 'react'
import { FieldArray, Formik } from 'formik'
import { observer } from 'mobx-react'
import StoreContext from '../../storeContext'
import { Form, Input, Select, SubmitButton } from 'formik-antd'
import { Observer } from 'mobx-react-lite'
import { Button } from 'antd'
import intl from 'react-intl-universal'
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined'
import { action } from 'mobx'
import * as Yup from 'yup'

export default observer(function ({ close }) {
  const { compare } = useContext(StoreContext)
  const [companies, setCompanies] = useState([])
  useEffect(() => {
    compare.loadCompanies()
    action(() => {
      compare.compareFilters.company_list = compare.companies.map(
        (c) => c.company_id,
      )
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCompanies(compare.allCompanies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compare.allCompanies])
  const validation = Yup.object().shape({
    other_value: Yup.number().required(intl.get('otherValueRequired')),
    company_list: Yup.array().required(),
    team_members: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string().required(intl.get('teamMemberTitleRequired')),
          job_profile: Yup.string().required(
            intl.get('teamMemberPorifileRequired'),
          ),
          seniority: Yup.string().required(
            intl.get('teamMemberSeniorityRequired'),
          ),
          person_days: Yup.number()
            .required(intl.get('teamMemberDaysRequired'))
            .typeError(intl.get('validNumber')),
        }),
      )
      .required()
      .min(0, 'test'),
  })
  return (
    <Formik
      validationSchema={validation}
      initialValues={{
        ...compare.compareFilters,
        company_list: compare.companies.map((c) => c.company_id),
      }}
      onSubmit={(values) => {
        action(() => {
          compare.compareFilters = values
        })()
        return compare.compareCompanies(values).then(close)
      }}
    >
      {({ values, errors, setFieldValue }) => {
        return (
          <Observer>
            {() => (
              <Form>
                <Form.Item
                  style={{ margin: '0px 0px 20px 0' }}
                  name="company_list"
                >
                  <Select
                    disabled={compare.companies.length > 2}
                    value={compare.companies.map((c) => c.company_id)}
                    filterOption={false}
                    name="company_list"
                    onSearch={(txt) => {
                      setCompanies(
                        compare.allCompanies.filter((company) =>
                          company.name
                            .toLowerCase()
                            .includes(txt.toLowerCase()),
                        ),
                      )
                    }}
                    onChange={(e) => {
                      setFieldValue(e)
                      compare.updateCompanies(e)
                    }}
                    placeholder="Add companies"
                    mode="multiple"
                  >
                    {companies.map((comp) => (
                      <Select.Option
                        key={comp.company_id}
                        value={comp.company_id}
                      >
                        {comp.name}
                      </Select.Option>
                    ))}
                  </Select>
                  {errors['company_list'] && (
                    <span style={{ color: '#f5222d' }}>
                      {intl.get('companyListRequired')}
                    </span>
                  )}
                </Form.Item>
                <FieldArray name="team_members">
                  {({ remove, push }) => (
                    <div>
                      {values.team_members.length > 0 &&
                        values.team_members.map((m, i) => (
                          <div
                            style={{
                              position: 'relative',
                              padding: '30px 20px 0 20px',
                              border: '1px solid #e8e8e8',
                              marginBottom: 20,
                            }}
                          >
                            <CloseOutlined
                              onClick={() => remove(i)}
                              style={{
                                position: 'absolute',
                                top: 5,
                                right: 5,
                                cursor: 'pointer',
                              }}
                            />
                            <Form.Item
                              style={{ margin: '20 0' }}
                              name={`team_members.${i}.title`}
                            >
                              <Input
                                addonBefore="Title"
                                name={`team_members.${i}.title`}
                              />
                            </Form.Item>
                            <Form.Item
                              style={{ margin: '20 0' }}
                              name={`team_members.${i}.job_profile`}
                            >
                              <Select
                                placeholder="Select job profile"
                                name={`team_members.${i}.job_profile`}
                              >
                                {compare.profiles.map((profile) => {
                                  return (
                                    <Select.Option value={profile}>
                                      {profile}
                                    </Select.Option>
                                  )
                                })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              style={{ margin: '20 0' }}
                              name={`team_members.${i}.seniority`}
                            >
                              <Select
                                placeholder="Select seniority"
                                name={`team_members.${i}.seniority`}
                              >
                                <Select.Option value="l1">L1</Select.Option>
                                <Select.Option value="l2">L2</Select.Option>
                                <Select.Option value="l3">L3</Select.Option>
                                <Select.Option value="l4">L4</Select.Option>
                                <Select.Option value="l5">L5</Select.Option>
                              </Select>
                            </Form.Item>
                            <Form.Item
                              style={{ margin: '20 0' }}
                              name={`team_members.${i}.person_days`}
                            >
                              <Input
                                addonBefore="Person days"
                                name={`team_members.${i}.person_days`}
                              />
                            </Form.Item>
                          </div>
                        ))}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: 20,
                        }}
                      >
                        <Button
                          style={{ width: '100%', marginBottom: 0 }}
                          onClick={() =>
                            push({
                              title: '',
                              job_profile: '',
                              seniority: '',
                              person_days: '30',
                            })
                          }
                        >
                          {intl.get('addNewMember')}
                        </Button>
                        {errors['team_members'] && (
                          <span style={{ color: '#f5222d' }}>
                            {intl.get('teamMemberRequired')}
                          </span>
                        )}
                      </div>
                      <Form.Item
                        style={{ margin: '0px 0px 20px 0' }}
                        name={`other_value`}
                      >
                        <Input
                          addonBefore={intl.get('other')}
                          name="other_value"
                        />
                      </Form.Item>
                      <Form.Item style={{ margin: '20px 0' }} name={`currency`}>
                        <Select placeholder="Select currency" name={`currency`}>
                          <Select.Option value={'CHF'}>CHF</Select.Option>
                          <Select.Option value={'USD'}>USD</Select.Option>
                          <Select.Option value={'EUR'}>EUR</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        style={{ margin: '0px 0px 20px 0' }}
                        name={`comment`}
                      >
                        <Input.TextArea
                          placeholder={intl.get('otherComment')}
                          name="comment"
                        />
                      </Form.Item>
                    </div>
                  )}
                </FieldArray>

                <SubmitButton disabled={false} style={{ width: '100%' }}>
                  Compare
                </SubmitButton>
              </Form>
            )}
          </Observer>
        )
      }}
    </Formik>
  )
})
