import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Modal, Tooltip } from 'antd'
import ExpertiseGroup from './ExpertiseGroup'
import StoreContext from '../../../../storeContext'
import { observer } from 'mobx-react'
import { useParams } from 'react-router'
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'
import intl from 'react-intl-universal'
import TrendExpertiseGroup from './TrendExpertiseGroup'
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined'
import MinusOutlined from '@ant-design/icons/lib/icons/MinusOutlined'
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined'
import UpOutlined from '@ant-design/icons/lib/icons/UpOutlined'

export default observer(function ({ visible, handleOnCancel }) {
  const { companyProfile } = useContext(StoreContext)
  const [saving, setSaving] = useState(false)
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (companyProfile.toggleAll !== null) {
      setShow(companyProfile.toggleAll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProfile.toggleAll])
  const company_id = useParams().id
  return (
    <Modal
      width="50%"
      title={
        <>
          {`${intl.get('editBusinessExpertise')} ${intl.get('othersTitle')}`}
          <Tooltip title={intl.get('openAll')}>
            <DownOutlined
              style={{ marginLeft: '1em' }}
              onClick={() => companyProfile.setToggle(true)}
            />
          </Tooltip>
          <Tooltip title={intl.get('closeAll')}>
            <UpOutlined
              style={{ marginLeft: '1em' }}
              onClick={() => companyProfile.setToggle(false)}
            />
          </Tooltip>
        </>
      }
      visible={visible}
      footer={
        <>
          <Button
            type="primary"
            disabled={saving}
            onClick={async () => {
              setSaving(true)
              await companyProfile.saveBusinessExpertise(company_id)
              setSaving(false)
              handleOnCancel()
            }}
          >
            {intl.get('save')}
            {saving && <LoadingOutlined />}
          </Button>
          <Button
            disabled={saving}
            onClick={() => {
              companyProfile.loadBusinessExpertise()
              handleOnCancel()
            }}
          >
            {intl.get('cancel')}
          </Button>
        </>
      }
      onCancel={handleOnCancel}
    >
      {companyProfile.companyExpertises.map((d) => {
        return d.children.map((dd) => <ExpertiseGroup group={dd} />)
      })}
      <Card
        title={
          <h3
            style={{ cursor: 'pointer' }}
            onClick={() => setShow((show) => !show)}
          >
            {intl.get('trendsExpertise')}
          </h3>
        }
        bodyStyle={{
          padding: !show && 0,
          height: !show && 0,
          overflow: !show && 'hidden',
        }}
        extra={
          !show ? (
            <PlusOutlined onClick={() => setShow((show) => !show)} />
          ) : (
            <MinusOutlined onClick={() => setShow((show) => !show)} />
          )
        }
      >
        {companyProfile.trends.map((trend) => (
          <TrendExpertiseGroup trend={trend} />
        ))}
      </Card>
    </Modal>
  )
})
