import { Button, Card } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import intl from 'react-intl-universal'
import StoreContext from '../../../../../storeContext'
import EditModal from '../../../../shared/EditModal'
import { RequestSuccessStory } from './RequestSuccessStory'
import Story from './Story'
import './style.css'

export default observer(function ({ stories }) {
  const [showRequest, setShowRequest] = useState(false)
  const { user } = useContext(StoreContext)
  return (
    <Card
      type="inner"
      bordered={false}
      title={intl.get('successStories')}
      extra={
        user.user.company_type === 1 && (
          <Button
            onClick={() => setShowRequest(true)}
            size="small"
            style={{ margin: 0 }}
          >
            {intl.get('requestSuccessStory')}
          </Button>
        )
      }
    >
      {stories.length > 0 && (
        <div className="stories">
          {stories.map((story) => (
            <Story story={story} />
          ))}
        </div>
      )}
      <EditModal
        visible={showRequest}
        handleOnClose={() => setShowRequest(false)}
        title={intl.get('requestSurvey')}
      >
        <RequestSuccessStory />
      </EditModal>
    </Card>
  )
})
