export const TRANSLATIONS = {
  passwordErrorMin: `Password length must be {0} characters long`,
  createdBy: `Created by: {0} at {1}`,
  internalNoteTitle: `Internal note (last edited on {1} by {0} )`,
  editInternalNote: `Edit internal note`,
  shareholders: `Shareholders`,
  userNotFound: `Canot request private rate card, user not found`,
  deleteInternalNote: `Delete internal note`,
  rateCardPrivateDescription: `Private rate card description`,
  addPreferredSupplier: `Add preferred supplier`,
  addPreferredSupplierInfo: `You are going to add {0} as a preferred supplier.`,
  sharePreferredSupplierStatus: `Do not share preferred supplier status with other buyers.`,
  addedPrivateRateCard: `Private rate card successfully added`,
  rateCardUpdated: `Rate card is updated`,
  implementationPartnerFor: `Implementation partner for`,
  preferredSupplierOf: `Preferred supplier of`,
  lastUpdate: `(Last updated at {0})`,
  deleteCertificateConfirmation: `You are going to delete certificate, are you sure ?`,
  listNoDelete: `You cannot delete this list`,
  emailFileError: `File must be 2mb`,
  certificateInfoTitle: `Certificate information`,
  certificateName: `Certificate name`,
  deleteRow: `Clear row data`,
  viewProfiles: `View profiles`,
  viewRequests: `View requests`,
  manageLists: `Manage lists`,
  searchIconInList: `Company is in list`,
  searchIconNotInList: `Company is not in list`,
  accept: `Accept`,
  done: `Done`,
  interpolationTest: `Interpolation {0} test`,
  cancel: `Cancel`,
  interpolationTest2: `Interpolation ({0}, {1},{2}) test`,
  error: `Error`,
  registrationTitle: `Account registration`,
  userTypeLabel: `Please select your user type:`,
  buyerLabel: `Buyer`,
  sellerLabel: `Supplier`,
  buyerInputInfo: `User receives premium master data, market insights, benchmarks and further supplier information. Chargeable product.`,
  sellerInputInfo: `User provides market information and offerings for his company on the Titan platform. Limited User benefits are available. Base package is free of charge.`,
  userTypeError: `Choose type`,
  nameLabel: `First name`,
  nameErrorRequired: `First name is required field`,
  nameErrorMax: `Max length is {0}`,
  surnameLabel: `Surname`,
  surnameErrorRequired: `Surname is required field`,
  surnameErrorMax: `Max length is {0}`,
  companyNameLabel: `Company name`,
  companyErrorRequired: `Company name is required field`,
  companyErrorMax: `Max length is {0}`,
  positionLabel: `Position`,
  positionErrorRequired: `Position is required field`,
  selectCountryLabel: `Select country`,
  selectCountryErrorRequired: `You must select country`,
  vatLabel: `VAT registration number`,
  emailLabel: `Email`,
  emailErrorRequired: `Email is required field`,
  emailErrorNotValid: `You must enter valid email`,
  passwordLabel: `Password`,
  passwordErrorRequired: `Password is required field`,
  passwordErrorStrength: `Password must have 1 uppercase character, 1 number and 1 special character`,
  passwordConfirmErrorMatch: `Confirm password must match password`,
  phoneLabel: `Phone number (optional)`,
  acceptTermsText: `the terms of use`,
  acceptTermsErrorRequired: `You must accept terms of use`,
  registerButtonText: `Register`,
  acceptTermsLinkText: `Terms of use`,
  alreadySignUpText: `If you are already signed up:`,
  alreadySignUpLinkText: `Login here`,
  passwordConfirmLabel: `Confirm password`,
  passwordConfirmErrorRequired: `Confirm password is required field`,
  blacklistDomain: `Domain is blacklisted. Please use different email!`,
  usernameExists: `Username already exists!`,
  invalidPassword: `Password not according to rules!`,
  emailExists: `Email already exists!`,
  exception: `Something went wrong!`,
  confirmEmail: `Please check your email for verification code`,
  companyRegistered: `Company is already registered. You need to be approved by your company administrator!`,
  loginIncorrect: `The username or password is incorrect`,
  userUnconfirmed: `User is not confirmed`,
  waitTitanApproval: `Please wait for Titan admin to approve your account!`,
  waitAdminApproval: `Please wait for your company admin to approve your account!`,
  success: `Success!`,
  tokenExpired: `Token has expired. Please log in again.`,
  usernameNotExist: `Username does not exist!`,
  invalidCode: `Invalid verification code!`,
  userConfirmed: `User is already confirmed!`,
  passwordChanged: `Password changed successfully!`,
  verificationComplete: `Verification complete!`,
  vatErrorRequired: `Vat is required field`,
  phoneErrorFormat: `Not valid phone number format`,
  confirmationCodeLabel: `Code`,
  confirmationCodeErrorRequired: `You must enter the code`,
  confirmationCodeErrorNotValid: `Code must have exactly 6 digits`,
  registrationConfirmationTitle: `Check your email`,
  registrationConfirmationInfoText: `We sent a six-digit code to your email. Enter it below to unlock your account registration. Please check your spam folder in case you did not recieve a code. `,
  codeLabe: `Code/`,
  didntReceiveCode: `Didn't receive the code ?`,
  resend: `Resend`,
  sending: `Sending`,
  confirmLabel: `Confirm`,
  forgotPasswordTitle: `Forgot password`,
  submitLabel: `Submit`,
  resetPasswordConfirmTitle: `We just sent you a verification code`,
  resetPasswordConfirmInfoText: `We sent a verification code to your email.If you don't see our email in your inbox, check your spam folder.`,
  loginTitle: `Account login`,
  loginTermsText: `By continuing, you agree to our`,
  loginButtonText: `Login`,
  loginSignUpLinkText: `Create a new account`,
  loginResetPasswordLinkText: `Lost your password ?`,
  signUpSuccessTitleText: `Your are successfully registered`,
  signUpSuccessSubTitleText: `If you have any problems, please contact support@titan-org.com`,
  signUpSuccessLoginLinkText: `Go to login`,
  resetPasswordSuccessMessage: `You have successfully changed your password`,
  addNewUserTitle: `Add new user to the team`,
  sendInvite: `Send invite`,
  addNewUserCSV: `As Titan Admin you can send mass invites to the users of other companies to join the system by uploading CSV file`,
  uploadCSVButtonText: `Upload CSV`,
  optionAllUsers: `All users`,
  optionTitanUsers: `Titan users`,
  optionPendingUsers: `Pending users`,
  optionDisabledUsers: `Disabled users`,
  searchLabel: `Search`,
  usersTableColumnName: `Name`,
  usersTableColumnCompany: `Company`,
  usersTableColumnRole: `Role`,
  usersTableColumnType: `Type`,
  usersTableColumnEmail: `Email`,
  usersTableColumnTerms: `T&C version`,
  usersTableColumnActions: `Action`,
  vatHelpButtonText: `VAT help`,
  vatHelpModalTitle: `Help lines for value-added tax (VAT) information`,
  vatHelpModalEuLinkText: `EU VAT standard`,
  vatHelpModalVatFinderLinkText: `Swiss VAT finder`,
  vatHelpModalVatInfoText: `If you register for a company from a country without VAT number than please type 84826 (“Titan”) and we will contact you to support you with your registration.`,
  wrongInvite: `The invite code is not valid`,
  inviteAccepted: `Invite already accepted`,
  singleUserInviteTabTitle: `Single user invite`,
  bulkUserInviteTabTitle: `Bulk user invite`,
  bulkUserInviteButtonText: `Invite users`,
  successInviteUsersTitle: `You have successfully invited {0} user(s)`,
  addUserCardTitle: `Invite users`,
  alreadyInvited: `Email is already invited`,
  wrongDomain: `Wrong domain`,
  alreadyRegistered: `Email is already registered`,
  inviteUsersErrorTitle: `Error while inviting user(s)`,
  userApprovedSuccessTitle: `User approved`,
  userApprovedSuccessText: `You have successfully approved user: {0}`,
  userApprovedErrorTitle: `Error while approving user`,
  positionErrorMax: `Position max length is {0}`,
  uploadPhotoLinkText: `Upload photo`,
  changePasswordLinkText: `Change password`,
  deactivateAccountLinkText: `Deactivate account`,
  selectImageText: `Select image`,
  uploadImageErrorNotValid: `Image must be jpg and max size is 2MB`,
  upload: `Upload`,
  uploading: `Uploading ...`,
  acceptedTermsOfUse: `Accepted terms of use v {0}`,
  save: `Save`,
  edit: `Edit`,
  userInformationText: `User information`,
  updateUserProfileSuccess: `You have successfully updated the user profile`,
  updateUserProfileError: `Error while updating user profile`,
  changingPasswordError: `Error while changing password`,
  signUpSuccessInvitedSubTitleText: `You can now login to your account.`,
  tabDataClassification: `Data classification`,
  tabAccountStatus: `Account status`,
  tabDataCompletionStatus: `Data completion status`,
  tabActivityStatus: `Activity status`,
  tabVisibilityStatus: `Visibility status`,
  tabCompanyParameters: `Company parameters`,
  selectColumns: `Select columns`,
  downloadCSV: `Download CSV`,
  columnCompanyName: `Company name`,
  columnCompanyType: `Company type`,
  columnCompanySize: `Company size`,
  columnCompanyCountry: `Company country`,
  columnEmployeeNumber: `Employee number`,
  columnCompanyIndustry: `Company industry`,
  uploadCardTitle: `Upload csv to import companies`,
  uploadExplanation: `If you want to upload multiple companies just upload csv-file`,
  tableCardTitle: `Imported companies`,
  columnEmployeeCountReliability: `Employee count reliability`,
  columnVat: `VAT`,
  columnIndustryCodes: `Industry codes`,
  bigFirm: `Big firm`,
  boutique: `Boutique`,
  agency: `Agency`,
  oneManFirm: `One man firm`,
  startUp: `Start-up`,
  updateIndustryModalTitle: `Update company industry`,
  industryCode: `Industry code`,
  description: `Description`,
  priority: `Priority`,
  successImportCompany: `Successfully imported companies`,
  errorImportCompany: `Error while importing companies`,
  successUpdateCompany: `Successfully updated company`,
  columnCompanyTypeView: `Company type view`,
  columnCountryView: `Company country view`,
  viewMatrixBulkUpdateCardTitle: `Update selected companies`,
  viewMatrixTableCardTitle: `Companies view matrix`,
  selectCompanyType: `Select company type`,
  selectCountries: `Select countries`,
  bulkUserUploadCsvExplanation: `You can invite users from csv`,
  delete: `Delete`,
  deleting: `Deleting`,
  successDeletingCompany: `Successfully deleted company`,
  infoImportingCompanies: `Importing, estimate time of finish {0} seconds`,
  importInProgress: `Please finish import first`,
  changeTermsNotification: `Our Standard Terms & Conditions (STC) has changed, Please accept them during the next 14 days.`,
  termsNotAccepted: `You declined our terms of use`,
  companiesAlreadyExists: `Company already exists`,
  dunsNotFound: `DUNS not found`,
  importException: `Exception importing`,
  importing: `Importing`,
  finishImport: `Finish import`,
  companiesImported: `Import finished`,
  decline: `Decline`,
  declineModalTitle: `You are going to decline terms of use, are you sure ?`,
  declineModalText: `If you decline terms of use you are not be able to use TITAN platform !!`,
  columnVersion: `Version`,
  columnUploadDate: `Upload date`,
  columnPublishDate: `Publish date`,
  columnDownload: `Download`,
  termsAccepted: `You have successfully accepted new terms`,
  termsDeclined: `You have successfully declined new terms`,
  termsAdded: `You have successfully added new terms`,
  versionTypeRequired: `Version type is required field`,
  publishDateRequired: `Publish date is required field`,
  termsFileRequired: `You must select file`,
  uploadTermsTitle: `Upload new terms version`,
  selectPDF: `Select terms pdf`,
  selectVersion: `Select version type`,
  versionCritical: `Critical`,
  versionMajor: `Major`,
  versionMinor: `Minor`,
  publishDate: `Choose publish date`,
  uploadTermsButton: `Upload terms`,
  companyHasUsers: `Cannot delete company with users`,
  termsNotValidFile: `Terms file must be PDF`,
  notImportedCompaniesTitle: `Not imported companies list`,
  dateCreatedColumn: `Date created`,
  termsVersionColumn: `Last accepted terms version`,
  dateTermsAcceptedColumn: `Date accepted terms`,
  enable: `Enable`,
  disable: `Disable`,
  commentColumn: `Comment`,
  companyUserCountColumn: `Users on platform`,
  setComment: `Set comment`,
  addCustomTerms: `Add custom terms`,
  addCustomTermsModalTitle: `Add custom terms for company`,
  addCommentModalTitle: `Add comment for company`,
  notValidCsvFormat: `CSV format is not valid`,
  duns: `Bisnode DUNS`,
  cmptcs: `Bisnode CMPTCS`,
  reload: `Reload`,
  fetch: `Fetch`,
  cmptcsSuccess: `Successfully added Bisnode CMPTCS data`,
  totalCompaniesDataImported: `Companies data imported: {0}`,
  tabCmptcs: `Bisnode CMPTCS data`,
  tabKyc: `KYC data`,
  tabBitsight: `BITSIGHT data`,
  companyDisabled: `Your company has been disabled`,
  successDeleteUser: `You have successfully deleted user data`,
  errorRemovingYourAccountTryAgain: `Error removing user data, please try again`,
  notAuthorizedToDeleteProfile: `You cannot delete user data`,
  contactTitanAdminToRemoveProfile: `You cannot delete company owner, please contact TITAN admin`,
  cannotRemoveActiveCompanyOwner: `You cannot delete company owner, please contact TITAN admin`,
  wait_admin_approval: `You are not approved by your admin`,
  certificateNameEnRequired: `Certificate english name is required`,
  certificateNameDeRequired: `Certificate german name is required`,
  certificateAuthorityRequired: `Certificate authority is required`,
  certificateNameEnTitle: `Certificate english name`,
  certificateNameDeTitle: `Certificate german name`,
  authority: `Authority`,
  certificates: `Certificates`,
  addCertificate: `Add certificate`,
  tagName: `Tag name`,
  visibility: `Visibility`,
  addTag: `Add tag`,
  show: `Show`,
  hide: `Hide`,
  descriptionEnglish: `English description`,
  descriptionGerman: `German description`,
  category: `Category`,
  subcategory: `Subcategory`,
  totalCompanies: `Total companies`,
  industryCodes: `Industry codes`,
  addIndustryCode: `Add industry code`,
  basic: `Profile`,
  overview: `Capabilities`,
  details: `Details`,
  peopleAndOrganisation: `People and organisation`,
  resources: `Resources`,
  products: `Products`,
  procurement: `Procurement`,
  editBasicInfo: `Edit basic information`,
  editDescription: `Edit description`,
  editTags: `Edit tags`,
  editLogo: `Edit logo`,
  editIndustryCodes: `Edit industry codes`,
  editMediaLinks: `Edit social media links`,
  editSlidesAndVideos: `Edit slides and videos`,
  noCompanyUrl: `No company url`,
  founded: `Founded`,
  employees: `Employees`,
  editHyperlinks: `Edit hyperlinks`,
  hyperlinks: `Hyperlinks`,
  mediaRelease: `Media release`,
  annualReport: `Annual report`,
  financialReport: `Financial report`,
  companyDomain: `Company domain`,
  companyUrl: `Company URL`,
  importCanceled: `Import canceled`,
  editBusinessExpertise: `Edit business expertise`,
  editCertificates: `Edit certificates`,
  businessExpertise: `Business expertise`,
  requestBusinessExpertise: `Request business expertise`,
  certificateNumber: `Certificate number`,
  certificateValidDate: `Certificate valid until`,
  certificateFile: `Certificate file`,
  othersTitle: `Others ( to add more expertise change your industry codes )`,
  selectIndustryCodes: `Select industry codes`,
  editIndustryExpertise: `Edit industry expertise`,
  industryExpertise: `Industry expertise`,
  requestIndustryExpertise: `Request industry expertise`,
  successCMPLK: `Successfully added Bisnode CMPLK data`,
  companyLink: `Company url`,
  inviteCompany: `Invite company`,
  companyAlreadyExists: `Company already exists`,
  goToCompany: `Go to company`,
  inviteSent: `Your invite has been sent`,
  add: `Add`,
  selectCertificateFile: `Select certificate file`,
  city: `City`,
  editVideos: `Edit company videos`,
  companyVideoEnLabel: `Company english video`,
  companyVideoDeLabel: `Company german video`,
  editPresentation: `Edit company presentation`,
  uploadPresentationEn: `Upload english presentation`,
  presentationInvalidFormat: `Presentation must be in pdf format`,
  uploadPresentationDe: `Upload german presentation`,
  registrationAndAddress: `Registration and address`,
  companyPrincipals: `Company principals`,
  showParentCompanies: `View parent company`,
  parent: `Parent`,
  address: `Address`,
  domesticUltimate: `Domestic ultimate`,
  globalUltimate: `Global ultimate`,
  editRevenue: `Edit revenue`,
  addSuccessStory: `Add success story`,
  editSuccessStory: `Edit success stories`,
  successStories: `Success stories`,
  successStoryNameRequired: `Success story title is required`,
  successStoryDateRequired: `Success story year and month is required`,
  successStoryTagsRequired: `Success story tags are required`,
  successStoryMaxTags: `You can select maximum 5 tags`,
  successStoryTitle: `Title`,
  successStoryDate: `Year and month`,
  successStoryTags: `Success story tags`,
  successStoryFile: `Success story file`,
  selectSuccessStoryFile: `Select success story file`,
  selectStoryTags: `Select success story tags`,
  selectStoryDate: `Select success story year and month`,
  importNoIndustryCode: `Company industry code not found`,
  selectCompanySize: `Select company size`,
  cancelImport: `Cancel import`,
  successStoryMaxTitle: `Max is 100 characters for title`,
  storyFileRequired: `Success story file is required`,
  procurementUrl: `Procurement homepage`,
  supplierRegistration: `Supplier registration`,
  selectOrgChart: `Select sales team presentation`,
  editContactPerson: `Edit contact person`,
  importConfirmDeleteCompany: `You are going to remove company from import, are you sure ?`,
  industryCodeRequired: `Industry code is required`,
  industryCodeCategoryRequired: `Category is required`,
  industryCodeSubcategoryRequired: `Subcategory is required`,
  contactPerson: `Contact person`,
  salesTeam: `Sales team`,
  userDisabledSuccessTitle: `You have successfully disabled user`,
  invite: `Invite`,
  rateCardDescription: `Your public rate card can be viewed by all buyers on TItan. Please insert your daily rates per seniority level for all relevant countries and job profiles. Below you will find a definition of seniority levels which you can comment on. Daily rates are based on 8,4-hour day in CH/LI and on an 8-hour day in DE and AT); only rates for standard working times on week days. In addition please indicate if travel costs are included. Leaving a cell empty means you do not offer services for a certain profile and seniority level.`,
  titan_descriptionL1: `Junior (0-2 years relevant experience)`,
  titan_descriptionL2: `Professional (3-5 years relevant experience)`,
  titan_descriptionL3: `Senior (6+ years relevant experience)`,
  titan_descriptionL4: `Expert (8+ years relevant experience)`,
  titan_descriptionL5: `Senior expert (10+ years relevant experience)`,
  multipleEntriesForTheSameYear: `Revenue for that year already exist`,
  rateCardTargetDescription: `Your target rate card can be viewed only by users in your organization. Please insert your maximum target daily rates per seniority level for all relevant countries and job profiles. Below you will find a definition of seniority levels which you can comment on. Daily rates are based on 8,4-hour day in CH/LI and on an 8-hour day in DE and AT); only rates for standard working times on weekdays. In addition please indicate if travel costs are included.`,
  privateRateCardDescription: `This private rate card can only be viewed by users from {0} and {1}. The content will be kept strictly confidential. Please insert your daily rates per seniority level for all relevant countries and job profiles. Below you will find a definition of seniority levels which you can comment on. Daily rates are based on 8,4-hour day in CH/LI and on an 8-hour day in DE and AT); only rates for standard working times on weekdays. In addition please indicate if travel costs are included. Leaving a cell empty means you do not offer services for a certain profile and seniority level.`,
  privateRateCardRequestSuccess: `Request is sent`,
  personalNotes: `Personal notes`,
  errorUploadingFile: `Error while uploading file`,
  personalArea: `Personal area`,
  myListsDesc: `Here you can create and manage your company lists. List allow you to save a selection of companies and leave customized comments for everyone in your organization.`,
  companyProfileDesc: `Here you can access your company profile. If you have administrator rights you can add and edit information. It is visible to either suppliers (if you are a buyer) or buyers (if you are a supplier).`,
  welcomeToTitan: `Titan SIM`,
  myLists: `My lists`,
  companyProfile: `Company Profile`,
  tutorials: `Tutorials`,
  feedback: `Feedback`,
  support: `Support`,
  privacyStatement: `Privacy Statement`,
  privacyStatementUrl: `https://titan-org.com/privacy-statement/`,
  supportTitle: `Titan Helplines:`,
  strengthenProfile: `Strengthen profile`,
  profileStrength: `Profile strength:`,
  seniorityLevel: `Seniority level`,
  titanSeniorityDescription: `Titan seniority description`,
  supplierSeniorityDefinition: `Supplier seniority definition`,
  seniorityDefinition: `Seniority definition`,
  rateCards: `Rate cards`,
  rateCardTitleRequired: `Rate card title is required`,
  addRateCard: `Add rate card`,
  rateCardTitle: `Rate card title`,
  removeRateCardNotification: `You are going to delete rate card, are you sure ?`,
  noRateCards: `You don't have any rate cards`,
  profileEn: `Profile type`,
  profileDe: `Profile type`,
  currency: `Currency`,
  copyRow: `Copy row data`,
  pasteToRow: `Paste to row`,
  travelCostIncluded: `Travel cost included`,
  yes: `Yes`,
  no: `No`,
  notSet: `Not set`,
  rateCardDescriptionPlaceholder: `Please provide additional relevant information in English (max 1'000 characters)`,
  privateRateCardTitle: `Private rate card between {1} and {2}`,
  addSelectedToList: `Add selected to list`,
  matchCount: `Match count`,
  distance: `Distance`,
  searchPlaceholder: `Search for companies, people, capabilities, products or other key words`,
  listRequired: `List is required`,
  addCompanyToList: `Add company to list`,
  selectList: `Select list`,
  hitDetailsFor: `Hit details for`,
  filters: `Filters`,
  applyFilters: `Apply filters`,
  deleteRateCardNotification: `You are going to delete list, are you sure ?`,
  removeCompanyFromListNotification: `You are going to remove company from the list, are you sure ?`,
  listDescription: `List description`,
  addCompanies: `Add companies`,
  listNameRequired: `List name is required`,
  addNewList: `Add new list`,
  listName: `List name`,
  selectCompanies: `Select companies`,
  addCompaniesToList: `Add companies to list`,
  feedbackPopupText: `The Titan team is always grateful for your feedback!`,
  provideFeedback: `Provide feedback`,
  feedbackPopupTextL1: `For a bug report, please make sure to tell us exactly where and how it happened. Also add a screenshot if possible.`,
  feedbackPopupTextL2: `For a new feature request you can also provide a short explanation (use case) why this would be important to you or provide examples.`,
  addProduct: `Add product`,
  editProducts: `Edit products`,
  manual: `Manual`,
  requestSuccessStory: `Request success story`,
  industryExpertiseMostRevenue: `Which industry covers for most of your revenue {0} ?`,
  selectIndustry: `Select industry`,
  selectHighestRevenueIndustry: `Please select your highest revenue industry`,
  yearNumberValidation: `Year must be a number`,
  employeeCountValidation: `Employee count must be a number`,
  rateCardBenchmarkDescription: `Below you find Titan Benchmark rates. Daily rates are based on 8,4-hour day in CH/LI and on an 8-hour day in DE and AT); only rates for standard working times on week days.`,
  deleteContactPerson: `Delete contact person`,
  deleteContactPersonModal: `You are going to delete contact person, are you sure ?`,
  firsAndLastName: `First name and last name`,
  searchIconPreferredSupplier: `This is your preferred supplier`,
  searchIconNotPreferredSupplier: `This company is not your preferred supplier`,
  searchIconRateCard: `This company has a rate card`,
  searchIconNoRateCard: `This company does not have any visible rate cards`,
  deleteCompanyConfirmation: `You are going to delete company, are you sure ?`,
  openAll: `Open all sections`,
  closeAll: `Close all sections`,
  trendsExpertise: `Trends expertise`,
  addInternalNote: `Leave internal comment`,
  addToList: `Add to list`,
  addContractFolder: `Add contract folder`,
  confidential: `Confidential`,
  createdAt: `Created at`,
  updatedAt: `Updated at`,
  contractNumber: `Contract number`,
  purchaseOrderNumber: `Purchase order number`,
  deleteDocumentModal: `You are going to delete document, are you sure ?`,
  editContractDocument: `Edit contract document`,
  deleteContractFolderModal: `You are going to delete contract folder, are you sure ?`,
  editContractFolder: `Edit contract folder`,
  deleteContractFolder: `Delete contract folder`,
  addDocument: `Add document`,
  startDate: `Start date`,
  endDate: `End date`,
  value: `Value`,
  documentNameRequired: `Document name is required`,
  documentTypeRequired: `Document type is required`,
  selectDocumentType: `Select document type`,
  documentName: `Document name`,
  folderNameRequired: `Contract folder name required`,
  startDateRequired: `Start date is required`,
  contractValueError: `Value must be a number`,
  contractFolderName: `Contract folder name`,
  selectStartDate: `Select start date`,
  selectEndDate: `Select end date`,
  contractValue: `Contract value`,
  selectCurrency: `Select currency`,
  selectTags: `Select tags`,
  documentFileRequired: `Document file is required`,
  selectDocumentFile: `Select document file`,
  documentFiIeError: `Document file must be pdf and maximum 20mb`,
  internalNote: `Internal note`,
  visible: `Visible`,
  productName: `Product name`,
  productUrl: `Product URL`,
  implementationPartners: `Implementation partners`,
  selectImplementationPartners: `Select implementation partners`,
  productGroup: `Product group`,
  selectProductGroup: `Select product group`,
  productImage: `Product image`,
  selectProductImage: `Select product image`,
  productImageError: `File must be png or jpeg and maximum 2mb`,
  productPresentation: `Product presentation`,
  productPresentationError: `File must be pdf and maximum 10mb`,
  selectProductPresentation: `Select product presentation`,
  productReferenceInstallations: `Product reference installations`,
  selectReferenceInstallations: `Select reference installations`,
  productRoadmap: `Product roadmap`,
  selectRoadmap: `Select roadmap`,
  productPriceList: `Product price list`,
  selectPriceList: `Select price list`,
  deleteProductModal: `You are going to delete product, are you sure ?`,
  noContracts: `You do not have any contracts`,
  addPersonalNote: `Add personal note`,
  personalNoteTitle: `Personal note`,
  deleteUserConfimation: `You are going to delete all user data, are you sure ?`,
  errorFindingYourCompany: `Your company not found`,
  notAllowedToEditContracts: `You are not allowed to edit contract`,
  confidentialDocumentsExist: `There is confidential document`,
  wrongCompany: `Wrong company`,
  contractFolderNotFound: `Contract not found`,
  wrongUploadType: `Wrong upload type`,
  errorDeletingDocument: `Error while deleting document`,
  invalidRequest: `Not valid request`,
  unknownError: `Something went wrong`,
  productNameRequired: `Product name is required`,
  productGroupRequired: `Product group is required`,
  productDescription: `Product description`,
  riskAndCompliance: `Risk and compliance`,
  questionnaires: `Questionnaires`,
  cmptcsView: `CMPTCS view`,
  bitsightView: `BitSight view`,
  riskView: `Risk view`,
  kycView: `KYC view`,
  advancedRiskView: `Advanced risk view`,
  riskIndicator: `Risk indicator`,
  editRiskClass: `Edit risk class`,
  leaveComment: `Please leave a comment`,
  riskClassRequired: `Risk class is required field`,
  successRequestPremiumRiskData: `You have successfully requested premium risk data`,
  requestPremiumRiskData: `Request premium risk data`,
  financialRisks: `Financial risks`,
  financialRisksEmptyText: `Financial risk is the possibility of losing money on an investment or business venture. Some more common and distinct financial risks include credit risk and liquidity risk. `,
  requestSurvey: `Request survey`,
  survey: `Survey`,
  surveyInfoPopup: `Our Titan risk tolerance questionnaires consists of a set of survey questions per risk category that help our Titan clients to understand the nature of supplier’s style and risk as sociated with the supplier relationship better.`,
  complianceRisks: `Compliance & legal risks`,
  reputationalRisks: `Reputational & sustainability risks`,
  contractualRisks: `Contractual risks`,
  riskIndicatorInfo: `Titan Risk Indicator is a measure based on our Titan risk rating framework. The Risk results are indicating how risky an activity with this supplier and his legal entity is. The data are calculated based on the supplier self declerations as itself and best in class supplier risk related data feeds.`,
  operationalRisks: `Operational risks`,
  complianceRisksInfo: `Compliance and Legal risk captures the legal and financial penalties for failing to act under internal and external regulations and legislature.`,
  reputationalRisksInfo: `Reputational- and Sustainability risk is a newly emerging risk area. Is emerging as a business strategy that aligns profit goals with e.g. internal green strategies and policies.`,
  contractualRisksInfo: `The contract risk includes in particular losses for the buyer due to partial/non-performance of the contract.`,
  operationalRisksInfo: `Operational risk summarizes the chances and uncertainties a company faces in the course of conducting its daily business activities, procedures, and systems. It’s heavily dependent on the human factor: mistakes or failures due to actions or decisions made. Another important part is Cyber Risk. which is associated with financial loss, disruption or damage to the reputation of an organization from failure, unauthorized or erroneous use of its IT systems.`,
  noSurveysAnswered: `No survey answered`,
  profileInfoPopUp: `<div>The company profile section <b>"Profiles"</b> provides a brief summary of the company with all relevant information. It distinguishes between a core area with logo, description, company structure, company size and social media links and a more detailed area with for example the company presentation, company video or preferred supplier status. The profile is designed to be interactive. If the continuative area is not maintained, the user can "invite" the company. Then the corresponding filling out of the missing information is triggered. It is important to know that the titanium application is free of charge for suppliers.</div>`,
  personalInfoPopUp: `<div>The company profile area <b>"Personal area"</b> shows three central pieces of information. These are the contract management, the notes which are stored for companies (personal or internal information) as well as a list area which shows in which lists the company is stored for the user. This personal and company-specific usage function is a CRM function for companies to better manage their suppliers. The contract file is the i-point and rounds off the company profile and the search.</div>`,
  capabilitiesInfoPopUp: `<div>The company profile section <b>"Capabilities"</b> shows the two central topic blocks "Business Expertises" (in which topics the company does business) and "Industry Expertise" (in which industries it is active). The underlying framework of more than 300 Capability Items enables a detailed search for Capabilities by companies. The framework is fully integrated with the Titan search and is a core function of Titan in conjunction with the other company profile areas. No other provider in the market goes as deep as we do.</div>`,
  detailsPopUp: `<div>The company profile area <b>"Details"</b> shows company registration, (modelled) sales and publication data, the location of the company and the main contact person for customers. A Sales Orga Chart of the company is also available, if provided by the company.</div>`,
  peoplePopUp: `<div>The company profile area <b>"People & Organization"</b> displays the key principles of the company s as displayed in the official company register. In addition, if available as information, the auditor of the company. The respective Most Senior Principle of the company is highlighted first.</div>`,
  riskInfoPopUp: `<div>The Company Profile section <b>"Risk & Compliance"</b> and displays the Titan's Risk Profile of the company. This consists of the Risk Indicator, the Risk Class of the supplier at the own company, the data feeds and the self-declaration questionnaires. The data feeds and the questionnaires calculate the Titan Risk Indicator. Basically, the data feeds are divided into Basic Data and Payable Data. Basic Data is provided by Titan for about 50-100 companies (the most important companies in the market). Further data feeds that go into more detail or are required for other companies can be ordered seperatly via our platform.</div>`,
  procurementInfoPopUp: `<div>The company profile area <b>"Procurement"</b> shows cumulatively all commercial information of the company. This refers mainly to the daily rates and price lists, but also to further purchase-related information such as the company's standard purchasing terms and conditions. It is important to note that the information in this area can be displayed in two ways (a) Public or (b) Private. Depending on how e.g. the Rate Card was set up. Private means exclusively 1:1 shown in the business relationship between the company and the own company.</div>`,
  productInfoPopUp: `<div>The company profile section <b> "Products"</b> presents the company's IT and non-IT products. In the first step, the products are created by the company and then adapted in a second step. The adaptation refers to the detailed information of the product, such as logo, description, product presentation or technology roadmap. The most important thing, however, are the "implementation partners" of the (software) products. These are included in the search. </div>`,
  deletePersonalNote: `Delete personal note`,
  editPersonalNote: `Edit personal note`,
  requestPremiumRiskQuestion: `<p>Dear requester,</p> <p> Risk data are a payable option on the Titan SIM platform. Our Titan basic risk data package costs 50 CHF/onetime per request. The second premium data feed from BitSight is based on the BitSight pricing scheme which you agreed individually with them (and needs to be ordered seperately (in addition to the basic risk data package)). The BitSight package will not issue additional costs to your Titan account (because it's based on your individual contract with them). </p> <span>Please note that any final payable order request will issue a Titan invoice to you.</span>`,
  order: `Order`,
  view: `View`,
  showMore: `Show more`,
  credit: `Credit`,
  payment: `Payment`,
  control: `Control`,
  date: `Date`,
  paydexRating: `Paydex rating`,
  paymentBehaviorDays: `Payment behavior days:`,
  paymentBehaviorDescription: `Paymnet behavior description:`,
  selfDeclarationResponse: `Self declaration response`,
  creditRiskRating: `Credit risk rating`,
  dAndBRational: `Dun & Bradstreet rational:`,
  recommendedCreditLimit: `Recommended credit limit:`,
  controlStandardRating: `Control standard rating`,
  governance: `Governance`,
  amlRating: `AML rating`,
  matches: `Matches`,
  noMatches: `No matches`,
  noShareholders: `Shareholders are not available for this company`,
  rdParty: `3rd party`,
  dataProtection: `Data prot.`,
  subContractorRiskRating: `Sub-contractor risk rating:`,
  lastLoginDate: `Last login date`,
  noData: `No data`,
  lastLoginUser: `Last login user`,
  numberOfProducts: `Number of products`,
  cmptcsImportDate: `CMPTCS imported at`,
  cmpelkImportDate: `CMPELK imported at`,
  kycImportDate: `KYC imported at`,
  successfullySentMessage: `You have successfully sent message`,
  sendMail: `Send E-mail`,
  myRequests: `My requests`,
  myRequestsDesc: `Here you can see your requests and also our collaboration and messaging feature to interact with your supplier and partners.`,
  requestType: `Request type:`,
  internalNoteAdded: `Internal note added`,
  internalNoteDeleted: `Internal note deleted`,
  internalNoteEdited: `Internal note edited`,
  contractFolderChanged: `Contract folder changed`,
  changedBy: `Changed by {0}`,
  companyInvite: `Company invite`,
  contractInfo: `Contract info:`,
  premiumRiskDataRequest: `Premium risk data requested`,
  selfDeclarationRequested: `Self declaration requested`,
  privateRateCardRequested: `Private rate card requested`,
  addRiskClass: `Add risk class`,
  riskOverRule: `Over-rule risk indicator`,
  riskOverRuleCommentRequired: `Over-rule comment is required`,
  riskClassificationDescription: `Risk classification description`,
  riskReviewComment: `Risk review comment`,
  riskOverRuleDelete: `Delete over-rule indicator`,
  riskReview: `Risk review`,
  riskClass: `Risk class`,
  myCompany: `My company`,
  addNewMember: `Add new FTE Resource`,
  otherValueRequired: `Other Resource value is required`,
  teamMemberTitleRequired: `FTE Resource name is required`,
  teamMemberPorifileRequired: `Job profile is required`,
  teamMemberSeniorityRequired: `Seniority is required`,
  teamMemberDaysRequired: `Person Days is required`,
  teamMemberRequired: `Valid FTE resources are required`,
  other: `Other resources`,
  otherComment: `Other resources comment`,
  teamMembers: `Team resources`,
  missingJobProfileForCompany: `There is missing job profile for this company`,
  companyListRequired: `Please select company`,
  publicRateCardAvailable: `Public rate card available for`,
  noPublicRateCard: `No public rate card available`,
  publicRateCardFrom: `Public rate card from`,
  privateRateCardAvailableFor: `Private rate card available for`,
  requestPrivateRateCardFrom: `You are going to send a request for a private rate card from {0}`,
  requestPrivateRateCardQuestion: `The email will be send to this company with your request please confirm?`,
  requetPrivateRateCard: `Request private rate card`,
  privateRateCardBetween: `Private rate card between {0} and {1}`,
  premiumDataType: `Please select premium data type`,
  chooseDataType: `Select data type`,
  signUpTitleOne: `Exceptional supplier`,
  signUpTitleTwo: `data intelligence - unlocked.`,
  signUpTermsOne: `By creating account you agree to`,
  signUpTermsTwo: `and our privacy statment.`,
  validNumber: `Field must be a valid number`,
  members: `FTE resources`,
  others: `Other resources`,
  searchIconPremiumData: `Company has premium data`,
  searchIconNoPremiumData: `Company doesnt have premium data`,
  bitsightPremium: `BitSight premium data`,
  titanBasic: `Titan basic risk data`,
  printSurvey: `Print survey`,
  addAttachemnt: `Add attachment`,
  subject: `Subject`,
  messageRequired: `Message is required`,
  message: `Message`,
  archiveMessage: `Archive message`,
  archiveMessageQuestion: `You are going to archive message, are you sure ?`,
  showMessage: `Show message`,
  sentAt: `Sent at: {0}`,
  status: `Status`,
  compareCompanies: `Compare companies`,
  companies: `Companies`,
  personalNoteAdded: `Personal note added`,
  personalNoteDelete: `Personal note deleted`,
  personalNoteEdited: `Personal note edited`,
  listChanged: `List updated`,
  company_update: `Company updated`,
  message_sent: `Message sent`,
  compare_companies: `Compare companies`,
  invite_company: `Company invite`,
  company_list_change: `Company list changed`,
  survey_answer: `Survey answered`,
  contract_folder_change: `Contract folder changed`,
  internal_note_add: `Internal note added`,
  internal_note_delete: `Internal note deleted`,
  internal_note_edit: `Internal note edited`,
  personal_note_add: `Personal note added`,
  personal_note_delete: `Personal note deleted`,
  personal_note_edit: `Personal note edited`,
  request_premium_risk_data: `Premium risk data requested`,
  request_self_declaration: `Supplier Self declaration requested`,
  private_rate_card_request: `Private rate card requested`,
  standardTermsEnName: `Standard terms english name`,
  selectEnglishTerms: `Select english standard terms`,
  standardTermsDeName: `Standard terms german name`,
  selectGermanTerms: `Select german terms of use `,
  companiesView: `Companiew view`,
  archiveRequest: `Archive request`,
  archiveRequestQuestion: `You are going to archive the request, are you sure ?`,
  resendRequest: `Resend request`,
  resendRequestQuestion: `You are going to resend the request, are you sure ?`,
  archive: `Archive`,
  requestResended: `Request successfuly resended`,
  allCompanies: `All companies`,
  allBuyers: `All buyers`,
  allSupliers: `All supliers`,
  noCompanyType: `No company type`,
  columnCompanyResourceVetting: `Resource vetting`,
  remove: `Remove`,
  requestResourceVetting: `Request resource vetting`,
  requestSingleResourceVetting: `Request single resource vetting`,
  requestBulkResourceVetting: `Request bulk resource vetting`,
  nationalityLabel: `Nationality`,
  sendRequest: `Send request`,
  nationalityRequired: `Please select nationality`,
  birthDateRequired: `Birth date is required`,
  birthDateLabel: `Birth date`,
  requestBulkExplanation: `You can send multiple request, please add request information in CSV`,
  screeningReportTable: `Screening reports`,
  clientName: `Client name`,
  report: `Report`,
  noReport: `No report`,
  lastVettingDate: `Last vetting date`,
  vettingNumber: `Vettings #`,
  reportHistory: `Report history`,
  showHistory: `Show history`,
  requestSuccessfull: `You successfuly requested report`,
  reportUpdated: `Report successfuly updated`,
  refetchReport: `Refetch report`,
  refetchReportText: `You are going to re-fetch report, are you sure ?`,
  refetch: `Re-fetch`,
  generatePdf: `Generate PDF`,
  generatingReport: `Generating report ....`,
  downloadReport: `Download report`,
  reportStatus: `Report status`,
  caseCreated: `In progress`,
  caseReady: `Finished`,
  updateExists: `Update exists`,
  requestResourceVettingText: `You are going to request report, are you sure ?`,
  request: `Request`,
  columnCompanyBenchmark: `Benchmark rate card`,
  resourceId: `Resource ID`,
  residenceLabel: `Residence`,
  deleteReportQuestion: `You are going to delete the screening report, are you sure  ?`,
  reportDeleteSuccess: `Screening report successfuly deleted`,
  reportVettingDescriptionTitle: `Screening report table description`,
  reportVettingDescriptionContent: `Screening report section description; not filled yet. It will be filled shortly.`,
  lastName: `Last name`,
  lastNameRequired: `Last name is required`,
  documents: `Documents`,
  tags: `Tags`,
  viewCompanyProfile: `View company profile`,
  contracts: `Contracts`,
  welcome: `Welcome`,
  compare: `Compare`,
  users: `Users`,
  search: `Search`,
  companiesViewMatrix: `Comapanies view matrix`,
  importCompanies: `Import companies`,
  terms: `Terms`,
  preferredSuppliers: `Preferred suppliers`,
  companyLists: `Company lists`,
  surveys: `Surveys`,
  generalCriteria: `General criteria`,
  targetRateCards: `Target rate cards`,
  privateRateCards: `Private rate cards`,
  titanBenchmarks: `Titan benchmarks`,
  publicRateCards: `Public rate cards`,
  resourceVetting: `Resource vetting`,
  screeningRequests: `Screening requests`,
  screeningReports: `Screening reports`,
  companiesOverview: `Companies overview`,
  deletingFile: `You are going to delete product file are you sure ?`,
  invitedUsers: `Invited users`,
  inviteDate: `Invited at`,
  requestSuccessStoryTitle: `You are going to send a request for a success story from {0}. Please select the type of request below. An e-mail will be sent to the company with your request, please confirm your selection.`,
  general_success_story: `General success story`,
  business_expertise_specific: `Business expertise specific`,
  sort: `Sort`,
  addInvisibleTag: `Add invisible tag`,
  editInvisibleTags: `Edit invisible tags`,
  globalList: `Global list`,
  cantEditDomain: `Cannot change domain, company have users !`,
  editPersonalRiskNote: `Edit personal risk note`,
  deletePersonalRiskNote: `Delete personal risk note`,
}
