import { decorate, flow, observable } from 'mobx'
import { NetworkCall } from '../../../store/models/NetworkModels'
import { StoreBase } from '../../../store/StoreBase'
import intl from 'react-intl-universal'

const ResourceVettingStoreCalls = Object.freeze({
  RESOURCE_VETTING_REQUEST: 'resourceVettingRequest',
})

export default class ResourceVettingStore extends StoreBase {
  report = []
  constructor(rootStore) {
    super(rootStore)
    this.resourceVettingRequestCall = new NetworkCall(rootStore, {
      id: ResourceVettingStoreCalls.RESOURCE_VETTING_REQUEST,
      secured: true,
      path: 'resourcevetting',
    })
  }

  loadReport = flow(function* () {
    if (this.resourceVettingRequestCall.callInProgress) return

    const res = yield this.resourceVettingRequestCall.call('get')
    res.mapResult((result) => {
      if (result.data.statusCode === 200) {
        this.report = result.data.resource_vetting
        return true
      }
    })
  })

  sendRequest = flow(function* (data) {
    if (this.resourceVettingRequestCall.callInProgress) return

    const res = yield this.resourceVettingRequestCall.call('post', data)
    res.mapResult((result) => {
      if (result.data.statusCode === 200) {
        if (data.resource_vetting_request_id) {
          if (data.delete_request) {
            this.report = this.report.filter(
              (r) =>
                r.resource_vetting_request_id !==
                data.resource_vetting_request_id,
            )
            this.rootStore.toast.setNotification(
              {
                message: intl.get('reportDeleteSuccess'),
                description: '',
                placement: 'topRight',
              },
              'success',
            )
          } else {
            this.report = this.report.map((r) => {
              if (
                r.resource_vetting_request_id ===
                data.resource_vetting_request_id
              ) {
                return result.data.resource_vetting_updated
              }
              return r
            })
            this.rootStore.toast.setNotification(
              {
                message: intl.get('reportUpdated'),
                description: '',
                placement: 'topRight',
              },
              'success',
            )
          }
        } else {
          this.rootStore.toast.setNotification(
            {
              message: intl.get('requestSuccessfull'),
              description: '',
              placement: 'topRight',
            },
            'success',
          )
        }
      } else {
        this.rootStore.toast.setNotification(
          {
            message: intl.get('exception'),
            description: '',
            placement: 'topRight',
          },
          'error',
        )
      }
    })
  })
}

decorate(ResourceVettingStore, {
  report: observable,
})
