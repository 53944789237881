import React, { useContext } from 'react'
import { Modal, Table } from 'antd'
import intl from 'react-intl-universal'
import { observer } from 'mobx-react'
import StoreContext from '../../../../../storeContext'
import ActionButton from '../../../../shared/ActionButton'
import EditFileCell from '../../../../shared/EditFileCell'
import { useParams } from 'react-router'
import EditProfileField from '../../../../Profile/EditProfileField'
import { DatePicker, Select } from 'formik-antd'

export default observer(function ({ visible, handleOnCancel, stories }) {
  const { layout, companyProfile } = useContext(StoreContext)
  const company_id = useParams().id
  const renderTags = () =>
    companyProfile.storiesTags.map((tag) => {
      return <Select.Option value={tag}>{tag}</Select.Option>
    })
  const columns = [
    {
      title: intl.get('successStoryTitle'),
      dataIndex: 'story_title',
      render: (text, record) => (
        <EditProfileField
          type="cell"
          name="story_title"
          value={record.story_title}
          callback={async (val, setEdit) => {
            await companyProfile.updateCompany(
              {
                company_id,
                success_story_edit: { ...val, story_id: record.story_id },
              },
              'editSuccessStory',
            )
            setEdit(false)
          }}
        />
      ),
      width: 250,
    },
    {
      title: intl.get('successStoryDate'),
      dataIndex: 'story_date',
      width: 200,
      render: (text, record) => (
        <EditProfileField
          type="cell"
          name="story_date"
          value={record.story_date}
          component={(setFieldValue) => (
            <DatePicker
              name="story_date"
              picker="month"
              onChange={(s, ss) => setFieldValue('story_date', ss)}
              format="YYYY-MM"
              placeholder={intl.get('selectStoryDate')}
              style={{ width: '100%' }}
            />
          )}
          callback={async (val, setEdit) => {
            await companyProfile.updateCompany(
              {
                company_id,
                success_story_edit: { ...val, story_id: record.story_id },
              },
              'editSuccessStory',
            )
            setEdit(false)
          }}
        />
      ),
    },
    {
      title: intl.get('successStoryTags'),
      width: 250,
      render: (text, record) => (
        <EditProfileField
          type="cell"
          name="story_tags"
          value={record.story_tags}
          valueLabel={record.story_tags.join(',')}
          component={(setFieldValue) => (
            <Select
              mode="multiple"
              name="story_tags"
              placeholder={intl.get('selectStoryTags')}
            >
              {renderTags()}
            </Select>
          )}
          callback={async (val, setEdit) => {
            await companyProfile.updateCompany(
              {
                company_id,
                success_story_edit: { ...val, story_id: record.story_id },
              },
              'editSuccessStory',
            )
            setEdit(false)
          }}
        />
      ),
    },
    {
      title: intl.get('successStoryFile'),
      width: 350,
      render: (text, record) => (
        <EditFileCell
          url={record.story_url}
          urlTitle={
            record.file_name.split('/')[record.file_name.split('/').length - 1]
          }
          selectFileTitle={intl.get('selectSuccessStoryFile')}
          callback={(story_file, story_file_name) => {
            return companyProfile.updateCompany({
              company_id,
              success_story_add_file: {
                story_file,
                story_id: record.story_id,
                story_file_name,
              },
            })
          }}
        />
      ),
    },
    {
      title: intl.get('usersTableColumnActions'),
      width: 200,
      render: (text, record) => (
        <ActionButton
          text={intl.get('delete')}
          onClick={() =>
            companyProfile.updateCompany({
              company_id,
              success_story_remove: { story_id: record.story_id },
            })
          }
        />
      ),
    },
  ]

  return (
    <Modal
      title={intl.get('editSuccessStory')}
      visible={visible}
      destroyOnClose={true}
      width="80%"
      footer={null}
      onCancel={handleOnCancel}
    >
      <Table
        rowKey="certificate_id"
        columns={columns}
        dataSource={stories}
        bordered={true}
        scroll={layout.width < 950 && { x: 850 }}
        pagination={{
          total: stories.length,
          showSizeChanger: true,
          defaultPageSize: 10,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    </Modal>
  )
})
