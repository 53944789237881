import React, { useContext } from 'react'
import { Modal } from 'antd'
import { Formik } from 'formik'
import { Form, SubmitButton, Input, Select } from 'formik-antd'
import StoreContext from '../../../../storeContext'
import * as Yup from 'yup'
import intl from 'react-intl-universal'
import { ProductGroup } from "../../../../constants/dropdowns";
export default function ({ visible, handleOnCancel }) {
  const { companyProfile } = useContext(StoreContext)
  const initialValues = {
    add: {
      product_name: '',
      product_group: '',
      description: '',
      product_url: '',
    },
    company_id: companyProfile.company.company_id,
  }
  const validation = Yup.object().shape({
    add: Yup.object().shape({
      product_name: Yup.string().required(intl.get('productNameRequired')),
      product_group: Yup.string().required(intl.get('productGroupRequired')),
      description: Yup.string().max(800, intl.get('nameErrorMax', { 0: 800 })),
    }),
  })
  return (
    <Modal
      title={intl.get('addProduct')}
      visible={visible}
      destroyOnClose={true}
      onCancel={handleOnCancel}
      footer={null}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={async (vals, { setSubmitting }) => {
          await companyProfile.editProduct(vals, 'add')
          setSubmitting(false)
          handleOnCancel()
        }}
      >
        {() => {
          return (
            <Form>
              <Form.Item name="add.product_name">
                <Input name="add.product_name" addonBefore="Product name" />
              </Form.Item>
              <Form.Item name="add.product_group">
                <Select
                  name="add.product_group"
                  placeholder={intl.get('selectProductGroup')}
                >
                  {ProductGroup.map(group=><Select.Option value={group.value}>{group.name}</Select.Option>)}
                </Select>
              </Form.Item>
              <Form.Item name="add.product_url">
                <Input
                  name="add.product_url"
                  addonBefore={intl.get('productUrl')}
                />
              </Form.Item>
              <Form.Item name="add.description">
                <Input.TextArea
                  placeholder={intl.get('productDescription')}
                  name="add.description"
                />
              </Form.Item>
              <SubmitButton>{intl.get('add')}</SubmitButton>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
