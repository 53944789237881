import React, { useContext, useEffect } from 'react'
import StoreContext from '../../storeContext'
import { RoleGroups } from '../../utils/roles'
import CompanySurveys from './CompanySurveys'
import TitanSurveys from './TitanSurveys'

export default function () {
  const { user, survey } = useContext(StoreContext)
  useEffect(() => {
    survey.getTags(false)
    survey.getInvisibleTags(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return RoleGroups.TITANS.includes(user.user['custom:role_id']) ? (
    <TitanSurveys />
  ) : (
    <CompanySurveys />
  )
}
