import React from 'react'
import { Loading } from "../../loading"
import { Skeleton } from "antd"
import { CompanyProfileStoreCalls } from "../store/CompanyProfileStore"

export default function({children}) {
  return <Loading blockers={[CompanyProfileStoreCalls.GET_COMPANY_DATA]}>
    <Loading.Done>
      {children}
    </Loading.Done>
    <Loading.InProgress>
      <Skeleton active />
    </Loading.InProgress>
  </Loading>
}