import { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import StoreContext from '../../storeContext'
import { Can, displayContent } from '../authorization'

const Loading = observer((props) => {
  const store = useContext(StoreContext)
  return displayContent(
    props,
    store.network.runningCalls.filter((call) => props.blockers.includes(call))
      .length > 0,
  )
})

Loading.InProgress = Can.Pass
Loading.Done = Can.Fail

export { Loading }
