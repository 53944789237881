import React, { useContext, useState } from 'react'
import { Button, message, Space, Upload } from 'antd'
import StoreContext from '../../../../storeContext'
import { useParams } from 'react-router'
import { UploadOutlined } from '@ant-design/icons'
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'

export default function ({ url, certificate_id }) {
  const company_id = useParams().id
  const { companyProfile } = useContext(StoreContext)
  const [editing, setEditing] = useState(false)
  function onFileChange(file) {
    if (!file.name.includes('.pdf')) {
      message.error('File must be pdf')
      return false
    }

    const reader = new FileReader()
    reader.onload = async () => {
      setEditing(true)
      await companyProfile.updateCompany({
        company_id,
        certificateaddfile: {
          certificate_file: reader.result.split(',')[1],
          certificate_id,
        },
      })
      setEditing(false)
    }
    reader.readAsDataURL(file)
    return false
  }
  return (
    <Space align="center">
      {url && (
        <>
          <a target="_blank" rel="noopener noreferrer" href={url}>
            Certificate file
          </a>
          <Button
            disabled={editing}
            onClick={async () => {
              setEditing(true)
              await companyProfile.updateCompany({
                company_id,
                certificateremovefile: { certificate_id },
              })
              setEditing(false)
            }}
          >
            Remove{editing && <LoadingOutlined />}
          </Button>
        </>
      )}
      <Upload fileList={false} beforeUpload={onFileChange}>
        <Button disabled={editing} style={{ width: '100%' }}>
          <UploadOutlined />
          Select certificate file
          {editing && <LoadingOutlined />}
        </Button>
      </Upload>
    </Space>
  )
}
