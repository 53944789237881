import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import StoreContext from '../../../../storeContext'
import { COMPANY_PROFILE_TABS } from '../../index'
import { Card, Col, Modal, Row, Space } from 'antd'
import intl from 'react-intl-universal'
import LoadingWrapper from '../LoadingWrapper'
import EditButton from '../../EditButton'
import EditNote from './EditNote'
import { Link } from 'react-router-dom'
import AddToList from './AddToList'
import EditModal from '../../../shared/EditModal'
import Contracts from './Contracts'
import AddContractFolder from './Contracts/AddContractFolder'
import InfoPopUp from '../../../InfoPopUp'
import { RoleGroups } from '../../../../utils/roles'
import AddPersonalNote from './AddPersonalNote'

export default React.memo(
  observer(
    React.forwardRef(function (props, ref) {
      const element = useRef()
      const [showNote, setShowNote] = useState(false)
      const [showAddList, setShowAddList] = useState(false)
      const [showAddFolder, setShowAddFolder] = useState(false)
      const [showAddPersonalNote, setShowAddPersonalNote] = useState(false)
      const { companyProfile, user } = useContext(StoreContext)
      const confirmDeletePersonalNote = () => {
        Modal.confirm({
          title: intl.get('deletePersonalNote'),
          okText: intl.get('delete'),
          onOk: async () => {
            await companyProfile.personalNote({
              remove: { note_id: companyProfile.company.personal_note.note_id },
              company_id: companyProfile.company.company_id,
            })
          },
        })
      }
      const confirmDeleteInternalNote = () => {
        Modal.confirm({
          title: intl.get('deleteInternalNote'),
          okText: intl.get('delete'),
          onOk: async () => {
            await companyProfile.internalNote({
              remove: { note_id: companyProfile.company.internal_note.note_id },
              company_id: companyProfile.company.company_id,
            })
          },
        })
      }
      const menuItems = [
        {
          name: companyProfile.company.internal_note
            ? intl.get('editInternalNote')
            : intl.get('addInternalNote'),
          callback: () => setShowNote(true),
          show: RoleGroups.ADMINS.includes(user.user['custom:role_id']),
        },
        {
          name: companyProfile.company.personal_note
            ? intl.get('editPersonalNote')
            : intl.get('addPersonalNote'),
          callback: () => setShowAddPersonalNote(true),
        },
        {
          name: intl.get('deletePersonalNote'),
          callback: () => confirmDeletePersonalNote(),
          show: !!companyProfile.company.personal_note,
        },

        {
          name: intl.get('deleteInternalNote'),
          callback: () => confirmDeleteInternalNote(),
          show: !!companyProfile.company.internal_note,
        },
        {
          name: intl.get('addToList'),
          callback: () => setShowAddList(true),
        },
        {
          name: intl.get('addContractFolder'),
          callback: () => setShowAddFolder(true),
          show: RoleGroups.ADMINS.includes(user.user['custom:role_id']),
        },
      ]
      useEffect(() => {
        ref.current[COMPANY_PROFILE_TABS.personal.id] = element.current
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [element.current])
      return (
        <Card
          title={intl.get('personalArea')}
          bodyStyle={{
            padding:
              !companyProfile.company.internal_note &&
              !companyProfile.company.personal_note &&
              !companyProfile.company.list_member.length &&
              !companyProfile.company.contracts.length &&
              0,
          }}
          extra={
            <div style={{ display: 'flex' }}>
              <EditButton menuItems={menuItems} show={true} />
              <InfoPopUp
                html={intl.get('personalInfoPopUp')}
                title={intl.get('personalArea')}
              />
            </div>
          }
        >
          <LoadingWrapper>
            <div ref={element} id={COMPANY_PROFILE_TABS.personal.id}>
              <Row>
                {companyProfile.company.personal_note && (
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <Card
                      type="inner"
                      bordered={false}
                      title={intl.get('personalNoteTitle')}
                    >
                      <div
                        style={{
                          wordBreak: 'break-word',
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {companyProfile.company.personal_note.note}
                      </div>
                    </Card>
                  </Col>
                )}
                {companyProfile.company.internal_note && (
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <Card
                      type="inner"
                      bordered={false}
                      title={intl.get('internalNoteTitle', {
                        0: companyProfile.company.internal_note.updated_by,
                        1: new Date(
                          companyProfile.company.internal_note.last_updated,
                        ).toLocaleDateString(),
                      })}
                    >
                      <div
                        style={{
                          wordBreak: 'break-word',
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {companyProfile.company.internal_note.note}
                      </div>
                    </Card>
                  </Col>
                )}
                {companyProfile.company.list_member.length > 0 && (
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <Card bordered={false} title="Lists" type="inner">
                      <Space direction="vertical">
                        {companyProfile.company.list_member.map((list) => (
                          <Link to={`/dashboard/company-lists`}>
                            {list.list_name}
                          </Link>
                        ))}
                      </Space>
                    </Card>
                  </Col>
                )}
              </Row>
              <Contracts folders={companyProfile.company.contracts} />
            </div>
            <EditNote
              visible={showNote}
              handleOnCancel={() => setShowNote(false)}
              note={companyProfile.company.internal_note}
            />
            <AddToList
              visible={showAddList}
              handleOnCancel={() => setShowAddList(false)}
            />
            <EditModal
              handleOnClose={() => setShowAddPersonalNote(false)}
              visible={showAddPersonalNote}
              title={intl.get('addPersonalNote')}
            >
              <AddPersonalNote note={companyProfile.company.personal_note} />
            </EditModal>
            <EditModal
              handleOnClose={() => setShowAddFolder(false)}
              visible={showAddFolder}
              title={intl.get('addContractFolder')}
            >
              <AddContractFolder />
            </EditModal>
          </LoadingWrapper>
        </Card>
      )
    }),
  ),
)
