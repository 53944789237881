import { Button } from 'antd'

import React, { useState } from 'react'
import EditModal from '../../shared/EditModal'
import intl from 'react-intl-universal'
import EditRiskReview from './EditRiskReview'

export default function RiskReview({ aml, id }) {
  const [show, setShowEdit] = useState(false)
  const init = {
    risk_review_rating: aml.risk_review_rating,
    risk_review_description: aml.risk_review_description,
    resource_vetting_request_id: id,
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {aml.risk_review_rating || 'N/A'}
      <Button
        onClick={() => setShowEdit(true)}
        style={{ margin: 0 }}
        type="link"
      >
        Edit
      </Button>
      <EditModal
        handleOnClose={() => setShowEdit(false)}
        visible={show}
        title={intl.get('riskOverRule')}
      >
        <EditRiskReview init={init} />
      </EditModal>
    </div>
  )
}
