import React, { useContext } from "react";
import EditProfileField from '../../../../Profile/EditProfileField'
import { Statistic } from 'antd'
import { Form, Input, Select } from 'formik-antd'
import * as Yup from 'yup'
import StoreContext from "../../../../../storeContext";
import { Roles } from "../../../../../utils/roles";
import { action } from "mobx";

export default function ({ record }) {
  const {companyProfile, user} = useContext(StoreContext)
  const validation = Yup.object().shape({
    value: Yup.number()
      .required('Revenue is required')
      .typeError('Revenue must be a number'),
    currency: Yup.string().required('Currency is required'),
  })
  return record.yearlyRevenue.map((rev) => {
    return (
      <EditProfileField
        init={{ value: rev.value, currency: rev.currency }}
        callback={async (vals, setEdit)=> {
          action(() => {
           record.yearlyRevenue = record.yearlyRevenue.map(m=>{
             if(m === rev) return vals
             return m
           })
            record.reliability =
              user.user['custom:role_id'] === Roles.CLIENT_ADMIN
                ? 'Confirmed'
                : 'Titan'
          })()
          await companyProfile.updateCompany({
            company_id: companyProfile.company.company_id,
            financials: companyProfile.company.financials,
          })

          setEdit(false)
        }}
        validator={validation}
        name="yearlyRevenue"
        valueLabel={<Statistic suffix={rev.currency} value={rev.value} />}
        component={(setFieldValue, isSubmiting) => (
          <div style={{display: 'flex', alignItems:'baseline'}}>
            <Form.Item name="value" >
              <Input name="value"  />
            </Form.Item>
            <Form.Item name="currency" hasFeedback={true}>
              <Select
                placeholder="Select currency"
                name="currency"
                addonBefore="Currency"
              >
                <Select.Option value={'CHF'}>CHF</Select.Option>
                <Select.Option value={'USD'}>USD</Select.Option>
                <Select.Option value={'EUR'}>EUR</Select.Option>
              </Select>
            </Form.Item>
          </div>
        )}
      />
    )
  })
}
