import React, { useContext } from 'react'
import { Avatar, Checkbox } from 'antd'
import { observer } from 'mobx-react'
import StoreContext from '../../../storeContext'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import Pin from '../../../assets/svgs/map-pin.svg'
import Navigation from '../../../assets/svgs/navigation.svg'
import styled from 'styled-components'

const StyledItemAvatar = styled.div`
  .avatar-wrap {
    max-width: 95px;
  }
  .item-avatar {
    width: 95px !important;
  }
  .avatar-icons {
    display: flex;
    font-size: 12px;
    color: rgba(29, 42, 61, 0.5);
    align-items: end;
    margin-top: 5px;
    & :first-child {
      margin-right: 5px;
    }
  }
`

export default observer(function ({ item }) {
  const { search, compare } = useContext(StoreContext)
  return (
    <StyledItemAvatar
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'baseline',
        }}
      >
        <Avatar
          style={{
            cursor: 'pointer',
            background: 'transparent',
            color: 'black',
          }}
          icon={
            compare.companies.find((i) => item.company_id === i.company_id) ? (
              <MinusOutlined onClick={() => compare.removeCompany(item)} />
            ) : (
              <PlusOutlined
                style={{ color: compare.totalCompanies === 3 && '#d9d9d9' }}
                onClick={() => compare.addCompany(item)}
              />
            )
          }
        />
        <Checkbox
          checked={search.selectedCompanies.indexOf(item.company_id) !== -1}
          onChange={() => {
            search.toggleSearchItem(item.company_id)
          }}
        />
      </div>
      <div className="avatar-wrap">
        <Avatar
          className="item-avatar"
          shape="square"
          size={60}
          src={`http://${item.logo_url}`}
        />
        <div className="avatar-icons">
          <img alt="city icon" src={Pin} />
          <span>{item.city}</span>
        </div>
        <div className="avatar-icons">
          <img alt="city icon" src={Navigation} />
          <span>{`${Math.round(item.distance)} km`}</span>
        </div>
      </div>
    </StyledItemAvatar>
  )
})
