import React, { useContext, useState } from "react"
import { Button, Modal } from "antd"
import { Formik } from "formik"
import { Form, Input, SubmitButton } from "formik-antd"
import intl from "react-intl-universal"
import StoreContext from "../../../storeContext"

export default function ({ record }) {
  const [show, setShow] = useState(false)
  const {companiesOverview} = useContext(StoreContext)
  const initialValues = {
    terms_custom_version: record.terms_version
  }

  return (
    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between'}}>
      <span
        style={{
          maxWidth: 200,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {record.terms_version}
      </span>
      <Button onClick={()=> setShow(true)} style={{ marginBottom: 0 }} type="link">
        {intl.get('addCustomTerms')}
      </Button>
      <Modal
        title={intl.get('addCustomTermsModalTitle')}
        visible={show}
        footer={null}
        onCancel={() => {
          setShow(false)
        }}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={async (vals, {setSubmitting}) => {
            await companiesOverview.updateCompany(record.company_id, vals, 'terms_version', vals.terms_custom_version )
            setSubmitting(false)
            setShow(false)
          }}
        >
          {({ isSubmitting, setFieldValue, resetForm }) => (
            <Form>
              <Form.Item name="terms_custom_version">
                <Input.TextArea disabled={isSubmitting} name="terms_custom_version" rows={10} placeholder="Terms"/>
              </Form.Item>
              <SubmitButton>{intl.get('save')}</SubmitButton>
              <Button
                onClick={() => {
                  resetForm()
                  setShow(false)
                }}
              >
                {intl.get('cancel')}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  )
}
