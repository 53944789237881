import React, { useContext } from 'react'
import { Modal, Card, Col, Row } from 'antd'
import * as Yup from 'yup'
import EditProfileField from './EditProfileField'
import { observer } from 'mobx-react'
import StoreContext from '../../storeContext'
import { ValidationFields } from '../../utils/validationFields'
import { Phone } from '../../utils/phoneInput'
import intl from 'react-intl-universal'
import PhoneInput from 'react-phone-number-input'
import { RoleGroups, Roles, RolesName } from '../../utils/roles'
import { Select } from 'formik-antd'
import moment from 'moment'
import ShowHideCell from '../GeneralCriteria/ShowHideCell'
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Loading } from '../loading'
import { UserProfileStoreCalls } from './store/UserProfileStore'
const { confirm } = Modal
export default observer(function ({ id }) {
  const { profile, router, user } = useContext(StoreContext)
  const userProfile = new URLSearchParams(router.location.search).get('user')
  function showConfirm() {
    confirm({
      title: intl.get('deleteUserConfimation'),
      icon: <ExclamationCircleOutlined />,
      okText: intl.get('delete'),
      onOk: async () => await profile.deleteUser(id),
    })
  }
  return (
    profile.profile && (
      <Card
        title={
          <>
            <UserOutlined />
            <span style={{ fontWeight: 500, marginLeft: 10 }}>
              {intl.get('userInformationText')}
            </span>
          </>
        }
        extra={
          <>
            {profile.profile.user_id === user.user['cognito:username'] && (
              <Loading blockers={[UserProfileStoreCalls.CHANGE_PASSWORD]}>
                <Loading.InProgress>
                  <LoadingOutlined />
                </Loading.InProgress>
                <Loading.Done>
                  <span
                    onClick={() => profile.changePassword()}
                    className="gx-link"
                  >
                    {intl.get('changePasswordLinkText')}
                  </span>
                </Loading.Done>
              </Loading>
            )}
            <span
              onClick={showConfirm}
              style={{ marginLeft: 10 }}
              className="gx-link"
            >
              {intl.get('deactivateAccountLinkText')}
            </span>
          </>
        }
        className="gx-card-widget"
      >
        <Row>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <EditProfileField
              value={profile.profile.name}
              label={intl.get('nameLabel')}
              name="name"
              callback={async (value, setEdit) => {
                await profile.updateProfile(value)
                setEdit(false)
              }}
              validator={Yup.object({ name: ValidationFields.name() })}
            />
            <EditProfileField
              value={profile.profile.surname}
              label={intl.get('surnameLabel')}
              name="surname"
              callback={async (value, setEdit) => {
                await profile.updateProfile(value)
                setEdit(false)
              }}
              validator={Yup.object({ surname: ValidationFields.surname() })}
            />
            {Roles.CLIENT_ADMIN === user.user['custom:role_id'] &&
              user.user['resource_vetting_feature'] &&
              userProfile && (
                <EditProfileField
                  value={RolesName[profile.profile.role_id]}
                  label={intl.get('columnCompanyResourceVetting')}
                  withoutEdit
                  valueLabel={
                    profile.profile.resource_vetting_feature
                      ? 'Enabled'
                      : 'Disabled'
                  }
                  name="resource_vetting_feature"
                  component={(setFieldValue, disabled, setEdit) => (
                    <ShowHideCell
                      checked="remove"
                      unchecked="add"
                      visible={profile.profile.resource_vetting_feature}
                      callback={(checked) =>
                        profile
                          .updateProfile({ resource_vetting_feature: checked })
                          .then(() => setEdit(false))
                      }
                    />
                  )}
                />
              )}
            {!RoleGroups.TITANS.includes(user.user['custom:role_id']) &&
              RoleGroups.ADMINS.includes(user.user['custom:role_id']) &&
              userProfile && (
                <EditProfileField
                  value={RolesName[profile.profile.role_id]}
                  label={intl.get('usersTableColumnRole')}
                  name="role_id"
                  component={(setFieldValue, disabled) => (
                    <Select name="role_id">
                      <Select.Option value={Roles.CLIENT_USER}>
                        Client user
                      </Select.Option>
                      <Select.Option value={Roles.CLIENT_ADMIN}>
                        Client admin
                      </Select.Option>
                    </Select>
                  )}
                  callback={async (value, setEdit) => {
                    await profile.updateProfile(value)
                    setEdit(false)
                  }}
                />
              )}
            <EditProfileField
              value={profile.profile.email}
              label={intl.get('emailLabel')}
              name="email"
              locked={true}
            />
            <EditProfileField
              value={profile.profile.phone}
              label={intl.get('phoneLabel')}
              name="phone"
              component={(setFieldValue, disabled) => (
                <PhoneInput
                  inputComponent={Phone}
                  name="phone"
                  disabled={disabled}
                  value={profile.profile.phone}
                  className="phone-input-wrapper"
                  onChange={(e) => setFieldValue('phone', e)}
                  placeholder={intl.get('phoneLabel')}
                />
              )}
              callback={async (value, setEdit) => {
                await profile.updateProfile(value)
                setEdit(false)
              }}
              validator={Yup.object({ phone: ValidationFields.phone() })}
            />
            <EditProfileField
              value={profile.profile.company_position}
              label={intl.get('positionLabel')}
              name="company_position"
              callback={async (value, setEdit) => {
                await profile.updateProfile(value)
                setEdit(false)
              }}
              validator={Yup.object({
                company_position: ValidationFields.position(),
              })}
            />
            <EditProfileField
              value={profile.profile.company_name}
              label={intl.get('companyNameLabel')}
              name="company_name"
              locked={true}
            />
            <EditProfileField
              value={moment
                .utc(profile.profile.last_login)
                .local()
                .format('MM-DD-YYYY HH:mm:ss')}
              label={intl.get('lastLoginDate')}
              name="last_login"
              locked={true}
            />
            <a
              rel="noopener noreferrer"
              href={profile.profile.terms_url}
              target="_blank"
            >
              {intl.get('acceptedTermsOfUse', {
                0: profile.profile.terms_version,
              })}
            </a>
          </Col>
        </Row>
      </Card>
    )
  )
})
