import React, { useEffect, useRef, useState } from 'react'
import { Card } from 'antd'
import Principal from './Principal'
import intl from 'react-intl-universal'
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined'
import UpOutlined from '@ant-design/icons/lib/icons/UpOutlined'

export default function ({ standard = false, senior = false }) {
  const ref = useRef()
  const [showTotal, setShowTotal] = useState(0)
  const [showMore, setShowMore] = useState(false)
  useEffect(() => {
    if (ref.current) {
      setShowTotal(Math.floor(ref.current.offsetWidth / (200+10)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Card title={intl.get('companyPrincipals')} type="inner" bordered={false}>
      <div className="principal-wrapper" ref={ref}>
        {senior && <Principal type="senior" name={senior.fullName} title={senior.title} /> }
        {standard && standard.map((principal, i) => {
          if (i > showTotal - 2 && !showMore) return null
          return (
            <Principal
              type="standard"
              title={principal.jobTitles.length ? principal.jobTitles[0].title : ''}
              name={principal.fullName}
            />
          )
        })}
      </div>
      { standard.length > showTotal && <div className="show-more" onClick={() => setShowMore((more) => !more)}>
        <span>{showMore ? 'Show less' : 'Show more'}</span> {showMore ? <UpOutlined /> : <DownOutlined />}{' '}
      </div>}
    </Card>
  )
}
