import React, { useContext, useEffect, useRef, useState } from 'react'
import StoreContext from '../../../../storeContext'
import { Button, List, Popover, Input } from 'antd'
import intl from 'react-intl-universal'
import { Link } from 'react-router-dom'
import {
  BarChartOutlined,
  StarOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import ItemDescription from '../ItemDescription'
import ItemAvatar from '../ItemAvatar'

const { Search } = Input

function ImplementationPartnersList({ productId }) {
  const { search } = useContext(StoreContext)
  const [partners, setPartners] = useState([])
  const [showMore, setShowMore] = useState(false)
  const [loading, setLoading] = useState(true)
  const allPartners = useRef()
  const init = (p) => {
    setPartners(p)
    allPartners.current = p
    setLoading(false)
  }
  const onSearch = (val) => {
    const filtered = allPartners.current.filter((c) =>
      c.name.toLowerCase().includes(val.toLowerCase()),
    )
    setPartners(filtered)
  }
  useEffect(() => {
    search.loadPartners(productId, init)
    // eslint-disable-next-line
  }, [])
  return (
    <div style={{ display: ' flex', flexDirection: 'column' }}>
      {allPartners.current && allPartners.current.length > 3 && (
        <Search
          placeholder="Search partners"
          onSearch={onSearch}
          style={{ alignSelf: 'center', marginTop: '8px' }}
        />
      )}
      <List
        itemLayout="horizontal"
        dataSource={showMore ? partners : partners.slice(0, 3)}
        loading={loading}
        renderItem={(item) => (
          <List.Item style={{ gap: '1em' }}>
            <List.Item.Meta
              avatar={<ItemAvatar item={item} />}
              description={
                <div>
                  <div className="desc-wrap">
                    <Link to={`/dashboard/company/${item.company_id}`}>
                      {item.name}
                    </Link>
                    <div style={{ display: 'flex' }}>
                      <div
                        className={`icon-wrap ${
                          item.list_member ? 'icon-active' : ''
                        }`}
                      >
                        <Popover
                          placement="topLeft"
                          content={
                            item.preferred_supplier
                              ? intl.get('searchIconInList')
                              : intl.get('searchIconNotInList')
                          }
                          trigger="hover"
                        >
                          <UnorderedListOutlined />
                        </Popover>
                      </div>
                      <div
                        className={`icon-wrap ${
                          item.preferred_supplier ? 'icon-active' : ''
                        }`}
                      >
                        <Popover
                          placement="topLeft"
                          content={
                            item.preferred_supplier
                              ? intl.get('searchIconPreferredSupplier')
                              : intl.get('searchIconNotPreferredSupplier')
                          }
                          trigger="hover"
                        >
                          <StarOutlined />
                        </Popover>
                      </div>
                      <div
                        className={`icon-wrap ${
                          item.has_rate_card ? 'icon-active' : ''
                        }`}
                      >
                        <Popover
                          placement="topLeft"
                          content={
                            item.has_rate_card
                              ? intl.get('searchIconRateCard')
                              : intl.get('searchIconNoRateCard')
                          }
                          trigger="hover"
                        >
                          <BarChartOutlined />
                        </Popover>
                      </div>
                    </div>
                  </div>
                  <ItemDescription item={item} />
                </div>
              }
            />
            {/* <Details item={item} /> */}
          </List.Item>
        )}
      />
      {partners.length > 3 && (
        <Button
          onClick={() => setShowMore((show) => !show)}
          style={{ alignSelf: 'center' }}
          type="link"
        >
          Show {showMore ? 'less' : 'more'}
        </Button>
      )}
    </div>
  )
}

export default ImplementationPartnersList
