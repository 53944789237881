import React, { useState } from 'react'
import { Button, Typography } from 'antd'
import intl from 'react-intl-universal'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import Rating from '../Rating'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 6px;
`
const { Text } = Typography
export default observer(function ({
  name,
  icon,
  modal,
  rating,
  showView = true,
}) {
  const [showInfo, setShowInfo] = useState(false)
  const hasQuestions = rating.questions ? rating.questions.length > 0 : false
  const hasRating =
    rating.rating !== 'N/A' && rating.rating && rating.rating !== 'no rating'
  return (
    <StyledDiv>
      <div>
        <Text strong>{name}</Text>{' '}
        {showView && (hasRating || hasQuestions) && (
          <Button
            style={{ margin: 0 }}
            type="link"
            onClick={() => setShowInfo(true)}
          >
            {intl.get('view')}
          </Button>
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ color: '#7E889B', marginRight: 5 }}>{rating.rating}</div>
        {!hasRating && !hasQuestions ? (
          <Rating trafficLight score={'N/A'} />
        ) : (
          <Rating trafficLight score={rating.rating} />
        )}
      </div>

      {modal(showInfo, setShowInfo)}
    </StyledDiv>
  )
})
