import React, { useContext, useState } from "react";
import { Row } from "antd";
import { Formik } from "formik";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import { RiskCategoryOptions, RiskSubcategoryOptions } from "../../constants/dropdowns";
import { observer } from "mobx-react";
import StoreContext from "../../storeContext";
import { action } from "mobx";

const AddRiskRow = ({ question, callback }) => {
  const [show, setShow] = useState(false)

  let init = {
    risk_category: '',
    risk_subcategory: '',
  }
  if (question.type === 'radiogroup') {
    init.risk_values = {}
    question.choices.forEach((c) => {
      init.risk_values[c.value] = ''
    })
    if (question.hasOther) {
      init.risk_values.other = ''
    }
  }
  init = { ...init, ...question }
  const renderChoicesValues = () => {
    const temp = question.choices.map((c) => {
      return (
        <Form.Item name={`risk_values.${c.value}`}>
          <Input
            name={`risk_values.${c.value}`}
            addonBefore={`${c.value} (${
              typeof c.text === 'string' ? c.text : c.text.default
            })`}
          />
        </Form.Item>
      )
    })
    if (question.hasOther) {
      temp.push(
        <Form.Item name={`risk_values.other`}>
          <Input
            name={`risk_values.other`}
            addonBefore={`Other (${
              typeof question.otherText === 'string'
                ? question.otherText
                : question.otherText.default
            })`}
          />
        </Form.Item>,
      )
    }
    return temp
  }
  return (
    <Row
      className="ant-form-item"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        gap: '2em',
        border: '1px solid #d9d9d9',
        borderRadius: 10,
      }}
    >
      <div
        style={{
          width: '100%',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          padding: '1.5em',
          backgroundColor: '#fafafa',
          borderBottom: !show ? 'none' : '1px solid #d9d9d9',
          borderBottomLeftRadius: show ? 0 : 10,
          borderBottomRightRadius: show ? 0 : 10,
        }}
        onClick={() => setShow((val) => !val)}
      >
        Question: {question.name}({question.title.de})
      </div>
      {show && (
        <div style={{ padding: '0 2em' }}>
          <Formik
            initialValues={init}
            onSubmit={(vals, { setSubmitting }) => {
              action(() => {
                question.risk_category = vals.risk_category
                question.risk_subcategory = vals.risk_subcategory
                question.risk_values = vals.risk_values
              })()
              setSubmitting(false)
              setShow(false)
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Form.Item name="risk_category">
                  <Select
                    placeholder="Select category"
                    name="risk_category"
                    onChange={(e) => {
                      setFieldValue('risk_subcategory', '')
                    }}
                  >
                    {RiskCategoryOptions.map((opt) => (
                      <Select.Option value={opt.value}>
                        {opt.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {values['risk_category'] && (
                  <Form.Item name="risk_subcategory">
                    <Select
                      placeholder="Select subcategory"
                      name="risk_subcategory"
                    >
                      {RiskSubcategoryOptions[values['risk_category']].map(
                        (opt) => (
                          <Select.Option value={opt.value}>
                            {opt.name}
                          </Select.Option>
                        ),
                      )}
                    </Select>
                  </Form.Item>
                )}
                {question.type === 'radiogroup' ? (
                  renderChoicesValues()
                ) : (
                  <Form.Item name="risk_value">
                    <Input addonBefore="Risk value" name="risk_value" />
                  </Form.Item>
                )}
                <SubmitButton>Add</SubmitButton>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Row>
  )
}

export default observer(function () {
  const { survey } = useContext(StoreContext)
  const { editingSurvey: data } = survey

  if (!data) return null
  const renderQuestions = () => {
    return data.pages.map((page) => {
      if (!page.elements) return null
      return page.elements.map((question) => {
        if (question.elements) {
          return question.elements.map(
            (q) => q.type === 'radiogroup' && <AddRiskRow question={q} />,
          )
        }
        return (
          question.type === 'radiogroup' && <AddRiskRow question={question} />
        )
      })
    })
  }
  return (
    <div>
      <h3>Edit questions </h3>
      {renderQuestions()}
    </div>
  )
})
