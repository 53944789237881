import React, { useContext } from 'react'
import { Card, Divider, Tag } from 'antd'
import StoreContext from '../../../../storeContext'
import { Link } from 'react-router-dom'
import { getValidLink } from '../../../../utils/helpers'
import packagePng from '../../../../assets/images/package.png'
import { observer } from 'mobx-react'

export default observer(function ({ product }) {
  const { companyProfile } = useContext(StoreContext)
  return (
    <Card
      title={
        <div style={{ display: 'flex', gap: '1em' }}>
          <h3>{product.product_name}</h3>
          <Tag>{product.product_group}</Tag>
        </div>
      }
      style={{ flex: '1 1 500px', maxWidth: '100%' }}
      type="inner"
      bordered={false}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ maxWidth: 150, maxHeight: 150, marginRight: 10 }}>
          <img
            alt="product"
            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            src={product.logo_url || packagePng}
          />
        </div>
        <div
          style={{
            display: 'flex',
            gap: '1em',
            margin: '10px 0 10px 0',
            flexWrap: 'wrap',
            flexDirection: 'column',
            flex: '1 1 0',
          }}
        >
          <div
            style={{
              overflowWrap: 'anywhere',
              wordBreak: 'break-word',
              whiteSpace: 'pre-line',
            }}
          >
            {product.description}
          </div>
          <div
            style={{
              display: 'flex',
              gap: '1em',
              flexWrap: 'wrap',
            }}
          >
            {product.product_url && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getValidLink(product.product_url)}
              >
                {product.product_url}
              </a>
            )}
            {product.presentation_url && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={product.presentation_url}
              >
                Presentation
              </a>
            )}
            {product.reference_installations_url && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={product.reference_installations_url}
              >
                Reference installations
              </a>
            )}
            {product.roadmap_url && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={product.roadmap_url}
              >
                Roadmap
              </a>
            )}
            {product.price_list_url && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={product.price_list_url}
              >
                Price list
              </a>
            )}
          </div>
          {product.implementation_partners && (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
              }}
            >
              <h5>Implementation partners:</h5>
              <div>
                {companyProfile.partners
                  .filter((partner) =>
                    product.implementation_partners.includes(
                      partner.company_id,
                    ),
                  )
                  .map((partner) => (
                    <>
                      <Link to={`/dashboard/company/${partner.company_id}`}>
                        {partner.name}
                      </Link>
                      <Divider type="vertical" />
                    </>
                  ))}
              </div>
            </div>
          )}

          <span
            style={{ marginLeft: 'auto', color: 'rgba(29, 42, 61, 0.5)' }}
          >{`Last updated: ${new Date(
            product.last_updated,
          ).toLocaleDateString()}`}</span>
        </div>
      </div>
    </Card>
  )
})
