import { Divider, Modal, Space, Statistic } from 'antd'
import moment from 'moment'
import Rating from '../../Rating'
import SurveyData from '../SurveyData'
import React from 'react'

export default function ({ showPreview, setShowPreview, data }) {
  return (
    <Modal
      title="Data protection"
      visible={showPreview}
      footer={null}
      destroyOnClose={true}
      onCancel={() => setShowPreview(false)}
    >
      <Space style={{ width: '100%' }} direction={'vertical'}>
        <Statistic
          title="Date"
          value={
            data.questionaire_completed_date
              ? moment(data.questionaire_completed_date).format('DD MMMM YYYY')
              : 'N/A'
          }
        />
        <Divider type="horizontal" />
        <Statistic
          title="Data protection risk rating:"
          valueRender={() => <Rating score={data.rating} />}
        />

        <Divider type="horizontal" />
        <Statistic
          title={`Self declaration response`}
          valueRender={() => <SurveyData questions={data.questions} />}
        />
      </Space>
    </Modal>
  )
}
