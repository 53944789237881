import React from 'react'
import { Popover, Tag, Typography } from 'antd'
import intl from 'react-intl-universal'
const { Text } = Typography
export default function ({ tags }) {
  const renderTags = () =>
    tags.map((tag) => {
      return (
        <Popover
          trigger="hover"
          content={<div style={{ maxWidth: 200 }}>{tag.tag_description}</div>}
          title={intl.get('description')}
        >
          <Tag>{tag.tag_name}</Tag>
        </Popover>
      )
    })
  return (
    <div className="tags-wrapp">
      <Text type="secondary">Tags:</Text>
      <div style={{ marginTop: 10 }}>{renderTags()}</div>
    </div>
  )
}
