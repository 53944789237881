import React, { useContext, useEffect } from 'react'
import { Card, Col, Input, Row, Select, Table } from 'antd'
import { observer } from 'mobx-react'
import StoreContext from '../../../storeContext'
import { cols } from './UsersTableColumns'
import intl from 'react-intl-universal'
import { Roles } from '../../../utils/roles'

const { Option } = Select
const { Search } = Input
export default observer(function UsersTable() {
  const { users, user } = useContext(StoreContext)
  useEffect(() => {
    users.getUsersList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  function handleChange(val) {
    users.filterUsers(val)
  }
  return (
    <Card title={intl.get('users')} id="users_table">
      <Row>
        <Col>
          <Select
            defaultValue="allUsers"
            id="users_table_filter-users"
            style={{ width: 130, margin: 16 }}
            onChange={handleChange}
          >
            <Option value="allUsers">{intl.get('optionAllUsers')}</Option>
            {user.user['custom:role_id'] === Roles.TITAN_ADMIN && (
              <Option value="titanUsers">{intl.get('optionTitanUsers')}</Option>
            )}
            <Option value="pendingUsers">
              {intl.get('optionPendingUsers')}
            </Option>
            <Option value="disabledUsers">
              {intl.get('optionDisabledUsers')}
            </Option>
          </Select>
        </Col>
        <Col>
          <Search
            id="users_table_search-users"
            placeholder={intl.get('searchLabel')}
            onSearch={(value) => users.searchUsers(value)}
            style={{ width: 200, margin: 16 }}
          />
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <Table
            rowKey="user_id"
            bordered
            columns={cols(user.user['cognito:username'])}
            scroll={{ x: 1500 }}
            dataSource={users.filteredUsers}
            pagination={{
              total: users.filteredUsers.length,
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '20', '50', '100'],
            }}
          />
        </Col>
      </Row>
    </Card>
  )
})
