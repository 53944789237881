import React, { useContext } from 'react'
import { Statistic } from 'antd'
import { observer } from 'mobx-react'
import StoreContext from '../../../storeContext'

export default observer(function () {
  const { compare } = useContext(StoreContext)

  return (
    compare.benchmark && (
      <div className={`company company-5`}>
        <div className="company-title">
          <h2 style={{ display: 'flex' }}>Benchmark</h2>
        </div>
        <div className="company-risk"></div>
        <div className="company-price">
          <Statistic
            title="Veary good"
            value={compare.benchmark.rate}
            precision={2}
            suffix="CHF"
          />
        </div>
        <div className="company-capabilities"></div>
        <div className="company-my-view"></div>
      </div>
    )
  )
})
