import React from "react";
import { Formik } from "formik";
import { Checkbox, Form, Input, Select, SubmitButton } from "formik-antd";
import { DocumentType } from "../../../../../constants/dropdowns";
import * as Yup from "yup";
import intl from "react-intl-universal";

export default function ({
  document,
  close,
  contract_folder_id,
  company_id,
  update,
}) {
  const initialValues = {
    company_id,
    edit_document: {
      contract_folder_id,
      document_id: document.document_id,
      document_name: document.document_name,
      document_type: document.document_type,
      is_confidential: document.is_confidential,
      description: document.description,
      contract_no: document.contract_no,
      po_number: document.po_number,
    },
  }

  const validation = Yup.object().shape({
    edit_document: Yup.object().shape({
      document_name: Yup.string().required(intl.get('documentNameRequired')),
      document_type: Yup.string().required(intl.get('documentTypeRequired')),
      description: Yup.string().max(200, intl.get('nameErrorMax', { 0: 200 })),
    }),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={async (vals) => {
        await update(vals, 'edit_document')
        close()
      }}
    >
      {({ setFieldValue, errors }) => {
        return (
          <Form>
            <Form.Item name="edit_document.document_type">
              <Select
                name="edit_document.document_type"
                placeholder={intl.get('selectDocumentType')}
                onChange={(val, option) =>
                  setFieldValue('edit_document.document_name', option.children)
                }
              >
                {DocumentType.map((type) => {
                  return (
                    <Select.Option value={type.value}>
                      {type.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item name="edit_document.document_name">
              <Input
                name="edit_document.document_name"
                addonBefore={intl.get('documentName')}
              />
            </Form.Item>
            <Form.Item name="edit_document.contract_no">
              <Input
                name="edit_document.contract_no"
                addonBefore={intl.get('contractNumber')}
              />
            </Form.Item>
            <Form.Item name="edit_document.po_number">
              <Input
                name="edit_document.po_number"
                addonBefore={intl.get('purchaseOrderNumber')}
              />
            </Form.Item>
            <Form.Item name="edit_document.is_confidential">
              <Checkbox name="edit_document.is_confidential">
                {intl.get('confidential')}
              </Checkbox>
            </Form.Item>
            <Form.Item name="edit_document.description">
              <Input.TextArea
                name="edit_document.description"
                addonBefore="Description"
                placeholder={intl.get('description')}
              />
            </Form.Item>
            <SubmitButton>{intl.get('save')}</SubmitButton>
          </Form>
        )
      }}
    </Formik>
  )
}
