import React, { useContext, useState } from 'react'
import { Card, Divider, Modal, Space, Statistic } from 'antd'
import { observer } from 'mobx-react'
import StoreContext from '../../../../storeContext'

export default observer(function () {
  const { companyProfile } = useContext(StoreContext)
  const [showDetails, setShowDetails] = useState(false)
  const renderRevenue = (revenue) => {
    const defaultCurrency = revenue.yearlyRevenue.filter(
      (rev) =>
        companyProfile.company.country.toLowerCase() === 'ch' &&
        rev.currency.toLowerCase() === 'chf',
    )
    if (defaultCurrency.length > 0) {
      return (
        <Statistic
          suffix={defaultCurrency[0].currency}
          value={defaultCurrency[0].value}
        />
      )
    }
    return (
      revenue.yearlyRevenue.length > 0 && (
        <Statistic
          suffix={revenue.yearlyRevenue[0].currency}
          value={revenue.yearlyRevenue[0].value}
        />
      )
    )
  }
  return (
    <Card
      type="inner"
      title="Revenue"
      extra={
        companyProfile.company.financials &&
        companyProfile.company.financials.length > 2 && (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => setShowDetails(true)}
          >
            Show more
          </span>
        )
      }
      bordered={false}
    >
      {companyProfile.company.financials &&
        companyProfile.company.financials.map((r) => (
          <>
            <div>
              <h3>{`${r.year} ${r.reliability}`}</h3>
              <Space direction="vertical">{renderRevenue(r)}</Space>
            </div>
            <Divider />
          </>
        ))}
      <Modal
        visible={showDetails}
        footer={null}
        title={'Revenue details'}
        onCancel={() => setShowDetails(false)}
      >
        {companyProfile.company.financials &&
          companyProfile.company.financials.map((r) => (
            <>
              <div>
                <h3>{`${r.year} ${r.reliability}`}</h3>
                <Space>
                  {r.yearlyRevenue.map((y) => (
                    <Statistic suffix={y.currency} value={y.value} />
                  ))}
                </Space>
              </div>
              <Divider />
            </>
          ))}
      </Modal>
    </Card>
  )
})
