import { action, decorate, observable } from 'mobx'
import { StoreBase } from './StoreBase'
import { notification } from 'antd'

export const toastMessage = Object.freeze({
  SUCCESS: Object.freeze({ variant: 'success' }),
  INFO: Object.freeze({ variant: 'info' }),
  WARNING: Object.freeze({ variant: 'warning' }),
  ERROR: Object.freeze({ variant: 'error' }),
})

export default class ToastStore extends StoreBase {
  showMsg = null
  api = null
  constructor(rootStore) {
    super(rootStore)
    this.rootStore = rootStore
  }

  setNotification = (config, type) => {
    return this.api && this.api[type](config)
  }

  closeNotification = (key) => {
    notification.close(key)
  }

  setToast(showMsg) {
    this.showMsg = showMsg
  }
}
decorate(ToastStore, {
  showMsg: observable,
  setToast: action,
})
