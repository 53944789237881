import React, { useContext } from 'react'
import { Button, DatePicker, Modal, Table } from 'antd'
import StoreContext from '../../../../storeContext'
import { useParams } from 'react-router'
import { CompanyProfileStoreCalls } from '../../store/CompanyProfileStore'
import { Loading } from '../../../loading'
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'
import CertificateFileCell from './CertificateFileCell'
import EditProfileField from '../../../Profile/EditProfileField'
import intl from 'react-intl-universal'
import moment from 'moment'

export default function ({ certificates, visible, handleOnCancel }) {
  const { companyProfile } = useContext(StoreContext)
  const company_id = useParams().id
  const columns = [
    {
      title: intl.get('nameLabel'),
      dataIndex: 'certificate_name',
      width: 350,
      fixed: 'left',
      render: (text, record) => (
        <EditProfileField
          type="cell"
          name="certificate_name"
          value={record.certificate_name}
          callback={async (val, setEdit) => {
            await companyProfile.updateCompany({
              certificateedit: {
                certificate_id: record.certificate_id,
                ...val,
              },
              company_id,
            })
            setEdit(false)
          }}
        />
      ),
    },
    {
      title: intl.get('authority'),
      dataIndex: 'certification_authority',
      width: 350,
      render: (text, record) => (
        <EditProfileField
          type="cell"
          name="certification_authority"
          value={record.certification_authority}
          callback={async (val, setEdit) => {
            await companyProfile.updateCompany({
              certificateedit: {
                certificate_id: record.certificate_id,
                ...val,
              },
              company_id,
            })
            setEdit(false)
          }}
        />
      ),
    },
    {
      title: intl.get('certificateNumber'),
      dataIndex: 'certificate_number',
      width: 350,
      render: (text, record) => (
        <EditProfileField
          type="cell"
          name="certificate_number"
          value={record.certificate_number}
          callback={async (val, setEdit) => {
            await companyProfile.updateCompany({
              certificateedit: {
                certificate_id: record.certificate_id,
                ...val,
              },
              company_id,
            })
            setEdit(false)
          }}
        />
      ),
    },
    {
      title: intl.get('description'),
      dataIndex: 'certificate_description',
      width: 200,
      render: (text, record) => (
        <EditProfileField
          type="cell"
          name="certificate_description"
          value={record.certificate_description}
          callback={async (val, setEdit) => {
            await companyProfile.updateCompany({
              certificateedit: {
                certificate_id: record.certificate_id,
                ...val,
              },
              company_id,
            })
            setEdit(false)
          }}
        />
      ),
    },
    {
      title: intl.get('certificateValidDate'),
      dataIndex: 'certificate_valid_until',
      width: 300,
      render: (text, record) => (
        <EditProfileField
          type="cell"
          name="certificate_valid_until"
          init={{
            certificate_valid_until: new Date(record.certificate_valid_until),
          }}
          valueLabel={moment(
            record.certificate_valid_until,
            'YYYY-MM-DD',
          ).format('MM-DD-YYYY')}
          component={(setFieldValue, s, a, value) => (
            <DatePicker
              name="certificate_valid_until"
              defaultValue={moment(value, 'MM-DD-YYYY')}
              onChange={(date) => {
                if (!date) return null
                setFieldValue(
                  'certificate_valid_until',
                  moment(date, 'YYYY-MM-DD'),
                )
                return date
              }}
              placeholder={intl.get('certificateValidDate')}
              style={{ width: '100%' }}
            />
          )}
          callback={async (val, setEdit) => {
            await companyProfile.updateCompany({
              certificateedit: {
                certificate_id: record.certificate_id,
                ...val,
              },
              company_id,
            })
            setEdit(false)
          }}
        />
      ),
    },
    {
      title: intl.get('certificateFile'),
      dataIndex: 'certificate_valid_until',
      width: 400,
      render: (text, record) => (
        <CertificateFileCell
          url={record.certificate_url}
          certificate_id={record.certificate_id}
        />
      ),
    },
    {
      title: 'Action',
      width: 200,
      fixed: 'right',
      render: (text, record) => (
        <Loading blockers={[CompanyProfileStoreCalls.UPDATE_COMPANY_PROFILE]}>
          <Loading.InProgress>
            <Button disabled>
              {intl.get('delete')}
              <LoadingOutlined />
            </Button>
          </Loading.InProgress>
          <Loading.Done>
            <Button
              onClick={() =>
                companyProfile.updateCompany({
                  certificateremove: { certificate_id: record.certificate_id },
                  company_id,
                })
              }
            >
              {intl.get('delete')}
            </Button>
          </Loading.Done>
        </Loading>
      ),
    },
  ]
  return (
    <Modal
      title={intl.get('editCertificates')}
      visible={visible}
      destroyOnClose={true}
      width="80%"
      footer={null}
      onCancel={handleOnCancel}
    >
      <Table
        rowKey="certificate_id"
        columns={columns}
        dataSource={certificates}
        bordered={true}
        scroll={{ x: 1500 }}
        pagination={{
          total: certificates.length,
          showSizeChanger: true,
          defaultPageSize: 10,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    </Modal>
  )
}
