import { StoreBase } from "./StoreBase";
import { action, decorate, observable } from "mobx";

export default class LayoutStore extends StoreBase {
  sideBarCollapsed = false
  contentScrollPosition = 0
  contentWidth = 0
  width = window.innerWidth

  setContentScroll = (e) => {
    this.contentScrollPosition = e.currentTarget.scrollTop
    this.contentWidth = e.currentTarget.clientWidth - ( this.width > 575 ? 64 : 31 )
  }
}

decorate(LayoutStore, {
  sideBarCollapsed: observable,
  width: observable,
  contentScrollPosition: observable,
  setContentScroll: action,
  contentWidth: observable,
})
