import React, { useContext, useState } from 'react'
import { Button, Input, Modal, Popover, Statistic } from 'antd'
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import StoreContext from '../../../storeContext'
import { Link } from 'react-router-dom'
import Rating from '../../CompanyProfile/Sections/Risk/Rating'
import intl from 'react-intl-universal'
import { getValidLink } from '../../../utils/helpers'
const { TextArea } = Input
const { confirm } = Modal
export default function ({ company, ind }) {
  const { compare, companyProfile } = useContext(StoreContext)
  const [key] = useState(new Date().getTime())
  const getPriceCalc = () => {
    if (!compare.benchmark || !compare.benchmark.rate || !company.rate)
      return ''
    if (company.rate > compare.benchmark.rate) return 'Very good'
    if (company.rate > compare.benchmark.rate - compare.benchmark.rate * 0.1)
      return 'Good'
    if (company.rate > compare.benchmark.rate - compare.benchmark.rate * 0.25)
      return 'Ok'
    return 'Not Ok'
  }
  return (
    <div className={`company company-${ind}`}>
      <div className="company-title">
        <div style={{ width: 80 }}>
          <img
            alt="company-logo"
            src={company.logo_url && `http://${company.logo_url}?${key}`}
          />
        </div>
        <h2 style={{ display: 'flex' }}>
          <Link to={`/dashboard/company/${company.company_id}`}>
            {company.name}
          </Link>
        </h2>
        {ind < 4 && (
          <CloseOutlined onClick={() => compare.removeCompany(company)} />
        )}
      </div>
      <div className="company-attributes">
        {company.country &&
        company.employee_count_range &&
        company.company_url ? (
          <>
            <Statistic title="Country:" value={company.country} />
            <Statistic
              title="Employees:"
              value={company.employee_count_range}
            />
            <a
              style={{ alignSelf: 'baseline' }}
              rel="noopener noreferrer"
              href={getValidLink(company.company_url)}
              target="_blank"
            >
              View website
            </a>
          </>
        ) : (
          <span>Please select job profile and seniority</span>
        )}
      </div>
      <div className="company-risk">
        {company.risk && (
          <div style={{ width: '100%' }}>
            <Rating score={company.risk} />
          </div>
        )}
      </div>
      <div className="company-price">
        {company.missing_rates && (
          <Popover content={intl.get('missingJobProfileForCompany')}>
            <ExclamationCircleOutlined className="price-info" />
          </Popover>
        )}
        {typeof company.rate === 'undefined' ? (
          <span>Please select job profile and seniority</span>
        ) : company.rate > 0 ? (
          <Statistic
            title={getPriceCalc()}
            value={company.rate}
            precision={2}
            suffix="CHF"
          />
        ) : (
          <Button
            style={{ margin: 0 }}
            size="small"
            onClick={() =>
              confirm({
                title: `${intl.get('requestPrivateRateCardFrom', {
                  0: company.name,
                })}`,
                icon: <ExclamationCircleOutlined />,
                content: intl.get('requestPrivateRateCardQuestion'),
                onOk: async () => {
                  await companyProfile.sendPrivateRateCardRequest(
                    company.company_id,
                  )
                },
              })
            }
          >
            {intl.get('requetPrivateRateCard')}
          </Button>
        )}
      </div>
      {/*<div className="company-capabilities">
        <Statistic value={56} suffix="%" />
      </div>*/}
      <div className="company-my-view">
        <TextArea placeholder="Comment" rows={4} />
      </div>
    </div>
  )
}
