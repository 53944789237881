import React, { useContext } from 'react'
import intl from 'react-intl-universal'
import { Modal } from 'antd'
import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import { Phone } from '../../../../../utils/phoneInput'
import PhoneInput from 'react-phone-number-input'
import ImageSelect from '../../../../shared/ImageSelect'
import * as Yup from 'yup'
import { ValidationFields } from '../../../../../utils/validationFields'
import { observer } from 'mobx-react'
import StoreContext from '../../../../../storeContext'
import FileUpload from '../../../../FileUpload'

export default observer(function ({ visible, handleOnCancel }) {
  const { companyProfile } = useContext(StoreContext)
  const {
    name = '',
    email = '',
    telephone = '',
    sales_org_chart = '',
    procurement_url = '',
    supplier_registration_url = '',
    image_file_url = '',
  } = companyProfile.company.contact_person || {}
  const initialValues = {
    delete_contact_image: false,
    sales_org_chart,
    delete_sales_org_chart: false,
    name,
    email,
    telephone,
    contact_image: '',
    procurement_url,
    supplier_registration_url,
  }

  const validation = Yup.object().shape({
    name: ValidationFields.name(),
    email: ValidationFields.email(),
    telephone: ValidationFields.phone().required('Telephone is required'),
  })

  return (
    <Modal
      title={intl.get('editContactPerson')}
      visible={visible}
      destroyOnClose={true}
      footer={null}
      onCancel={handleOnCancel}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={async (vals) => {
          await companyProfile.updateCompany({
            contact_person: vals,
            company_id: companyProfile.company.company_id,
          })
          handleOnCancel()
        }}
      >
        {({ setFieldValue, errors }) => (
          <Form>
            <Form.Item name="name">
              <Input
                name="name"
                addonBefore={intl.get('firsAndLastName')}
                placeholder={intl.get('firsAndLastName')}
              />
            </Form.Item>
            <Form.Item name="email">
              <Input
                name="email"
                addonBefore={intl.get('emailLabel')}
                placeholder={intl.get('emailLabel')}
              />
            </Form.Item>
            <div
              className="ant-row ant-form-item"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <PhoneInput
                inputComponent={Phone}
                name="telephone"
                value={telephone}
                className="phone-input-wrapper"
                onChange={(e) => setFieldValue('telephone', e)}
                placeholder="Telephone number"
              />
              <span style={{ color: '#f5222d' }}>{errors['telephone']}</span>
            </div>
            <Form.Item name="contact_image">
              <ImageSelect
                removeCallback={() => {
                  setFieldValue('contact_image', '')
                  setFieldValue('delete_contact_image', true)
                }}
                callback={(image) => setFieldValue('contact_image', image)}
                formats={['image/png', 'image/jpeg']}
                currentImage={image_file_url}
              />
            </Form.Item>
            {companyProfile.company.company_type !== 1 ? (
              <Form.Item name="sales_org_chart">
                <FileUpload
                  setFile={(file) => setFieldValue('sales_org_chart', file)}
                  errorMessage="File must be pdf"
                  buttonText={intl.get('selectOrgChart')}
                  remove={() => {
                    setFieldValue('sales_org_chart', '')
                  }}
                />
              </Form.Item>
            ) : (
              <>
                <Form.Item name="procurement_url">
                  <Input
                    name="procurement_url"
                    addonBefore={intl.get('procurementUrl')}
                    placeholder={intl.get('procurementUrl')}
                  />
                </Form.Item>
                <Form.Item name="supplier_registration_url">
                  <Input
                    name="supplier_registration_url"
                    addonBefore={intl.get('supplierRegistration')}
                    placeholder={intl.get('supplierRegistration')}
                  />
                </Form.Item>
              </>
            )}
            <SubmitButton disabled={false}>{intl.get('save')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </Modal>
  )
})
