import React from 'react'
import { Map as LeafletMap, Marker, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

import icon from 'leaflet/dist/images/marker-icon.png'

let DefaultIcon = L.icon({
  iconUrl: icon,
  iconSize: [24, 36],
})

L.Marker.prototype.options.icon = DefaultIcon
export default function ({ lat, lng, text }) {
  return (
    <LeafletMap
      center={{ lat, lng }}
      zoom={10}
      style={{ height: 150, width: 250 }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      />
      <Marker position={{ lat, lng }}>
        {/*<Popup>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </Popup>*/}
      </Marker>
    </LeafletMap>
  )
}
