import React, { useContext, useEffect } from 'react'
import { Table } from 'antd'
import { observer } from 'mobx-react'
import StoreContext from '../../storeContext'
import ShowHideCell from '../GeneralCriteria/ShowHideCell'
import ActionButton from '../shared/ActionButton'
import { Link } from 'react-router-dom'
import { RoleGroups, Roles } from '../../utils/roles'
import intl from 'react-intl-universal'

export default observer(function () {
  const { companyProfile, user } = useContext(StoreContext)
  useEffect(() => {
    companyProfile.preferredSupplierList(user.user.company_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const columns = [
    {
      title: intl.get('sellerLabel'),
      dataIndex: 'supplier_name',
      show: true,
      render: (text, record) => (
        <Link to={`/dashboard/company/${record.supplier_id}`}>{text}</Link>
      ),
    },
    {
      title: intl.get('visible'),
      show: RoleGroups.ADMINS.includes(user.user['custom:role_id']),
      render: (text, record) => (
        <ShowHideCell
          visible={record.visible}
          callback={(check) => {
            if (user.user['custom:role_id'] !== Roles.CLIENT_ADMIN) return
            companyProfile.editPreferredSupplier({
              edit: {
                visible: check,
                preferred_supplier_status_id:
                  record.preferred_supplier_status_id,
              },
              companies: [record.buyer_id, record.supplier_id],
            })
          }}
        />
      ),
    },
    {
      title: intl.get('internalNote'),
      show: true,
      dataIndex: 'internal_note',
    },
    {
      title: intl.get('usersTableColumnActions'),
      show: RoleGroups.ADMINS.includes(user.user['custom:role_id']),
      render: (text, record) => (
        <ActionButton
          text={intl.get('delete')}
          onClick={() => {
            if (user.user['custom:role_id'] !== Roles.CLIENT_ADMIN) return
            return companyProfile.editPreferredSupplier(
              {
                remove: {
                  preferred_supplier_status_id:
                    record.preferred_supplier_status_id,
                },
                companies: [record.buyer_id, record.supplier_id],
              },
              'delete',
            )
          }}
        />
      ),
    },
  ]
  return (
    <Table
      columns={columns.filter((c) => c.show)}
      dataSource={companyProfile.preferredSuppliers}
    />
  )
})
