import React, { useContext, useState } from "react";
import { Button, message, Modal, Upload } from "antd";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import intl from "react-intl-universal";
import StoreContext from "../../storeContext";
import { Loading } from "../loading";
import { UserProfileStoreCalls } from "./store/UserProfileStore";

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export default function ({ close, visible }) {
  const [imagePreview, setImagePreview] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const { profile } = useContext(StoreContext)
  const uploadButton = (
    <div>
      {/*{this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}*/}
      <div className="ant-upload-text">{intl.get('selectImageText')}</div>
    </div>
  )
  const handleChange = (info) => {
    if (info.file.type !== 'image/jpeg' || info.file.size / 1024 / 1024 > 2) {
      message.error(intl.get('uploadImageErrorNotValid'))
      return false
    }
    setSelectedImage(info.file)
    getBase64(info.file, (image) => setImagePreview(image))
  }
  const handleOnCancel = () => {
    setImagePreview(null)
    setSelectedImage(null)
    close()
  }

  const handleBeforeUpload = (file) => {
    return false
  }

  return (
    <Modal
      title="Upload image"
      visible={visible}
      footer={null}
      onCancel={handleOnCancel}
    >
      <div className="upload-modal-wrapper">
        <Upload
          name="avatar"
          listType="picture-card"
          showUploadList={false}
          beforeUpload={handleBeforeUpload}
          onChange={handleChange}
        >
          {imagePreview ? (
            <img src={imagePreview} alt="avatar" style={{ width: '100%' }} />
          ) : (
            uploadButton
          )}
        </Upload>
        <div>
          <Loading blockers={[UserProfileStoreCalls.UPDATE_PROFILE]}>
            <Loading.InProgress>
              <Button type="primary" disabled={true}>
                <LoadingOutlined />{intl.get('uploading')}
              </Button>
            </Loading.InProgress>
            <Loading.Done>
              <Button
                disabled={selectedImage === null}
                onClick={async () => {
                  await profile.updateProfile(
                    { profile_image: imagePreview.split(',')[1] },
                    handleOnCancel,
                  )
                  setTimeout(()=>{
                    profile.imagesRefreshers[profile.profile.user_id] = new Date().getTime()
                  }, 2000)
                }}
                type="primary"
              >
                {intl.get('upload')}
              </Button>
            </Loading.Done>
          </Loading>
          <Button onClick={handleOnCancel} type="secondary">
            {intl.get('cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
