import React, { useState } from 'react'
import csv from 'csvtojson'
import { Button, Popover, Upload, message } from 'antd'
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined'
import { UploadOutlined } from '@ant-design/icons'
import intl from 'react-intl-universal'
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'
import './style.css'

export default function ({
  callback,
  csvExampleFile,
  csvExplanationText,
  buttonText,
}) {
  const [fileList, setFileList] = useState([])
  const [uploading, setUploading] = useState(false)
  function onRemoveFile(file) {
    setFileList([])
  }

  function onFileChange(file) {
    if (!file.name.includes('.csv')) return false
    setFileList([file])
    return false
  }

  function handleUpload() {
    const reader = new FileReader()
    reader.onload = () => {
      csv({
        checkColumn: true,
        output: 'json',
      })
        .setEncoding('utf-8')
        .fromString(reader.result)
        .then(
          (csvRow) => {
            if (csvRow.length === 0) return
            setUploading(true)
            callback(csvRow).then(() => {
              setFileList([])
              setUploading(false)
            })
          },
          (err) => {
            message.error(intl.get('notValidCsvFormat'))
          },
        )
    }

    reader.readAsText(fileList[0], 'utf-8')
  }

  return (
    <div className="bulk-invite-wrapper">
      {csvExplanationText && (
        <Popover className="bulk-explanation" content={csvExplanationText}>
          <QuestionCircleOutlined />
        </Popover>
      )}
      {csvExampleFile && (
        <a href={csvExampleFile} download="example.csv">
          Download CSV example file
        </a>
      )}
      <Upload
        onRemove={onRemoveFile}
        beforeUpload={onFileChange}
        fileList={fileList}
        className="upload"
      >
        <Button>
          <UploadOutlined /> {intl.get('uploadCSVButtonText')}
        </Button>
      </Upload>
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={fileList.length === 0 || uploading}
        style={{ marginTop: 16 }}
      >
        {buttonText} {uploading && <LoadingOutlined />}
      </Button>
    </div>
  )
}
