import React, { useContext, useEffect, useState } from 'react'
import { Modal, Tabs } from 'antd'
import { observer } from 'mobx-react'
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined'
import StoreContext from '../../storeContext'
import AddList from './AddList'
import CompanyList from './CompanyList'
import intl from 'react-intl-universal'
import { CompanyListsCalls } from './store/CompanyListsStore'
import { action } from 'mobx'

const { confirm, info } = Modal

export default observer(function () {
  const { lists, user, network } = useContext(StoreContext)
  const [showAdd, setShowAdd] = useState(false)
  useEffect(() => {
    lists.getCompanyList()
    return action(() => {
      lists.lists = []
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onEdit = async (targetKey, action) => {
    if (action === 'add') {
      setShowAdd(true)
    } else {
      lists.lists.find((l) => l.company_list_id === targetKey).personal
        ? info({
            title: intl.get('listNoDelete'),
          })
        : confirm({
            title: intl.get('deleteRateCardNotification'),
            icon: <ExclamationCircleOutlined />,
            onOk: async () => {
              await lists.companyList(
                {
                  remove: {
                    company_list_id: targetKey,
                  },
                  company_id: user.user.company_id,
                },
                'remove',
              )
            },
          })
    }
  }
  return (
    <>
      <Tabs type="editable-card" onEdit={onEdit}>
        {lists.lists.map((list) => (
          <Tabs.TabPane
            disabled={network.checkRunning(CompanyListsCalls.COMPANY_LIST)}
            tab={list.list_name}
            key={list.company_list_id}
          >
            <CompanyList list={list} />
          </Tabs.TabPane>
        ))}
      </Tabs>
      <AddList visible={showAdd} handleOnCancel={() => setShowAdd(false)} />
    </>
  )
})
