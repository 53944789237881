import axios from 'axios'

export const http = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  headers: { 'Content-Type': 'application/json' },
  validateStatus: false,
  transformRequest: [
    function (data, headers) {
      headers['Authorization'] = localStorage.getItem('token')
        ? localStorage.getItem('token')
        : ''
      /*if (oc(data, 'axios', false)) {
        data.axios = {}
      }*/
      return JSON.stringify(data)
    },
  ],
  transformResponse: [
    (data, headers) => {
      if (headers.accesstoken) {
        localStorage.setItem('token', headers.accesstoken)
      }

      let result
      try {
        result = JSON.parse(data)
      } catch (e) {
        result = {}
      }
      return result
    },
  ],
})
