import React from 'react'

export default function ({ record }) {
  return record.cmptcs ? (
    <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
      <span>
        Assessment date:{' '}
        {record.cmptcs.creditLimitRecommendation.assessmentDate}
      </span>
      <span>
        Average recommendation limit:
        {record.cmptcs.creditLimitRecommendation.comment.description
          ? `${record.cmptcs.creditLimitRecommendation.comment.description}`
          : ' No data'}
      </span>
      <span>
        Average recommendation limit:
        {record.cmptcs.creditLimitRecommendation.averageRecommendedLimit.value
          ? `${record.cmptcs.creditLimitRecommendation.averageRecommendedLimit.value} ${record.cmptcs.creditLimitRecommendation.averageRecommendedLimit.currency}`
          : ' No data'}
      </span>
      <span>
        Maximum recommendation limit:
        {record.cmptcs.creditLimitRecommendation.maximumRecommendedLimit.value
          ? `${record.cmptcs.creditLimitRecommendation.maximumRecommendedLimit.value} ${record.cmptcs.creditLimitRecommendation.maximumRecommendedLimit.currency}`
          : ' No data'}
      </span>
    </div>
  ) : (
    'No data'
  )
}
