import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router";
import "./index.css";

import * as serviceWorker from "./serviceWorker";
import StoreContext, { history, rootStore } from "./storeContext";
import App from "./App";

ReactDOM.render(
    <React.StrictMode>
        <StoreContext.Provider value={rootStore}>
            <Router history={history}>
              <App />
            </Router>
        </StoreContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
