import { Avatar, Button, List, Modal, Space, Typography } from 'antd'
import moment from 'moment'
import React, { useContext } from 'react'
import StoreContext from '../../storeContext'
import intl from 'react-intl-universal'
const { Text } = Typography
export default function GenericRequest({
  avatar,
  title,
  secondary = '',
  actions = [],
  date,
  id,
  extra,
}) {
  const { requests } = useContext(StoreContext)
  const confirm = () => {
    Modal.confirm({
      title: intl.get('archiveRequest'),
      content: intl.get('archiveRequestQuestion'),
      onOk: () =>
        requests.archiveRequest({
          change_status: 'archived',
          user_request_id: id,
        }),
    })
  }
  const resend = () => {
    Modal.confirm({
      title: intl.get('resendRequest'),
      content: intl.get('resendRequestQuestion'),
      onOk: () =>
        requests.archiveRequest({
          resend_request: true,
          user_request_id: id,
        }),
    })
  }
  actions.push(
    <Button
      style={{ margin: 0, padding: 0 }}
      type="link"
      key="archive"
      onClick={resend}
    >
      {intl.get('resend')}
    </Button>,
  )
  if (extra)
    actions.push(
      <Button
        style={{ margin: 0, padding: 0 }}
        type="link"
        key="archive"
        onClick={confirm}
      >
        {intl.get('archive')}
      </Button>,
    )
  return (
    <List.Item actions={actions}>
      <List.Item.Meta
        avatar={<Avatar>{avatar}</Avatar>}
        title={<Text type="secondary">{title}</Text>}
        description={
          <Space>
            {secondary !== '' && <Text type="secondary">{secondary}</Text>}
            <Text type="secondary">{`Requested at:  ${moment
              .utc(date)
              .local()
              .format('MM/DD/YYYY, HH:mm:ss')}`}</Text>
          </Space>
        }
      />
    </List.Item>
  )
}
