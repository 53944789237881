import { Divider, Modal, Space, Statistic, Typography } from "antd";
import moment from "moment";
import Rating from "../../Rating";
import React from "react";
import CyberDetails from "./CyberDetails";

const { Paragraph } = Typography
export default function ({ showPreview, setShowPreview, cyber }) {
  return (
    <Modal
      title="CyberIT"
      visible={showPreview}
      footer={null}
      destroyOnClose={true}
      onCancel={() => setShowPreview(false)}
    >
      <Space style={{ width: '100%' }}  direction={'vertical'}>
        <Statistic
          title="Date"
          value={
            cyber.rating_date && cyber.rating_date !== -1
              ? moment(cyber.rating_date).format('DD MMMM YYYY')
              : 'N/A'
          }
        />
        <Divider type="horizontal" />
        <Statistic
          title="Cyber risk rating"
          valueRender={() => <Rating score={cyber.rating} />}
        />

        <Divider type="horizontal" />
        <Typography>
          <Paragraph>
            Security ratings are a data-driven, dynamic measurement of an
            organization’s cybersecurity performance. BitSight Security Ratings
            are the most widely used ratings solution on the market.
          </Paragraph>
          <Paragraph>
            BitSight ratings are the only security ratings proven to correlate
            with risk of data breach. The rating features your overall security
            rating and shows you how you benchmark against your peers in 20
            major risk categories, including:
          </Paragraph>
          <Paragraph>
            <ul>
              <li>
                <span>Botnet infections</span>
              </li>
              <li>
                <span>Spam propagation</span>
              </li>
              <li>
                <span>Malware servers</span>
              </li>
              <li>
                <span>Open ports</span>
              </li>
              <li>
                <span>Patching cadence</span>
              </li>
              <li>
                <span>File sharing</span>
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            The Public Edition of the Basel AML Index 2020 covers 141 countries
            with sufficient data to calculate a reliable ML/TF risk score.
          </Paragraph>
        </Typography>
        <CyberDetails details={cyber.details} />
      </Space>
    </Modal>
  )
}
