import React, { useContext, useEffect, useState } from 'react'
import ComparedCompany from './ComparedCompany'
import StoreContext from '../../storeContext'
import { observer } from 'mobx-react'
import BenchmarkCompare from './ComparedCompany/BenchmarkCompare'
import PriceSelect from './PriceSelect'
import { Modal, Button } from 'antd'
import AddCompanies from './AddCompanies'
import { PrintContext } from '../../App'
import { useReactToPrint } from 'react-to-print'
import TeamTable from './TeamTable'
import { PrinterOutlined } from '@ant-design/icons'

export default observer(function () {
  const { compare } = useContext(StoreContext)
  const [show, setShow] = useState(!compare.benchmark)
  const { printRef } = useContext(PrintContext)
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })
  useEffect(() => {
    compare.getRateCards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div ref={printRef}>
      <div className="compare-grid">
        <div
          style={{
            display: 'flex',
            padding: '20px 10px',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Button
            type="ghost"
            style={{ width: '100%', backgroundColor: 'white' }}
            onClick={() => printRef.current && handlePrint()}
          >
            <PrinterOutlined />
            Print
          </Button>
          <Button
            style={{ width: '100%' }}
            onClick={() => setShow(true)}
            type="primary"
          >
            Filter compare
          </Button>
          <AddCompanies />
        </div>
        <div className="compare-row attributes-row">
          <div className="risk-title">
            <h2>Attributes</h2>
          </div>
        </div>
        <div className="compare-row risk-row">
          <div className="risk-title">
            <h2>Risk</h2>
          </div>
        </div>
        <div className="compare-row price-row">
          <div className="risk-title">
            <h2>Price</h2>
          </div>
        </div>
        {/*<div className="compare-row capabilities-row">
        <div className="risk-title">
          <h1>Capabilities</h1>
          <span>Matching schema</span>
        </div>
      </div>*/}
        <div className="compare-row my-row">
          <div className="risk-title">
            <h2>My view</h2>
          </div>
        </div>
        {compare.companies.map((comp, index) => (
          <ComparedCompany company={comp} ind={index + 1} />
        ))}
        <BenchmarkCompare />
        <Modal
          onCancel={() => setShow(false)}
          footer={null}
          visible={show}
          destroyOnClose={true}
          title="Filter compare"
        >
          <PriceSelect close={() => setShow(false)} />
        </Modal>
      </div>
      <TeamTable />
    </div>
  )
})
