import React from 'react'
import { Avatar } from 'antd'

export default function ({ score, trafficLight = false, riskClass = false }) {
  const calculateScoreColor = () => {
    if (riskClass) return 'rgb(204 204 204)'
    switch (score) {
      case 'low':
        return 'rgb(0,176,80)'
      case 'moderate':
        return 'rgb(255, 192, 0)'
      case 'high':
        return 'rgb(255, 0, 0)'
      case 'severe':
        return 'rgb(169, 0, 3)'
      default:
        return 'grey'
    }
  }
  if (trafficLight)
    return (
      <div
        style={{
          padding: '2px',
          borderRadius: '50%',
          border: `1px solid ${calculateScoreColor()}`,
        }}
      >
        <Avatar
          style={{
            backgroundColor: calculateScoreColor(),
            verticalAlign: 'middle',
            textTransform: 'uppercase',
          }}
          size="small"
        >
          {score ? score.charAt(0) : 'N'}
        </Avatar>
      </div>
    )
  return (
    <div
      style={{
        padding: '2px',
        borderRadius: '45px',
        border: `1px solid ${calculateScoreColor()}`,
      }}
    >
      <Avatar
        style={{
          backgroundColor: calculateScoreColor(),
          verticalAlign: 'middle',
          width: '100%',
          height: '30px',
          textTransform: 'uppercase',
          borderRadius: '45px',
          display: 'flex',
          alignItems: 'center',
        }}
        shape="square"
        size="large"
      >
        {score || <span>N/A</span>}
      </Avatar>
    </div>
  )
}
