import React, { useContext, useEffect, useState } from 'react'
import { Modal, Table } from 'antd'
import StoreContext from '../../../../storeContext'
import EditProfileField from '../../../Profile/EditProfileField'
import { Form, Select, Input } from 'formik-antd'
import intl from 'react-intl-universal'
import ActionButton from '../../../shared/ActionButton'
import EditFileCell from '../../../shared/EditFileCell'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { Link } from 'react-router-dom'
import { ProductGroup } from '../../../../constants/dropdowns'
import { DeleteFile } from '../../../Products/Edit'

export default observer(function ({ visible, handleOnCancel }) {
  const { companyProfile } = useContext(StoreContext)
  const [partners, setPartners] = useState([])
  useEffect(() => {
    if (companyProfile.partners.length > 0)
      setPartners(toJS(companyProfile.partners))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProfile.partners])
  useEffect(() => {
    companyProfile.getImplementationPartners()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const columns = [
    {
      title: 'Product name',
      dataIndex: 'product_name',
      width: 300,
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="product_name"
          callback={async (value, setEdit) => {
            await companyProfile.editProduct(
              {
                edit: {
                  ...record,
                  ...value,
                  product_id: record.product_id,
                },
                company_id: companyProfile.company.company_id,
              },
              'edit',
            )
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: 'Product url',
      width: 300,
      dataIndex: 'product_url',
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="product_url"
          callback={async (value, setEdit) => {
            await companyProfile.editProduct(
              {
                edit: {
                  ...record,
                  ...value,
                  product_id: record.product_id,
                },
                company_id: companyProfile.company.company_id,
              },
              'edit',
            )
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: 'Implementation partners',
      width: 500,
      render: (text, record) => (
        <EditProfileField
          value={record.implementation_partners || []}
          valueLabel={
            record.implementation_partners
              ? companyProfile.partners
                  .filter((partner) =>
                    record.implementation_partners.includes(partner.company_id),
                  )
                  .map((partner) => partner.name)
                  .join(', ')
              : 'No data'
          }
          name="implementation_partners"
          component={() => {
            return (
              <Select
                name="implementation_partners"
                mode="multiple"
                notFoundContent={
                  <Link to={'/dashboard/invite-company'}>Invite company</Link>
                }
                filterOption={false}
                onSearch={(txt) =>
                  setPartners(
                    companyProfile.partners.filter((partner) =>
                      partner.name.toLowerCase().includes(txt.toLowerCase()),
                    ),
                  )
                }
                placeholder="Select implementation partners"
              >
                {partners.map((partner) => (
                  <Select.Option value={partner.company_id}>
                    {partner.name}
                  </Select.Option>
                ))}
              </Select>
            )
          }}
          callback={async (value, setEdit) => {
            await companyProfile.editProduct(
              {
                edit: {
                  ...value,
                  product_id: record.product_id,
                },
                company_id: companyProfile.company.company_id,
              },
              'edit',
            )
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: 'Product group',
      width: 200,
      dataIndex: 'product_group',
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="product_group"
          component={() => {
            return (
              <Form.Item name="product_group">
                <Select
                  name="product_group"
                  addonBefore="Product group"
                  placeholder="Select product group"
                >
                  {ProductGroup.map((group) => (
                    <Select.Option value={group.value}>
                      {group.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )
          }}
          callback={async (value, setEdit) => {
            await companyProfile.editProduct(
              {
                edit: {
                  ...record,
                  ...value,
                  product_id: record.product_id,
                },
                company_id: companyProfile.company.company_id,
              },
              'edit',
            )
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: 400,
      render: (text, record) => (
        <EditProfileField
          value={text}
          name="description"
          component={() => {
            return (
              <Form.Item name="description">
                <Input.TextArea name="description" />
              </Form.Item>
            )
          }}
          callback={async (value, setEdit) => {
            await companyProfile.editProduct(
              {
                edit: {
                  ...record,
                  ...value,
                  product_id: record.product_id,
                },
                company_id: companyProfile.company.company_id,
              },
              'edit',
            )
            setEdit(false)
          }}
          type="cell"
        />
      ),
    },
    {
      title: 'Image',
      width: 400,
      render: (text, record) => (
        <>
          <EditFileCell
            url={record.logo_url}
            urlTitle={
              record.logo_file_name &&
              record.logo_file_name.split('/')[
                record.logo_file_name.split('/').length - 1
              ]
            }
            error="File is not image"
            sizeLimit={2}
            extensions={['image/png', 'image/jpeg']}
            selectFileTitle="Select product image"
            callback={(file, product_file_name) => {
              return companyProfile.editProduct(
                {
                  addfile: {
                    product_file: file,
                    upload_type: 'logo',
                    product_id: record.product_id,
                    product_file_name,
                  },
                  company_id: companyProfile.company.company_id,
                },
                'addfile',
              )
            }}
          />
          {record.logo_file_name && (
            <DeleteFile
              fileType="logo"
              productId={record.product_id}
              productFileName={record.logo_file_name}
              companyId={companyProfile.company.company_id}
            />
          )}
        </>
      ),
    },
    {
      title: 'Product presentation',
      width: 400,
      render: (text, record) => (
        <>
          <EditFileCell
            url={record.presentation_url}
            urlTitle={
              record.presentation_file_name &&
              record.presentation_file_name.split('/')[
                record.presentation_file_name.split('/').length - 1
              ]
            }
            error="File is not pdf"
            sizeLimit={10}
            extensions={['application/pdf']}
            selectFileTitle="Select product presentation"
            callback={(file, product_file_name) => {
              return companyProfile.editProduct(
                {
                  addfile: {
                    product_file: file,
                    upload_type: 'presentation',
                    product_id: record.product_id,
                    product_file_name,
                  },
                  company_id: companyProfile.company.company_id,
                },
                'addfile',
              )
            }}
          />
          {record.presentation_file_name && (
            <DeleteFile
              fileType="presentation"
              productId={record.product_id}
              productFileName={record.presentation_file_name}
              companyId={companyProfile.company.company_id}
            />
          )}
        </>
      ),
    },
    {
      title: 'Product reference installations',
      width: 400,
      render: (text, record) => (
        <>
          <EditFileCell
            url={record.reference_installations_url}
            urlTitle={
              record.reference_installations_file_name &&
              record.reference_installations_file_name.split('/')[
                record.reference_installations_file_name.split('/').length - 1
              ]
            }
            error="File is not pdf"
            sizeLimit={10}
            extensions={['application/pdf']}
            selectFileTitle="Select reference installations"
            callback={(file, product_file_name) => {
              return companyProfile.editProduct(
                {
                  addfile: {
                    product_file: file,
                    upload_type: 'reference_installations',
                    product_id: record.product_id,
                    product_file_name,
                  },
                  company_id: companyProfile.company.company_id,
                },
                'addfile',
              )
            }}
          />
          {record.reference_installations_file_name && (
            <DeleteFile
              fileType="reference_installations"
              productId={record.product_id}
              productFileName={record.reference_installations_file_name}
              companyId={companyProfile.company.company_id}
            />
          )}
        </>
      ),
    },
    {
      title: 'Product roadmap',
      width: 400,
      render: (text, record) => (
        <>
          <EditFileCell
            url={record.roadmap_url}
            urlTitle={
              record.roadmap_file_name &&
              record.roadmap_file_name.split('/')[
                record.roadmap_file_name.split('/').length - 1
              ]
            }
            error="File is not pdf"
            sizeLimit={10}
            extensions={['application/pdf']}
            selectFileTitle="Select product roadmap"
            callback={(file, product_file_name) => {
              return companyProfile.editProduct(
                {
                  addfile: {
                    product_file: file,
                    upload_type: 'roadmap',
                    product_id: record.product_id,
                    product_file_name,
                  },
                  company_id: companyProfile.company.company_id,
                },
                'addfile',
              )
            }}
          />
          {record.reference_installations_file_name && (
            <DeleteFile
              fileType="roadmap"
              productId={record.product_id}
              productFileName={record.roadmap_file_name}
              companyId={companyProfile.company.company_id}
            />
          )}
        </>
      ),
    },
    {
      title: 'Product price list',
      width: 400,
      render: (text, record) => (
        <>
          <EditFileCell
            url={record.price_list_url}
            urlTitle={
              record.price_list_file_name &&
              record.price_list_file_name.split('/')[
                record.price_list_file_name.split('/').length - 1
              ]
            }
            error="File is not pdf"
            sizeLimit={10}
            extensions={['application/pdf']}
            selectFileTitle="Select price list"
            callback={(file, product_file_name) => {
              return companyProfile.editProduct(
                {
                  addfile: {
                    product_file: file,
                    upload_type: 'price_list',
                    product_id: record.product_id,
                    product_file_name,
                  },
                  company_id: companyProfile.company.company_id,
                },
                'addfile',
              )
            }}
          />
          {record.price_list_file_name && (
            <DeleteFile
              fileType="price_list"
              productId={record.product_id}
              productFileName={record.price_list_file_name}
              companyId={companyProfile.company.company_id}
            />
          )}
        </>
      ),
    },
    {
      title: 'Action',
      render: (text, record) => (
        <ActionButton
          record={record}
          text={intl.get('delete')}
          showModal={true}
          confirmTitle="You are going to delete product, are you sure ?"
          onClick={() =>
            companyProfile.editProduct(
              {
                remove: {
                  product_id: record.product_id,
                },
                company_id: companyProfile.company.company_id,
              },
              'remove',
            )
          }
        />
      ),
    },
  ]
  return (
    <Modal
      width="80%"
      title="Update products"
      destroyOnClose={true}
      visible={visible}
      onCancel={handleOnCancel}
      footer={null}
    >
      <Table
        scroll={{ x: 3500 }}
        bordered={true}
        dataSource={companyProfile.company.products}
        columns={columns}
      />
    </Modal>
  )
})
