import React, { useContext } from 'react'
import { Table, Button, Modal } from 'antd'
import StoreContext from '../../storeContext'
import { observer } from 'mobx-react'
import ActionButton from '../shared/ActionButton'
import { Link } from 'react-router-dom'
import intl from 'react-intl-universal'
import { DeleteOutlined } from '@ant-design/icons'

const DeleteNote = ({ noteId, companyId, listId, note, type }) => {
  const { companyProfile, lists } = useContext(StoreContext)
  const del = () => {
    Modal.confirm({
      title: intl.get('deletePersonalNote'),
      okText: intl.get('delete'),
      onOk: async () => {
        await companyProfile[type]({
          remove: { note_id: noteId },
          company_id: companyId,
        })
        await lists.getListCompanies(listId)
      },
    })
  }
  return (
    <div style={{ display: 'flex', alignItems: 'baseline' }}>
      {note}
      <Button style={{ margin: '0 10px' }} onClick={del}>
        <DeleteOutlined />
      </Button>
    </div>
  )
}

export default observer(function ({ list }) {
  const { lists, user } = useContext(StoreContext)
  const columns = [
    {
      title: intl.get('companyNameLabel'),
      dataIndex: 'name',
      width: 200,
      render: (text, record) => (
        <Link to={`/dashboard/company/${record.company_id}`}>{text}</Link>
      ),
    },
    {
      title: intl.get('columnCompanyCountry'),
      dataIndex: 'country',
      width: 100,
    },
    {
      title: intl.get('city'),
      dataIndex: 'city',
      width: 150,
    },
    {
      title: intl.get('rateCards'),
      dataIndex: 'has_rate_card',
      render: (text, record) =>
        record.has_rate_card ? intl.get('yes') : intl.get('no'),
      width: 150,
    },
    {
      title: intl.get('personalNotes'),
      width: 200,
      hide: !list.personal,
      dataIndex: 'personal_note',
      render: (text, record) => (
        <DeleteNote
          companyId={record.company_id}
          noteId={record.note_id}
          listId={list.company_list_id}
          note={record.personal_note}
          type={
            list.list_name === 'Internal notes'
              ? 'internalNote'
              : 'personalNote'
          }
        />
      ),
    },
    {
      title: 'Action',
      width: 200,
      hide: list.personal,
      render: (text, record) => (
        <ActionButton
          text={intl.get('delete')}
          confirmTitle={intl.get('removeCompanyFromListNotification')}
          showModal={true}
          onClick={async () =>
            await lists.companyList(
              {
                edit: {
                  companies_remove: [record.company_id],
                  company_list_id: list.company_list_id,
                },
                company_id: user.user.company_id,
              },
              'removeCompany',
            )
          }
        />
      ),
    },
  ]
  return (
    <Table
      bordered={true}
      dataSource={list.companies}
      columns={columns.filter((column) => !column.hide)}
    />
  )
})
