import React, { useContext } from 'react'
import { Card, Col, Row } from 'antd'
import intl from 'react-intl-universal'
import { Form, Input, Select, SubmitButton } from 'formik-antd'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { ValidationFields } from '../../utils/validationFields'
import StoreContext from '../../storeContext'
import { Countries } from '../../constants/dropdowns'

export default function () {
  const { companiesImport } = useContext(StoreContext)
  const initialValues = {
    company_country: '',
    contact_email: '',
    company_name: '',
  }
  const validator = Yup.object({
    company_country: ValidationFields.country(),
    contact_email: ValidationFields.email(),
    company_name: ValidationFields.companyName(),
  })
  return (
    <Row>
      <Col span={24}>
        <Card title={intl.get('inviteCompany')}>
          <Formik
            initialValues={initialValues}
            validationSchema={validator}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              await companiesImport.companyInvite(values)
              setSubmitting(false)
              resetForm()
            }}
          >
            {() => (
              <Form>
                <Form.Item name="contact_email">
                  <Input
                    name="contact_email"
                    placeholder={intl.get('emailLabel')}
                  />
                </Form.Item>
                <Form.Item name="company_name">
                  <Input
                    name="company_name"
                    placeholder={intl.get('companyNameLabel')}
                  />
                </Form.Item>
                <Form.Item name="company_country">
                  <Select
                    name="company_country"
                    placeholder={intl.get('selectCountryLabel')}
                  >
                    {Countries.map((country) => (
                      <Select.Option value={country.value}>
                        {country.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <div className="ant-row">
                  <SubmitButton>{intl.get('sendInvite')}</SubmitButton>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </Col>
    </Row>
  )
}
