import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import StoreContext from '../../storeContext'
import { Modal } from 'antd'
import { Formik } from 'formik'
import { Form, Select, SubmitButton } from 'formik-antd'
import { action } from 'mobx'
import * as Yup from 'yup'
import intl from 'react-intl-universal'

export default observer(function ({ visible, handleOnCancel }) {
  const { lists, user, search } = useContext(StoreContext)
  useEffect(() => {
    lists.getCompanyList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const validation = Yup.object().shape({
    edit: Yup.object().shape({
      company_list_id: Yup.string().required(intl.get('listRequired')),
    }),
  })
  const initialValues = {
    edit: {
      companies_add: search.selectedCompanies,
      company_list_id: '',
    },
    company_id: user.user.company_id,
  }

  return (
    <Modal
      destroyOnClose={true}
      title={intl.get('addCompanyToList')}
      footer={null}
      visible={visible}
      onCancel={handleOnCancel}
    >
      <Formik
        validationSchema={validation}
        initialValues={initialValues}
        onSubmit={async (vals, { setSubmitting }) => {
          await lists.companyList(vals)
          action(() => {
            search.selectedCompanies = []
          })()
          setSubmitting(false)
          handleOnCancel()
        }}
      >
        {() => (
          <Form>
            <Form.Item name="edit.company_list_id">
              <Select
                name="edit.company_list_id"
                placeholder={intl.get('selectList')}
              >
                {lists.lists.map((list) => (
                  <Select.Option value={list.company_list_id}>
                    {list.list_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <SubmitButton>{intl.get('add')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </Modal>
  )
})
